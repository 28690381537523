import React, { useState, useEffect } from "react";
import NoticiaService from "../../services/noticia.service";
import UsuarioService from "../../services/usuario.service";
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';//se instala DOMPurify con npm y se importa
import Swal from 'sweetalert2'
import http from "../../http-common.js";
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { Helmet } from 'react-helmet';

const Noticia = () => {
    const params = useParams();
    const [datos, setDatos] = useState([]);
    const [imagen, setImagen] = useState("");

    useEffect(() => {
        NoticiaService.get(parseInt(params.id))
            .then((response) => {
                setDatos(response.data);
                
                http.get('/s3url2/' + response.data.linkImagen).then(response => {
                    document.getElementById('imgNoticiaDetalle').src = response.data;
                    setImagen(response.data);
                }).catch(error => { console.log(error); });

                if(response.data.linkImagenDos != ""){
                    http.get('/s3url2/' + response.data.linkImagenDos).then(response => {
                        document.getElementById('img-2').src = response.data;
                        setImagen(response.data);
                    }).catch(error => { console.log(error); });
                }

                if(response.data.linkImagenTres != ""){
                    http.get('/s3url2/' + response.data.linkImagenTres).then(response => {
                        document.getElementById('img-3').src = response.data;
                        setImagen(response.data);
                    }).catch(error => { console.log(error); });
                }                                        
            })
            .catch((error) => console.log(error));

        changeStyle();
        
    }, []);

    const datosAutor = () => {
        let x = datos.autor;
        let imagen = datos.autorImagen
        Swal.fire({
            title: x,
            text: 'Modal with a custom image.',
            imageUrl: imagen,
            html:
                '<br/><br/><a href="#!"><i class="fab fa-facebook-f"></i></a>   ' +
                '<a href="#!"><i class="fab fa-twitter"></i></a>   ' +
                '<a href="#!"><i class="fab fa-instagram"></i></a>   ' +
                '<a href="#!"><i class="fab fa-youtube"></i></a>   ' +
                '<a href="#!"><i class="fab fa-linkedin-in"></i></a>   ',
            imageWidth: 320,
            imageHeight: 320,
            imageAlt: 'Custom image',
            showConfirmButton: false,
        })
    }

    const datosAutorId = () => {
        UsuarioService.get(datos.autorId)
            .then(response => {
                let informacion = "<br/><br/>";
                if (response.data.facebook != "" && response.data.facebook != null) {
                    informacion += '<a href="' + response.data.facebook + '"><i class="fab fa-facebook-f"></i></a>   ';
                }
                if (response.data.instagram != "" && response.data.instagram != null) {
                    informacion += '<a href="' + response.data.instagram + '"><i class="fab fa-instagram"></i></a>   ';
                }
                if (response.data.twitter != "" && response.data.twitter != null) {
                    informacion += '<a href="' + response.data.twitter + '"><i class="fab fa-twitter"></i></a>   ';
                }
                if (response.data.linkedin != "" && response.data.linkedin != null) {
                    informacion += '<a href="' + response.data.linkedin + '"><i class="fab fa-linkedin-in"></i></a>   ';
                }

                let x = response.data.nombre + " " + response.data.paterno;
                http.get('/s3url2/' + response.data.imagen).then(response => {
                    Swal.fire({
                        title: x,
                        text: 'Modal with a custom image.',
                        imageUrl: response.data,
                        html: informacion,
                        imageWidth: 320,
                        imageHeight: 320,
                        imageAlt: 'Custom image',
                        showConfirmButton: false,
                    })
                }).catch(error => { console.log(error); });
            })
    }

    const changeStyle = () => {
        var top = document.getElementById('barraNavegacion');
        var footer = document.getElementById('footerNavegacion');
        var estilo = document.getElementById('estiloPrincipal');
        var estiloPlugin = document.getElementById('estiloPlugin');

        document.body.className = "";
        top.style.display = 'block';
        footer.style.display = 'block';
        estilo.href = 'assets/css/styles.css';
        estiloPlugin.href = 'assets/css/plugins.css';
    }

    const createMarkup = (html) => {
        return {
            __html: DOMPurify.sanitize(html)
        }
    }

    return (
        <>
            <Helmet>
                <title>M&eacute;xico Amparame - Blog</title>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content={datos.noticia}/>
                <meta property="og:image" content={imagen}/>

                <meta property="twitter:card" content="summary_large_image"/>
                <meta property="twitter:title" content={datos.noticia}/>
                <meta property="twitter:description" content="Blog de noticias de México Ampárame."/>
                <meta property="twitter:image" content={imagen}/>    
            </Helmet> 
            <section class="page-title-section bg-img cover-background mx-lg-4 mx-xl-6 rounded-lg" data-overlay-dark="4" style={{
                backgroundImage: `url("https://imagenescityexpress.scdn6.secure.raxcdn.com/sites/default/files/2019-06/hotel-oaxaca-city-centro%20%281%29.jpg")`
            }}>
                <div class="container">
                    <div class="row text-center">
                        <div class="col-md-12">
                            <h1 class="text-animation" data-in-effect="fadeInRight">Noticias</h1>
                            <ul>
                                <li><a><Link to={"/"}>Regresar</Link></a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="position-absolute z-index-1 right bottom-n5 opacity2 ani-left-right">
                    <span class="square bg-primary"></span>
                    <span class="square bg-secondary"></span>
                </div>
            </section>

            <section className="cause-details">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 pe-lg-2-5 pe-xl-2-9 mb-2-9 mb-lg-0">
                            <div>
                                <div class="wow fadeIn" data-wow-delay="200ms">

                                    <center><img id="imgNoticiaDetalle" class="rounded" height={"1160"} alt="..." /></center>

                                </div>
                                <br></br>
                                <div div className="mb-2-5 wow fadeIn" data-wow-delay="200ms">
                                    <div class="meta">
                                        <center><h2 className="mb-4">{datos.noticia}</h2></center>
                                        <ul class="meta ps-0">
                                            <li>
                                                <a href="#!" onClick={datosAutorId}>
                                                    <i class="fa fa-user me-1 text-secondary"></i>{datos.autor}</a>
                                            </li>
                                            <li>
                                                <a>
                                                    <i class="fa fa-calendar me-1 text-secondary"></i>{String(datos.updatedAt).substr(0, 10)}</a>
                                            </li>

                                        </ul>
                                    </div>
                                    <div>
                                        {/* <p class="mb-0">{(datos.textoNoticia)}</p> */}
                                        <div dangerouslySetInnerHTML={{ __html: datos.textoNoticia }} />
                                    </div>
                                    <div className="row mb-1-9 mb-md-2-5">
                                        <div id="img-detalles1" className="col-md-6 mb-1-6 mb-md-0">
                                            <img id="img-2" className="rounded"/>
                                        </div>
                                        <div id="img-detalles2" className="col-md-6">
                                            <img id="img-3" className="rounded"/>
                                        </div>
                                    </div>
                                    <div class="separator"></div>

                                    <div class="row g-0">
                                        <div className="mb-2-5 wow fadeIn" data-wow-delay="400ms">
                                            <br></br><br></br>
                                            <center><h6 class="mb-3 text-md-end text-start">#MexicoAmparame</h6></center>
                                            <center><h5 class="mb-3 text-md-end text-start">Compartir en:</h5></center>
                                            <div>
                                                <ul class="social-icon-style1 text-md-end text-start">

                                                    <li className="fb"><FacebookShareButton quote={datos.noticia + " leer más en: "} id="btn-fb" url={window.location.href}><a><i class="fab fa-facebook-f"></i></a></FacebookShareButton></li>

                                                    <li className="fb"><TwitterShareButton id="btn-tw" title={datos.noticia + " leer más en: "} url={window.location.href}><a><i class="fab fa-twitter"></i></a></TwitterShareButton></li>
                                                    {/*<li><a href="https://www.instagram.com/mxamparame/"><i class="fab fa-instagram"></i></a></li>*/}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )

}

export default Noticia;
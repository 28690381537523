import React, { Component } from "react";
import ReactDOM from "react-dom";
import Swal from 'sweetalert2';
import TestimonioService from "../../services/testimonios.service"
import EditarTestimonio from "./editarTestimonio.component";
import ListarTestimonio from "./listarTestimonio.component";
import http from "../../http-common.js";

export default class ListItemTestimonio extends Component {

    constructor(props) {
        super(props);
        
        this.onPicoEliminar = this.onPicoEliminar.bind(this);
        this.editarTestimonio = this.editarTestimonio.bind(this);
        this.regresarLista = this.regresarLista.bind(this);

        this.state = {
            id: this.props.id,
            testimonio: this.props.testimonio,
            autor: this.props.autor,
            ocupacion: this.props.ocupacion,
            linkImagen: this.props.linkImagen,            
        }
    }

    regresarLista(e) {
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }
        ReactDOM.render(<ListarTestimonio />, divDash);
    }    

    editarTestimonio() {
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }
        ReactDOM.render(<EditarTestimonio id={this.state.id}
                                       testimonio={this.state.testimonio}
                                       autor={this.state.autor}
                                       ocupacion={this.state.ocupacion}
                                       linkImagen={this.state.linkImagen}                                                                              
                                       />, divDash
        );
    }

    onPicoEliminar(e) {
        Swal.fire({
            text: 'Deseas eliminar este elemento?',
            icon: 'warning',
            //showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            //denyButtonText: `Don't save`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                http.delete('/s3url3/'+this.props.linkImagen);
                TestimonioService.delete(this.state.id)
                .then(response => {
                    console.log(response.data);
                    this.regresarLista();
                })
                .catch(e => {
                    console.log(e);
                });
              Swal.fire('Eliminado', 'Elemento Eliminado Correctamente', 'success')
             
            }
          })
    }
    //<td class="col-md-1 align-middle"><button class="btn btn-light" >Editar</button></td>
    render() {
        return (
            <tr key={this.state.id}>
                <td class="col-md-4 align-middle">{this.state.testimonio}</td>
                <td class="col-md-4 align-middle">{this.state.autor}</td>
                <td class="col-md-4 align-middle">{this.state.ocupacion}</td>                
                <td class="col-md-1 align-middle"><button class="btn btn-light" onClick={this.editarTestimonio}>Editar</button></td>
                <td class="col-md-1 align-middle"><button class="btn btn-danger" onClick={this.onPicoEliminar} ><i className="far fa-trash-alt"></i></button></td>
            </tr>
        )
    }

}
import React, { Component } from "react";
import NoticiaService from "../../services/noticia.service";
import { Routes, Route, Link } from 'react-router-dom'
import NoticiaGridItem from "./noticiaGridItem.component";

export default class Inicio extends Component {
    noticiasx = []
    constructor(props) {
        super(props);

        this.state = {
            arrayNoticias: []
        }
    }

    componentDidMount() {
        this.crearGridItems();
    }

    crearGridItems() {
        let arr = [];
        NoticiaService.getAllActive().then(response => {            
            
            for (let i = 0; i < 3; i++) {                
                if(i < response.data.length){
                    arr.push(<NoticiaGridItem
                        linkImagen={response.data[i].linkImagen}
                        autorImagen={response.data[i].autorImagen}
                        autor={response.data[i].autor}
                        noticia={response.data[i].noticia}
                        textoPreview={response.data[i].textoPreview}
                        updatedAt={response.data[i].updatedAt}
                        id={response.data[i].id}
                        autorId={response.data[i].autorId}
                    />);                             
                }                
            }

            this.setState({
                arrayNoticias: arr
            })
        });
    }

    render() {
        return (
            <>
                {this.state.arrayNoticias}
            </>
        )
        
    }
}
import { Component } from "react";
import TestimonioService from "../../services/testimonios.service";
import OwlCarousel from "react-owl-carousel";
import http from "../../http-common";
import TestimonioInicioItem from "./testimonioInicioItem.component";

export default class TestimonioInicio extends Component {
  constructor(props) {
    super(props);

    this.getTestimonios = this.getTestimonios.bind(this);

    this.state = {
      datos: [],
    };
  }

  componentWillMount() {
    this.getTestimonios();
  }

  getTestimonios() {
    TestimonioService.getAll()
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          let wrapper = document.createElement("div");
          wrapper.className = "item";
          wrapper.key = i;

          let parrafo = document.createElement("p");
          parrafo.innerHTML = response.data[i].testimonio;
          parrafo.className =
            "mt-3 mb-5 display-28 display-md-26 display-lg-24";

          let div1 = document.createElement("div");
          div1.className = "d-flex justify-content-center align-items-center";

          let icono = document.createElement("i");
          icono.className = "ti-quote-left display-10 text-primary";

          let img = document.createElement("img");
          img.className = "rounded-circle";
          img.style.width = '200px';
          img.style.height = '200px';

          http
            .get("/s3url2/" + response.data[i].imagenPerfil)
            .then((response) => {
              img.src = response.data;
            });

          let div3 = document.createElement("div");
          div3.className = "text-start";

          let div2 = document.createElement("div");
          div2.className = "me-3";

          let autor = document.createElement("h6");
          autor.className = "mb-0";
          autor.innerHTML = response.data[i].autor;

          let ocupacion = document.createElement("span");
          ocupacion.className = "small";
          ocupacion.innerHTML = response.data[i].ocupacion;

          div3.appendChild(autor);
          div3.appendChild(ocupacion);

          div2.appendChild(img);

          div1.appendChild(div2);
          div1.appendChild(div3);
          wrapper.appendChild(parrafo);
          wrapper.appendChild(div1);

          document.getElementById("owl-d").appendChild(wrapper);
        }
        this.setState({
          datos: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <section>
        <div class="container">
          <div class="section-heading">
            <span class="d-block text-primary display-22 display-md-21 display-lg-20 alt-font wow text-animation fadeInRight">
              Testimonios
            </span>
            <h2>Lo que dicen de nosotros</h2>
          </div>
          <img
            src="/assets/img/icons/icon-7.png"
            alt=""
            class="position-absolute left-5 top-15 ani-top-bottom opacity7 d-none d-sm-block"
          />
          <img
            src="/assets/img/icons/icon-8.png"
            alt=""
            class="position-absolute right-5 top-75 ani-top-bottom opacity7"
          />
        </div>
        <center>
          <OwlCarousel
            id="owl-d"
            items={1}
            className="owl-theme"
            loop
            autoplay={true}
            autoplayTimeout={5000}
            margin={10}
          ></OwlCarousel>
        </center>
      </section>
    );
  }
}

import http from "../http-common";

class CarouselService {
    getAll() {
        return http.get("/carousel");
    }   

    get(id) {
        return http.get(`/carousel/${id}`);
    }

    create(data) {
        return http.post("/carousel/", data);
    }

    update(id, data) {
        return http.put(`/carousel/${id}`, data);
    }

    delete(id) {
        return http.delete(`/carousel/${id}`);
    }

    findTodo(){
        return http.get('/carousel/activos/');
    }
}

export default new CarouselService();
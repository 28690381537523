import React, { Component } from "react";
import ReactDOM from "react-dom";
import Swal from 'sweetalert2';
import CarouselService from "../../services/carousel.service"
import GifLoader from 'react-gif-loader';
import ListarCarousel from "./listCarousel.component";
import http from "../../http-common.js";

export default class EditarCarousel extends Component {
    constructor(props) {
        super(props);

        this.regresarLista = this.regresarLista.bind(this);
        this.onChangeTitulo = this.onChangeTitulo.bind(this);
        this.onChangeSubti = this.onChangeSubti.bind(this);
        this.onChangeEstado = this.onChangeEstado.bind(this);
        this.guardarCarousel = this.guardarCarousel.bind(this);

        this.state = {
            id: this.props.id,
            titulo: this.props.titulo,
            subtitulo: this.props.subtitulo,
            linkImagen: this.props.linkImagen,
            estatus: this.props.estatus,
            imagen: null,
            nombreImg: "",
            loading: false
        }
    }

    componentDidMount() {
        http.get('/s3url2/'+this.state.linkImagen).then(response => {document.getElementById('imgCarousel').src = response.data}).catch(error => {console.log(error);});   
    }

    onChangeTitulo(e) {
        this.setState({
            titulo: e.target.value
        });
    }

    onChangeSubti(e) {
        this.setState({
            subtitulo: e.target.value
        });
    }

    onChangeEstado(e) {
        let target = e.target;
        let estado = !this.state.estatus;        

        this.setState({ estatus: estado }, () => {
            CarouselService.update(this.state.id, this.state)
                .then(response => {
                    console.log(response.data);
                })
                .catch(e => {
                    console.log(e);
                });
        });
    }  

    onClickRecuadros(e) {
        Swal.fire({
            text: 'Deseas eliminar este elemento? no se podrá recuperar',
            icon: 'warning',
            //showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            //denyButtonText: `Don't save`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                CarouselService.get(this.state.id).then(response =>{                    
                    http.delete('/s3url3/'+response.data.linkImagen)
                }).catch(error => { console.log(error); })                                           
            }
          })
    }

    guardarCarousel =(e) => {
        var data = {
            titulo: this.state.titulo,
            subtitulo: this.state.subtitulo,
            linkImagen: this.state.linkImagen,
            estatus: this.state.estatus
        };                     
        if (this.state.titulo && this.state.subtitulo) {
            console.log(data);
            CarouselService.update(this.state.id, data)
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        text: "Se modificaron los datos."
                    })
                    this.regresarLista();
                })
                .catch(e => {
                    console.log(e);
                    Swal.fire({
                        icon: 'error',
                        text: "Hubo un error en la solicitud."
                    })
                });
        }
        else {
            Swal.fire({
                icon: 'warning',
                text: "Hay campos requeridos vacios."
            })
            console.log("error");
        }

    }

    regresarLista(e) {
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }
        ReactDOM.render(<ListarCarousel />, divDash);
    }

    nameHandler(e) {
        this.setState({ imagen: e.target.files[0], nombreImg: e.target.files[0].name });
    }

    async submitFormHandler(e) {
        e.preventDefault()
        let file;
        file = this.state.imagen;

        
        if(file!==null){            
            this.setState({
                nombreImg: "",
                loading: true
            });
            let data = new FormData();
                data.append('foto', file);

                    
            await http.post('/s3Url', data).then((response) => {            
                document.getElementById('imgCarousel').src = "";
                http.get('/s3url2/'+response.data.data).then(response => {document.getElementById('imgCarousel').src = response.data}).catch(error => {console.log(error);});   
                this.setState({ linkImagen: response.data.data,
                loading: false});
            })
        }else{
            Swal.fire({
                icon: 'error',
                text: "No ha cargado un archivo aun."
            })
        }
    }


    render() {
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <h2 className="myaccount-content">Datos del Carousel</h2>
                    </div>
                </div>

                <form class="row g-2 needs-validation" novalidate>
                    <div class="form-group col-md-12">
                        <label for="validationCustom01" class="form-label">Titulo*</label>
                        <input type="text" class="form-control" id="validationCustom01" value={this.state.titulo} onChange={this.onChangeTitulo} required />
                        <div class="valid-feedback">
                            Valido
                        </div>
                    </div>
                    <div class="form-group col-md-12">
                        <label for="validationCustom02" class="form-label">Subtitulo*</label>
                        <input type="text" class="form-control" id="validationCustom02" value={this.state.subtitulo} onChange={this.onChangeSubti} required />
                        <div class="valid-feedback">
                            Valido
                        </div>
                    </div>
                </form>
                <div class="col-md-12">
                    <div class="row justify-content-between">
                        <div class="col-6">
                            <div class="row">
                                <div class="col-md-4">
                                    <label htmlFor="validationCustom02" class="form-label">Estado</label>
                                    <br></br>
                                    <label class="switch">
                                        <input type="checkbox" checked={this.state.estatus} onChange={this.onChangeEstado}/>
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className="form-group col-md-8">
                    <label className="font-weight-bold">Imagen <span className="required"> * </span> </label>
                    <GifLoader
                            loading={this.state.loading}
                            imageSrc="https://media.giphy.com/media/17mNCcKU1mJlrbXodo/giphy.gif"                        
                            overlayBackground="none"
                            imageStyle={{width: "30%", display: "block"}}
                        />
                    <div id={"recuadro0"} className="recuadro-imagen">
                        <img width="50%" id="imgCarousel"  />
                        <button className="btn-danger" onClick={e => this.onClickRecuadros(e)}><i className="far fa-trash-alt"></i></button>
                    </div>
                    <form id="imageForm" style={{ "marginTop": '10px' }} className="form-group" onSubmit={e => this.submitFormHandler(e)}>
                        
                        <div class="input-group">
                            <input id="imageInput" id="exampleInputFile" class="custom-file-input" type="file" accept="image/*" onChange={e => this.nameHandler(e)} />
                            <label class="custom-file-label" id="nombre">{this.state.nombreImg}</label>
                            <button style={{ "margin-top": '10px' }} className="btn btn-success" type="submit">Cargar</button>
                        </div>
                    </form>
                </div>
                <div >
                <div className="form-group col-sm-12 text-right">
                        <button class="btn btn-info" onClick={this.regresarLista}><i class="fas fa-undo-alt"></i> Regresar</button>
                        <>&nbsp;</>
                        <button class="btn btn-primary" onClick={this.guardarCarousel}><i class="fas fa-save"></i> Guardar</button>                                                                
                    </div>
                </div>
            </div>
        )
    }
}
import http from "../http-common";

class DonadorService {
    getAll() {
        return http.get("/donador");
    }

    getAllPaid() {
        return http.get("/donador/pagados");
    }

    get(id) {
        return http.get("/donador/" + id);
    }

    create(data) {
        return http.post("/donador", data);
    }

    update(id, data) {
        return http.put("/donador/" + id, data);
    }

    delete(id) {
        return http.delete(`/donador/${id}`);
    }

    deleteAll() {
        return http.delete("/donador");
    }

}

export default new DonadorService();

import React, { Component } from "react";
import ReactDOM from "react-dom";
import Swal from "sweetalert2";
import ProgramaService from "../../services/programa.service";
import EditarPrograma from "./editarPrograma.component";
import ListaProgramas from "./programaList.component";
import http from "../../http-common.js";
export default class ProgramaListItem extends Component {

    constructor(props) {
        super(props);

        this.onChangeMostrarInicio = this.onChangeMostrarInicio.bind(this);
        this.editarPrograma = this.editarPrograma.bind(this);
        this.onChangeActivo = this.onChangeActivo.bind(this);
        this.eliminarPrograma = this.eliminarPrograma.bind(this);


        this.state = {
            id: this.props.id,
            titulo: this.props.titulo,
            subtitulo: this.props.subtitulo,
            descripcion: this.props.descripcion.replace(/<[^>]+>/g, ' '),
            activo: this.props.activo,
            inicio: this.props.inicio,
            imagenPrincipal: this.props.imagenPrincipal,
            imagenesGaleria: this.props.imagenesGaleria,
            icono: this.props.icono,
            colorIcono: this.props.colorIcono
        }
    }

    onChangeActivo(e) {
        let target = e.target;
        let inicioActivo = this.state.inicio;

        if (!target.checked) {
            inicioActivo = false;
        }

        this.setState({ activo: target.checked, inicio: inicioActivo }, () => {
            ProgramaService.update(this.state.id, this.state)
                .then(response => {
                    //console.log(response.data);
                })
                .catch(e => {
                    console.log(e);
                });
        });
    }

    onChangeMostrarInicio(e) {
        let target = e.target;
        let value = target.checked;

        if (target.checked && this.state.activo) {
            ProgramaService.getAllHomePage()
                .then(response => {
                    if (response.data.length >= 3) {
                        Swal.fire({
                            icon: 'warning',
                            text: "Solo se permiten tres programas para mostrar en el inicio."
                        })
                    }
                    else {
                        this.setState({ inicio: value }, () => {
                            ProgramaService.update(this.state.id, this.state)
                                .then(response => {
                                    //console.log(response.data);
                                })
                                .catch(e => {
                                    console.log(e);
                                });
                        });
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        }
        else if (!target.checked) {
            this.setState({ inicio: value }, () => {
                ProgramaService.update(this.state.id, this.state)
                    .then(response => {
                        //console.log(response.data);
                    })
                    .catch(e => {
                        console.log(e);
                    });
            });
        }
        else {
            Swal.fire({
                icon: 'warning',
                text: "Necesita estar activo para mostrarse en el inicio."
            })
        }
    }

    eliminarPrograma() { 
        Swal.fire({
            text: '¿Deseas eliminar este elemento?',
            icon: 'warning',                                
            showCancelButton: true,
            confirmButtonText: 'Eliminar',                                
          }).then((result) => {                                
            if (result.isConfirmed) {
                for(let i = 0; i <this.props.imagenesGaleria.length;i++){
                    http.delete('/s3url3/'+this.props.imagenesGaleria[i]);
                }     
                http.delete('/s3url3/'+this.props.imagenPrincipal);
                ProgramaService.delete(this.props.id).then(response => {
                    let divDash = document.getElementById('contenidoTablero');
                    Swal.fire('Eliminado', 'Elemento Eliminado Correctamente', 'success')
                    if (divDash.children.length > 0) {
                        ReactDOM.unmountComponentAtNode(divDash);
                    }
                    ReactDOM.render(<ListaProgramas />, divDash);
                });
            }
            
        });

    }

    editarPrograma() {
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }

        ReactDOM.render(<EditarPrograma
            id={this.state.id}
            titulo={this.state.titulo}
            subtitulo={this.state.subtitulo}
            descripcion={this.props.descripcion}
            activo={this.state.activo}
            inicio={this.state.inicio}
            imagenPrincipal={this.state.imagenPrincipal}
            imagenesGaleria={this.state.imagenesGaleria}
            icono={this.state.icono}
            colorIcono={this.state.colorIcono} 
            indice={this.state.imagenesGaleria.length > 0 ? 0 : -1}/>, divDash);
    }

    render() {
        return (
            <tr key={this.state.id}>
                <td class="col-md-2 text-center align-middle">{this.state.titulo}</td>
                <td class="col-md-2 text-center align-middle">{this.state.subtitulo}</td>
                <td class="col-md-4 text-center align-middle">{this.state.descripcion}</td>
                <td class="col-md-2 text-center align-middle">
                    <div className="custom-control custom-switch">
                        <input type="checkbox" className="custom-control-input" onChange={this.onChangeActivo} id={"customSwitch" + this.state.id} checked={this.state.activo} />
                        <label className="custom-control-label" htmlFor={"customSwitch" + this.state.id}>{this.state.activo ? "Activo" : "Inactivo"}</label>
                    </div>
                </td>
                <td class="col-md-2 align-middle text-center">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" onChange={this.onChangeMostrarInicio} id={"flexCheckChecked" + this.state.id} checked={this.state.inicio} />
                        <label class="form-check-label" for={"flexCheckChecked" + this.state.id}></label>
                    </div>
                </td>
                <td class="col-md-4 text-center align-middle"><button class="btn btn-light" onClick={this.editarPrograma}>Editar</button></td>
                <td class="col-md-1 text-center align-middle"><button class="btn btn-danger" onClick={this.eliminarPrograma}><i className="far fa-trash-alt"></i></button></td>
            </tr>
        )
    }
} 
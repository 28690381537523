import React, { Component } from "react";
import TestimonioService from "../../services/testimonios.service";
import ReactDOM from "react-dom";
import CrearTestimonio from "./crearTestimonio.component";
import ListItemTestimonio from "./listarTestimonioItem.component";

export default class ListarTestimonio extends Component {
    constructor(props) {
        super(props);

        this.obtenerTestimonio = this.obtenerTestimonio.bind(this);
        this.crearTestimonio = this.crearTestimonio.bind(this);

        this.state = {
            testimonios: []
        };
    }

    componentDidMount() {
        this.obtenerTestimonio();
    }

    obtenerTestimonio() {
        TestimonioService.getAll()
            .then(response => {
                let carros = [];
                for (let i = 0; i < response.data.length; i++) {
                    carros.push(<ListItemTestimonio 
                        id={response.data[i].id}
                        testimonio={response.data[i].testimonio}
                        autor={response.data[i].autor}
                        ocupacion={response.data[i].ocupacion}
                        linkImagen={response.data[i].imagenPerfil}
                        />
                    );
                }

                this.setState({
                    testimonios: carros
                });
            })
            .catch(e => {
                console.log(e);
            });
    }

    crearTestimonio(e) {
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }
        ReactDOM.render(<CrearTestimonio />, divDash);
    }

    render() {
        const { testimonios } = this.state;
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div class="row justify-content-between">
                        <div class="col-6">
                            <h2 className="myaccount-content">Testimonios registrados</h2>
                        </div>
                        <div class="col-3-2">
                            <button type="button" onClick={this.crearTestimonio} class="btn btn-warning btn-lg">Agregar Testimonio</button>
                        </div>
                    </div>
                </div>

                <table class="table table-striped table-hover">
                    <thead class="thead-dark">
                        <tr>
                            <th class="text-center" scope="col">Testimonio</th>
                            <th class="text-center" scope="col">Autor</th>                            
                            <th class="text-center" scope="col">Ocupación</th>                            
                            <th class="text-center" scope="col"></th>
                            <th class="text-center" scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {testimonios}
                    </tbody>
                </table>
            </div>
        )
    }
}
import React, { Component } from "react";
import ReactDOM from "react-dom";
import Swal from "sweetalert2";
import ProyectoService from "../../services/proyecto.service";
import CrearProyecto from "./crearProyecto.component";
import ListaProyectos from "./proyectoList.component";
import http from "../../http-common.js";

export default class ProyectoListItem extends Component {

    constructor(props) {
        super(props);

        this.onChangeMostrarInicio = this.onChangeMostrarInicio.bind(this);
        this.editarProyecto = this.editarProyecto.bind(this);
        this.onChangeActivo = this.onChangeActivo.bind(this);

        this.state = {
            id: this.props.id,
            titulo: this.props.titulo,
            textoPre: this.props.textoPre,            
            activo: this.props.activo,
            mostrarInicio: this.props.mostrarInicio
        }
    }

    onChangeActivo(e) {
        let target = e.target;
        let inicioActivo = this.state.mostrarInicio;        

        if(!target.checked){
            inicioActivo = false;
        }

        this.setState({ activo: target.checked, mostrarInicio: inicioActivo}, () => {
            ProyectoService.update(this.state.id, this.state)
                .then(response => {
                    console.log(response.data);
                })
                .catch(e => {
                    console.log(e);
                });
        });
    }

    onChangeMostrarInicio(e) {
        let target = e.target;
        let value = target.checked;

        if(target.checked && this.state.activo){
            ProyectoService.findByInicio()
            .then(response => {
                if (response.data.length >= 6) {
                    Swal.fire({
                        icon: 'warning',
                        text: "Solo se permiten seis proyectos para mostrar en el inicio."
                    })
                }
                else {
                    this.setState({ mostrarInicio: value }, () => {
                        ProyectoService.update(this.state.id, this.state)
                            .then(response => {
                                console.log(response.data);
                            })
                            .catch(e => {
                                console.log(e);
                            });
                    });
                }
            })
            .catch(e => {
                console.log(e);
            });
        }
        else if(!target.checked){
            this.setState({ mostrarInicio: value }, () => {
                ProyectoService.update(this.state.id, this.state)
                    .then(response => {
                        console.log(response.data);
                    })
                    .catch(e => {
                        console.log(e);
                    });
            });
        }
        else{
            Swal.fire({
                icon: 'warning',
                text: "Necesita estar activo para mostrarse en el inicio."
            })
        }
    }

    editarProyecto() {
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }
        ReactDOM.render(<CrearProyecto id={this.state.id} />, divDash);
    }

    render() {
        return (
            <tr key={this.state.id}>
                <td class="col-md-2 align-middle">{this.state.titulo}</td>
                <td class="col-md-2 align-middle">{this.state.textoPre}</td>                
                <td class="col-md-2 align-middle">
                    <div className="custom-control custom-switch">
                        <input type="checkbox" className="custom-control-input" onChange={this.onChangeActivo} id={"customSwitch" + this.state.id} checked={this.state.activo} />
                        <label className="custom-control-label" htmlFor={"customSwitch" + this.state.id}>{this.state.activo ? "Activo" : "Inactivo"}</label>
                    </div>
                </td>
                <td class="col-md-2 align-middle">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" onChange={this.onChangeMostrarInicio} id={"flexCheckChecked" + this.state.id} checked={this.state.mostrarInicio} />
                        
                        <label class="form-check-label" for={"flexCheckChecked" + this.state.id}>{this.state.mostrarInicio ? "Habilitado" : "Inhabilitado"}</label>
                    </div>
                </td>
                <td class="col-md-1 align-middle"><button class="btn btn-light" onClick={this.editarProyecto}>Editar</button></td>
                <td class="col-md-1 align-middle"><button class="btn btn-danger" onClick={()=>{ 
                                    if(this.props.id == this.props.idUs){
                                        Swal.fire({
                                            icon: 'warning',
                                            text: "No puede eliminar esta cuenta"
                                        });
                                    }else{
                                        Swal.fire({
                                            text: 'Deseas eliminar este elemento?',
                                            icon: 'warning',                                
                                            showCancelButton: true,
                                            confirmButtonText: 'Eliminar',                                
                                        }).then((result) => {                                
                                            if (result.isConfirmed) {
                                                for(let i = 0; i <this.props.imagenes.length;i++){
                                                    http.delete('/s3url3/'+this.props.imagenes[i]);
                                                }                                                
                                                ProyectoService.delete(this.props.id).then(response => {
                                                    let divDash = document.getElementById('contenidoTablero');
                                                    Swal.fire('Eliminado', 'Elemento Eliminado Correctamente', 'success')
                                                    if (divDash.children.length > 0) {
                                                        ReactDOM.unmountComponentAtNode(divDash);
                                                    }
                                                    ReactDOM.render(<ListaProyectos />, divDash);
                                                });
                                            }
                                            
                                        });
                                    }                                                                       
                                }}><i className="far fa-trash-alt"></i></button></td>
            </tr>
        )
    }
} 
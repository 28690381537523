import React, { Component } from "react";
import Swal from 'sweetalert2';
import ReactDOM from "react-dom";
import UsuarioService from "../../services/usuario.service.js";
import ListaUsuarios from "./usuarioList.component";
import Boton from "../button.component.js";
import GifLoader from 'react-gif-loader';
import http from "../../http-common.js";

export default class CrearUsuario extends Component {

    constructor(props) {
        super(props);
        this.onChangeNombre = this.onChangeNombre.bind(this);
        this.onChangePaterno = this.onChangePaterno.bind(this);
        this.onChangeMaterno = this.onChangeMaterno.bind(this);
        this.onChangeCorreo = this.onChangeCorreo.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onChangeSesion = this.onChangeSesion.bind(this);
        this.onChangeRol = this.onChangeRol.bind(this);
        this.onChangeFacebook = this.onChangeFacebook.bind(this);
        this.onChangeInstagram = this.onChangeInstagram.bind(this);
        this.onChangeTwitter = this.onChangeTwitter.bind(this);
        this.onChangeLinkedIn = this.onChangeLinkedIn.bind(this);
        this.nombreImg = this.nameHandler.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.regresar = this.regresar.bind(this);

        this.state = {
            id: null,
            nombre: "",
            paterno: "",
            materno: "",
            correo: "",
            password: "",
            sesion: false,
            nombreImg: "",
            imagen: "",
            imagenURL: "",
            facebook: "",
            instagram: "",
            twitter: "",
            linkedin: "",
            rol: "Usuario",
            rolOrigen: this.props.rol,
            isAdmin: false,
            bandera: null,
            loading: false
        };
    }

    regresar(e, rol){
        http.delete('/s3url3/'+this.state.imagen);
        this.obtenerUsuarios(e, rol);
    }

    obtenerUsuarios(e, rol) {
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }
        ReactDOM.render(<ListaUsuarios  rol={rol}/>, divDash);
    }

    onChangeNombre(e) {
        this.setState({
            nombre: e.target.value
        });
    }

    onChangePaterno(e) {
        this.setState({
            paterno: e.target.value
        });
    }

    onChangeMaterno(e) {
        this.setState({
            materno: e.target.value
        });
    }

    onChangeCorreo(e) {
        this.setState({
            correo: e.target.value
        });
    }

    onChangePassword(e) {
        this.setState({
            password: e.target.value
        });
    }

    onChangeSesion(e) {
        let target = e.target;
        let inicioActivo = !this.state.sesion;

        this.setState({ sesion: inicioActivo }, () => {
            UsuarioService.update(this.state.id, this.state)
                .then(response => {
                    console.log(response.data);
                })
                .catch(e => {
                    console.log(e);
                });
        });
    }

    onChangeRol(e) {
        let target = e.target;
        let value = target.checked;

        if(this.state.rolOrigen == 'Administrador'){
            if (value) {
                this.setState({
                    rol: "Administrador",
                    isAdmin: true
                });
            }
            else {
                this.setState({
                    rol: "Usuario",
                    isAdmin: false
                });
            }
        }else{
            Swal.fire({
                icon: 'error',
                text: "No tienes los permisos para habilitar esta opcion."
            });
        }
    }

    onChangeFacebook(e) {
        this.setState({
            facebook: e.target.value
        });
    }

    onChangeInstagram(e) {
        this.setState({
            instagram: e.target.value
        });
    }

    onChangeTwitter(e) {
        this.setState({
            twitter: e.target.value
        });
    }

    onChangeLinkedIn(e) {
        this.setState({
            linkedin: e.target.value
        });
    }

    guardarUsuario = () => {
        var sha256 = require('js-sha256');
        var data = {
            nombre: this.state.nombre,
            paterno: this.state.paterno,
            materno: this.state.materno,
            correo: this.state.correo,
            password: sha256(this.state.password),
            sesion: this.state.sesion,
            imagen: this.state.imagenURL,
            facebook: this.state.facebook,
            instagram: this.state.instagram,
            twitter: this.state.twitter,
            linkedin: this.state.linkedin,
            rol: this.state.rol
        };

        if (this.state.nombre && this.state.paterno && this.state.materno
            && this.state.correo && this.state.password && this.validateEmail(this.state.correo) &&this.state.imagenURL){

            UsuarioService.findByEmail(this.state.correo).then(response => {
                if (response.data.length >= 1) {
                    Swal.fire({
                        icon: 'warning',
                        text: "Correo tomado"
                    });
                } else {
                    UsuarioService.create(data)
                        .then(response => {
                            this.setState({
                                id: response.data.id,
                                nombre: "",
                                paterno: "",
                                materno: "",
                                correo: "",
                                password: "",
                                imagen: "",
                                imagenURL: "",
                                facebook: "",
                                instagram: "",
                                twitter: "",
                                linkedin: "",
                                rol: "Usuario",
                                isAdmin: false
                            });
                            document.getElementById('imgPerfil').src = "";
                            Swal.fire({
                                icon: 'success',
                                text: "Ingresado con éxito."
                            })
                        })
                        .catch(e => {
                            console.log(e);
                            Swal.fire({
                                icon: 'error',
                                text: "Hubo un error en la solicitud."
                            })
                        });
                }
            }).catch(e => {
                console.log(e);
                Swal.fire({
                    icon: 'error',
                    text: "Hubo un error en la solicitud."
                })
            });
        }else if(!this.validateEmail(this.state.correo)){
            Swal.fire({
                icon: 'warning',
                text: "El correo es inválido"
            })            
        }
        else {
            Swal.fire({
                icon: 'warning',
                text: "Hay campos requeridos vacios."
            })
            console.log("error");
        }

    }

    onClickRecuadros = (e) => {
        http.delete('/s3url3/'+this.state.imagenURL);
        document.getElementById('imgPerfil').src = "";
        this.setState({
            imagen: "",
            imagenURL: ""
        })
    }

    async submitFormHandler(e) {
        e.preventDefault()
        let file;
        file = this.state.imagen;

        if(file){
            if(this.state.imagenURL!==""){
                http.delete('/s3url3/'+this.state.imagenURL);
            }
            this.setState({
                nombreImg: "",
                loading: true
    
            })
            let data = new FormData();
            data.append('foto', file);
    
            await http.post('/s3Url', data).then((response) => {
    
                http.get('/s3url2/'+response.data.data).then(response => {document.getElementById('imgPerfil').src = response.data}).catch(error => {console.log(error);});   
                this.setState({
                    imagenURL: response.data.data,
                    loading: false
                });
            })    
        }else{
            Swal.fire({
                icon: 'warning',
                text: "Debe cargar una imagen."
            })
        }                
    }

    nameHandler(e) {

        let archivo = e.target.files[0];

        if (archivo) {
            let nombre = "Archivo elegido: " + archivo.name;
            const scope = this;
            var reader = new FileReader();

            //Read the contents of Image File.
            reader.readAsDataURL(archivo);
            reader.onload = function (file) {
                var image = new Image();
                image.src = file.target.result;
                image.onload = function () {
                    let height = this.naturalHeight;
                    let width = this.naturalWidth;

                    if (height != width || height > 551 || width > 551) {
                        Swal.fire({
                            icon: 'warning',
                            text: "La imagen no debe exceder 551 pixeles de alto y 551 pixeles de ancho. " +
                             "Ademas, debe ser una imagen cuadrada."
                        });
                        return false;
                    }
                    scope.setState({ imagen: archivo, nombreImg: nombre });
                    return true;
                };
            };
        }
    }

    validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    };


    render() {

        return (

            <div className="content-wrapper">

                <div className="content-header">
                    <h2 className="">Datos del usuario</h2>
                </div>
                <form class="row g-3 " novalidate>
                    <div class="col-md-4 mb-3">
                        <label for="validationCustom01" class="form-label">Nombre(s)*</label>
                        <input type="text" class="form-control" id="validationCustom01" value={this.state.nombre} onChange={this.onChangeNombre} required />
                        <div class="valid-feedback">
                            Valido
                        </div>
                    </div>
                    <div class="col-md-4">
                        <label for="validationCustom02" class="form-label">Apellido paterno*</label>
                        <input type="text" class="form-control" id="validationCustom02" value={this.state.paterno} onChange={this.onChangePaterno} required />
                        <div class="valid-feedback">
                            Valido
                        </div>
                    </div>
                    <div class="col-md-4">
                        <label for="validationCustom02" class="form-label">Apellido materno*</label>
                        <input type="text" class="form-control" id="validationCustom03" value={this.state.materno} onChange={this.onChangeMaterno} required />
                        <div class="valid-feedback">
                            Valido
                        </div>
                    </div>

                    <div class="col-md-4 mb-3">
                        <label for="validationCustomUsername" class="form-label">Correo*</label>
                        <div class="input-group has-validation">
                            <span class="input-group-text" id="inputGroupPrepend"><i class="fas fa-at"></i></span>
                            <input type="text" class="form-control" id="validationCustomUsername" aria-describedby="inputGroupPrepend"
                                value={this.state.correo} onChange={this.onChangeCorreo} required />
                        </div>
                    </div>

                    <div class="col-md-4">
                        <GifLoader
                            loading={this.state.loading}
                            imageSrc="https://media.giphy.com/media/17mNCcKU1mJlrbXodo/giphy.gif"
                            overlayBackground="none"
                            imageStyle={{ width: "30%", display: "block", marginRight: "auto", marginLeft: "auto" }}
                        />
                        <label for="validationCustomUsername" class="form-label">Password*</label>
                        <div class="input-group has-validation">
                            <input type="password" class="form-control" id="validationCustomPass" aria-describedby="inputGroupPrepend"
                                value={this.state.password} onChange={this.onChangePassword} />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <label for="validationCustomUsername1" class="form-label">Facebook</label>
                        <div class="input-group has-validation">
                            <span class="input-group-text" id="inputGroupPrepend1"><i class="fab fa-facebook-square"></i></span>
                            <input type="text" class="form-control" id="validationCustomUsername1" aria-describedby="inputGroupPrepend1"
                                value={this.state.facebook} onChange={this.onChangeFacebook} />
                        </div>
                    </div>

                    <div class="col-md-4 mb-3">
                        <label for="validationCustomUsername2" class="form-label">Instagram</label>
                        <div class="input-group has-validation">
                            <span class="input-group-text" id="inputGroupPrepend2"><i class="fab fa-instagram-square"></i></span>
                            <input type="text" class="form-control" id="validationCustomUsername2" aria-describedby="inputGroupPrepend2"
                                value={this.state.instagram} onChange={this.onChangeInstagram} />
                        </div>
                    </div>

                    <div class="col-md-4">
                        <label for="validationCustomUsername3" class="form-label">Twitter</label>
                        <div class="input-group has-validation">
                            <span class="input-group-text" id="inputGroupPrepend3"><i class="fab fa-twitter-square"></i></span>
                            <input type="text" class="form-control" id="validationCustomUsername3" aria-describedby="inputGroupPrepend3"
                                value={this.state.twitter} onChange={this.onChangeTwitter} />
                        </div>
                    </div>

                    <div class="col-md-4">
                        <label for="validationCustomUsername4" class="form-label">LinkedIn</label>
                        <div class="input-group has-validation">
                            <span class="input-group-text" id="inputGroupPrepend4"><i class="fab fa-linkedin"></i></span>
                            <input type="text" class="form-control" id="validationCustomUsername4" aria-describedby="inputGroupPrepend4"
                                value={this.state.linkedin} onChange={this.onChangeLinkedIn} />
                        </div>
                    </div>
                </form>

                <div className="col-md-4 mb-3">

                    <label className="font-weight-bold">Foto de perfil*<span className="required"> * </span> </label>

                    <div id={"recuadro0"} className="recuadro-imagen">

                        <img width="50%" id="imgPerfil" />

                        <button className="btn-danger" onClick={e => this.onClickRecuadros(e)}><i className="far fa-trash-alt"></i></button>
                    </div>
                    <form id="imageForm" style={{ "marginTop": '10px' }} className="form-group" onSubmit={e => this.submitFormHandler(e)}>

                        <div class="input-group">
                            <input id="imageInput" id="exampleInputFile" class="custom-file-input" type="file" accept="image/*" onChange={e => this.nameHandler(e)} />
                            <label class="custom-file-label" id="nombre">{this.state.nombreImg}</label>

                            <button style={{ "margin-top": '10px' }} className="btn btn-success" type="submit">Cargar</button>
                        </div>
                    </form>
                </div>

                <div class="col-md-12">
                    <div class="row justify-content-between">
                        <div class="col-6">
                            <div class="row">
                                <div class="col-md-4">
                                    <label htmlFor="validationCustom05" class="form-label">Sesión activa</label>
                                    <br></br>
                                    <label class="switch">
                                        <input type="checkbox" checked={this.state.sesion} onChange={this.onChangeSesion} />
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                                <div class="col-md-4">
                                    <label htmlFor="validationCustom06" class="form-label">{this.state.rol}</label>
                                    <br></br>
                                    <label class="switch">
                                        <input type="checkbox" checked={this.state.isAdmin} onChange={this.onChangeRol} />
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div >
                            <div className="form-group col-sm-12 text-right">
                                <button onClick={e => this.regresar(e, this.state.rolOrigen)} class="btn btn-info"><i class="fas fa-undo-alt"></i> Regresar</button>
                                <>&nbsp;</>
                                <button class="btn btn-primary" onClick={this.guardarUsuario}><i class="fas fa-save"></i> Guardar</button>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
import http from "../http-common";

class TestimonioService {
    getAll() {
        return http.get("/testimonios");
    }   

    get(id) {
        return http.get(`/testimonios/${id}`);
    }

    create(data) {
        return http.post("/testimonios/", data);
    }

    update(id, data) {
        return http.put(`/testimonios/${id}`, data);
    }

    delete(id) {
        return http.delete(`/testimonios/${id}`);
    }

}

export default new TestimonioService();
import React, { useState, useEffect, createContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardCvcElement, CardExpiryElement, CardNumberElement, useStripe, useElements, PaymentElement, CardElement } from '@stripe/react-stripe-js';
import StripeService from "../services/stripe.service.js";
import validator from "validator";
import Swal from 'sweetalert2';
import axios from 'axios';

export default function Stripe({ nombreDonador, apellidos, email, telefono, cantidad, mensaje, causa, causaId }) {

    const stripePromise = loadStripe("pk_test_51KHLRSAM1camC89d68yVxjl2NznB1N6CkcIKjyLJ6nIajMovBP7hsuMr3XZF75RO9ra2D5maSGKq3NRywzlWjZ7D00svxCUQvK");

    const [meses, setMeses] = useState([]);
    const [anios, setAnios] = useState([]);

    const CheckoutForm = () => {
        const stripe = useStripe();
        const elements = useElements();

        const cardStyle = {
            style: {
                base: {
                    color: "#32325d",
                    fontFamily: 'Arial, sans-serif',
                    fontSmoothing: "antialiased",
                    fontSize: "24px",
                    "::placeholder": {
                        color: "#32325d"
                    }
                },
                invalid: {
                    fontFamily: 'Arial, sans-serif',
                    color: "#fa755a",
                    iconColor: "#fa755a"
                }
            }
        };

        const donarCantidad = async (e) => {
            e.preventDefault();
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement),
                billing_details: {
                    email: email,
                    name: nombreDonador + " " + apellidos,
                    phone: telefono
                },
            });

            if (causaId && causa && cantidad && mensaje && email && telefono && nombreDonador && apellidos) {
                if (!error) {
                    const { id } = paymentMethod;
                    
                    var data = {
                        id: id,
                        causa: causa,
                        causaID: causaId,
                        donador: nombreDonador,
                        apellidos: apellidos,
                        email: email,
                        telefono: telefono,
                        cantidad: parseInt(cantidad) * 100,
                        descripcion: mensaje,
                    };

                    StripeService.create(data).then(response => {
                        let status = response.data;
                        switch (status) {
                            case 'succeeded':
                                Swal.fire({
                                    icon: 'success',
                                    title: '¡GRACIAS POR APOYAR!',
                                    text: 'México agradece tu apoyo.',
                                });
                                break;

                            case 'failed':
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Error al donar',
                                    text: 'La transacción fue fallida.',
                                });
                                break;

                            case 'card_declined':
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Error al donar',
                                    text: 'Tu tarjeta fue declinada.',
                                });
                                break;

                            default:
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Error al donar',
                                    text: 'La transacción fue fallida.',
                                });
                                break;
                        }
                        elements.getElement(CardElement).clear();
                    });
                }
            }
            else if (!email || !telefono || !cantidad || !mensaje
                || !nombreDonador || !apellidos || !causa || !causaId) {
                Swal.fire({
                    icon: 'warning',
                    text: "Campos requeridos vacíos."
                });
            }
        }

        return (
            <form onSubmit={donarCantidad} id="payment-form">
                <div className="row mb-4 mt-4">
                    <CardElement options={cardStyle} />
                </div>
                <div className="row mb-3 gx-12 justify-content-center">
                    <div className="col-3 border-end text-center">
                        <label>Transacciones realizadas vía:</label>
                        <img style={{ width: '100px' }}
                            src="assets/img/formulario-tarjeta/stripe.png" alt="Logo Stripe"></img>
                    </div>
                    <div className="col-4 border-end">
                        <div className="row">
                            <div className="col-2 text-center  justify-content-center">
                                <img style={{ width: '40px', marginTop: '20%' }}
                                    src="assets/img/formulario-tarjeta/security.png" alt="Escudo verde"></img>
                            </div>
                            <div className="col text-center  justify-content-center">
                                <label>Tus pagos se realizan de forma segura con encriptación de 256 bits</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-3 text-center">
                        <button type="submit" class="btn btn-success" style={{width: '100%', marginTop: '5%'}}
                        disabled={!stripe ? true : false}>Donar</button>
                    </div>
                </div>
            </form>
        )
    }

    useEffect(() => {
        let mesesCombo = [];
        let anioCombo = [];
        let fecha = new Date();
        let anioActual = fecha.getFullYear();

        for (let i = 1; i < 13; i++) {
            let tag = i < 10 ? "0" + i : i;
            mesesCombo.push(<option key={i} value={tag}>{tag}</option>);
        }

        for (let i = anioActual; i <= anioActual + 16; i++) {
            let tag = i.toString().substring(2);
            anioCombo.push(<option key={i} value={tag}>{i}</option>);
        }

        setMeses(mesesCombo);
        setAnios(anioCombo);
    }, []);

    return (
        <div class="card text-dark  bg-light mb-3">
            <div class="card-body">
                <div className="row gx-10 mb-4">
                    <div className="col-2 border-end">
                        <p>Tarjetas de cr&eacute;dito</p>
                        <img src="assets/img/formulario-tarjeta/cards1.png" alt="Logos tarjetas"></img>
                    </div>
                    <div className="col">
                        <p>Tarjetas de d&eacute;bito</p>
                        <img src="assets/img/formulario-tarjeta/cards2.png" alt="Logos tarjetas2"></img>
                    </div>
                </div>
                <Elements stripe={stripePromise}>
                    <CheckoutForm></CheckoutForm>
                </Elements>
            </div>
        </div>
    )
}
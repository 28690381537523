import http from "../http-common";

class NoticiaService {
    getAll() {
        return http.get("/noticias");
    }

    get(id) {
        return http.get("/noticias/" + id);
    }

    getCategoria(id) {
        return http.get("/noticias/categoria/" + id);
    }

    create(data) {
        return http.post("/noticias", data);
    }

    update(id, data) {
        return http.put("/noticias/" + id, data);
    }

    delete(id) {
        return http.delete("/noticias/" + id);
    }

    deleteAll() {
        return http.delete("/noticias");
    }

    getAllActive(){
        return http.get("/noticias/activos");
    }

}

export default new NoticiaService();

import React, { Component, useState } from "react";
import ProgramaListItem from "./programaListItem.component";
import ProgramaService from "../../services/programa.service.js";
import ReactDOM from "react-dom";
import CrearPrograma from "../Programas/crearPrograma.component";

export default class ListaProgramas extends Component {

    constructor(props) {
        super(props);

        this.obtenerProgramas = this.obtenerProgramas.bind(this);
        this.crearPrograma = this.crearPrograma.bind(this);

        this.state = {
            programas: this.obtenerProgramas()
        };
    }

    crearPrograma() {
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }
        ReactDOM.render(<CrearPrograma />, divDash);
    }

    obtenerProgramas() {
        ProgramaService.getAll()
            .then(response => {
                var tresProgramas = [];
                for (let i = 0; i < response.data.length; i++) {
                    var imagenes = response.data[i].imagenes;
                    var galeria = imagenes.slice(1,);
                    tresProgramas.push(<ProgramaListItem
                        id={response.data[i].id}
                        titulo={response.data[i].titulo}
                        subtitulo={response.data[i].subtitulo}
                        descripcion={response.data[i].descripcion}
                        activo={response.data[i].activo}
                        inicio={response.data[i].inicio}
                        imagenPrincipal={imagenes[0]}
                        imagenesGaleria={galeria}
                        icono={response.data[i].icono}
                        colorIcono={response.data[i].colorIcono} />
                    );
                }

                this.setState({
                    programas: tresProgramas
                });
            })
            .catch(e => {
                console.log(e);
            });
    }

    render() {
        const { programas } = this.state;

        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div class="row justify-content-between">
                        <div class="col-6">
                            <h2 className="myaccount-content">Programas registrados</h2>
                        </div>
                        <div class="col-3-2">
                            <button type="button" onClick={this.crearPrograma} class="btn btn-warning btn-lg">Agregar programa</button>
                        </div>
                    </div>
                </div>

                <div class="table-responsive">
                    <table class="table table-striped table-hover">
                        <thead class="thead-dark">
                            <tr>
                                <th class="text-center align-middle" scope="col">Titulo</th>
                                <th class="text-center align-middle" scope="col">Subtitulo</th>
                                <th class="text-center align-middle" scope="col">Descripcion</th>
                                <th class="text-center align-middle" scope="col">Estatus</th>
                                <th class="text-center align-middle" scope="col">Mostrar en inicio</th>
                                <th class="text-center align-middle" scope="col"></th>
                                <th class="text-center align-middle" scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {programas}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}
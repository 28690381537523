import React, { Component } from "react";
import ReactDOM from "react-dom";
import Swal from "sweetalert2";
import SolicitudService from "../../../services/solicitud.service.js";
import ListaSolicitudes from "./solicitudList.component";
import VerSolicitud from "./verSolicitud.component.js";

export default class SolicitudListItem extends Component {

    constructor(props) {
        super(props);

        this.eliminarSolicitud = this.eliminarSolicitud.bind(this);
        this.verSolicitud = this.verSolicitud.bind(this);

        this.state = {
            id: this.props.id,
            nombre: this.props.nombre,
            paterno: this.props.paterno,
            materno: this.props.materno,
            correo: this.props.correo,
            telefono: this.props.telefono,
            motivo: this.props.motivo
        }
    }

    eliminarSolicitud() { 
        Swal.fire({
            text: '¿Deseas eliminar este elemento?',
            icon: 'warning',                                
            showCancelButton: true,
            confirmButtonText: 'Eliminar',                                
          }).then((result) => {                                
            if (result.isConfirmed) {
                SolicitudService.delete(this.props.id).then(response => {
                    let divDash = document.getElementById('contenidoTablero');
                    Swal.fire('Eliminado', 'Elemento Eliminado Correctamente', 'success')
                    if (divDash.children.length > 0) {
                        ReactDOM.unmountComponentAtNode(divDash);
                    }
                    ReactDOM.render(<ListaSolicitudes />, divDash);
                });
            }
        });
    }

    verSolicitud() {
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }

        ReactDOM.render(<VerSolicitud
            id={this.state.id}
            nombre={this.state.nombre}
            paterno={this.state.paterno}
            materno={this.state.materno}
            correo={this.state.correo}
            telefono={this.state.telefono}
            motivo={this.state.motivo} />, divDash);
    }


    render() {
        const { redes } = this.state;
        return (
            <tr key={this.state.id}>
                <td class="col-md-2 align-middle text-center">{this.state.nombre + " " + this.state.paterno}</td>
                <td class="col-md-2 align-middle text-center">{this.state.correo}</td>
                <td class="col-md-2 align-middle text-center">{this.state.telefono}</td>
                <td class="col-md-1 align-middle text-center">{this.state.motivo}</td>
                <td class="col-md-2 align-middle text-center"><button class="btn btn-light" onClick={this.verSolicitud}>Ver ficha</button></td>
                <td class="col-md-1 align-middle text-center"><button class="btn btn-danger" onClick={this.eliminarSolicitud}><i className="far fa-trash-alt"></i></button></td>
            </tr>
        )
    }
} 
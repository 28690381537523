import React, { Component } from "react";
import Swal from 'sweetalert2';
import ReactDOM from "react-dom";
import VoluntarioService from "../../services/voluntario.service.js";
import ListaVoluntarios from "../Voluntarios/voluntarioList.component";
import GifLoader from 'react-gif-loader';
import http from "../../http-common.js";

export default class EditarVoluntario extends Component {

    constructor(props) {
        super(props);
        this.onChangeNombre = this.onChangeNombre.bind(this);
        this.onChangePaterno = this.onChangePaterno.bind(this);
        this.onChangeMaterno = this.onChangeMaterno.bind(this);
        this.onChangeTelefono = this.onChangeTelefono.bind(this);
        this.onChangeCorreo = this.onChangeCorreo.bind(this);
        this.onChangeFacebook = this.onChangeFacebook.bind(this);
        this.onChangeInstagram = this.onChangeInstagram.bind(this);
        this.onChangeTwitter = this.onChangeTwitter.bind(this);
        this.onChangeLinkedIn = this.onChangeLinkedIn.bind(this);
        this.obtenerVoluntarios = this.obtenerVoluntarios.bind(this);
        this.guardarVoluntario = this.guardarVoluntario.bind(this);
        this.onChangeMostrarInicio = this.onChangeMostrarInicio.bind(this);
        this.onChangeActivo = this.onChangeActivo.bind(this);
        this.submitFormHandler = this.submitFormHandler.bind(this);
        this.nameHandler = this.nameHandler.bind(this);
        this.cancelar = this.cancelar.bind(this);

        this.state = {
            id: this.props.id,
            nombre: this.props.nombre,
            paterno: this.props.paterno,
            materno: this.props.materno,
            correo: this.props.correo,
            telefono: this.props.telefono,
            facebook: this.props.facebook,
            instagram: this.props.instagram,
            twitter: this.props.twitter,
            linkedin: this.props.linkedin,
            activo: this.props.activo,
            inicio: this.props.inicio,
            imagenPrincipal: this.props.imagen,
            imagenNombre: "",
            imagen: null,
            loading: false
        };
    }

    componentDidMount() {
        http.get('/s3url2/'+this.state.imagenPrincipal).then(response => {document.getElementById('imgP').src = response.data}).catch(error => {console.log(error);});   
    }

    validateTelefono = (telefono) => {
        return telefono.match("^[0-9]+$");
    };

    obtenerVoluntarios(e) {
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }
        ReactDOM.render(<ListaVoluntarios />, divDash);
    }

    onChangeNombre(e) {
        this.setState({
            nombre: e.target.value
        });

        this.checarNuevoEstado(e);
    }

    onChangePaterno(e) {
        this.setState({
            paterno: e.target.value
        });

        this.checarNuevoEstado(e);
    }

    onChangeMaterno(e) {
        this.setState({
            materno: e.target.value
        });

        this.checarNuevoEstado(e);
    }

    onChangeTelefono(e) {
        this.setState({
            telefono: e.target.value
        });

        this.checarNuevoEstado(e);
    }

    onChangeCorreo(e) {
        this.setState({
            correo: e.target.value
        });

        this.checarNuevoEstado(e);
    }

    onChangeFacebook(e) {
        this.setState({
            facebook: e.target.value
        });
    }

    onChangeInstagram(e) {
        this.setState({
            instagram: e.target.value
        });
    }

    onChangeTwitter(e) {
        this.setState({
            twitter: e.target.value
        });
    }

    onChangeLinkedIn(e) {
        this.setState({
            linkedin: e.target.value
        });
    }

    checarNuevoEstado = (e) => {
        let input = document.getElementById(e.target.id);
        if (e.target.value == "") {
            input.className = "form-control is-invalid";
        }
        else {
            input.className = "form-control";
        }
    }

    onChangeActivo(e) {
        let target = e.target;
        let inicioActivo = this.state.inicio;
        console.log(target.checked);

        if (!target.checked) {
            inicioActivo = false;
        }

        this.setState({ activo: target.checked, inicio: inicioActivo }, () => {
            VoluntarioService.update(this.state.id, this.state)
                .then(response => {
                    console.log(response.data);
                })
                .catch(e => {
                    console.log(e);
                });
        });
    }

    onChangeMostrarInicio(e) {
        let target = e.target;
        let value = target.checked;

        if (target.checked && this.state.activo) {
            VoluntarioService.getAllHomePage()
                .then(response => {
                    if (response.data.length >= 3) {
                        Swal.fire({
                            icon: 'warning',
                            text: "Solo se permiten tres voluntarios para mostrar en el inicio."
                        })
                    }
                    else {
                        this.setState({ inicio: value }, () => {
                            VoluntarioService.update(this.state.id, this.state)
                                .then(response => {
                                    console.log(response.data);
                                })
                                .catch(e => {
                                    console.log(e);
                                });
                        });
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        }
        else if (!target.checked) {
            this.setState({ inicio: value }, () => {
                VoluntarioService.update(this.state.id, this.state)
                    .then(response => {
                        console.log(response.data);
                    })
                    .catch(e => {
                        console.log(e);
                    });
            });
        }
        else {
            Swal.fire({
                icon: 'warning',
                text: "Necesita estar activo para mostrarse en el inicio."
            })
        }
    }

    guardarVoluntario() {
        var data = {
            nombre: this.state.nombre,
            paterno: this.state.paterno,
            materno: this.state.materno,
            correo: this.state.correo,
            telefono: this.state.telefono,
            facebook: this.state.facebook,
            instagram: this.state.instagram,
            twitter: this.state.twitter,
            linkedin: this.state.linkedin,
            activo: this.state.activo,
            inicio: this.state.inicio,
            imagen: this.state.imagenPrincipal
        };

        if (this.state.nombre && this.state.paterno && this.state.materno
            && this.validateEmail(this.state.correo) && this.validateTelefono(this.state.telefono)) {
            VoluntarioService.update(this.state.id, data)
                .then(response => {
                    this.setState({
                        id: response.data.id
                    });
                    Swal.fire({
                        icon: 'success',
                        text: "Se actualizó el voluntario."
                    });
                    this.obtenerVoluntarios();
                })
                .catch(e => {
                    console.log(e);
                    Swal.fire({
                        icon: 'error',
                        text: "Hubo un error en la solicitud."
                    });
                });
        }
        else if (this.state.nombre == '' || this.state.paterno == '' || this.state.materno == ''
            || this.state.telefono == '' || this.state.correo == ''){
            var form = document.querySelector('.needs-validation');
            var inputs = form.querySelectorAll('.form-control');

            for (let i = 0; i < inputs.length; i++) {
                let inputI = inputs[i];
                if (inputI.value == '' && inputI.className != 'form-control no-required') {
                    inputI.className = "form-control is-invalid";
                }
                else {
                    inputI.className = "form-control";
                }
            }
            Swal.fire({
                icon: 'warning',
                text: "Hay campos requeridos vacios."
            });
        }
        else if (this.state.correo !== '' && !this.validateEmail(this.state.correo)) {
            Swal.fire({
                icon: 'warning',
                text: "El email es inválido."
            });
        }
        else if (this.state.telefono !== '' && !this.validateTelefono(this.state.telefono)) {
            Swal.fire({
                icon: 'warning',
                text: "El telefono es inválido."
            });
        }
        else if (this.state.imagenPrincipal == '') {
            Swal.fire({
                icon: 'warning',
                text: "Debe tener una imagen de perfil."
            });
        }
    }

    nameHandler(e) {
        let archivo = e.target.files[0];
        
        if (archivo) {
            let nombre = "Archivo elegido: " + archivo.name;
            const scope = this;
            var reader = new FileReader();

            //Read the contents of Image File.
            reader.readAsDataURL(archivo);
            reader.onload = function (file) {
                var image = new Image();
                image.src = file.target.result;
                image.onload = function () {
                    let height = this.height;
                    let width = this.width;

                    if (height != width || height > 551 || width > 551) {
                        Swal.fire({
                            icon: 'warning',
                            text: "La imagen no debe exceder 551 pixeles de alto y 551 pixeles de ancho. " +
                             "Ademas, debe ser una imagen cuadrada."
                        });
                        return false;
                    }
                    scope.setState({ imagen: archivo, imagenNombre: nombre });
                    return true;
                };
            };
        }
    }

    async submitFormHandler(e) {
        e.preventDefault();
        let file;
        file = this.state.imagen;

        

        if (file) {
            this.setState({loading: true});
            let data = new FormData();
            data.append('foto', file);
            await http.post('/s3Url', data).then((response) => {                
                http.get('/s3url2/'+response.data.data).then(response => {document.getElementById('imgP').src = response.data}).catch(error => {console.log(error);});   
                this.setState({
                    imagenPrincipal: response.data.data,
                    imagenNombre: "",
                    imagen: null,
                    loading: false
                });
            })
        }
        else {
            Swal.fire({
                icon: 'warning',
                text: "Debe elegir una imagen."
            });
        }
    }

    validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    };

    async cancelar(e) {
        if (this.state.imagenPrincipal !== '') {
            http.delete('/s3url3/' + this.state.imagenPrincipal);
            this.setState({
                imagenPrincipal: "",
                imagenNombre: "",
                imagen: null,
                loading: false
            });
        }
        else{
            Swal.fire({
                icon: 'warning',
                text: "Debe cargar una imagen."
            });
        }
    }

    render() {
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <h2 className="">Datos del voluntario</h2>
                </div>

                <form class="row g-3 needs-validation" novalidate>
                <div class="col-md-4 mb-3">
                        <label class="form-label">Nombre(s) <span className="quform-required">*</span></label>
                        <input type="text" class="form-control" id="nombre" aria-describedby="nombreInvalido"
                            value={this.state.nombre} onChange={this.onChangeNombre} required />
                        <div id="nombreInvalido" class="invalid-feedback">
                            Este campo es obligatorio
                        </div>
                    </div>
                    <div class="col-md-4">
                        <label class="form-label">Apellido paterno <span className="quform-required">*</span></label>
                        <input type="text" class="form-control" id="paterno" aria-describedby="paternoInvalido"
                            value={this.state.paterno} onChange={this.onChangePaterno} required />
                        <div id="paternoInvalido" class="invalid-feedback">
                            Este campo es obligatorio
                        </div>
                    </div>
                    <div class="col-md-4">
                        <label class="form-label">Apellido materno <span className="quform-required">*</span></label>
                        <input type="text" class="form-control" id="materno" aria-describedby="maternoInvalido"
                            value={this.state.materno} onChange={this.onChangeMaterno} required />
                        <div id="maternoInvalido" class="invalid-feedback">
                            Este campo es obligatorio
                        </div>
                    </div>

                    <div class="col-md-4 mb-3">
                        <label class="form-label">Correo <span className="quform-required">*</span></label>
                        <div class="input-group has-validation">
                            <span class="input-group-text"><i class="fas fa-at"></i></span>
                            <input type="text" class="form-control" id="correo" aria-describedby="correoInvalido"
                                value={this.state.correo} onChange={this.onChangeCorreo} required />
                            <div id="correoInvalido" class="invalid-feedback">
                                Este campo es obligatorio
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <label class="form-label">Tel&eacute;fono <span className="quform-required">*</span></label>
                        <div class="input-group has-validation">
                            <span class="input-group-text"><i class="fas fa-phone-square-alt"></i></span>
                            <input type="text" class="form-control" id="telefono" aria-describedby="telefonoInvalido"
                                value={this.state.telefono} onChange={this.onChangeTelefono} required />
                            <div id="telefonoInvalido" class="invalid-feedback">
                                Este campo es obligatorio
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <label class="form-label">Facebook</label>
                        <div class="input-group has-validation">
                            <span class="input-group-text"><i class="fab fa-facebook-square"></i></span>
                            <input type="text" class="form-control no-required" id="facebook" value={this.state.facebook} onChange={this.onChangeFacebook} />
                        </div>
                    </div>

                    <div class="col-md-4 mb-3">
                        <label class="form-label">Instagram</label>
                        <div class="input-group has-validation">
                            <span class="input-group-text"><i class="fab fa-instagram-square"></i></span>
                            <input type="text" class="form-control no-required" id="instagram" value={this.state.instagram} onChange={this.onChangeInstagram} />
                        </div>
                    </div>

                    <div class="col-md-4">
                        <label class="form-label">Twitter</label>
                        <div class="input-group has-validation">
                            <span class="input-group-text"><i class="fab fa-twitter-square"></i></span>
                            <input type="text" class="form-control no-required" id="twitter" value={this.state.twitter} onChange={this.onChangeTwitter} />
                        </div>
                    </div>

                    <div class="col-md-4">
                        <label class="form-label">LinkedIn</label>
                        <div class="input-group has-validation">
                            <span class="input-group-text"><i class="fab fa-linkedin"></i></span>
                            <input type="text" class="form-control no-required" id="linkedin" value={this.state.linkedin} onChange={this.onChangeLinkedIn} />
                        </div>
                    </div>
                </form>

                <div className="form-group col-md-12">
                    <label className="font-weight-bold">Foto de perfil<span className="required"> * </span> </label>
                    <div class="container" style={{ marginTop: '10px' }}>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="text-center align-middle" style={{ width: "100%" }}>
                                    <GifLoader
                                            loading={this.state.loading}
                                            imageSrc="https://media.giphy.com/media/17mNCcKU1mJlrbXodo/giphy.gif"                        
                                            overlayBackground="none"
                                            imageStyle={{width: "30%", display: "block", marginRight: "auto", marginLeft: "auto"}}
                                        />  
                                        <img id="imgP" style={{ marginBottom: '10px', width: '50%' }}  class="img-fluid" 
                                         src={this.state.imagenPrincipal} alt="Carga una imagen"></img>
                                    </th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <form id="imagenMain" style={{ "marginTop": '10px' }} className="form-group" onSubmit={this.submitFormHandler}>
                        <div class="input-group">
                            <input id="imagenMain" class="custom-file-input" type="file" accept="image/*" onChange={this.nameHandler} />
                            <label class="custom-file-label" id="nombre">{this.state.imagenNombre}</label>
                            <button style={{ marginTop: '10px' }} className="btn btn-success" type="submit">Cargar</button>
                            <button type="button" onClick={this.cancelar} style={{ marginTop: '10px', marginLeft: '10px' }} className="btn btn-danger" >Eliminar</button>
                        </div>
                    </form>
                </div>

                <div class="col-md-12">
                    <div class="row justify-content-between">
                        <div class="col-6">
                            <div class="row">
                                <div class="col-md-4">
                                    <label htmlFor="validationCustom02" class="form-label">Activo</label>
                                    <br></br>
                                    <label class="switch">
                                        <input type="checkbox" checked={this.state.activo} onChange={this.onChangeActivo} />
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                                <div class="col-md-4">
                                    <label htmlFor="validationCustom02" class="form-label">Inicio</label>
                                    <br></br>
                                    <label class="switch">
                                        <input type="checkbox" checked={this.state.inicio} onChange={this.onChangeMostrarInicio} />
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="form-group col-sm-12 text-right">
                                <button onClick={this.obtenerVoluntarios} class="btn btn-info"><i class="fas fa-undo-alt"></i> Regresar</button>
                                <>&nbsp;</>
                                <button class="btn btn-primary" onClick={this.guardarVoluntario}><i class="fas fa-save"></i> Guardar</button>                                                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
import { Component } from "react";
import CarouselService from "../../services/carousel.service"
import OwlCarousel from 'react-owl-carousel'
import http from '../../http-common'
export default class InicioCarousel extends Component {

    constructor(props) {
        super(props);

        this.pushear = this.pushear.bind(this);
        this.darEstado = this.darEstado.bind(this);

        this.state = {
            datos: [],

        }
    }
    componentDidMount() {
        this.darEstado();
    }

    getImage(key) {
        const url =
            url.then((result) => {
                console.log(result);
                return result;
            })
    }

    darEstado() {
        CarouselService.findTodo()
            .then(response => {
                for (let i = 0; i < response.data.length; i++) {
                    let div = document.createElement('div');
                    div.style.position = "relative";
                    div.style.textAlign = "center";                    
                    
                    
                    let img = document.createElement('img');

                    http.get('/s3url2/' + response.data[i].linkImagen).then(response => {
                        img.src = response.data
                        img.style.width = "100%";
                        img.style.height = "auto";
                        img.style.opacity="1";
                        img.style.borderRadius="8px";
                        img.style.backgroundImage="linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5))";
                    }).catch(error => { console.log(error); });

                    var divi =document.createElement('div');
                    divi.style.position = "absolute";
                    divi.style.top = "20%";
                    divi.style.left = "15%";

                    var titu = document.createElement('h2');
                    titu.innerHTML = response.data[i].titulo;
                    titu.className = "d-block text-primary display-22 display-md-21 display-lg-20 alt-font text-animations mb-3";
                    titu.style.position = "absolute";                    

                    let subti = document.createElement('h1');
                    subti.innerHTML = response.data[i].subtitulo;
                    subti.style.position = "left";
                    subti.style.marginTop = "25px"; 
                    subti.style.marginRight="60%";
                    subti.style.textAlign="left";          


                    div.id ="caja_carrusel" ;

                    div.appendChild(img);
                    divi.appendChild(titu);
                    divi.appendChild(subti);
                    div.appendChild(divi);
                    document.getElementById('owl-c').appendChild(div);
                }
                this.setState({
                    datos: response.data
                });
            });
    }

    pushear() {
        console.log(this.state)
    }
    /*{< section class="p-0 main-banner" >
                <div class="px-lg-1-6 px-xl-2-0 px-xxl-8">
                    <div class="slider-fade owl-carousel owl-theme w-100 alt-font"></div>
                    {carros}
                </div>
            </section >}*/


    render() {

        return (
                <section className="p-4 main-banner" >
                    <div className="px-lg-1-6 px-xl-2-0 px-xxl-8">
                        <div className="slider-fade owl-theme w-100 alt-font owl-loaded">

                            <OwlCarousel id="owl-c" items={1}
                                className="owl-theme"
                                animateOut={'fadeOut'}
                                animateIn={'fadeIn'}
                                loop
                                autoplay={true}
                                autoplayTimeout={7000}       
                                center={true} 
                                mouseDrag={false}       
                                lazyLoad={true}        
                                //responsiveBaseElement={}                                                                         
                                margin={20} >
                            </OwlCarousel>
                        </div>
                    </div>
                </section>
        )
    }
}
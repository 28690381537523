import React, { Component, useState } from "react";
import SolicitudService from "../../../services/solicitud.service.js";
import ListaVoluntarios from "../voluntarioList.component.js";
import SolicitudListItem from "./solicitudListItem.component.js";
import ReactDOM from "react-dom";

export default class ListaSolicitudes extends Component {

    constructor(props) {
        super(props);

        this.obtenerSolicitudes = this.obtenerSolicitudes.bind(this);
        this.obtenerVoluntarios = this.obtenerVoluntarios.bind(this);

        this.state = {
            solicitudes: this.obtenerSolicitudes()
        };
    }

    obtenerSolicitudes() {
        SolicitudService.getAll()
            .then(response => {
                var tresSolicitudes = [];
                for (let i = 0; i < response.data.length; i++) {
                    tresSolicitudes.push(<SolicitudListItem
                        id={response.data[i].id}
                        nombre={response.data[i].nombre}
                        paterno={response.data[i].paterno}
                        materno={response.data[i].materno}
                        correo={response.data[i].correo}
                        telefono={response.data[i].telefono}
                        motivo={response.data[i].motivo} />
                    );
                }

                this.setState({
                    solicitudes: tresSolicitudes
                });

                console.log(this.state.solicitudes);
            })
            .catch(e => {
                console.log(e);
            });
    }

    obtenerVoluntarios(e) {
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }
        ReactDOM.render(<ListaVoluntarios />, divDash);
    }

    render() {
        const { solicitudes } = this.state;

        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div class="row justify-content-between">
                        <div class="col-6">
                            <h2 className="myaccount-content">Solicitudes de voluntarios</h2>
                        </div>
                        <div class="col-2-1">
                            <button type="button" onClick={this.obtenerVoluntarios} class="btn btn-primary btn-lg"><i class="fas fa-undo-alt"></i> Regresar</button>
                        </div>
                    </div>
                </div>

                <div class="table-responsive">
                    <table class="table table-striped table-hover">
                        <thead class="thead-dark">
                            <tr>
                                <th class="align-middle text-center" scope="col">Nombre</th>
                                <th class="align-middle text-center" scope="col">Correo</th>
                                <th class="align-middle text-center" scope="col">Telefono</th>
                                <th class="align-middle text-center" scope="col">Motivo</th>
                                <th class="align-middle text-center" scope="col"></th>
                                <th class="align-middle text-center" scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {solicitudes}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}
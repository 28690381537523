import React, { Component } from "react";
import http from "../../http-common.js";

export default class VoluntarioItem extends Component {

    constructor(props) {
        super(props);

        this.crearRedesSociales = this.crearRedesSociales.bind(this);

        this.state = {
            facebookLink: this.props.facebookLink,
            instagramLink: this.props.instagramLink,
            twitterLink: this.props.twitterLink,
            linkedinLink: this.props.linkedinLink,
            imagen: this.props.imagen,
            redes: [],
            id: this.props.id
        }
    }

    componentDidMount(){
        this.crearRedesSociales();
        http.get('/s3url2/'+this.state.imagen).then(response => {document.getElementById('imgVoluntario'+this.state.id).src = response.data}).catch(error => {console.log(error);});   
    }
    
    crearRedesSociales(){
        var redesSociales = [];
        if(this.state.facebookLink != ""){
            redesSociales.push(<li key="redes1"><a href={this.state.facebookLink}><i class="fab fa-facebook"></i></a></li>);
        }

        if(this.state.instagramLink != ""){
            redesSociales.push(<li key="redes2"><a href={this.state.instagramLink}><i class="fab fa-instagram"></i></a></li>);
        }

        if(this.state.twitterLink != ""){
            redesSociales.push(<li key="redes3"><a href={this.state.twitterLink}><i class="fab fa-twitter"></i></a></li>);
        }

        if(this.state.linkedinLink != ""){
            redesSociales.push(<li key="redes4"><a href={this.state.linkedinLink}><i class="fab fa-linkedin-in"></i></a></li>);
        }

        this.setState({
            redes: redesSociales
        })
    }


    render(){
        const { redes } = this.state; 

        return(
            <div key={this.props.id} class="col-sm-4 mb-3 wow fadeIn" data-wow-delay="600ms">
                <div class="position-relative team-style1">
                    <div class="team-img">
                        <center><img id={"imgVoluntario"+this.props.id}  alt="..." width={320} height={320} /></center>
                        <div class="team-overlay"></div>
                    </div>
                    <div class="team-content text-center">
                        <h3 class="text-white h4">{this.props.nombre}</h3>
                        <p class="text-white opacity7 mb-2">Voluntario</p>
                        <ul class="social-icon-style1">
                            {redes}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }

}
import React, { Component, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import FooterDash from "./footerDash.component";
import NavBarDashBoard from "./navbarDash.component";
import SideBarDash from "./sidebarDash.component";

export default function Dashboard({ usuario }) {

    useEffect(() =>{
        var top = document.getElementById('barraNavegacion');
        var footer = document.getElementById('footerNavegacion');
        var estilo = document.getElementById('estiloPrincipal');
        var estiloPlugin = document.getElementById('estiloPlugin');

        top.style.display = 'none';
        footer.style.display = 'none';
        estilo.href = 'assets/css/adminlte.min.css';
        estiloPlugin.href = 'assets/plugins/overlayScrollbars/css/OverlayScrollbars.min.css';
        document.body.className = "hold-transition dark-mode sidebar-mini layout-fixed layout-navbar-fixed layout-footer-fixed";
    }, []);

    if (!usuario) {
        return <Navigate to='/iniciar-sesion' />
    }
    else {
        return(
            <div class="wrapper">
                <NavBarDashBoard />
                <SideBarDash usuario={usuario}/>
                <div id="contenidoTablero"></div>
                <FooterDash />
            </div>
        )
    }
}

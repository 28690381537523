import React, { Component } from "react";
import TutorItem from "./brigadaSaludListItem.component";
import saveAs from "file-saver";
import brigadaSaludTutorService from "../../services/brigadaSaludTutor.service";
import brigadaSaludHijoService from "../../services/brigadaSaludHijo.service";
import Swal from "sweetalert2";
import * as XLSX from 'xlsx';

class TablaSalud extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tutores: [],
            tutoresInfo: []
        }
    }

    componentDidMount() {
        this.obtenerTutores();
    }

    obtenerTutores = () => {
        brigadaSaludTutorService.getAll()
            .then(response => {
                var tuto = [];

                for (let i = 0; i < response.data.length; i++) {
                    var tutorI = response.data[i];
                    tutorI.createdAt = '';
                    tutorI.updatedAt = '';

                    tuto.push(
                        <TutorItem
                            id={tutorI.id}
                            nombre={tutorI.nombre}
                            paterno={tutorI.paterno}
                            materno={tutorI.materno}
                            calle={tutorI.calle}
                            ext={tutorI.ext}
                            cp={tutorI.cp}
                            colonia={tutorI.colonia}
                            correo={tutorI.correo}
                            telefono={tutorI.telefono}
                            hijos={tutorI.hijos}
                            verificado={tutorI.verificado}
                        />
                    );
                }

                this.setState({
                    tutores: tuto,
                    tutoresInfo: response.data
                });
            })
            .catch(e => {
                console.log(e);
            });
    }

    exportarExcel = () => {
        brigadaSaludHijoService.getAll()
            .then(response => {
                try {
                    for (let i = 0; i < response.data.length; i++) {
                        var hijoI = response.data[i];
                        hijoI.createdAt = '';
                        hijoI.updatedAt = '';
                        hijoI.servicios = hijoI.servicios.join(", ") || "S/N";
                    }

                    var headersTutor = ["Id", "Nombre", "Apellido Paterno", "Apellido Materno", "Calle", 
                        "Num. Interior", "Num. Exterior", "Codigo Postal", "Colonia", "Ciudad", "Estado", 
                        "Correo", "Telefono", "Num. de hijos", "Verificado"];

                    var headersHijo = ["Id", "Nombre", "Apellido Paterno", "Apellido Materno", "CURP", "Edad", 
                        "Enfermedad, alergia y/o discapacidad", "Servicios medicos solicitados", "Id del tutor"];

                    var now = new Date();
                    var archivo = XLSX.utils.book_new();
                    var hojaTutores = XLSX.utils.aoa_to_sheet([headersTutor]);
                    var hojaHijos = XLSX.utils.aoa_to_sheet([headersHijo]);
                    
                    XLSX.utils.sheet_add_json(hojaTutores, this.state.tutoresInfo, { origin: 'A2', skipHeader: true });
                    XLSX.utils.sheet_add_json(hojaHijos, response.data, { origin: 'A2', skipHeader: true });

                    XLSX.utils.book_append_sheet(archivo, hojaTutores, 'Tutores');
                    XLSX.utils.book_append_sheet(archivo, hojaHijos, 'Hijos');
                    let fecha = now.getDate() + "-" + (now.getMonth() + 1) + "-" + now.getFullYear();

                    XLSX.writeFile(archivo, "Tutores " + fecha + ".xlsx");

                } catch (error) {
                    console.log(error);
                    Swal.fire({
                        icon: 'error',
                        text: "Hubo un error al exportar los datos al Excel"
                    });
                }
            })
            .catch(e => {
                Swal.fire({
                    icon: 'error',
                    text: "Hubo un error al exportar los datos al Excel"
                });
            });
    }


    render() {
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div class="row justify-content-between">
                        <div class="col-6">
                            <h2 className="myaccount-content">Tutores registrados</h2>
                        </div>
                        <div class="col-2-1">
                            <button type="button" onClick={this.exportarExcel} class="btn btn-success btn-lg">
                                <i class="fas fa-file-excel"></i> Descargar Excel</button>
                        </div>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table table-striped table-hover">
                        <thead class="thead-dark">
                            <tr>
                                <th class="text-center align-middle" scope="col">Nombre</th>
                                <th class="text-center align-middle" scope="col">Apellidos</th>
                                <th class="text-center align-middle" scope="col">Dirección</th>
                                <th class="text-center align-middle" scope="col">Email</th>
                                <th class="text-center align-middle" scope="col">Telefono</th>
                                <th class="text-center align-middle" scope="col">Numero de Hijos</th>
                                <th class="text-center align-middle" scope="col">Verificado</th>
                                <th class="text-center align-middle" scope="col"></th>
                                <th class="text-center align-middle" scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.tutores}
                        </tbody>
                    </table>

                </div>

            </div>
        );
    }
}

export default TablaSalud;
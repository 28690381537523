import React, { Component } from "react";
import Swal from 'sweetalert2';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import CarouselService from "../../services/carousel.service";
import ReactDOM from "react-dom";
import CrearCarousel from "./createCarousel.component";
import ListItemCarousel from "./listItemCarousel.component";

export default class ListarCarousel extends Component {
    constructor(props) {
        super(props);

        this.obtenerCarousel = this.obtenerCarousel.bind(this);
        this.crearCarousel = this.crearCarousel.bind(this);

        this.state = {
            carousel: []
        };
    }

    componentDidMount() {
        this.obtenerCarousel();
    }

    obtenerCarousel() {
        CarouselService.getAll()
            .then(response => {
                var carros = [];
                for (let i = 0; i < response.data.length; i++) {
                    carros.push(<ListItemCarousel 
                        id={response.data[i].id}
                        titulo={response.data[i].titulo}
                        subtitulo={response.data[i].subtitulo}
                        linkImagen={response.data[i].linkImagen}
                        estatus={response.data[i].estatus}/>
                    );
                }

                this.setState({
                    carousel: carros
                });
            })
            .catch(e => {
                console.log(e);
            });
    }

    crearCarousel(e) {
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }
        ReactDOM.render(<CrearCarousel />, divDash);
    }

    render() {
        const { carousel } = this.state;
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div class="row justify-content-between">
                        <div class="col-6">
                            <h2 className="myaccount-content">Carousel registrados</h2>
                        </div>
                        <div class="col-3-2">
                            <button type="button" onClick={this.crearCarousel} class="btn btn-warning btn-lg">Agregar Carousel</button>
                        </div>
                    </div>
                </div>

                <table class="table table-striped table-hover">
                    <thead class="thead-dark">
                        <tr>
                            <th class="text-center" scope="col">Titulo</th>
                            <th class="text-center" scope="col">Subtitulo</th>
                            <th class="text-center" scope="col">Estado</th>
                            <th class="text-center" scope="col"></th>
                            <th class="text-center" scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {carousel}
                    </tbody>
                </table>
            </div>
        )
    }
}
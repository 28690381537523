import React, { useState, useEffect, createContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';

export default function BrigadaDatosHijo({ data }) {

    return (
        <div className="container mt-3">
            <div className="quform-elements">
                <div className="row">
                    <div className="col-sm">
                        <div className="quform-element">
                            <label htmlFor="name">Nombre</label>
                            <div className="quform-input">
                                <input className="form-control" readOnly value={data.nombre} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div className="quform-element">
                            <label htmlFor="name">Apellido Paterno</label>
                            <div className="quform-input">
                                <input className="form-control" readOnly value={data.paterno} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div className="quform-element">
                            <label htmlFor="name">Apellido Materno</label>
                            <div className="quform-input">
                                <input className="form-control" readOnly value={data.materno} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm">
                        <div className="quform-element">
                            <label htmlFor="name">CURP</label>
                            <div className="quform-input">
                                <input className="form-control" readOnly value={data.curp} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div className="quform-element">
                            <label htmlFor="name">Edad</label>
                            <div className="quform-input">
                                <input className="form-control" readOnly value={data.edad} />
                            </div>
                        </div>
                    </div>

                    <div className="col-sm">
                        <div className="quform-element">
                            <label htmlFor="name">Enfermedad, alergia y/o discapacidad</label>
                            <div className="quform-input">
                                <input className="form-control" readOnly value={data.discapacidad} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="quform-element">
                        <label htmlFor="name">Servicios m&eacute;dicos solicitados</label>
                        <div className="row mb-4">
                            {data.servicios.map((member, index) => (
                                <div className="col-sm">
                                    <button type="button" class="btn btn-danger w-100" id={"btn" + index}>
                                        {member}
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )

}

import React, { Component, useEffect, useState } from "react";
import Card from './Noticias/card.component.js';
import {  Link, useNavigate } from 'react-router-dom'
import ProgramaTarjeta from "./Programas/programaInicio.component.js";
import VoluntarioTarjeta from "./Voluntarios/voluntarioInicio.component.js";
import InicioGrid from "../components/Causas/inicioGrid.component.js";
import InicioCarousel from "./Carousel/inicioCarousel.component.js";
import TestimonioInicio from "./Testimonios/testimoniosInicio.component"
import BeneficiariosInicio from './beneficiariosInicio.component';
import { Helmet } from 'react-helmet';

export default function Inicio() {

    const navigate = useNavigate();
    const [inicio, setInicio] = useState();

    useEffect(() => {
        var top = document.getElementById('barraNavegacion');
        var footer = document.getElementById('footerNavegacion');
        var estilo = document.getElementById('estiloPrincipal');
        var estiloPlugin = document.getElementById('estiloPlugin');

        document.body.className = "";
        top.style.display = 'block';
        footer.style.display = 'block';
        estilo.href = 'assets/css/styles.css';
        estiloPlugin.href = 'assets/css/plugins.css';        
        
    }, []);

    return (
        <div class="main-wrapper">
            <Helmet>
                <title>M&eacute;xico Amparame</title>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content="México Ampárame"/>
                <meta property="og:image" content="https://eacnur.org/blog/wp-content/uploads/2017/12/formas-de-ayuda-solidaria.jpg"/>

                <meta property="twitter:card" content="summary_large_image"/>
                <meta property="twitter:title" content="México Ampárame"/>
                <meta property="twitter:description" content="Página web de México Ampárame."/>
                <meta property="twitter:image" content="https://eacnur.org/blog/wp-content/uploads/2017/12/formas-de-ayuda-solidaria.jpg"/>    
            </Helmet>

            {/* <!-- MAIN WRAPPER
                ================================================== --> */}                        
            
            <div >

                {/* <!-- BANNER
                    ================================================== --> */}

                <InicioCarousel />)

                {/* <!-- ABOUT
                    ================================================== --> */}
                <section class="mb-8">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-7 mb-1-9 mb-md-2-9 mb-lg-0 wow fadeIn" data-wow-delay="200ms">
                                <div class="about-us pe-lg-5">
                                    <div class="text-center text-md-end">
                                        <img src="/assets/img/content/about-01.jpg" class="rounded tilt" alt="..." />
                                    </div>
                                    <img src="/assets/img/content/about-02.jpg" class="about-img rounded tilt d-none d-md-inline-block" alt="..." />
                                </div>
                            </div>
                            <div class="col-lg-5 wow fadeIn" data-wow-delay="400ms">
                                <div class="ps-xl-8">
                                    <div class="section-heading mb-4 text-start">
                                        <span class="d-block text-primary display-22 display-md-21 display-lg-20 alt-font wow text-animation fadeInRight" >Nosotros</span>
                                        <h2 class="mb-0">Mexico Amparame</h2>
                                    </div>
                                    <p>Somos una asociación civil que busca coberturar y brindar atención emocional además de servicios de educación y alimentación a todas las niñas y niños huerfanos por COVID en México</p>
                                    <a href="/causasGrid" class="butn-read"><span>Leer más</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src="/assets/img/icons/icon-7.png" alt="" class="position-absolute left-5 top-15 ani-top-bottom opacity7 d-none d-lg-block" />
                    <img src="/assets/img/icons/icon-8.png" alt="" class="position-absolute right-5 top-75 ani-top-bottom opacity7 d-none d-lg-block" />
                </section>

                {/* <!-- CAUSES
                    ================================================== --> */}
                <section class="mb-8 pt-1-6 pt-lg-14 bg-img cover-background" style={{ backgroundImage: 'url(assets/img/content/map-bg.jpg' }}>
                    <div class="container">
                        {<InicioGrid />}
                    </div>
                </section>

                {/* <!-- COUNTER
                    ================================================== --> */}
                <BeneficiariosInicio/>
                {/* 
                    <!-- SERVICES
                    ================================================== --> */}


                {/* <!-- PROGRAMAS */}
                <ProgramaTarjeta />
                {/* ================================================== -->                

                    <!-- TESTIMONIAL
                    ================================================== --> */}
                <TestimonioInicio />

                {/* <!-- VOLUNTEERS */}
                <VoluntarioTarjeta />
                {/* ================================================== -->


                    <!-- CALL TO ACTION
                    ================================================== --> */}
                <section class="bg-img cover-background mx-lg-4 mx-xl-6 rounded-lg wow fadeIn" id="img-ninios" data-wow-delay="200ms"
                    data-overlay-dark="6" style={{ backgroundImage: 'url(assets/img/bg/bg-02.jpg)' }}>
                    <div class="container">
                        <div class="row">
                            <div class="col-md-10 col-lg-8">
                                <h2 class="text-white display-16 display-sm-11 display-md-9 display-lg-5 mb-1-9">Queremos un México sin marginacion en donde nuestros niños y niñas puedan crecer felices</h2>
                                
                                <a class="butn secondary getMoreCausasBtn" href="/dona">Ayudanos a ayudar</a>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- BLOG
                    ================================================== --> */}
                <section>
                    <div class="container">
                        <div class="section-heading">
                            <span class="d-block text-primary display-22 display-md-21 display-lg-20 alt-font wow text-animation fadeInRight" >Blog</span>
                            <h2>Noticias relevantes</h2>
                        </div>
                        <div class="row mt-n1-9">
                            <Card />
                        </div>
                        <center><a class="butn secondary getMoreCausasBtn" href="/noticias">Ver más noticias</a></center>
                    </div>
                    <img src="img/icons/icon-10.png" alt="" class="position-absolute left-5 top-15 ani-top-bottom opacity4 d-none d-lg-block" />

                </section>
            </div>

            {/* <!-- start scroll to top --> */}
            <a onClick={ ()=>{window.scrollTo(0, 0)}} class="scroll-to-top"><i class="fas fa-angle-up" aria-hidden="true"></i></a>
            {/* <!-- end scroll to top --> */}
        </div>
    )

}

import React, { Component } from "react";
import ProyectoService from "../../services/proyecto.service";
import CausasGridItem from "./causasGridItem.component";
import {Link} from "react-router-dom";
import { Helmet } from 'react-helmet';

export default class CausasGrid extends Component {
    constructor(props) {
        super(props);

        this.cambiarPagina = this.cambiarPagina.bind(this);
        this.crearGridItems = this.crearGridItems.bind(this);
        this.crearButtonsNavigation = this.crearButtonsNavigation.bind(this);        

        this.state = {
            arrayCasos : [],
            numPag : 1, 
            pagActual: 1,
            arrayNumPag: []
        }        
    }        

    crearGridItems(){
        let arr = [];        
        ProyectoService.findByActivo().then(response => {  
            var pags = 0;
            if(response.data.length >= 12){
                pags = Math.ceil(response.data.length/12);
            }else{
                pags = 1;
            }

            this.setState({
                numPag : pags
            })

            this.crearButtonsNavigation();

            var contador = 1;
            for (let i = (this.state.pagActual*12-12); i < response.data.length; i++) {                 
                if(contador < 13 && i < response.data.length){
                    var porcentaje = Math.ceil((response.data[i].dineroActual * 100) / response.data[i].dineroMeta);
                    if(porcentaje > 100){
                        porcentaje = '+'+100;
                    }
                    arr.push(<CausasGridItem titulo={response.data[i].titulo}            
                                                texto={response.data[i].texto} 
                                                dineroActual={response.data[i].dineroActual} 
                                                dineroMeta={response.data[i].dineroMeta} 
                                                porcentaje={porcentaje+'%'}
                                                id={response.data[i].id}
                                                imagenes={response.data[i].imagenes}
                                                />);
                    contador++;
                }else{
                    break;
                }                
            }
            this.setState({
                arrayCasos : arr
            })            
        });               
    }

    componentDidMount(){
        this.crearGridItems();
        var top = document.getElementById('barraNavegacion');
        var footer = document.getElementById('footerNavegacion');
        var estilo = document.getElementById('estiloPrincipal');
        var estiloPlugin = document.getElementById('estiloPlugin');    
        
        document.body.className = "";        
        top.style.display = 'block';    
        footer.style.display = 'block';
        estilo.href = 'assets/css/styles.css';
        estiloPlugin.href = 'assets/css/plugins.css';
    }

    cambiarPagina(i){   
        this.setState({
            arrayNumPag : [],
            arrayCasos : [],
            pagActual: i
        });
        this.crearGridItems();
        this.crearButtonsNavigation();
    }

    sigPagina(){
        if(this.state.pagActual == this.state.numPag){

        }else{
            this.cambiarPagina(this.state.pagActual+1);
        }
    }

    antPagina(){
        if(this.state.pagActual == 1){

        }else{
            this.cambiarPagina(this.state.pagActual-1);
        }
    }

    crearButtonsNavigation(){
        let arr = [];        
        for(let i = 1; i <= this.state.numPag; i++){                          

            if(i == this.state.pagActual){
                arr.push(<li class="active" onClick={() => this.cambiarPagina(i)}><a>{i}</a></li>)
            }else{
                arr.push(<li onClick={() => this.cambiarPagina(i)} ><a>{i}</a></li>)
            }            
        }
        this.setState({
            arrayNumPag: arr
        })
    }

    render() {                   
        const {arrayCasos, arrayNumPag} = this.state;
        return(            
            <div>     
                <Helmet>
                    <title>M&eacute;xico Amparame - Causas</title>
                    <meta property="og:type" content="website"/>
                    <meta property="og:title" content="Causas"/>
                    <meta property="og:image" content="https://eacnur.org/blog/wp-content/uploads/2017/12/formas-de-ayuda-solidaria.jpg"/>

                    <meta property="twitter:card" content="summary_large_image"/>
                    <meta property="twitter:title" content="Causas"/>
                    <meta property="twitter:description" content="Causas de México Ampárame."/>
                    <meta property="twitter:image" content="https://eacnur.org/blog/wp-content/uploads/2017/12/formas-de-ayuda-solidaria.jpg"/>    
                </Helmet> 
                <img id="img-e"/>           
                <section class="page-title-section bg-img cover-background mx-lg-4 mx-xl-6 rounded-lg" data-overlay-dark="4" style={{ backgroundImage: `url("/assets/img/causes/causas-banner.jpg")`}} >
                <div class="container">
                    <div class="row text-center">
                        <div class="col-md-12">
                            <h1 class="text-animationfadeInRight" >Causas</h1>
                            <ul>
                                <li><Link to={"/"}><a>Regresar</a></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="position-absolute z-index-1 right bottom-n5 opacity2 ani-left-right">
                    <span class="square bg-primary"></span>
                    <span class="square bg-secondary"></span>
                </div>
            </section>
            
            <section>
                <div class="container">
                    <div class="section-heading">
                        <span class="d-block text-primary display-22 display-md-21 display-lg-20 alt-font wow text-animation" data-in-effect="fadeInRight">nuestras causas</span>
                        <h2>Causas populares</h2>
                    </div>
                    <div class="row mt-n1-9">                        
                          
                        {arrayCasos}

                    </div>
                    <div class="row wow fadeIn" data-wow-delay="400ms">
                        <div class="col-sm-12">                        
                            <div class="text-center mt-6 mt-lg-7">
                                <div class="pagination text-extra-dark-gray">
                                    <ul>
                                        <li onClick={() => this.antPagina()}><a><i class="fas fa-long-arrow-alt-left me-1 d-none d-sm-inline-block"></i>Anterior</a></li>
                                        {arrayNumPag}
                                        <li onClick={() => this.sigPagina()}><a>Siguiente<i class="fas fa-long-arrow-alt-right ms-1 d-none d-sm-inline-block"></i></a></li>
                                    </ul>
                                </div>
                            </div>                        
                        </div>
                    </div>
                </div>
            </section>
            </div>
        );
    }
}
import { useState, useEffect } from "react";
import { Routes, Route, Link, useNavigate } from "react-router-dom";

import Dashboard from "./components/Dashboard/dashboard.component.js";
import Inicio from "./components/inicio.component.js";
import Boton from "./components/button.component.js";
import Dona from "./components/dona.component.js";
import Login from "./components/login.component.js";
import NotFound404 from "./components/404.component.js";

import CausasGrid from "./components/Causas/causasGrid.component.js";
import CausasDetalle from "./components/Causas/causasDetalle.component.js";
import CausasList from "./components/Causas/causasList.component";

import GridNoticias from "./components/Noticias/gridNoticias.component.js";
import Noticia from "./components/Noticias/noticia.component";

import VoluntariosGrid from "./components/Voluntarios/voluntarioGrid.component.js";

import ProgramaGrid from "./components/Programas/programaGrid.component.js";
import ProgramaDetalle from "./components/Programas/programaDetalle.component.js";

import http from "./http-common";

import SolicitudApoyo from "./components/apoyo.component.js";
import Gracias from "./components/graciasDonar.component.js"
import PasitosSanos from "./components/BrigadaInfantil/brigadaSalud.component.js";
import VerificaLiga from "./components/BrigadaInfantil/verificaliga.component.js";

function App() {
  const [usuario, setUsuario] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const loggedInUser = localStorage.getItem("usuario");
    if (loggedInUser) {
      try {
        const foundUser = JSON.parse(loggedInUser);
        setUsuario(foundUser);
      } catch (error) {
        console.log("Error");
      }
    }
  }, []);

  return (
    <div className="App">
      {/* <!-- HEADER
      ================================================== --> */}
      <header id="barraNavegacion" class="header-style3">
        <div id="top-bar">
          <div class="container">
            <div class="row">
              <div class="col-md-9 col-xs-12">
                <div class="top-bar-info">
                  <ul class="ps-0">
                    <li>
                      <i class="ti-mobile text-secondary"></i>(951) 98-56-720
                    </li>
                    <li class="d-none d-sm-inline-block">
                      <i class="ti-email text-secondary"></i>
                      acmexicoamparame@gmail.com
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-xs-12 col-md-3 d-none d-md-block">
                <ul class="top-social-icon ps-0">
                  <li>
                    <a href="https://www.facebook.com/MxAmparame">
                      <i class="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/MxAmparame">
                      <i class="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/mxamparame/">
                      <i class="fab fa-instagram"></i>
                    </a>
                  </li>
                  {usuario ? (
                    <li>
                      <div class="dropdown ">
                        <a
                          class="dropdown-toggle"
                          role="button"
                          id="dropdownMenuLink"
                          data-bs-toggle="dropdown"
                          data-bs-display="static"
                          aria-expanded="false"
                        >
                          {usuario.nombre}
                        </a>
                        <ul
                          class="dropdown-menu dropdown-menu-dark dropdown-menu-lg-end"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <li>
                            <a
                              class="dropdown-item"
                              style={{ fontSize: "1em", textAlign: "right" }}
                              href="/dashboard"
                            >
                              Tablero
                            </a>
                          </li>
                          <li>
                            <a
                              id="cerrar-sesion-a"
                              class="dropdown-item"
                              style={{ fontSize: "1em", textAlign: "right" }}
                              onClick={() => {
                                setUsuario(null);
                                localStorage.clear();
                                navigate('/');
                              }}
                            >
                              Cerrar sesión
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  ) : (
                    <li>
                      <a href="/iniciar-sesion">Iniciar sesión</a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="navbar-default">
          {/* <!-- top search --> */}
          <div class="top-search bg-primary">
            <div class="container">
              <form
                class="search-form"
                action="search.html"
                method="GET"
                accept-charset="utf-8"
              >
                <div class="input-group">
                  <span class="input-group-addon cursor-pointer">
                    <button
                      class="search-form_submit fas fa-search text-white"
                      type="submit"
                    ></button>
                  </span>
                  <input
                    type="text"
                    class="search-form_input form-control"
                    name="s"
                    autoComplete="off"
                    placeholder="Type and hit enter..."
                  />
                  <span class="input-group-addon close-search">
                    <i class="fas fa-times mt-3"></i>
                  </span>
                </div>
              </form>
            </div>
          </div>
          {/* <!-- end top search --> */}

          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-12">
                <div class="menu_area">
                  <nav class="navbar navbar-expand-lg navbar-light p-0">
                    <div class="navbar-header navbar-header-custom">
                      {/* <!-- logo --> */}
                      <a href="/" class="navbar-brand logowhite">
                        <img
                          id="logo1"
                          src="/assets/img/logos/logo-footer.png"
                          alt="logo"
                          width="auto"
                          height="auto"
                        />

                        <img
                          id="logo2"
                          src="/assets/img/logos/logo.png"
                          alt="logo"
                          width="150px"
                          height="auto"
                        />
                      </a>
                      {/* <!-- end logo --> */}
                    </div>

                    <div class="navbar-toggler bg-primary"></div>

                    {/* <!-- menu area --> */}
                    <ul class="navbar-nav ms-auto mt-lg-2" id="nav">
                      <li>
                        <a href="/">Inicio</a>
                      </li>
                      <li>
                        <a href="/causasGrid">Causas</a>
                      </li>
                      <li>
                        <a href="/programas">Programas</a>
                      </li>
                      <li>
                        <a href="/voluntarios">Voluntarios</a>
                      </li>
                      <li>
                        <a href="/noticias">Blog</a>
                      </li>
                      <li>
                        <a href="/solicitud-apoyo">Apoyo</a>
                      </li>
                      <li>
                        <a href="/brigadasaludinfantil">Salud Infantil</a>
                      </li>
                    </ul>
                    {/* <!-- end menu area -->

                                  <!-- attribute navigation --> */}
                    <div class="attr-nav">
                      <ul>
                        <li class="d-none d-xl-inline-block">
                          <a href={"/dona" + 0} class="butn theme small text-white">
                            Donar ahora
                          </a>
                        </li>
                      </ul>
                    </div>
                    {/* <!-- end attribute navigation --> */}
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>


      <Routes>
        <Route path="/" element={<Inicio />}></Route>
        <Route
          path="dashboard"
          element={<Dashboard usuario={usuario} />}
        ></Route>
        <Route
          path="solicitud-apoyo"
          element={<SolicitudApoyo />}
        ></Route>
        <Route
          path="iniciar-sesion"
          element={<Login usuario={usuario} setUsuario={setUsuario} />}
        ></Route>
        <Route path="voluntarios" element={<VoluntariosGrid />}></Route>
        <Route path="programas" element={<ProgramaGrid />} />
        <Route
          exact
          path="programasDetalle:id"
          element={<ProgramaDetalle />}
        />
        <Route path="brigadasaludinfantil" element={<PasitosSanos />}></Route>
        <Route path="verificacion:id" element={<VerificaLiga usuario={usuario}/>}></Route>        
        

        <Route path="causasDetalle:id" element={<CausasDetalle />} />
        <Route path="causasGrid" element={<CausasGrid />}></Route>
        <Route path="s3Url" element={<Boton />}></Route>
        <Route path="noticias" element={<GridNoticias />} />
        <Route exact path="noticias:id" element={<Noticia />} />
        <Route exact path="dona:id" element={<Dona />} />
        <Route path="gracias-por-donar" element={<Gracias />} />
        <Route path="*" element={<NotFound404 />} />
      </Routes>
      

      {/* <!-- FOOTER
        ================================================== --> */}
      <footer
        id="footerNavegacion"
        class="bg-img cover-background"
        data-overlay-dark="85"
      >
        <div class="container">
          <div class="row mt-n2-9">
            <div
              class="col-lg-5 col-md-6 mt-2-9 wow fadeIn"
              data-wow-delay="200ms"
            >
              <div class="mb-1-7 w-80 w-md-60 w-lg-80">
                <img src="/assets/img/logos/logo-footer.png" alt="..." width="70px" />
              </div>
              <p class="text-white opacity9 font-weight-300 mb-1-9">
                México Amparame
                <br />
                {/* Oaxaca 2018-2019. */}
              </p>
              <ul class="social-icon-style2">
                <li>
                  <a href="https://www.facebook.com/MxAmparame">
                    <i class="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/MxAmparame">
                    <i class="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/mxamparame/">
                    <i class="fab fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div
              class="col-lg-3 col-md-7 mt-2-9 wow fadeIn"
              data-wow-delay="400ms"
            >
              <div class="ps-lg-1-9 ps-xl-8">
                <h3 class="text-secondary h5 mb-1-9">Acceso r&aacute;pido</h3>
                <ul class="footer-list ps-0">
                  <li class="pt-0">
                    <a href="/">Sobre nosotros</a>
                  </li>
                  <li>
                    <a href="/programas">Programas</a>
                  </li>
                  <li>
                    <a href="/voluntarios">Voluntarios</a>
                  </li>
                  <li>
                    <a href="/causasGrid">Causas</a>
                  </li>
                  <li class="pb-0">
                    <a href="/noticias">Blogs</a>
                  </li>
                </ul>
              </div>
            </div>
            <div
              class="col-lg-3 col-md-7 mt-2-9 wow fadeIn"
              data-wow-delay="600ms"
            >
              <div>
                <h3 class="text-secondary h5 mb-1-9">Cont&aacute;ctanos</h3>
                <ul class="footer-list ps-0">
                  <li class="pt-0">
                    <span class="d-inline-block align-middle">
                      <i class="fas fa-map-marker-alt display-29"></i>
                    </span>
                    <span class="d-inline-block w-85 align-top ps-2">
                      Nicolás bravo #111 <br />
                      Santa Cruz Xoxocotlán, Oaxaca
                    </span>
                  </li>
                  <li>
                    <span class="d-inline-block align-middle">
                      <i class="far fa-envelope display-29"></i>
                    </span>
                    <span class="d-inline-block w-85 align-top ps-2">
                      acmexicoamparame@gmail.com
                    </span>
                  </li>

                  <li class="pb-0">
                    <span class="d-inline-block align-middle">
                      <i class="fas fa-phone display-29"></i>
                    </span>
                    <span class="d-inline-block w-85 align-top ps-2">
                      (951) 98-56-720
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            class="footer-bar borders-top border-color-light-white wow fadeIn"
            data-wow-delay="400ms"
          >
            <div class="row">
              <div class="col-md-12 text-center">

                <div>
                  <p class="footer-list ps-0">
                    Desarrollado por
                  </p>

                  <a href="https://karimnot.com/public">
                    <img
                      id="karimLogo"
                      src="https://karimnot.com/assets/logokarim-34252cf47b98fc41f4325030509f7a46d2a88ae7b8e0d48bf17bcb7ff7666419.png"
                      alt="Logo KarimNot Inc"
                    />
                  </a>

                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;

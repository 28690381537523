import http from "../http-common";

class ApoyoService {
    getAll() {
        return http.get("/apoyos");
    }

    get(id) {
        return http.get("/apoyos/" + id);
    }

    create(data) {
        return http.post("/apoyos", data);
    }

    update(id, data) {
        return http.put("/apoyos/" + id, data);
    }

    delete(id) {
        return http.delete(`/apoyos/${id}`);
    }

    deleteAll() {
        return http.delete("/apoyos");
    }

}

export default new ApoyoService();

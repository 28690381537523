import http from "../http-common";

class SolicitudService {
    getAll() {
        return http.get("/solicitudes");
    }

    get(id) {
        return http.get("/solicitudes/" + id);
    }

    create(data) {
        return http.post("/solicitudes", data);
    }

    update(id, data) {
        return http.put("/solicitudes/" + id, data);
    }

    delete(id) {
        return http.delete("/solicitudes/" + id);
    }

    deleteAll() {
        return http.delete("/solicitudes");
    }

}

export default new SolicitudService();

import http from "../http-common";

class ProgramaService {
    getAll() {
        return http.get("/programas");
    }

    get(id){
        return http.get(`/programas/${id}`);
    }

    create(data) {
        return http.post("/programas", data);
    }

    update(id, data) {
        return http.put("/programas/" + id, data);
    }

    delete(id) {
        return http.delete("/programas/" + id);
    }

    deleteAll() {
        return http.delete("/programas");
    }

    getAllActive(){
        return http.get("/programas/activos");
    }

    getAllHomePage(){
        return http.get("/programas/habilitados");
    }

}

export default new ProgramaService();

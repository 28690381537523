import React, { Component } from "react";
import ReactDOM from "react-dom";
import Swal from "sweetalert2";
import VoluntarioService from "../../services/voluntario.service";
import EditarVoluntario from "./editarVoluntario.component";
import ListaVoluntarios from "./voluntarioList.component";
import http from "../../http-common.js";

export default class VoluntarioListItem extends Component {

    constructor(props) {
        super(props);

        this.onChangeMostrarInicio = this.onChangeMostrarInicio.bind(this);
        this.crearRedesSociales = this.crearRedesSociales.bind(this);
        this.editarVoluntario = this.editarVoluntario.bind(this);
        this.onChangeActivo = this.onChangeActivo.bind(this);
        this.eliminarPrograma = this.eliminarPrograma.bind(this);

        this.state = {
            id: this.props.id,
            nombre: this.props.nombre,
            paterno: this.props.paterno,
            materno: this.props.materno,
            correo: this.props.correo,
            telefono: this.props.telefono,
            facebookLink: this.props.facebookLink,
            instagramLink: this.props.instagramLink,
            twitterLink: this.props.twitterLink,
            linkedinLink: this.props.linkedinLink,
            activo: this.props.activo,
            inicio: this.props.inicio,
            imagen: this.props.imagen,
            redes: this.crearRedesSociales()
        }
    }

    onChangeActivo(e) {
        let target = e.target;
        let inicioActivo = this.state.inicio;
        console.log(target.checked);

        if (!target.checked) {
            inicioActivo = false;
        }

        this.setState({ activo: target.checked, inicio: inicioActivo }, () => {
            VoluntarioService.update(this.state.id, this.state)
                .then(response => {
                    console.log(response.data);
                })
                .catch(e => {
                    console.log(e);
                });
        });
    }

    onChangeMostrarInicio(e) {
        let target = e.target;
        let value = target.checked;

        if (target.checked && this.state.activo) {
            VoluntarioService.getAllHomePage()
                .then(response => {
                    if (response.data.length >= 3) {
                        Swal.fire({
                            icon: 'warning',
                            text: "Solo se permiten tres voluntarios para mostrar en el inicio."
                        })
                    }
                    else {
                        this.setState({ inicio: value }, () => {
                            VoluntarioService.update(this.state.id, this.state)
                                .then(response => {
                                    console.log(response.data);
                                })
                                .catch(e => {
                                    console.log(e);
                                });
                        });
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        }
        else if (!target.checked) {
            this.setState({ inicio: value }, () => {
                VoluntarioService.update(this.state.id, this.state)
                    .then(response => {
                        console.log(response.data);
                    })
                    .catch(e => {
                        console.log(e);
                    });
            });
        }
        else {
            Swal.fire({
                icon: 'warning',
                text: "Necesita estar activo para mostrarse en el inicio."
            })
        }
    }

    crearRedesSociales() {
        let redesSociales = [];
        if(this.props.facebookLink != ""){
            console.log("que");
            redesSociales.push(<li key="redes1"><a href={this.props.facebookLink}><i class="fab fa-facebook"></i></a></li>);
        }

        if(this.props.instagramLink != ""){
            console.log("p2");
            redesSociales.push(<li key="redes2"><a href={this.props.instagramLink}><i class="fab fa-instagram"></i></a></li>);
        }

        if(this.props.twitterLink != ""){
            console.log("con");
            redesSociales.push(<li key="redes3"><a href={this.props.twitterLink}><i class="fab fa-twitter"></i></a></li>);
        }

        if(this.props.linkedinLink != ""){
            console.log("esto");
            redesSociales.push(<li key="redes4"><a href={this.props.linkedinLink}><i class="fab fa-linkedin-in"></i></a></li>);
        }

        this.setState({
            redes: redesSociales
        })
    }

    editarVoluntario() {
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }

        ReactDOM.render(<EditarVoluntario
            id={this.state.id}
            nombre={this.state.nombre}
            paterno={this.state.paterno}
            materno={this.state.materno}
            correo={this.state.correo}
            telefono={this.state.telefono}
            facebook={this.state.facebookLink}
            instagram={this.state.instagramLink}
            twitter={this.state.twitterLink}
            linkedin={this.state.linkedinLink}
            activo={this.state.activo}
            inicio={this.state.inicio} 
            imagen={this.state.imagen}/>, divDash);
    }

    eliminarPrograma() { 
        Swal.fire({
            text: '¿Deseas eliminar este elemento?',
            icon: 'warning',                                
            showCancelButton: true,
            confirmButtonText: 'Eliminar',                                
          }).then((result) => {                                
            if (result.isConfirmed) {
                http.delete('/s3url3/'+this.state.imagen);
                VoluntarioService.delete(this.props.id).then(response => {
                    let divDash = document.getElementById('contenidoTablero');
                    Swal.fire('Eliminado', 'Elemento Eliminado Correctamente', 'success')
                    if (divDash.children.length > 0) {
                        ReactDOM.unmountComponentAtNode(divDash);
                    }
                    ReactDOM.render(<ListaVoluntarios />, divDash);
                });
            }
            
        });

    }

    render() {

        const { redes } = this.state;
        return (
            <tr key={this.state.id}>
                <td class="col-md-1 align-middle text-center">{this.state.id}</td>
                <td class="col-md-2 align-middle text-center">{this.state.nombre + " " + this.state.paterno}</td>
                <td class="col-md-2 align-middle text-center">{this.state.correo}</td>
                <td class="col-md-2 align-middle text-center">{this.state.telefono}</td>
                <td class="col-md-2 align-middle text-center">
                    <div className="custom-control custom-switch">
                        <input type="checkbox" className="custom-control-input" onChange={this.onChangeActivo} id={"customSwitch" + this.state.id} checked={this.state.activo} />
                        <label className="custom-control-label" htmlFor={"customSwitch" + this.state.id}>{this.state.activo ? "Activo" : "Inactivo"}</label>
                    </div>
                </td>
                <td class="col-md-2 align-middle text-center">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" onChange={this.onChangeMostrarInicio} id={"flexCheckChecked" + this.state.id} checked={this.state.inicio} />
                        <label class="form-check-label" for={"flexCheckChecked" + this.state.id}></label>
                    </div>
                </td>
                <td class="col-md-2 align-middle text-center"><button class="btn btn-light" onClick={this.editarVoluntario}>Editar</button></td>
                <td class="col-md-1 align-middle text-center"><button class="btn btn-danger" onClick={this.eliminarPrograma}><i className="far fa-trash-alt"></i></button></td>
            </tr>
        )
    }
} 
import http from "../http-common";

class ProyectoService {
    getAll(){
        return http.get("/proyectos");
    }

    get(id){
        return http.get(`/proyectos/${id}`);
    }

    create(data){
        return http.post("/proyectos/", data);
    }

    update(id, data){
        return http.put(`/proyectos/${id}`, data);
    }

    delete(id){
        return http.delete(`/proyectos/${id}`);
    }

    deleteAll(){
        return http.delete(`/proyectos/`);
    }    

    findByActivo(){        
        return http.get('/proyectos/activo/');
    } 

    findByInicio(){        
        return http.get('/proyectos/inicio/');
    } 
}

export default new ProyectoService();
import React, { useState, useEffect, createContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';

export default function PasitosHijo({ onChange, id }) {

    const [nombre, setNombre] = useState("");
    const [paterno, setPaterno] = useState("");
    const [materno, setMaterno] = useState("");
    const [curp, setCurp] = useState("");
    const [edad, setEdad] = useState("");
    const [discapacidad, setDiscapacidad] = useState("N/A");
    const [servicios, setServicios] = useState([]);
    const [servicioAlt, setServicioAlt] = useState("");
    const [tieneDiscapacidad, setTiene] = useState(false);
    const [otroServicio, setOtro] = useState(true);


    const onChangeNombre = event => {
        let valor = event.target.value;
        setNombre(valor);
        onChange(id, 'nombre', valor);
    }

    const onChangePaterno = event => {
        let valor = event.target.value;
        setPaterno(valor);
        onChange(id, 'paterno', valor);
    }

    const onChangeMaterno = event => {
        let valor = event.target.value;
        setMaterno(valor);
        onChange(id, 'materno', valor);
    }

    const onChangeCURP = event => {
        let valor = event.target.value;
        setCurp(valor);
        onChange(id, 'curp', valor);
    }

    const onChangeEdad = event => {
        let valor = event.target.value;
        setEdad(valor);
        onChange(id, 'edad', valor);
    }

    const onChangeTieneDiscapacidad = (event, tiene) => {
        if(!tiene){
            setDiscapacidad("N/A");
            onChange(id, 'discapacidad', "N/A");
        }
        else{
            setDiscapacidad("");
            onChange(id, 'discapacidad', "");
        }
        setTiene(tiene);
    }

    const onChangeDiscapacidad = event => {
        let valor = event.target.value;
        setDiscapacidad(valor);
        onChange(id, 'discapacidad', valor);
    }

    const onChangeServicios = (event, value) => {
        var valor = servicios;
        if (event.target.className.includes('active')) {
            valor.push(value);
        }
        else {
            let index = valor.indexOf(value);
            if (index > -1) {
                valor.splice(index, 1);
            }
        }
        setServicios(valor);
        onChange(id, 'servicios', servicios);
    }

    const onChangeAlterno = (event) => {
        let viejos = servicios;
        let viejoValor = servicioAlt;

        if (event.target.className.includes('active')) {
            setOtro(false);
        }
        else {
            let index = viejos.indexOf(viejoValor);
            if (index > -1) {
                viejos.splice(index, 1);
            }
            setOtro(true);
            setServicios(viejos);
            onChange(id, 'servicios', servicios);
        }
        
    }

    const onChangeServicioAlterno = (event) => {
        let viejos = servicios;
        let viejoValor = servicioAlt;
        let index = viejos.indexOf(viejoValor);
        if (index > -1) {
            viejos.splice(index, 1);
        }

        viejos.push(event.target.value);
        setServicioAlt(event.target.value)
        setServicios(viejos);
        onChange(id, 'servicios', servicios);
    }

    return (
        <div className="container mt-3">
            <div className="quform-elements">
                <div className="row">
                    <div className="col-sm">
                        <div className="quform-element">
                            <label htmlFor="name">Nombre(s) <span className="quform-required">*</span></label>
                            <div className="quform-input">
                                <input className="form-control" id="nombre" type="text" name="nombre" value={nombre} onChange={onChangeNombre} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div className="quform-element">
                            <label htmlFor="name">Apellido Paterno <span className="quform-required">*</span></label>
                            <div className="quform-input">
                                <input className="form-control" id="paterno" type="text" name="paterno" value={paterno} onChange={onChangePaterno} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div className="quform-element">
                            <label htmlFor="name">Apellido Materno <span className="quform-required">*</span></label>
                            <div className="quform-input">
                                <input className="form-control" id="materno" type="text" name="materno" value={materno} onChange={onChangeMaterno} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm">
                        <div className="quform-element">
                            <label htmlFor="name">CURP <span className="quform-required">*</span></label>
                            <div className="quform-input">
                                <input className="form-control" id="curp" type="text" name="curp" value={curp} onChange={onChangeCURP} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div className="quform-element">
                            <label htmlFor="name">Edad <span className="quform-required">*</span></label>
                            <div className="quform-input">
                                <input className="form-control" id="edad" type="number" name="edad" value={edad} onChange={onChangeEdad} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="quform-element">
                        <label htmlFor="name">¿Tiene alguna enfermedad, alergia y/o discapacidad? <span className="quform-required">*</span></label>
                        <div className="row">
                            <div className="col-4">
                                <div className="donate-form mailform off2">
                                    <div class="select-box">
                                        <input type="radio" name="discapacidadG" id={"radio-one-" + id} />
                                        <label for={"radio-one-" + id} onClick={(e) => onChangeTieneDiscapacidad(e, true)}>Si</label>
                                    </div>
                                    <div class="select-box">
                                        <input type="radio" name="discapacidadG" id={"radio-two-" + id} />
                                        <label for={"radio-two-" + id} onClick={(e) => onChangeTieneDiscapacidad(e, false)}>No</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="quform-input">
                                    <input className="form-control" id={"discapacidad" + id} type="text" name={"discapacidad" + id}
                                        placeholder={tieneDiscapacidad ? "Especifique cual" : "Ninguna"}
                                        disabled={!tieneDiscapacidad} value={discapacidad}
                                        onChange={onChangeDiscapacidad} />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="row">
                    <div className="quform-element">
                        <label htmlFor="name">Servicios m&eacute;dicos solicitados <span className="quform-required">*</span></label>
                        <div className="row mb-4">
                            <div className="col-4">
                                <button type="button" class="btn btn-outline-danger w-100" data-bs-toggle="button" id={"btn" + id}
                                    onClick={(e) => onChangeServicios(e, "Dental")}>Dental
                                </button>
                            </div>

                            <div className="col-4">
                                <button type="button" class="btn btn-outline-danger w-100" data-bs-toggle="button" id={"btn" + id}
                                    onClick={(e) => onChangeServicios(e, "Nutricional")}>Nutricional
                                </button>
                            </div>

                            <div className="col-4">
                                <button type="button" class="btn btn-outline-danger w-100" data-bs-toggle="button" id={"btn" + id}
                                    onClick={(e) => onChangeServicios(e, "Psicologico")}>Psicologico
                                </button>
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-3">
                                <button type="button" class="btn btn-outline-danger w-100" data-bs-toggle="button" id={"btn" + id}
                                    onClick={(e) => onChangeServicios(e, "Optometrista")}>Optometrista
                                </button>
                            </div>

                            <div className="col-3">
                                <button type="button" class="btn btn-outline-danger w-100" data-bs-toggle="button" id={"btn" + id}
                                    onClick={(e) => onChangeServicios(e, "Ortopedico")}>Ortopedico
                                </button>
                            </div>

                            <div className="col-6">
                                <div class="input-group mb-3">
                                    <button class="btn btn-outline-danger" type="button" data-bs-toggle="button" id={"button-addon1" + id}
                                        onClick={(e) => onChangeAlterno(e)}>Otro</button>
                                    <input type="text" class="form-control" disabled={otroServicio} aria-describedby={"button-addon1" + id}
                                        value={servicioAlt} onChange={(e) => onChangeServicioAlterno(e)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

import React, { Component } from "react";
import {Link} from 'react-router-dom';
import http from "../../http-common.js";

export default class CausasGridItem extends Component{
    constructor(props){
        super(props);    

        this.state = {
            imagen: this.props.imagenes[0],
            id: this.props.id
            
        }
    }
       
    componentDidMount(){
        var top = document.getElementById('barraNavegacion');
        var footer = document.getElementById('footerNavegacion');
        var estilo = document.getElementById('estiloPrincipal');
        var estiloPlugin = document.getElementById('estiloPlugin');

        document.body.className = "";
        top.style.display = 'block';
        footer.style.display = 'block';
        estilo.href = 'assets/css/styles.css';
        estiloPlugin.href = 'assets/css/plugins.css';    
        
        http.get('/s3url2/'+this.state.imagen).then(response => {document.getElementById('img-g'+this.state.id).src = response.data}).catch(error => {console.log(error);});   
    }    

    render() {                
        return(
            <div className="col-md-6 col-lg-4 mt-1-9 wow fadeIn tilt" data-wow-delay="200ms">
                <div className="card card-style1 border-color-extra-light-gray h-100">
                    <img id={"img-g"+ this.props.id} style={{height: '274px', width: '100%', 'object-fit':'fit'}} className="card-img-top" alt="..."/>
                    <div className="card-body px-1-6 px-sm-1-9 pb-1-9 pt-2-4 position-relative">
                        <a className="card-btn"><Link to={"/dona"+this.props.id}>Dona</Link></a>
                        <h3 className="h4 mb-3"><a><Link to={"/causasDetalle"+this.props.id}>{this.props.titulo}</Link></a></h3>
                        <p className="mb-1-9">{this.props.texto}</p>
                        <div className="skills mb-1-9">
                            <div className="skills-progress">
                                <span style={{color: 'black; !important'}} data-value={this.props.porcentaje}></span>
                            </div>
                        </div>
                        <a className="butn-read"><span><Link to={"/causasDetalle"+this.props.id} >Leer Más</Link></span></a>
                    </div>
                    <div className="d-flex justify-content-between py-3 px-1-6 px-sm-1-9 bg-white card-footer">
                        <p className="mb-0">
                            <label className="mb-0 font-weight-700">Ahora:</label> <span className="text-primary">${this.props.dineroActual}</span>
                        </p>                        
                        <p className="mb-0">
                            <label className="mb-0 font-weight-700">Meta:</label> <span className="text-primary">${this.props.dineroMeta}</span>
                        </p>
                    </div>
                </div>
            </div>
        );
    };  
}
import React, { Component } from "react";
import ProgramaService from "../services/programa.service.js";
import ApoyoService from "../services/apoyo.service.js";
import Swal from "sweetalert2";
import { Helmet } from 'react-helmet';

export default class SolicitudApoyo extends Component {

    constructor(props) {
        super(props);

        this.onChangeNombre = this.onChangeNombre.bind(this);
        this.onChangePaterno = this.onChangePaterno.bind(this);
        this.onChangeMaterno = this.onChangeMaterno.bind(this);
        this.onChangeTelefono = this.onChangeTelefono.bind(this);
        this.onChangeElector = this.onChangeElector.bind(this);
        this.onChangeCorreo = this.onChangeCorreo.bind(this);
        this.onChangeCalle = this.onChangeCalle.bind(this);
        this.onChangeInt = this.onChangeInt.bind(this);
        this.onChangeExt = this.onChangeExt.bind(this);
        this.onChangeColonia = this.onChangeColonia.bind(this);
        this.onChangeCiudad = this.onChangeCiudad.bind(this);
        this.onChangeEstado = this.onChangeEstado.bind(this);
        this.onChangeCP = this.onChangeCP.bind(this);
        this.onChangePrograma = this.onChangePrograma.bind(this);
        this.onChangeSituacion = this.onChangeSituacion.bind(this);
        this.mandarSolicitud = this.mandarSolicitud.bind(this);
        this.crearComboEstados = this.crearComboEstados.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.validateElector = this.validateElector.bind(this);
        this.validateTelefono = this.validateTelefono.bind(this);

        this.state = {
            nombre: "",
            paterno: "",
            materno: "",
            elector: "",
            correo: "",
            telefono: "",
            calle: "",
            int: "",
            ext: "",
            colonia: "",
            ciudad: "",
            estado: "",
            cp: "",
            situacion: "",
            programa: 0,
            programas: [],
            estados: []
        }
    }

    validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    validateElector = (elector) => {
        return elector.length == 13 && elector.match("^[A-Za-z0-9]+$");
    };

    validateTelefono = (telefono) => {
        return telefono.match("^[0-9]+$");
    };

    onChangeNombre(e) {
        this.setState({
            nombre: e.target.value
        });
    }

    onChangePaterno(e) {
        this.setState({
            paterno: e.target.value
        });
    }

    onChangeMaterno(e) {
        this.setState({
            materno: e.target.value
        });
    }

    onChangeElector(e) {
        this.setState({
            elector: e.target.value
        });
    }

    onChangeTelefono(e) {
        this.setState({
            telefono: e.target.value
        });
    }

    onChangeCorreo(e) {
        this.setState({
            correo: e.target.value
        });
    }

    onChangeCalle(e) {
        this.setState({
            calle: e.target.value
        });
    }

    onChangeInt(e) {
        this.setState({
            int: e.target.value
        });
    }

    onChangeExt(e) {
        this.setState({
            ext: e.target.value
        });
    }

    onChangeColonia(e) {
        this.setState({
            colonia: e.target.value
        });
    }

    onChangeCiudad(e) {
        this.setState({
            ciudad: e.target.value
        });
    }

    onChangeEstado(e) {
        this.setState({
            estado: e.target.value
        });
    }

    onChangeCP(e) {
        this.setState({
            cp: e.target.value
        });
    }

    onChangePrograma(e) {
        this.setState({
            programa: e.target.value
        });
    }

    onChangeSituacion(e) {
        this.setState({
            situacion: e.target.value
        });
    }

    crearComboEstados(){
        var estadosDos = ["Aguascalientes", "Baja California", "Baja California Sur", "Campeche", "Coahuila", "Colima", "Chiapas", 
            "Chihuahua", "Ciudad de México", "Durango", "Guanajuato", "Guerrero", "Hidalgo", "Jalisco", "Estado de México", "Michoacán", 
            "Morelos", "Nayarit", "Nuevo León", "Oaxaca", "Puebla", "Querétaro", "Quintana Roo", "San Luis Potosí", "Sinaloa", "Sonora", 
            "Tabasco", "Tamaulipas", "Tlaxcala", "Veracruz", "Yucatán", "Zacatecas"];
        var estadosOpcion = [];
        
        for (let i = 0; i < estadosDos.length; i++) {
            estadosOpcion.push(<option key={i} value={estadosDos[i]}>
                {estadosDos[i]}
            </option>);
        }

        this.setState({
            estados: estadosOpcion
        });
    }

    obtenerProgramas() {
        ProgramaService.getAll()
            .then(response => {
                var tresProgramas = [];

                for (let i = 0; i < response.data.length; i++) {
                    tresProgramas.push(<option key={response.data[i].id} value={response.data[i].id}>
                        {response.data[i].titulo}
                    </option>);
                }

                this.setState({
                    programas: tresProgramas
                });
            })
            .catch(e => {
                console.log(e);
            });
    }

    mandarSolicitud() {
        var data = {
            nombre: this.state.nombre,
            paterno: this.state.paterno,
            materno: this.state.materno,
            elector: this.state.elector,
            calle: this.state.calle,
            int: this.state.int === "" ? "N/A" : this.state.int,
            ext: this.state.ext,
            cp: this.state.cp,
            colonia: this.state.colonia,
            ciudad: this.state.ciudad,
            estado: this.state.estado,
            correo: this.state.correo,
            telefono: this.state.telefono,
            programa: this.state.programa,
            situacion: this.state.situacion
        };

        if (this.state.nombre !== "" && this.state.paterno !== "" && this.state.materno !== ""
            && this.validateElector(this.state.elector) && this.state.calle !== ""
            && this.state.ext !== "" && this.state.cp !== "" && this.state.colonia !== ""
            && this.state.ciudad !== "" && this.state.estado !== "" && this.validateTelefono(this.state.telefono)
            && this.state.programa !== 0 && this.state.situacion !== "" && this.validateEmail(this.state.correo)) {
            console.log(data);
            ApoyoService.create(data)
                .then(response => {
                    this.setState({
                        nombre: "",
                        paterno: "",
                        materno: "",
                        elector: "",
                        correo: "",
                        telefono: "",
                        calle: "",
                        int: "",
                        ext: "",
                        colonia: "",
                        ciudad: "",
                        estado: "",
                        cp: "",
                        situacion: "",
                        programa: 0,
                    });
                    document.getElementById("selectProgramas").selectedIndex = 0;
                    document.getElementById("selectEstados").selectedIndex = 0;
                    Swal.fire({
                        icon: 'success',
                        text: "Se registró tu solicitud."
                    });
                })
                .catch(e => {
                    console.log(e);
                    Swal.fire({
                        icon: 'error',
                        text: "Hubo un error en la solicitud."
                    })
                });
        }
        else if (!this.validateElector(this.state.elector)) {
            Swal.fire({
                icon: 'warning',
                text: "El folio INE es inválido."
            });
        }
        else if (!this.validateEmail(this.state.correo)) {
            Swal.fire({
                icon: 'warning',
                text: "El email es inválido."
            });
        }
        else if (!this.validateTelefono(this.state.telefono)) {
            Swal.fire({
                icon: 'warning',
                text: "El telefono es inválido."
            });
        }
        else {
            Swal.fire({
                icon: 'warning',
                text: "Hay campos requeridos vacios."
            });
        }
    }

    componentDidMount() {
        var top = document.getElementById('barraNavegacion');
        var footer = document.getElementById('footerNavegacion');
        var estilo = document.getElementById('estiloPrincipal');
        var estiloPlugin = document.getElementById('estiloPlugin');

        document.body.className = "";
        top.style.display = 'block';
        footer.style.display = 'block';
        estilo.href = 'assets/css/styles.css';
        estiloPlugin.href = 'assets/css/plugins.css';
        this.obtenerProgramas();
        this.crearComboEstados();
    };

    render() {

        const { programas, estados } = this.state;

        return (
            <div>
                <Helmet>
                    <title>M&eacute;xico Amparame - Apoyo</title>
                    <meta property="og:type" content="website"/>
                    <meta property="og:title" content="Solicita apoyo a México Ampárame"/>
                    <meta property="og:image" content="https://eacnur.org/blog/wp-content/uploads/2017/12/formas-de-ayuda-solidaria.jpg"/>

                    <meta property="twitter:card" content="summary_large_image"/>
                    <meta property="twitter:title" content="Solicita apoyo México Ampárame"/>
                    <meta property="twitter:description" content="Puedes solicitar apoyo a México Ampárame en esta página."/>
                    <meta property="twitter:image" content="https://eacnur.org/blog/wp-content/uploads/2017/12/formas-de-ayuda-solidaria.jpg"/>    
                </Helmet>
                <section class="page-title-section bg-img cover-background mx-lg-4 mx-xl-6 rounded-lg" data-overlay-dark="4" style={{ backgroundImage: 'url(assets/img/bg/apoyo.jpg)' }}>
                    <div class="container">
                        <div class="row text-center">
                            <div class="col-md-12">
                                <h1 class="text-animation" data-in-effect="fadeInRight">Apoyo</h1>
                                <ul>
                                    <li><a href="/">Regresar</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="position-absolute z-index-1 right bottom-n5 opacity2 ani-left-right">
                        <span class="square bg-primary"></span>
                        <span class="square bg-secondary"></span>
                    </div>
                </section>

                <section class="pt-6">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col">
                                <div className="ps-lg-1-6">
                                    <div className="p-1-6 p-lg-1-9 p-xl-2-5 border border-color-extra-light-gray rounded">
                                        <h1 className="text-center mb-4">SOLICITUD DE APOYO</h1>
                                        <div className="quform-elements">
                                            <div className="row">
                                                <div className="d-block d-md-flex justify-content-md-between mb-4 mt-4 pb-1 border-bottom border-color-extra-light-gray">
                                                    <h4>DATOS</h4>
                                                </div>

                                                <div className="col-sm">
                                                    <div className="quform-element">
                                                        <label htmlFor="name">Nombre(s) <span className="quform-required">*</span></label>
                                                        <div className="quform-input">
                                                            <input className="form-control" id="nombre" type="text" name="nombre" value={this.state.nombre} onChange={this.onChangeNombre} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm">
                                                    <div className="quform-element">
                                                        <label htmlFor="name">Apellido Paterno <span className="quform-required">*</span></label>
                                                        <div className="quform-input">
                                                            <input className="form-control" id="paterno" type="text" name="paterno" value={this.state.paterno} onChange={this.onChangePaterno} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm">
                                                    <div className="quform-element">
                                                        <label htmlFor="name">Apellido Materno <span className="quform-required">*</span></label>
                                                        <div className="quform-input">
                                                            <input className="form-control" id="materno" type="text" name="materno" value={this.state.materno} onChange={this.onChangeMaterno} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm">
                                                    <div className="quform-element">
                                                        <label htmlFor="name">Folio INE <span className="quform-required">*</span></label>
                                                        <div className="quform-input">
                                                            <input className="form-control" id="elector" type="text" name="elector" value={this.state.elector} onChange={this.onChangeElector} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm">
                                                    <div className="quform-element">
                                                        <label htmlFor="name">Calle <span className="quform-required">*</span></label>
                                                        <div className="quform-input">
                                                            <input className="form-control" id="calle" type="text" name="calle" value={this.state.calle} onChange={this.onChangeCalle} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm">
                                                    <div className="quform-element">
                                                        <label htmlFor="name">N&uacute;m. Exterior <span className="quform-required">*</span></label>
                                                        <div className="quform-input">
                                                            <input className="form-control" id="ext" type="text" name="ext" value={this.state.ext} onChange={this.onChangeExt} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm">
                                                    <div className="quform-element">
                                                        <label htmlFor="name">N&uacute;m. Interior</label>
                                                        <div className="quform-input">
                                                            <input className="form-control" id="int" type="text" name="int" value={this.state.int} onChange={this.onChangeInt} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm">
                                                    <div className="quform-element">
                                                        <label htmlFor="name">C&oacute;digo Postal <span className="quform-required">*</span></label>
                                                        <div className="quform-input">
                                                            <input className="form-control" id="cp" type="number" name="cp" value={this.state.cp} onChange={this.onChangeCP} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm">
                                                    <div className="quform-element">
                                                        <label htmlFor="name">Colonia <span className="quform-required">*</span></label>
                                                        <div className="quform-input">
                                                            <input className="form-control" id="colonia" type="text" name="colonia" value={this.state.colonia} onChange={this.onChangeColonia} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm">
                                                    <div className="quform-element">
                                                        <label htmlFor="name">Ciudad <span className="quform-required">*</span></label>
                                                        <div className="quform-input">
                                                            <input className="form-control" id="ciudad" type="text" name="ciudad" value={this.state.ciudad} onChange={this.onChangeCiudad} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm">
                                                    <div className="quform-element">
                                                        <label htmlFor="phone">Estado <span className="quform-required">*</span></label>
                                                        <div className="quform-input">
                                                            <select id="selectEstados" className="form-control" style={{ boxSizing: 'border-box', padding: 10, margin: 0 }}
                                                                onChange={this.onChangeEstado}>
                                                                <option selected disabled hidden> Selecciona una entidad</option>
                                                                {estados}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="d-block d-md-flex justify-content-md-between mb-4 mt-4 pb-1 border-bottom border-color-extra-light-gray">
                                                <h4>CONTACTO</h4>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm">
                                                    <div className="quform-element">
                                                        <label htmlFor="subject">Correo <span className="quform-required">*</span></label>
                                                        <div className="quform-input">
                                                            <input className="form-control" id="correo" type="email" name="correo" value={this.state.correo} onChange={this.onChangeCorreo} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm">
                                                    <div className="quform-element">
                                                        <label htmlFor="phone">T&eacute;lefono <span className="quform-required">*</span></label>
                                                        <div className="quform-input">
                                                            <input className="form-control" id="telefono" type="text" name="telefono" value={this.state.telefono} onChange={this.onChangeTelefono} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm">
                                                    <div className="quform-element">
                                                        <label htmlFor="phone">Programa de apoyo <span className="quform-required">*</span></label>
                                                        <div className="quform-input">
                                                            <select id="selectProgramas" className="form-control" style={{ boxSizing: 'border-box', padding: 10, margin: 0 }}
                                                                onChange={this.onChangePrograma}>
                                                                <option selected disabled hidden> Selecciona un programa</option>
                                                                {programas}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="d-block d-md-flex justify-content-md-between mb-4 mt-4 pb-1 border-bottom border-color-extra-light-gray"></div>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="quform-element">
                                                        <label htmlFor="mensaje">Situaci&oacute;n <span className="quform-required">*</span></label>
                                                        <div className="quform-input">
                                                            <textarea className="form-control" id="mensaje" name="mensaje" rows={3} value={this.state.situacion} onChange={this.onChangeSituacion} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 text-center">
                                                    <button className="butn" onClick={this.mandarSolicitud}>ENVIAR SOLICITUD</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
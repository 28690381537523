import React, { Component } from "react";
import ReactDOM from "react-dom";
import Swal from 'sweetalert2';
import TestimonioService from "../../services/testimonios.service"
import ListarTestimonio from "./listarTestimonio.component"
import GifLoader from 'react-gif-loader';
import http from "../../http-common.js";

export default class EditarTestimonio extends Component {
    constructor(props) {
        super(props);
        
        this.onChangeTestimonio = this.onChangeTestimonio.bind(this);
        this.onChangeAutor = this.onChangeAutor.bind(this);
        this.onChangeOcupacion = this.onChangeOcupacion.bind(this);
        this.onChangeLinkImagen = this.onChangeLinkImagen.bind(this);                


        this.state = {
            id: this.props.id,
            testimonio: this.props.testimonio,
            autor: this.props.autor,
            ocupacion: this.props.ocupacion,
            linkImagen: this.props.linkImagen,            
            image: null,                        
            loading: false
        };
    }

    componentDidMount() {        
        http.get('/s3url2/'+this.state.linkImagen).then(response => {document.getElementById('imgCarousel').src = response.data}).catch(error => {console.log(error);});   
    }

    onChangeTestimonio(e) {
        if(e.target.value.length <= 254) {
            this.setState({
                testimonio: e.target.value
            });
        }
        else{
            Swal.fire({
                icon: 'warning',
                text: 'Solo se permiten 255 caracteres.'
                
            });
        }
    }

    onChangeAutor(e) {
        this.setState({
            autor: e.target.value
        });
    }

    onChangeLinkImagen(e) {
        this.setState({
            linkImagen: e.target.value
        });
    };

    onChangeOcupacion(e) {
        this.setState({
            ocupacion: e.target.value
        });
    };

    guardarTestimonio =(e) => {
        const data = {
            testimonio: this.state.testimonio,
            autor: this.state.autor,
            ocupacion: this.state.ocupacion,
            imagenPerfil: this.state.linkImagen
        };                     
        if (this.state.testimonio && this.state.autor && this.state.ocupacion&&this.state.linkImagen) {            
            TestimonioService.update(this.state.id, data)
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        text: "Se modificaron los datos."
                    })
                    this.regresarLista();
                })
                .catch(e => {
                    console.log(e);
                    Swal.fire({
                        icon: 'error',
                        text: "Hubo un error en la solicitud."
                    })
                });
        }
        else {
            Swal.fire({
                icon: 'warning',
                text: "Hay campos requeridos vacios."
            })
            console.log("error");
        }

    }

    regresar(e){        
        this.regresarLista(e);
    }

    regresarLista(e) {
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }
        ReactDOM.render(<ListarTestimonio />, divDash);
    }

    nameHandler(e) {
        this.setState({ imagen: e.target.files[0], nombreImg: e.target.files[0].name });
    }

    async submitFormHandler(e) {
        e.preventDefault()
        let file;
        file = this.state.imagen;

        if(file!=null){
            http.delete('/s3url3/'+this.state.linkImagen);
            this.setState({
                nombreImg: "",
                loading: true
            });
            let data = new FormData();
                data.append('foto', file);

                    
            await http.post('/s3Url', data).then((response) => {            
                document.getElementById('imgCarousel').src = "";
                http.get('/s3url2/'+response.data.data).then(response => {document.getElementById('imgCarousel').src = response.data}).catch(error => {console.log(error);});   
                this.setState({ linkImagen: response.data.data,
                loading: false});
            })
        }else{
            Swal.fire({
                icon: 'error',
                text: "No ha cargado un arhivo aun."
            })
        }
    }
    
    onClickRecuadros(e){ 
        http.delete('/s3url3/'+this.state.linkImagen);
        this.setState({ linkImagen: ""})
        document.getElementById('imgCarousel').src ="";
    }


    render() {
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <h2 className="myaccount-content">Datos del Testimonio</h2>
                    </div>                    
                </div>

                <div class="form-group">
                    <label htmlFor="titul">Testimonio<span className="required"> * </span> </label>
                    <textarea className="form-control" value={this.state.testimonio} id="mensaje" name="mensaje" rows={3} value={this.state.testimonio} onChange={this.onChangeTestimonio} />
                </div>
                <div class="form-group">
                    <label htmlFor="Subti">Autor<span className="required"> * </span> </label>
                    <input type="text" value={this.state.autor} class="form-control" id="Subti" placeholder="Autor" onChange={this.onChangeAutor} />
                </div>
                <div class="form-group">
                    <label htmlFor="Subti">Ocupacón<span className="required"> * </span> </label>
                    <input type="text" value={this.state.ocupacion} class="form-control" id="ocupacion" placeholder="Ocupación" onChange={this.onChangeOcupacion} />
                </div>               
                <div className="form-group col-md-8">
                    <label className="font-weight-bold">Imagen del autor<span className="required"> * </span> </label>
                    <GifLoader
                            loading={this.state.loading}
                            imageSrc="https://media.giphy.com/media/17mNCcKU1mJlrbXodo/giphy.gif"                        
                            overlayBackground="none"
                            imageStyle={{width: "30%", display: "block"}}
                        />
                    <div id={"recuadro0"} style={{width: "40%"}}className="recuadro-imagen">
                        <img  id="imgCarousel"  />
                        <button className="btn-danger" onClick={e => this.onClickRecuadros(e)}><i className="far fa-trash-alt"></i></button>
                    </div>
                    <form id="imageForm" style={{ "marginTop": '10px' }} className="form-group" onSubmit={e => this.submitFormHandler(e)}>
                        
                        <div class="input-group">
                            <input id="imageInput" id="exampleInputFile" class="custom-file-input" type="file" accept="image/*" onChange={e => this.nameHandler(e)} />
                            <label class="custom-file-label" id="nombre">{this.state.nombreImg}</label>
                            <button style={{ "margin-top": '10px' }} className="btn btn-success" type="submit">Cargar</button>
                        </div>
                    </form>
                    <div >
                    <div>
                        <div >
                        
                        </div>
                    </div>
                </div>
                </div>
                <div className="form-group col-sm-12 text-right">                                
                    <div className="form-group col-sm-12 text-right">
                        <button class="btn btn-primary" onClick={this.guardarTestimonio}><i class="fas fa-save"></i> Guardar</button>
                        <>&nbsp;</>
                        <button class="btn btn-info" onClick={this.regresarLista}><i class="fas fa-undo-alt"></i> Regresar</button>
                    </div>
                </div>
            </div>
        )
    }
}
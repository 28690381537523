import React, { Component } from "react";
import { CompactPicker, CirclePicker } from 'react-color';
import http from "../../http-common.js";
import Swal from "sweetalert2";
import ReactDOM from "react-dom";
export default class Beneficiarios extends Component {

    constructor(props) {
        super(props);

        this.onChangeColorCuadro = this.onChangeColorCuadro.bind(this);
        this.onChangeColorLetra = this.onChangeColorLetra.bind(this);
        this.onChangeCantidadCuadro = this.onChangeCantidadCuadro.bind(this);
        this.onChangeTextoCuadro = this.onChangeTextoCuadro.bind(this);
        this.generarFilasTabla = this.generarFilasTabla.bind(this);
        this.guardarNoticia = this.guardarNoticia.bind(this);

        this.state = {
            coloresLetras: ["black", "black", "black"],
            coloresCuadros: ["", "", ""],
            textoCuadros: ["", "", ""],
            cantidadCuadros: [0, 0, 0],
            fichas: []
        };
        this.getJSON();
    }

    getJSON(){
        http.get('beneficiarios').then(response => {            
            if(response.data.datos === 'error'){
                Swal.fire({
                    icon: 'warning',
                    text: "No hay informacion de beneficiarios, necesitar llenar los campos."
                });
            }else{
                let coloresL = [];
                let coloresC = [];
                let textoC = [];
                let cantidadC = [];                
                for (let i = 1; i <= 3 ; i++){                                        
                    coloresL.push(response.data.coloresLetras[i])
                    coloresC.push(response.data.coloresCuadros[i])
                    textoC.push(response.data.textoCuadros[i])
                    cantidadC.push(response.data.cantidadCuadros[i])
                }                
                this.setState({
                    coloresLetras: coloresL,
                    coloresCuadros: coloresC,
                    textoCuadros: textoC,
                    cantidadCuadros: cantidadC
                });

                this.generarFilasTabla();
                
            }                        
        })
    }

    guardarNoticia(e) {
        for(let i = 0; i< 3; i++){
            if(this.state.textoCuadros[i]===""){
                Swal.fire({
                    icon: 'warning',
                    text: "Necesita llenar los tres campos de texto."
                });
                return;
            }
            if(this.state.coloresCuadros[i]===""){
                Swal.fire({
                    icon: 'warning',
                    text: "Necesita seleccionar los tres colores de ficha."
                });
                return;   
            }
        }
        let datos = {
            coloresLetras: {
                1: this.state.coloresLetras[0], 
                2: this.state.coloresLetras[1], 
                3: this.state.coloresLetras[2], 
            },
            coloresCuadros: {
                1 : this.state.coloresCuadros[0], 
                2 : this.state.coloresCuadros[1], 
                3 : this.state.coloresCuadros[2]
            },
            textoCuadros: {
                1: this.state.textoCuadros[0], 
                2: this.state.textoCuadros[1], 
                3: this.state.textoCuadros[2]
            }, 
            cantidadCuadros :{
                1: this.state.cantidadCuadros[0],
                2: this.state.cantidadCuadros[1],
                3: this.state.cantidadCuadros[2]
            }
        }                
        http.post('/beneficiarios', datos)  
        Swal.fire({
            icon: 'success',
            text: "Datos guardados."
        })
        
    }

    onChangeColorCuadro(e, cuadro) {
        var coloresPrevios = this.state.coloresCuadros;
        coloresPrevios[cuadro] = e.hex;

        this.setState({
            coloresCuadros: coloresPrevios
        });
        this.generarFilasTabla();
    };

    onChangeColorLetra(e, cuadro) {
        var coloresPrevios = this.state.coloresLetras;
        coloresPrevios[cuadro] = e.hex;

        this.setState({
            coloresLetras: coloresPrevios
        });
        this.generarFilasTabla();
    }

    onChangeCantidadCuadro(e, cuadro) {
        var cantidadesPrevias = this.state.cantidadCuadros;
        cantidadesPrevias[cuadro] = e.target.value;

        this.setState({
            cantidadCuadros: cantidadesPrevias
        });
        this.generarFilasTabla();
    }

    onChangeTextoCuadro(e, cuadro) {
        var textoPrevios = this.state.textoCuadros;        
        if(textoPrevios[cuadro].length > 25){
            Swal.fire({
                icon: 'warning',
                text: "Texto demasiado largo."
            })
            return;
        }
        textoPrevios[cuadro] = e.target.value;

        this.setState({
            textoCuadros: textoPrevios
        }); 
        this.generarFilasTabla();
    }

    generarFilasTabla() {
        var tresFichas = [];

        for (let i = 0; i < 3; i++) {
            tresFichas.push(<tr key={i}>
                <td class="col-1 text-center align-middle">
                    <input type="number" class="form-control" value={this.state.cantidadCuadros[i]}
                        onChange={(e) => this.onChangeCantidadCuadro(e, i)}/>
                </td>
                <td class="col-1 text-center align-middle">
                    <div style={{
                        width: "70px", height: "90px", border: "1px solid #fff",
                        backgroundColor: this.state.coloresCuadros[i]
                    }}>
                    </div>
                </td>
                <td class="col-1 text-center align-middle">
                    <CirclePicker onChange={(e) => this.onChangeColorCuadro(e, i)} color={"#000"} />
                </td>
                <td class="col-3 text-center align-middle">
                    <input style={{ backgroundColor: "white", color: this.state.coloresLetras[i] }} type="text" class="form-control"
                        value={this.state.textoCuadros[i]} onChange={(e) => this.onChangeTextoCuadro(e, i)} />
                </td>
                <td class="col-3 text-center align-middle">
                    <CirclePicker onChange={(e) => this.onChangeColorLetra(e, i)} color={"#000"} />
                </td>
            </tr>);
        }

        this.setState({
            fichas: tresFichas
        });
    }

    componentDidMount() {
        this.generarFilasTabla();
    }
    regresar = () =>{
        let divDash = document.getElementById("contenidoTablero");
        if (divDash.children.length > 0) {
        ReactDOM.unmountComponentAtNode(divDash);
        }
        ReactDOM.render(<> </>, divDash);
    }
    render() {
        
        const { fichas } = this.state;

        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div class="row justify-content-between">
                        <div class="col-6">
                            <h2 className="myaccount-content">Beneficiarios</h2>
                        </div>
                        
                    </div>
                </div>

                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead class="thead-dark">
                            <tr>
                                <th class="text-center align-middle" scope="col">Cantidad</th>
                                <th class="text-center align-middle" scope="col">Ficha</th>
                                <th class="text-center align-middle" scope="col">Color de ficha</th>
                                <th class="text-center align-middle" scope="col">Texto a mostrar</th>
                                <th class="text-center align-middle" scope="col">Color del texto</th>
                            </tr>
                        </thead>
                        <tbody>
                            {fichas}
                        </tbody>
                    </table>
                </div>
                <br/>
                <div className="form-group col-sm-12 text-right">
                            <button onClick={this.guardarNoticia} class="btn btn-primary"><i class="fas fa-save"></i> Guardar
                            </button>

                            <>&nbsp;</>
                  <button onClick={this.regresar} class="btn btn-info">
                    <i class="fas fa-undo-alt"></i> Regresar
                  </button>
                        </div>
            </div>
        )
    }
}
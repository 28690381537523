import React, { Component } from 'react';
import DonadorService from "../../services/donador.service";
import ProyectoService from "../../services/proyecto.service.js";
import DonacionListItem from './donacionesListItem.component';

class TablaDonadores extends Component {
    constructor(props) {
        super(props);
        this.state = {
            donadores: [],
            proyectos: []
        }
    }

    componentDidMount() {
        this.obtenerProyectos();
        this.obtenerDonadores();

    }

    buscarElemento = (indice) => {
        let nombreProyecto = " ";
        for (let i = 0; i < this.state.proyectos.length; i++) {
            if (indice == this.state.proyectos[i][0]) {
                nombreProyecto = this.state.proyectos[i][1];
            }
        }

        return nombreProyecto;
    }

    obtenerDonadores = () => {
        DonadorService.getAllPaid()
            .then(response => {
                var donaciones = [];

                for (let i = 0; i < response.data.length; i++) {

                    let categoria = this.buscarElemento(response.data[i].causaID);

                    donaciones.push(

                        <DonacionListItem
                            id={response.data[i].id}
                            nombreDonador={response.data[i].nombreDonador}
                            apellidos={response.data[i].apellidos}
                            cantidad={response.data[i].cantidad}
                            causa={categoria}
                            email={response.data[i].email}
                            telefono={response.data[i].numeroTelefonico}
                            mensaje={response.data[i].mensaje}
                        />

                    );
                }

                this.setState({
                    donadores: donaciones
                });
            })
            .catch(e => {
                console.log(e);
            });
    }

    obtenerProyectos = () => {
        ProyectoService.getAll()
            .then(response => {
                var proyectosA = [];
                for (let i = 0; i < response.data.length; i++) {
                    proyectosA.push(
                        [
                            response.data[i].id,
                            response.data[i].titulo
                        ]
                    );
                }
                this.setState({
                    proyectos: proyectosA
                });
            })
            .catch(e => {
                console.log(e);
            });
    }

    render() {
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div class="row justify-content-between">
                        <div class="col-6">
                            <h2 className="myaccount-content">Donadores registrados</h2>
                        </div>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table table-striped table-hover">
                        <thead class="thead-dark">
                            <tr>
                                <th class="text-center align-middle" scope="col">Nombre</th>
                                <th class="text-center align-middle" scope="col">Apellidos</th>
                                <th class="text-center align-middle" scope="col">Cantidad</th>
                                <th class="text-center align-middle" scope="col">Causa</th>
                                <th class="text-center align-middle" scope="col">Email</th>
                                <th class="text-center align-middle" scope="col">Telefono</th>
                                <th class="text-center align-middle" scope="col">Mensaje</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.donadores}
                        </tbody>
                    </table>

                </div>

            </div>
        );
    }
}

export default TablaDonadores;
import React, { Component } from 'react';
import ReactDOM from "react-dom";
import brigadaSaludHijoService from '../../services/brigadaSaludHijo.service';
import HijoItem from './brigadaSaludHijoItem.component';
import TablaSalud from './brigadaSaludList.component';

class HijosTabla extends Component {
    constructor(props) {
        super(props);

        this.cancelar = this.cancelar.bind(this);

        this.state = {
            hijos: []
        }
    }

    componentDidMount() {
        this.obtenerHijos();
    }

    obtenerHijos = () => {
        brigadaSaludHijoService.get(this.props.id)
            .then(response => {
                var hijo = [];
                for (let i = 0; i < response.data.length; i++) {
                    hijo.push(
                        <HijoItem
                            id={response.data[i].id}
                            nombre={response.data[i].nombre}
                            paterno={response.data[i].paterno}
                            materno={response.data[i].materno}
                            curp={response.data[i].curp}
                            edad={response.data[i].edad}
                            discapacidad={response.data[i].discapacidad}
                            servicios={response.data[i].servicios}
                        />
                    );
                }

                this.setState({
                    hijos: hijo
                });
            })
            .catch(e => {
                console.log(e);
            });
    }

    cancelar(e) {
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }
        ReactDOM.render(<TablaSalud />, divDash);
    }

    render() {
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div class="row justify-content-between">
                        <div class="col-6">
                            <h2 className="myaccount-content">Hijos registrados de {this.props.nombre}</h2>
                        </div>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table table-striped table-hover">
                        <thead class="thead-dark">
                            <tr>
                                <th class="text-center align-middle" scope="col">Nombre</th>
                                <th class="text-center align-middle" scope="col">Apellidos</th>
                                <th class="text-center align-middle" scope="col">CURP</th>
                                <th class="text-center align-middle" scope="col">Edad</th>
                                <th class="text-center align-middle" scope="col">Discapacidad</th>
                                <th class="text-center align-middle" scope="col">Servicio Requerido</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.hijos}
                        </tbody>
                    </table>

                </div>
                <div class="card-footer row justify-content-end ">
                    <>&nbsp;</>
                    <button class="btn btn-info " onClick={this.cancelar}><i class="fas fa-undo-alt"></i> Regresar</button>
                </div>
            </div>
        );
    }
}

export default HijosTabla;
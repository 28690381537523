import React, { Component } from "react";
import Swal from 'sweetalert2';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import carouselService from "../../services/carousel.service";
import ListarCarousel from "./listCarousel.component";
import ReactDOM from "react-dom";
import GifLoader from 'react-gif-loader';
import http from "../../http-common.js";

export default class CrearCarousel extends Component {
    constructor(props) {
        super(props);
        this.onChangeTitulo = this.onChangeTitulo.bind(this);
        this.onChangeSubtitulo = this.onChangeSubtitulo.bind(this);
        this.onChangeLinkImagen = this.onChangeLinkImagen.bind(this);
        this.onChangeEstado = this.onChangeEstado.bind(this);
        this.guardarCarousel = this.guardarCarousel.bind(this);
        this.cancelar = this.cancelar.bind(this);


        this.state = {
            id: null,
            titulo: "",
            subtitulo: "",
            linkImagen: "",
            estado: false,
            image: null,
            imageSrc: "",
            loaded: true,
            imageName: "",
            imagePath: "",
            loading: false
        };
    }

    async cancelar(e){
        e.preventDefault();        
        if(this.state.linkImagen!==''){
             http.delete('/s3url3/'+this.state.linkImagen);                  
        }

        this.regresarLista(e);
    }

    regresarLista(e) {
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }
        ReactDOM.render(<ListarCarousel />, divDash);
    }

    nameHandler(e) {
        let archivo = e.target.files[0];

        this.setState({ imageName: archivo.name, imagePath: archivo.path })

        if (archivo) {
            let nombre = "Archivo elegido: " + archivo.name;
            const scope = this;
            var reader = new FileReader();

            //Read the contents of Image File.
            reader.readAsDataURL(archivo);
            reader.onload = function (file) {
                var image = new Image();
                image.src = file.target.result;
                image.onload = function () {
                    let height = this.height;
                    let width = this.width;

                    if (height < 700 || width < 1000) {
                        Swal.fire({
                            icon: 'warning',
                            text: "La imagen debe tener mínimo 700 píxeles de altura y 1000 de anchura."
                        });
                        return false;
                    }
                    scope.setState({ image: archivo });
                    document.getElementById("nombre").innerHTML = nombre;
                    return true;
                };
            };
        }
    }

    async submitFormHandler(e) {
        e.preventDefault();
        let image;
        image = this.state.image;

        

        if (image != null) {
            if(this.state.linkImagen!==''){                                       
                http.delete('/s3url3/'+this.state.linkImagen);                  
            }
            this.setState({ loading: true });
            let url = null;

            let data = new FormData();
            data.append('foto', image);


            await http.post('/s3Url', data).then(response => {
                this.setState({ loading: false })
                var previo = document.getElementById("imagenvis");
                http.get('/s3url2/' + response.data.data).then(response => { previo.src = response.data }).catch(error => { console.log(error); });
                this.setState({
                    linkImagen: response.data.data
                })
            })

        } else {
            Swal.fire({
                icon: 'error',
                text: "No ha cargado un archivo aun."
            })
        }
    }


    onChangeTitulo(e) {
        this.setState({
            titulo: e.target.value
        });
    }

    onChangeSubtitulo(e) {
        this.setState({
            subtitulo: e.target.value
        });
    }

    onChangeLinkImagen(e) {
        this.setState({
            linkImagen: e.target.value
        });
    };

    onChangeEstado(e) {
        this.setState({
            estado: e.target.checked
        });
    };

    guardarCarousel = async (e) => {
        e.preventDefault();
        if (!this.state.titulo) {
            document.getElementById("titul").focus();
            Swal.fire({
                icon: 'error',
                text: 'Tienen campos vacios'
                
            })
            
        } else if (!this.state.subtitulo) {
            document.getElementById("Subti").focus();
            Swal.fire({
                icon: 'error',
                text: 'Tienen campos vacios'
            })
        } else if (!this.state.linkImagen) {
            Swal.fire({
                icon: 'error',
                text: 'No ha cargado ninguna imagen aun'
            })
        } else {
            const data = {
                titulo: this.state.titulo,
                subtitulo: this.state.subtitulo,
                linkImagen: this.state.linkImagen,
                estatus: this.state.estado
            }
            carouselService.create(data)
                .then(response => {
                    this.setState({
                        titulo: "",
                        titulo: "",
                        subtitulo: "",
                        linkImagen: "",
                        estado: false,
                        image: null
                    });
                    Swal.fire({
                        icon: 'success',
                        text: "Se introdujo el Carousel correctamente"
                    });
                    this.limpiarControls();
                    this.regresarLista();
                })
                .catch(e => {
                    Swal.fire({
                        icon: 'error',
                        text: e.message
                    })

                    console.log(e.message)
                });
        }
    }


    limpiarControls = () => {
        document.getElementById("titul").value = "";
        document.getElementById("Subti").value = "";
        document.getElementById("nombre").innerHTML = "Elije un Archivo";
        document.getElementById("exampleCheck1").checked = false;
    }

    render() {
        return (
            <div className="content-wrapper">

                <div className="content-header">
                    <div className="container">
                        <h2 className="myaccount-content">Datos del Carousel</h2>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">


                        <div class="card ">
                            <div class="card-header">
                                <h3 class="card-title">Agregar Elemento</h3>
                            </div>

                            <form role="form">
                                <div class="card-body">
                                    <div class="form-group">
                                        <label htmlFor="titul">Titulo<span className="required"> * </span> </label>
                                        <input type="text" class="form-control" id="titul" placeholder="Ingresa un titulo" onChange={this.onChangeTitulo} />
                                    </div>
                                    <div class="form-group">
                                        <label htmlFor="Subti">Subtitulo<span className="required"> * </span> </label>
                                        <input type="text" class="form-control" id="Subti" placeholder="Subtitulo" onChange={this.onChangeSubtitulo} />
                                    </div>
                                    <div class="form-group">
                                        <div class="form-group" onSubmit={this.submitFormHandler.bind(this)}>
                                            <label htmlFor="exampleInputFile">Imagen<span className="required"> * </span></label>
                                            <div class="input-group">
                                                <div class="custom-file">
                                                    <input type="file" class="custom-file-input" id="exampleInputFile" accept="image/*" onChange={this.nameHandler.bind(this)} />
                                                    <label class="custom-file-label" htmlFor="exampleInputFile" id="nombre">Elije un Archivo</label>
                                                </div>
                                                <div class="input-group-append">
                                                    <span class="input-group-text" id="" style={{ cursor: 'pointer' }} onClick={this.submitFormHandler.bind(this)}>Cargar</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <GifLoader
                                            loading={this.state.loading}
                                            imageSrc="https://media.giphy.com/media/17mNCcKU1mJlrbXodo/giphy.gif"
                                            overlayBackground="none"
                                            imageStyle={{ width: "30%", display: "block" }}
                                        />
                                        <label >Previsualizaci&oacute;n</label>
                                        <div ><img id="imagenvis" width="200px" height="150px" /></div>
                                    </div>

                                    <div class="form-check">
                                        <input type="checkbox" class="form-check-input" id="exampleCheck1" onChange={this.onChangeEstado} />
                                        <label class="form-check-label" htmlFor="exampleCheck1">Estado de visualizaci&oacute;n</label>
                                    </div>
                                </div>

                                <div class="card-footer row justify-content-end ">
                                    <button type="submit" class="btn btn-primary " onClick={this.guardarCarousel}><i class="fas fa-save"></i> Guardar</button>
                                    <>&nbsp;</>
                                    <button class="btn btn-info " onClick={this.cancelar}><i class="fas fa-undo-alt"></i> Regresar</button>
                                </div>
                            </form>
                        </div>
                    </div>

                </section>
            </div>
        )
    }
}
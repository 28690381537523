import React, { Component } from "react";
import Swal from 'sweetalert2';
import TestimonioService from "../../services/testimonios.service";
import ListarTestimonio from "./listarTestimonio.component";
import ReactDOM from "react-dom";
import GifLoader from 'react-gif-loader';
import http from "../../http-common.js";

export default class CrearTestimonio extends Component {
    constructor(props) {
        super(props);
        this.onChangeTestimonio = this.onChangeTestimonio.bind(this);
        this.onChangeAutor = this.onChangeAutor.bind(this);
        this.onChangeOcupacion = this.onChangeOcupacion.bind(this);
        this.onChangeLinkImagen = this.onChangeLinkImagen.bind(this);             
        this.cancelar = this.cancelar.bind(this);   


        this.state = {
            id: null,
            testimonio: "",
            autor: "",
            ocupacion: "",
            linkImagen: "",            
            image: null,                        
            loading: false
        };
    }

    cancelar(e){
        http.delete('/s3url3/'+this.state.linkImagen);
        this.regresarLista(e)    
    }

    regresarLista(e) {
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }
        ReactDOM.render(<ListarTestimonio />, divDash);
    }

    nameHandler(e) {
        let archivo = e.target.files[0];

        this.setState({ imageName: archivo.name })

        if (archivo) {
            let nombre = "Archivo elegido: " + archivo.name;
            const scope = this;
            var reader = new FileReader();

            //Read the contents of Image File.
            reader.readAsDataURL(archivo);
            reader.onload = function (file) {
                var image = new Image();
                image.src = file.target.result;
                image.onload = function () {
                    let height = this.height;
                    let width = this.width;

                    if (height != width || height > 551 || width > 551) {
                        Swal.fire({
                            icon: 'warning',
                            text: "La imagen no debe exceder 551 pixeles de alto y 551 pixeles de ancho. " +
                             "Ademas, debe ser una imagen cuadrada."
                        });
                        return false;
                    }
                    scope.setState({ image: archivo });
                    document.getElementById("nombre").innerHTML = nombre;
                    return true;
                };
            };
        }
    }

    async submitFormHandler(e) {
        e.preventDefault();
        let image;
        image = this.state.image;

        if (image != null) {
            if(this.state.linkImagen!==""){
                http.delete('/s3url3/'+this.state.linkImagen);
            }
            
            this.setState({ loading: true });
            let url = null;

            let data = new FormData();
            data.append('foto', image);


            await http.post('/s3Url', data).then(response => {
                this.setState({ loading: false })
                var previo = document.getElementById("imagenvis");
                http.get('/s3url2/' + response.data.data).then(response => { previo.src = response.data }).catch(error => { console.log(error); });
                this.setState({
                    linkImagen: response.data.data
                })
            })

        } else {
            Swal.fire({
                icon: 'error',
                text: "No ha cargado un arhivo aun."
            })
        }
    }


    onChangeTestimonio(e) {
        if(e.target.value.length <= 254) {
            this.setState({
                testimonio: e.target.value
            });
        }
        else{
            Swal.fire({
                icon: 'warning',
                text: 'Solo se permiten 255 caracteres.'
                
            });
        }
    }

    onChangeAutor(e) {
        this.setState({
            autor: e.target.value
        });
    }

    onChangeLinkImagen(e) {
        this.setState({
            linkImagen: e.target.value
        });
    };

    onChangeOcupacion(e) {
        this.setState({
            ocupacion: e.target.value
        });
    };

    guardarTestimonio = async (e) => {
        e.preventDefault();
        if (!this.state.testimonio) {
            document.getElementById("titul").focus();
            Swal.fire({
                icon: 'error',
                text: 'Tienen campos vacios'
                
            })
            
        } else if (!this.state.autor) {
            document.getElementById("Subti").focus();
            Swal.fire({
                icon: 'error',
                text: 'Tienen campos vacios'
            })
        } else if (!this.state.ocupacion) {
            document.getElementById("ocupacion").focus();
            Swal.fire({
                icon: 'error',
                text: 'Tienen campos vacios'
            })
        } else if (!this.state.linkImagen) {
            Swal.fire({
                icon: 'error',
                text: 'No ha cargado ninguna imagen aun'
            })
        } else {
            const data = {
                testimonio: this.state.testimonio,
                autor: this.state.autor,
                ocupacion: this.state.ocupacion,
                imagenPerfil: this.state.linkImagen  
            }

            console.log(data);
            TestimonioService.create(data)
                .then(response => {                    
                    Swal.fire({
                        icon: 'success',
                        text: "Se introdujo el Testimonio correctamente"
                    });                    
                    this.regresarLista();
                })
                .catch(e => {
                    Swal.fire({
                        icon: 'error',
                        text: e.message
                    })

                    console.log(e.message)
                });
        }
    }

    render() {
        return (
            <div className="content-wrapper">

                <div className="content-header">
                    <div className="container">
                        <h2 className="myaccount-content">Datos del Testimonio</h2>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">


                        <div class="card ">
                            <div class="card-header">
                                <h3 class="card-title">Agregar Testimonio</h3>
                            </div>

                            <form role="form">
                                <div class="card-body">
                                    <div class="form-group">
                                        <label htmlFor="titul">Testimonio<span className="required"> * </span> </label>
                                        <textarea className="form-control" id="mensaje" name="mensaje" rows={3} value={this.state.testimonio} onChange={this.onChangeTestimonio} />
                                    </div>
                                    <div class="form-group">
                                        <label htmlFor="Subti">Autor<span className="required"> * </span> </label>
                                        <input type="text" class="form-control" id="Subti" placeholder="Autor" onChange={this.onChangeAutor} />
                                    </div>
                                    <div class="form-group">
                                        <label htmlFor="Subti">Ocupacón<span className="required"> * </span> </label>
                                        <input type="text" class="form-control" id="ocupacion" placeholder="Ocupación" onChange={this.onChangeOcupacion} />
                                    </div>
                                    <div class="form-group">
                                        <div class="form-group" onSubmit={this.submitFormHandler.bind(this)}>
                                            <label htmlFor="exampleInputFile">Imagen del autor<span className="required"> * </span></label>
                                            <div class="input-group">
                                                <div class="custom-file">
                                                    <input type="file" class="custom-file-input" id="exampleInputFile" accept="image/*" onChange={this.nameHandler.bind(this)} />
                                                    <label class="custom-file-label" htmlFor="exampleInputFile" id="nombre">Elije un Archivo</label>
                                                </div>
                                                <div class="input-group-append">
                                                    <span class="input-group-text" id="" style={{ cursor: 'pointer' }} onClick={this.submitFormHandler.bind(this)}>Cargar</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <GifLoader
                                            loading={this.state.loading}
                                            imageSrc="https://media.giphy.com/media/17mNCcKU1mJlrbXodo/giphy.gif"
                                            overlayBackground="none"
                                            imageStyle={{ width: "30%", display: "block" }}
                                        />
                                        <label >Previsualizaci&oacute;n</label>
                                        <div ><img id="imagenvis" width="200px" height="150px" /></div>
                                    </div>
                                </div>

                                <div class="card-footer row justify-content-end ">
                                    <button type="submit" class="btn btn-primary " onClick={this.guardarTestimonio}><i class="fas fa-save"></i> Guardar</button>
                                    <>&nbsp;</>
                                    <button type="submit" class="btn btn-info " onClick={this.cancelar}><i class="fas fa-undo-alt"></i> Regresar</button>
                                </div>
                            </form>
                        </div>
                    </div>

                </section>
            </div>
        )
    }
}
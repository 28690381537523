import React, { useState, useEffect, createContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import UsuarioService from "../services/usuario.service";

export default function Login({ usuario, setUsuario }) {

    const [correo, setCorreo] = useState("");
    const [pwd, setPwd] = useState("");
    const navigate = useNavigate();

    const onChangeCorreo = event => setCorreo(event.target.value);
    const onChangePwd = event => setPwd(event.target.value);

    const iniciarSesion = () => {
        var sha256 = require('js-sha256');
        if (correo && pwd) {
            UsuarioService.findByEmail(correo)
                .then(response => {
                    if (response.data == 0) {
                        Swal.fire({
                            icon: 'error',
                            text: "No existe el usuario."
                        })
                    } else {
                        let supposePwd = response.data[0].password;
                        if (supposePwd === sha256(pwd)) {

                            let sesionIniciada = response.data[0];
                            sesionIniciada.sesion = true;
                            localStorage.setItem('usuario', JSON.stringify(sesionIniciada));

                            setUsuario(sesionIniciada);
                            navigate(-1);
                        }
                        else {
                            Swal.fire({
                                icon: 'error',
                                text: "Contraseña incorrecta."
                            })
                        }
                    }
                })
                .catch(e => {
                    console.log(e.message)
                    Swal.fire({
                        icon: 'error',
                        text: "Hubo un error en la solicitud."
                    })
                });
        }
        else {
            Swal.fire({
                icon: 'warning',
                text: "Hay campos requeridos vacios."
            })
        }
    }

    useEffect(() => {
        var top = document.getElementById('barraNavegacion');
        var footer = document.getElementById('footerNavegacion');
        var estilo = document.getElementById('estiloPrincipal');
        var estiloPlugin = document.getElementById('estiloPlugin');

        top.style.display = 'none';
        footer.style.display = 'none';
        estilo.href = 'assets/css/mainLogin.css';
        estiloPlugin.href = '';
        document.body.className = "";
        if (usuario) {
            navigate('/dashboard');
        }
    }, []);

    return (
        <div class="limiter">
            <div className="container-login100">
                <div className="wrap-login100">
                    <div className="login100-form validate-form">
                        <span className="login100-form-title p-b-43">
                            Iniciar Sesi&oacute;n
                        </span>
                        <div className="wrap-input100 validate-input">
                            <input className="input100" type="text" value={correo} onChange={onChangeCorreo} />
                            <span className="focus-input100" />
                            <span className="label-input100">Usuario</span>
                        </div>
                        <div className="wrap-input100 validate-input sm" data-validate="Password is required">
                            <input className="input100" type="password" value={pwd} onChange={onChangePwd} />
                            <span className="focus-input100" />
                            <span className="label-input100 p-b-43">Contraseña</span>
                        </div>
                        <div className="container-login100-form-btn">
                            <button id="btnEntrar" className="login100-form-btn" onClick={iniciarSesion}>
                                Entrar
                            </button>
                        </div>
                        <div class="text-center p-t-46 p-b-20">
                            <span class="txt2">
                                <a style={{ cursor: 'pointer', textDecoration: 'none' }} href="/" cursor="">Regresar al inicio</a>
                            </span>
                        </div>
                    </div>

                    <div className="login100-more" style={{ backgroundImage: 'url("/assets/img/bg/voluntarios.jpg")' }}>
                    </div>
                </div>
            </div>
        </div>


    )
}
import { Component } from "react";
import http from "../http-common";

export default class BeneficiariosInicio extends Component{
    constructor(props){
        super(props);

        this.state = {            
            fichas: []
        };
        
    }

    componentDidMount() {
        this.getJSON();
    }

    getJSON(){
        http.get('beneficiarios').then(response => {                
            console.log( )  
            if(response.data.textoCuadros[1] != "" && response.data.textoCuadros[2] != "" && response.data.textoCuadros[3] != ""){
                let arr = [];
                for (let i = 1; i <= 3 ; i++){  
                    console.log(i)                                      
                    arr.push(
                    <div key={i} class="col-md-6 col-lg-3 mt-1-9 wow fadeIn" data-wow-delay="200ms">
                        <div class="counter-style1" style={{'backgroundColor': response.data.coloresCuadros[i]}}>
                            <div class="counter-text">
                                <span style={{color: response.data.coloresLetras[i]}} class="countup mb-0 h1 ">
                                    {response.data.cantidadCuadros[i]}</span>
                            </div>
                            <p class="display-28 letter-spacing-1 mb-0 text-white">{response.data.textoCuadros[i]}</p>
                        </div>
                    </div>
                    )

                }              
                
                this.setState({
                    fichas: arr
                });                    
            }
            
        })
    }

    render(){
        const { fichas } = this.state;
        console.log(fichas);
        return (
            <section class="bg-img cover-background mx-lg-4 mx-xl-6 rounded-lg" data-overlay-dark="4" style={{ backgroundImage: 'url(assets/img/bg/bg-01.jpg)' }}>
                    <div class="container">
                        <div class="section-heading text-start">
                            <span class="d-block text-secondary display-22 display-md-21 display-lg-20 alt-font wow text-animation fadeInRight">Beneficiarios</span>
                            <h2 class="mb-0 text-white">Ayudamos a muchas personas</h2>
                        </div>
                        <div class="row mt-n1-9">
                            {fichas}
                            <div class="col-md-6 col-lg-3 mt-1-9 wow fadeIn tilt" data-wow-delay="800ms">
                                <div class="become-volunteer text-center">
                                    <a href='/solicitud-apoyo'><button class="btn btn-default" ><i class="ti-plus mx-auto text-white"></i></button>
                                    <h4 class="display-28 letter-spacing-1 mb-0 text-white">¿Necesitas apoyo?</h4></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
        )
    }
}
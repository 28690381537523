import http from "../http-common";

class UsuarioService {
    getAll() {
        return http.get("/usuarios");
    }

    get(id) {
        return http.get("/usuarios/" + id);
    }

    create(data) {
        return http.post("/usuarios", data);
    }

    update(id, data) {
        return http.put("/usuarios/" + id, data);
    }

    delete(id) {
        return http.delete("/usuarios/" + id);
    }

    deleteAll() {
        return http.delete("/usuarios");
    }

    findByEmail(correo){
        return http.get(`/usuarios/correo=${correo}`);
    }

    findByRol(rol){
        return http.get(`/usuarios/rol=${rol}`);
    }

}

export default new UsuarioService();

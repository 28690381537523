import React, { Component } from "react";
import SolicitudService from "../../services/solicitud.service.js";
import VoluntarioService from "../../services/voluntario.service.js";
import VoluntarioItem from "./voluntarioGridItem.component";
import Swal from 'sweetalert2';
import { Helmet } from 'react-helmet';
export default class VoluntariosGrid extends Component {

    constructor(props) {
        super(props);

        this.onChangeNombre = this.onChangeNombre.bind(this);
        this.onChangePaterno = this.onChangePaterno.bind(this);
        this.onChangeMaterno = this.onChangeMaterno.bind(this);
        this.onChangeTelefono = this.onChangeTelefono.bind(this);
        this.onChangeCorreo = this.onChangeCorreo.bind(this);
        this.onChangeMotivo = this.onChangeMotivo.bind(this);
        this.mandarSolicitud = this.mandarSolicitud.bind(this);
        this.cambiarPagina = this.cambiarPagina.bind(this);
        this.crearGridItems = this.crearGridItems.bind(this);
        this.crearNombreApellido = this.crearNombreApellido.bind(this);
        this.crearButtonsNavigation = this.crearButtonsNavigation.bind(this);
        this.validateEmail = this.validateEmail.bind(this);

        this.state = {
            arrayCasos: [],
            numPag: 1,
            pagActual: 1,
            arrayNumPag: [],
            nombre: "",
            paterno: "",
            materno: "",
            correo: "",
            telefono: "",
            motivo: ""
        }
    }

    validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    };

    onChangeNombre(e) {
        this.setState({
            nombre: e.target.value
        });
    }

    onChangePaterno(e) {
        this.setState({
            paterno: e.target.value
        });
    }

    onChangeMaterno(e) {
        this.setState({
            materno: e.target.value
        });
    }

    onChangeTelefono(e) {
        this.setState({
            telefono: e.target.value
        });
    }

    onChangeCorreo(e) {
        this.setState({
            correo: e.target.value
        });
    }

    onChangeMotivo(e) {
        this.setState({
            motivo: e.target.value
        });
    }


    crearGridItems() {
        let arr = [];
        VoluntarioService.getAllActive().then(response => {
            var pags = 0;
            if (response.data.length >= 12) {
                pags = Math.ceil(response.data.length / 12);
            } else {
                pags = 1;
            }

            this.setState({
                numPag: pags
            })

            this.crearButtonsNavigation();

            var contador = 1;
            for (let i = (this.state.pagActual * 12 - 12); i < response.data.length; i++) {
                if (contador < 12 && i < response.data.length) {
                    let nombreTitulo = this.crearNombreApellido(response.data[i].nombre, response.data[i].paterno);
                    arr.push(<VoluntarioItem
                        nombre={nombreTitulo}
                        id={response.data[i].id}
                        facebookLink={response.data[i].facebook}
                        instagramLink={response.data[i].instagram}
                        twitterLink={response.data[i].twitter}
                        linkedinLink={response.data[i].linkedin}
                        imagen={response.data[i].imagen}
                    />);
                    contador++;
                }
                else {
                    break;
                }
            }

            this.setState({
                arrayCasos: arr
            })
        });
    }

    componentDidMount() {
        this.crearGridItems();
        var top = document.getElementById('barraNavegacion');
        var footer = document.getElementById('footerNavegacion');
        var estilo = document.getElementById('estiloPrincipal');
        var estiloPlugin = document.getElementById('estiloPlugin');

        document.body.className = "";
        top.style.display = 'block';
        footer.style.display = 'block';
        estilo.href = 'assets/css/styles.css';
        estiloPlugin.href = 'assets/css/plugins.css';
    }

    cambiarPagina(i) {
        this.setState({
            arrayNumPag: [],
            arrayCasos: [],
            pagActual: i
        });
        this.crearGridItems();
        this.crearButtonsNavigation();
    }

    sigPagina() {
        if (this.state.pagActual == this.state.numPag) {

        } else {
            this.cambiarPagina(this.state.pagActual + 1);
        }
    }

    antPagina() {
        if (this.state.pagActual == 1) {

        } else {
            this.cambiarPagina(this.state.pagActual - 1);
        }
    }

    crearButtonsNavigation() {
        let arr = [];
        for (let i = 1; i <= this.state.numPag; i++) {

            if (i == this.state.pagActual) {
                arr.push(<li class="active" onClick={() => this.cambiarPagina(i)}><a>{i}</a></li>)
            } else {
                arr.push(<li onClick={() => this.cambiarPagina(i)} ><a>{i}</a></li>)
            }
        }
        this.setState({
            arrayNumPag: arr
        })
    }

    crearNombreApellido(nombre, apellido) {
        let unSoloNombre = nombre.split(" ")[0];
        return unSoloNombre + " " + apellido;
    }

    mandarSolicitud() {
        var data = {
            nombre: this.state.nombre,
            paterno: this.state.paterno,
            materno: this.state.materno,
            correo: this.state.correo,
            telefono: this.state.telefono,
            motivo: this.state.motivo
        };

        if (this.state.nombre != "" && this.state.paterno != "" && this.state.materno != ""
            && this.state.telefono != "" && this.validateEmail(this.state.correo) && this.state.motivo != "") {
            console.log(data);
            SolicitudService.create(data)
                .then(response => {
                    this.setState({
                        nombre: "",
                        paterno: "",
                        materno: "",
                        correo: "",
                        telefono: "",
                        motivo: ""
                    });
                    Swal.fire({
                        icon: 'success',
                        text: "Gracias por ayudar. Te contactaremos pronto."
                    })
                })
                .catch(e => {
                    console.log(e);
                    Swal.fire({
                        icon: 'error',
                        text: "Hubo un error en la solicitud."
                    })
                });
        } else if (this.validateEmail(this.state.correo)) {
            Swal.fire({
                icon: 'warning',
                text: "El email es inválido."
            })
        } else {
            Swal.fire({
                icon: 'warning',
                text: "Hay campos requeridos vacios."
            })
            console.log("error");
        }
    }

    render() {
        const { arrayCasos, arrayNumPag } = this.state;
        return (
            <div>
                <Helmet>
                    <title>M&eacute;xico Amparame - Voluntarios</title>
                    <meta property="og:type" content="website"/>
                    <meta property="og:title" content="Voluntarios de México Ampárame"/>
                    <meta property="og:image" content="https://eacnur.org/blog/wp-content/uploads/2017/12/formas-de-ayuda-solidaria.jpg"/>

                    <meta property="twitter:card" content="summary_large_image"/>
                    <meta property="twitter:title" content="Voluntarios de México Ampárame"/>
                    <meta property="twitter:description" content="Conoce a los voluntarios de México Ampárame."/>
                    <meta property="twitter:image" content="https://eacnur.org/blog/wp-content/uploads/2017/12/formas-de-ayuda-solidaria.jpg"/>    
                </Helmet>
                <section class="page-title-section bg-img cover-background mx-lg-4 mx-xl-6 rounded-lg" data-overlay-dark="4" style={{ backgroundImage: 'url(assets/img/bg/voluntarios.jpg)' }}>
                    <div class="container">
                        <div class="row text-center">
                            <div class="col-md-12">
                                <h1 class="text-animation" data-in-effect="fadeInRight">Voluntarios</h1>
                                <ul>
                                    <li><a href="/">Regresar</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="position-absolute z-index-1 right bottom-n5 opacity2 ani-left-right">
                        <span class="square bg-primary"></span>
                        <span class="square bg-secondary"></span>
                    </div>
                </section>

                <section>
                    <div class="container">
                        <div class="section-heading">
                            <span class="d-block text-primary display-22 display-md-21 display-lg-20 alt-font wow text-animation" data-in-effect="fadeInRight">Nuestro equipo</span>
                            <h2>Voluntarios</h2>
                        </div>
                        <div class="row justify-content-center">

                            {arrayCasos}

                        </div>
                        <div class="row wow fadeIn" data-wow-delay="400ms">
                            <div class="col-sm-12">
                                <div class="text-center mt-6 mt-lg-7">
                                    <div class="pagination text-extra-dark-gray">
                                        <ul>
                                            <li onClick={() => this.antPagina()}><a><i class="fas fa-long-arrow-alt-left me-1 d-none d-sm-inline-block"></i>Anterior</a></li>
                                            {arrayNumPag}
                                            <li onClick={() => this.sigPagina()}><a>Siguiente<i class="fas fa-long-arrow-alt-right ms-1 d-none d-sm-inline-block"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="py-5 wow fadeIn bg-secondary mx-lg-4 mx-xl-6 rounded-lg" data-wow-delay="100ms">
                    <div class="container-fluid px-md-8 px-lg-10 px-xl-14">
                        <div class="owl-carousel owl-theme client-style1 text-center">
                            <div class="image-wrapper">
                                <img class="hover-image" src="img/partners/01.png" alt="..." />
                                <img class="main-image" src="img/partners/01.png" alt="..." />
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-9 col-xl-7">
                                <div className="ps-lg-1-6">
                                    <div className="p-1-6 p-lg-1-9 p-xl-2-5 border border-color-extra-light-gray rounded">
                                        <h2 className="text-center mb-4">VUÉLVETE UN VOLUNTARIO</h2>
                                        <div className="quform-elements">
                                            <div className="row">
                                                <div className="col-sm">
                                                    <div className="quform-element">
                                                        <label htmlFor="name">Nombre(s) <span className="quform-required">*</span></label>
                                                        <div className="quform-input">
                                                            <input className="form-control" id="nombre" type="text" name="nombre" value={this.state.nombre} onChange={this.onChangeNombre} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm">
                                                    <div className="quform-element">
                                                        <label htmlFor="name">Apellido Paterno <span className="quform-required">*</span></label>
                                                        <div className="quform-input">
                                                            <input className="form-control" id="paterno" type="text" name="paterno" value={this.state.paterno} onChange={this.onChangePaterno} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm">
                                                    <div className="quform-element">
                                                        <label htmlFor="name">Apellido Materno <span className="quform-required">*</span></label>
                                                        <div className="quform-input">
                                                            <input className="form-control" id="materno" type="text" name="materno" value={this.state.materno} onChange={this.onChangeMaterno} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="quform-element">
                                                        <label htmlFor="subject">Correo <span className="quform-required">*</span></label>
                                                        <div className="quform-input">
                                                            <input className="form-control" id="correo" type="email" name="correo" value={this.state.correo} onChange={this.onChangeCorreo} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="quform-element">
                                                        <label htmlFor="phone">T&eacute;lefono <span className="quform-required">*</span></label>
                                                        <div className="quform-input">
                                                            <input className="form-control" id="telefono" type="text" name="telefono" value={this.state.telefono} onChange={this.onChangeTelefono} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="quform-element">
                                                        <label htmlFor="mensaje">Motivaci&oacute;n <span className="quform-required">*</span></label>
                                                        <div className="quform-input">
                                                            <textarea className="form-control" id="mensaje" name="mensaje" rows={3} placeholder="Cuéntanos por que quieres unirte"
                                                                value={this.state.motivo} onChange={this.onChangeMotivo} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <button className="butn" onClick={this.mandarSolicitud}>QUIERO AYUDAR</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }

}
import React, { Component } from "react";
import ReactDOM from "react-dom";
import Swal from 'sweetalert2';
import CarouselService from "../../services/carousel.service"
import EditarCarousel from "./editarCarousel.component";
import ListarCarousel from "./listCarousel.component";
import http from '../../http-common';

export default class ListItemCarousel extends Component {

    constructor(props) {
        super(props);

        this.onChangeActivo = this.onChangeActivo.bind(this);
        this.onPicoEliminar = this.onPicoEliminar.bind(this);
        this.editarCarousel = this.editarCarousel.bind(this);
        this.regresarLista = this.regresarLista.bind(this);

        this.state = {
            id: this.props.id,
            titulo: this.props.titulo,
            subtitulo: this.props.subtitulo,
            linkImagen: this.props.linkImagen,
            estatus: this.props.estatus
        }
    }

    regresarLista(e) {
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }
        ReactDOM.render(<ListarCarousel />, divDash);
    }

    onChangeActivo(e) {
        let target = e.target;
        let inicioActivo = this.state.estatus;
        console.log(target.checked);

        if (!target.checked) {
            inicioActivo = false;
        }

        this.setState({ estatus: target.checked }, () => {
            CarouselService.update(this.state.id, this.state)
                .then(response => {
                    console.log(response.data);
                })
                .catch(e => {
                    console.log(e);
                });
        });
    }

    editarCarousel() {
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }
        ReactDOM.render(<EditarCarousel id={this.state.id}
                                       titulo={this.state.titulo}
                                       subtitulo={this.state.subtitulo}
                                       linkImagen={this.state.linkImagen}
                                       estatus={this.state.estatus}
                                       
                                       />, divDash
        );
    }

    onPicoEliminar(e) {
        Swal.fire({
            text: 'Deseas eliminar este elemento?',
            icon: 'warning',
            //showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            //denyButtonText: `Don't save`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                CarouselService.get(this.state.id).then(response =>{                    
                    http.delete('/s3url3/'+response.data.linkImagen)
                }).catch(error => { console.log(error); })
                CarouselService.delete(this.state.id)
                .then(response => {                    
                    this.regresarLista();
                })
                .catch(e => {
                    console.log(e);
                });
              Swal.fire('Eliminado', 'Elemento Eliminado Correctamente', 'success')
             
            }
          })
    }
    //<td class="col-md-1 align-middle"><button class="btn btn-light" >Editar</button></td>
    render() {
        return (
            <tr key={this.state.id}>
                <td class="col-md-4 align-middle">{this.state.titulo}</td>
                <td class="col-md-4 align-middle">{this.state.subtitulo}</td>
                <td class="col-md-2 align-middle">
                    <div className="custom-control custom-switch">
                        <input type="checkbox" className="custom-control-input" onChange={this.onChangeActivo} id={"customSwitch" + this.state.id} checked={this.state.estatus} />
                        <label className="custom-control-label" htmlFor={"customSwitch" + this.state.id}>{this.state.estatus ? "Activo" : "Inactivo"}</label>
                    </div>
                </td>
                <td class="col-md-1 align-middle"><button class="btn btn-light" onClick={this.editarCarousel}>Editar</button></td>
                <td class="col-md-1 align-middle"><button class="btn btn-danger" onClick={this.onPicoEliminar} ><i className="far fa-trash-alt"></i></button></td>
            </tr>
        )
    }

}
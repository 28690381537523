import React, { Component } from "react";

export default class NotFound404 extends Component {

    componentDidMount() {
        var top = document.getElementById('barraNavegacion');
        var footer = document.getElementById('footerNavegacion');
        var estilo = document.getElementById('estiloPrincipal');
        var estiloPlugin = document.getElementById('estiloPlugin');

        document.body.className = "";
        top.style.display = 'block';
        footer.style.display = 'block';
        estilo.href = './assets/css/styles.css';
        estiloPlugin.href = './assets/css/plugins.css';
    }

    render() {
        return (
            <div class="full-screen bg-img p-0" data-overlay-dark="0" data-background="img/banner/bg-pattern.png">
                <div class="container d-flex flex-column">
                    <div class="row align-items-center justify-content-center text-center min-vh-100">
                        <div class="col-lg-8">
                            <div class="error-wrapper bg-black-opacity py-5 rounded">
                                <h1 class="text-white">404</h1>
                                <h3 class="h2 font-weight-600 text-white mb-2-3">P&aacute;gina no encontrada</h3>
                                <div class="text-center mb-1-9 mb-md-2-5">
                                    <a href="/" class="butn"><span>Regresar al inicio</span></a>
                                </div>
                                <div class="col-md-12 text-center">
                                    <p class="mb-0 text-white">&copy; Copyright 2021 Mexico Amparame. Todos los derechos reservados</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <a href="/" class="scroll-to-top"><i class="fas fa-angle-up" aria-hidden="true"></i></a>
            </div>
        )
    }
}
import React, { Component, useState } from "react";
import UsuarioService from "../../services/usuario.service";
import ReactDOM from "react-dom";
import EditarUsuario from "../Usuarios/editarUsuario.component";
import CrearUsuario from "./crearUsuario.component";
import UsuarioListItem from "./usuarioListItem.component"

export default class ListaUsuarios extends Component {

    constructor(props) {
        super(props);

        this.obtenerUsuarios = this.obtenerUsuarios.bind(this);
        this.crearUsuario = this.crearUsuario.bind(this);
        console.log(this.props)
        this.state = {
            usuarios: this.obtenerUsuarios(),
            idUs: this.props.idUs
        };
    }

    crearUsuario() {
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }
        ReactDOM.render(<CrearUsuario rol={this.props.rol} />, divDash);
    }

    obtenerUsuarios() {
        UsuarioService.getAll()
            .then(response => {
                var usuarios = [];
                for (let i = 0; i < response.data.length; i++) {
                    usuarios.push(<UsuarioListItem
                        idUs={this.props.idUs}
                        id={response.data[i].id}
                        nombre={response.data[i].nombre}
                        paterno={response.data[i].paterno}
                        materno={response.data[i].materno}
                        correo={response.data[i].correo}
                        password={response.data[i].password}
                        sesion={this.props.idUs == response.data[i].id ? true : false}
                        imagen={response.data[i].imagen}
                        facebook={response.data[i].facebook}
                        instagram={response.data[i].instagram}
                        twitter={response.data[i].twitter}
                        linkedin={response.data[i].linkedin}
                        rol={response.data[i].rol}
                        rolOrigen={this.props.rol}
                        isAdmin={response.data[i].rol == 'Administrador' ? true : false}
                        idUs={this.state.idUs} />
                    );
                }

                this.setState({
                    usuarios: usuarios
                });
            })
            .catch(e => {
                console.log(e);
            });
    }

    render() {
        const { usuarios } = this.state;

        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div class="row justify-content-between">
                        <div class="col-6">
                            <h2 className="myaccount-content">Usuarios registrados</h2>
                        </div>
                        <div class="col-3-2">
                            <button type="button" onClick={this.crearUsuario} class="btn btn-warning btn-lg">Agregar Usuario</button>
                        </div>
                    </div>
                </div>

                <div class="table-responsive">
                    <table class="table table-striped table-hover">
                        <thead class="thead-dark">
                            <tr>
                                <th class="text-center" scope="col">Imagen</th>
                                <th class="text-center" scope="col">Nombre</th>
                                <th class="text-center" scope="col">Paterno</th>
                                <th class="text-center" scope="col">Materno</th>
                                <th class="text-center" scope="col">Correo</th>
                                <th class="text-center" scope="col">Sesión activa</th>
                                <th class="text-center" scope="col"></th>
                                <th class="text-center" scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {usuarios}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}
import { Component } from "react";
import Swal from 'sweetalert2';
import usuarioService from "../../services/usuario.service";
import  ListaUsuarios from './usuarioList.component'
import ReactDOM from "react-dom";

export default class CambiarPsw extends Component{
    constructor(props){
        super(props);

        this.onChangePsw1 = this.onChangePsw1.bind(this);
        this.onChangePsw2 = this.onChangePsw2.bind(this);

        this.state ={
            mostrar: false,
            pswd1: "",
            pswd2: "",
            id: this.props.id
        }
    }


    onChangePsw1(e){
        this.setState({pswd1: e.target.value})
    }

    onChangePsw2(e){
        this.setState({pswd2: e.target.value})
    }

    togglePassword = () => {
        this.setState({mostrar: !this.state.mostrar});                
    };

    guardarPsw=()=>{
        if(this.state.pswd1 != this.state.pswd2){
            Swal.fire({
                icon: 'warning',
                text: "Las contraseñas no son iguales."})  
        }else{
            var sha256 = require('js-sha256');
            let data ={
                password: sha256(this.state.pswd1)
            }
            usuarioService.update(this.state.id, data).then((response) =>{
                Swal.fire({
                    icon: 'success',
                    text: "Contraseña actualizada."})  
                    let divDash = document.getElementById('contenidoTablero');
                if (divDash.children.length > 0) {
                    ReactDOM.unmountComponentAtNode(divDash);
                }
                ReactDOM.render(<ListaUsuarios/>, divDash);
            }).catch((error) =>{console.log(error)})
        }
    }

    regresarAActualizar(e){
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }
        ReactDOM.render(<ListaUsuarios />, divDash);
    }


    render(){
        return (
            <>
            <div className="content-wrapper">
                <div className="col-sm-12 text-right" >
                        <button style={{'margin-top': '20px'}} onClick={this.regresarAActualizar} class="btn btn-danger"><i class="fas fa-times"></i><br/>Regresar</button>
                </div>
                    <h2 className="myaccount-content">Cambiar contraseña</h2>
                    <div class="col-md-8 mb-3">
                        <label className="font-weight-bold">Contraseña<span className="required"> * </span> </label>
                        <div class="input-group has-validation">                            
                            <input onChange={this.onChangePsw1} class="form-control" value={this.state.pswd1} type={this.state.mostrar ? "text" : "password"} />
                            <span><button className="btn btn-secondary" onClick={this.togglePassword}><i class="fas fa-eye"></i></button></span>                    
                        </div>
                    </div>
                    <div class="col-md-8 mb-3">
                        <label className="font-weight-bold">Confirme contraseña<span className="required"> * </span> </label>
                        <div class="input-group has-validation">                            
                            <input onChange={this.onChangePsw2} class="form-control" value={this.state.pswd2} type={this.state.mostrar ? "text" : "password"} />                            
                        </div>
                    </div>
                    <div className="col-sm-12 text-right">
                        <button onClick={this.guardarPsw}  class="btn btn-success"><i class="fas fa-save"></i><br/>Actualizar</button>
                    </div> 
            </div>
            </>
        );
    }
}
import React, { Component, useState } from "react";
import VoluntarioService from "../../services/voluntario.service";
import ReactDOM from "react-dom";
import CrearVoluntario from "./crearVoluntario.component";
import VoluntarioListItem from "./voluntarioListItem.component";
import ListaSolicitudes from "./Solicitudes/solicitudList.component";

export default class ListaVoluntarios extends Component {

    constructor(props) {
        super(props);

        this.obtenerVoluntarios = this.obtenerVoluntarios.bind(this);
        this.crearVoluntario = this.crearVoluntario.bind(this);
        this.obtenerSolicitudes = this.obtenerSolicitudes.bind(this);

        this.state = {
            voluntarios: this.obtenerVoluntarios()
        };
    }

    crearVoluntario() {
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }
        ReactDOM.render(<CrearVoluntario />, divDash);
    }

    obtenerSolicitudes(e) {
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }
        ReactDOM.render(<ListaSolicitudes />, divDash);
    }

    obtenerVoluntarios() {
        VoluntarioService.getAll()
            .then(response => {
                var tresVoluntarios = [];
                for (let i = 0; i < response.data.length; i++) {
                    tresVoluntarios.push(<VoluntarioListItem
                        id={response.data[i].id}
                        nombre={response.data[i].nombre}
                        paterno={response.data[i].paterno}
                        materno={response.data[i].materno}
                        correo={response.data[i].correo}
                        telefono={response.data[i].telefono}
                        facebookLink={response.data[i].facebook}
                        instagramLink={response.data[i].instagram}
                        twitterLink={response.data[i].twitter}
                        linkedinLink={response.data[i].linkedin}
                        activo={response.data[i].activo}
                        inicio={response.data[i].inicio}
                        imagen={response.data[i].imagen} />
                    );
                }

                this.setState({
                    voluntarios: tresVoluntarios
                });
            })
            .catch(e => {
                console.log(e);
            });
    }

    render() {
        const { voluntarios } = this.state;

        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div class="row justify-content-between">
                        <div class="col-6">
                            <h2 className="myaccount-content">Voluntarios registrados</h2>
                        </div>
                        <div class="col-3-2">
                            <button type="button" onClick={this.crearVoluntario} class="btn btn-warning btn-lg">Crear voluntario</button>
                        </div>
                        <div class="col-2-1">
                            <button type="button" onClick={this.obtenerSolicitudes} class="btn btn-info btn-lg">Ver solicitudes</button>
                        </div>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table table-striped table-hover">
                        <thead class="thead-dark">
                            <tr>
                                <th class="align-middle text-center" scope="col">Id</th>
                                <th class="align-middle text-center" scope="col">Nombre</th>
                                <th class="align-middle text-center" scope="col">Correo</th>
                                <th class="align-middle text-center" scope="col">Telefono</th>
                                <th class="align-middle text-center" scope="col">Estatus</th>
                                <th class="align-middle text-center" scope="col">Mostrar en inicio</th>
                                <th class="align-middle text-center" scope="col"></th>
                                <th class="align-middle text-center" scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {voluntarios}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}
import React, { Component, useState } from "react";
import ApoyoService from "../../services/apoyo.service.js";
import SolicitudApoyoListItem from "./apoyoListItem.component.js";

export default class ListaSolicitudesApoyo extends Component {

    constructor(props) {
        super(props);

        this.obtenerSolicitudes = this.obtenerSolicitudes.bind(this);

        this.state = {
            solicitudes: this.obtenerSolicitudes()
        };
    }

    obtenerSolicitudes() {
        ApoyoService.getAll()
            .then(response => {
                var tresSolicitudes = [];
                for (let i = 0; i < response.data.length; i++) {
                    tresSolicitudes.push(<SolicitudApoyoListItem
                        key={response.data[i].id}
                        solicitudPersona={response.data[i]} />
                    );
                }

                this.setState({
                    solicitudes: tresSolicitudes
                });
            })
            .catch(e => {
                console.log(e);
            });
    }

    render() {
        const { solicitudes } = this.state;

        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div class="row justify-content-between">
                        <div class="col-6">
                            <h2 className="myaccount-content">Solicitudes de apoyo</h2>
                        </div>
                    </div>
                </div>

                <div class="table-responsive">
                    <table class="table table-striped table-hover">
                        <thead class="thead-dark">
                            <tr>
                                <th class="align-middle text-center" scope="col">Nombre</th>
                                <th class="align-middle text-center" scope="col">Folio INE</th>
                                <th class="align-middle text-center" scope="col">Correo</th>
                                <th class="align-middle text-center" scope="col">Telefono</th>
                                <th class="align-middle text-center" scope="col"></th>
                                <th class="align-middle text-center" scope="col"></th>
                                <th class="align-middle text-center" scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {solicitudes}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}
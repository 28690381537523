import React, { Component } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromHTML, ContentState } from "draft-js"; //se importa convert from html para convertir el html a texto para el editor
import { convertToHTML } from "draft-convert"; //se importa y se instala con npm
import draftToHtml from "draftjs-to-html";
import Boton from "../button.component";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import NoticiaService from "../../services/noticia.service";
import Swal from "sweetalert2";
import GifLoader from "react-gif-loader";
import http from "../../http-common.js";
import ListarNoticias from "./listarNoticia.component";
import ReactDOM from "react-dom";

export default class EditarNoticia extends Component {
  constructor(props) {
    super(props);
    console.log(props)
    this.onChangeTituloNoticia = this.onChangeTituloNoticia.bind(this);
    this.onChangeTextoPreview = this.onChangeTextoPreview.bind(this);
    this.onChangeEditor = this.onChangeEditor.bind(this); //Texto noticia
    this.onChangeActivo = this.onChangeActivo.bind(this);
    this.guardarNoticia = this.guardarNoticia.bind(this);
    this.onChangeLinkImagen = this.onChangeLinkImagen.bind(this);
    this.onChangelinkImagenDos = this.onChangelinkImagenDos.bind(this);
    this.onChangelinkImagenTres = this.onChangelinkImagenTres.bind(this);
    this.clickNextButton = this.clickNextButton.bind(this);
    this.clickPrevButton = this.clickPrevButton.bind(this);
    this.nameHandler = this.nameHandler.bind(this);
    this.nameHandlerImg1 = this.nameHandlerImg1.bind(this);
    this.nameHandlerImg2 = this.nameHandlerImg2.bind(this);
    this.submitFormHandlerImg1 = this.submitFormHandlerImg1.bind(this);
    this.submitFormHandlerImg2 = this.submitFormHandlerImg2.bind(this);
    this.eliminarImagenDos = this.eliminarImagenDos.bind(this);
    this.eliminarImagenTres = this.eliminarImagenTres.bind(this);

    this.state = {
      id: this.props.id,
      noticia: this.props.noticia,
      textoPreview: this.props.textoPreview,
      estatus: this.props.estatus,
      editorState: EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(this.props.textoNoticia)
        )
      ),
      loading: false,
      loading1: false,
      loading2: false,
      imagen: this.props.imagen,
      imagenDos: this.props.imagenDos,
      imagenTres: this.props.imagenTres,
      imagenActual: 1
    };
  }

  componentDidMount() {
    console.log(this.state)
    http
      .get("/s3url2/" + this.state.imagen)
      .then((response) => {
        document.getElementById("imgNoticia").src = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

      
      
      http
      .get("/s3url2/" + this.state.imagenDos)
      .then((response) => {
        document.getElementById("imgDos").src = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

      http
      .get("/s3url2/" + this.state.imagenTres)
      .then((response) => {
        document.getElementById("imgTres").src = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
      
  }

  onChangeTituloNoticia(e) {
    this.setState({
      noticia: e.target.value,
    });
  }

  onChangeLinkImagen(e) {
    this.setState({
      linkImagen: e.target.value,
    });
  }

  onChangeTextoPreview(e) {
    this.setState({
      textoPreview: e.target.value,
    });
  }

  onChangeActivo(e) {
    let target = e.target;
    let inicioActivo = this.state.estatus;

    if (!target.checked) {
      inicioActivo = false;
    }

    this.setState({ estatus: target.checked }, () => {
      NoticiaService.update(this.state.id, this.state)
        .then((response) => {
          console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    });
  }

  onChangeEditor = (editorState) => {
    this.setState({
      editorState: editorState,
    });
    this.convertContentToHTML();
  };

  convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(
      this.state.editorState.getCurrentContent()
    );
    this.setState({ convertedContent: currentContentAsHTML });
  };

  nameHandler(e) {
    this.setState({ image: e.target.files[0] });
    if (e.target.files[0].name != null || e.target.files[0].name != "") {
      document.getElementById("nombre").innerHTML = e.target.files[0].name;
    }
  }

  regresar = () => {
    let divDash = document.getElementById("contenidoTablero");
    if (divDash.children.length > 0) {
      ReactDOM.unmountComponentAtNode(divDash);
    }
    ReactDOM.render(<ListarNoticias />, divDash);
  };

  async submitFormHandler(e) {
    e.preventDefault();
    const file = this.state;

    if (file.image != null) {
      http.delete("/s3url3/" + this.state.imagen);
      this.setState({ loading: true });
      let data = new FormData();
      data.append("foto", file.image);

      await http.post("/s3Url", data).then((response) => {
        http
          .get("/s3url2/" + response.data.data)
          .then((response) => {
            document.getElementById("imgNoticia").src = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
        this.setState({
          linkImagen: response.data.data,
          loading: false,
        });
      });
    } else {
      Swal.fire({
        icon: "error",
        text: "No ha cargado un arhivo aun.",
      });
    }
  }

  guardarNoticia() {
    let data = {
      noticia: this.state.noticia,
      textoPreview: this.state.textoPreview,
      textoNoticia: this.state.convertedContent,
      linkImagen: this.state.linkImagen,
      linkImagenDos: this.state.linkImagen2,
      linkImagenTres: this.state.linkImagen3,
    };

    console.log(data);
    NoticiaService.update(this.state.id, data)
      .then((response) => {
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    Swal.fire({
      icon: "success",
      title: "Noticia actualizada con exito!",
    });
    let divDash = document.getElementById("contenidoTablero");
    if (divDash.children.length > 0) {
      ReactDOM.unmountComponentAtNode(divDash);
    }
    ReactDOM.render(<ListarNoticias />, divDash);
  }

  eliminarImagenDos(e) {
    this.setState({
      linkImagen2: ""
    })
  }

  eliminarImagenTres(e) {
    this.setState({
      linkImagen3: ""
    })
  }

  onChangelinkImagenDos(e) {
    this.setState({
      linkImagenDos: e.target.value,
    });
  }

  onChangelinkImagenTres(e) {
    this.setState({
      linkImagenTres: e.target.value,
    });
  }

  clickNextButton(e) {
    let elementoUno = document.getElementById('imagenUno');
    let elementoDos = document.getElementById('imagenDosCarr');


    if (this.state.imagenActual == 1) {
      this.state.imagenActual = 2;
      ReactDOM.findDOMNode(elementoUno).style.display = "none";
      ReactDOM.findDOMNode(elementoDos).style.display = "inline";
    } else if (this.state.imagenActual == 2) {
      this.state.imagenActual = 1;
      ReactDOM.findDOMNode(elementoUno).style.display = "inline";
      ReactDOM.findDOMNode(elementoDos).style.display = "none";
    }

    console.log("Estamos en la imagen no" + this.state.imagenActual)

  }

  async submitFormHandlerImg1(e) {
    e.preventDefault();
    const file = this.state;

    if (file.imagenDos != null && file.imagenDos != "") {
      if (this.state.linkImagen2 !== "") {
        http.delete("/s3url3/" + this.state.linkImagen2);
      }
      this.setState({ loading1: true });
      let data = new FormData();
      data.append("foto", file.imagenDos);

      await http.post("/s3Url", data).then((response) => {
        this.setState({ loading1: false });
        http
          .get("/s3url2/" + response.data.data)
          .then((response) => {
            document.getElementById("imgDos").src = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
        this.setState({
          imagenDos: "",
          linkImagen2: response.data.data,
        });
      });
    } else {
      Swal.fire({
        icon: "error",
        text: "No ha cargado un arhivo aun.",
      });
    }
  }

  async submitFormHandlerImg2(e) {
    e.preventDefault();
    const file = this.state;
    console.log(this.state)

    if (file.imagenTres != null && file.imagenTres != "") {
      if (this.state.linkImagen3 != "") {

        http.delete("/s3url3/" + this.state.linkImagen3);
      }
      this.setState({ loading2: true });
      let data = new FormData();
      data.append("foto", file.imagenTres);

      await http.post("/s3Url", data).then((response) => {
        this.setState({ loading2: false });
        http
          .get("/s3url2/" + response.data.data)
          .then((response) => {
            document.getElementById("imgTres").src = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
        this.setState({
          imagenTres: "",
          linkImagen3: response.data.data,
        });
      });
    } else {
      Swal.fire({
        icon: "error",
        text: "No ha cargado un arhivo aun.",
      });
    }
  }

  clickPrevButton(e) {
    let elementoUno = document.getElementById('imagenUno');
    let elementoDos = document.getElementById('imagenDosCarr');


    if (this.state.imagenActual == 1) {
      this.state.imagenActual = 2;
      ReactDOM.findDOMNode(elementoUno).style.display = "none";
      ReactDOM.findDOMNode(elementoDos).style.display = "inline";
    } else if (this.state.imagenActual == 2) {
      this.state.imagenActual = 1;
      ReactDOM.findDOMNode(elementoUno).style.display = "inline";
      ReactDOM.findDOMNode(elementoDos).style.display = "none";
    }

    console.log("Estamos en la imagen no" + this.state.imagenActual)
  }

  nameHandlerImg1(e) {
    this.setState({ imagenDos: e.target.files[0] });
    if (e.target.files[0].name != null || e.target.files[0].name != "") {
      document.getElementById("nombreImg1").innerHTML = e.target.files[0].name;
    }
  }

  nameHandlerImg2(e) {
    this.setState({ imagenTres: e.target.files[0] });
    if (e.target.files[0].name != null || e.target.files[0].name != "") {
      document.getElementById("nombreImg2").innerHTML = e.target.files[0].name;
    }
  }

  render() {
    const { editorState } = this.state;

    return (
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container">
            <h2 className="myaccount-content">
              Actualizar datos de la Noticia
            </h2>
          </div>
        </div>

        <section className="content">
          <div className="container">
            <div className="row">
              <div className="form-row">
                <div className="form-group col-md-12">
                  <label className="font-weight-bold">
                    Titulo<span className="required"> * </span>{" "}
                  </label>
                  <input
                    type="text"
                    value={this.state.noticia}
                    onChange={this.onChangeTituloNoticia}
                    name="titulo"
                    className="form-control"
                    placeholder="Titulo"
                  />
                </div>
                <div className="form-group col-md-12">
                  <label className="font-weight-bold">
                    Vista previa<span className="required"> * </span>{" "}
                  </label>
                  <textarea
                    size="129"
                    height="400px"
                    value={this.state.textoPreview}
                    placeholder="Texto preview"
                    onChange={this.onChangeTextoPreview}
                    name="subtitulo"
                    className="form-control"
                    required
                  />
                </div>

                <div className="form-group col-md-12">
                  <label className="font-weight-bold">
                    Imagen de la noticia<span className="required"> * </span>{" "}
                  </label>
                  <GifLoader
                    loading={this.state.loading}
                    imageSrc="https://media.giphy.com/media/17mNCcKU1mJlrbXodo/giphy.gif"
                    overlayBackground="none"
                    imageStyle={{
                      width: "30%",
                      display: "block",
                      marginRight: "auto",
                      marginLeft: "auto",
                    }}
                  />
                  <center>
                    <img
                      style={{
                        width: "580px",
                        height: "280px",
                        marginBottom: "10px",
                      }}
                      id="imgNoticia"
                      class="img-fluid"
                      alt="Carga una imagen"
                    ></img>
                  </center>
                </div>

                <div class="form-group col-md-12">
                  <div
                    class="form-group"
                    onSubmit={this.submitFormHandler.bind(this)}
                  >
                    <label htmlFor="exampleInputFile">Archivo</label>
                    <div class="input-group">
                      <div class="custom-file">
                        <input
                          type="file"
                          class="custom-file-input"
                          id="exampleInputFile"
                          accept="image/*"
                          onChange={this.nameHandler.bind(this)}
                        />
                        <label
                          class="custom-file-label"
                          htmlFor="exampleInputFile"
                          id="nombre"
                        >
                          Elije un Archivo
                        </label>
                      </div>
                      <div class="input-group-append">
                        <span
                          class="input-group-text"
                          id=""
                          style={{ cursor: "pointer" }}
                          onClick={this.submitFormHandler.bind(this)}
                        >
                          Cargar
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group col-md-12 editor">
                  <label className="font-weight-bold">
                    Texto noticia<span className="required"> * </span>{" "}
                  </label>
                  <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={this.onChangeEditor}
                    toolbar={{
                      options: ["inline", "blockType", "list"],
                      inline: {
                        inDropdown: false,
                        className: "test",
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ["bold", "italic", "underline"],
                        bold: { className: "test", style: { color: "red" } },
                        italic: { className: undefined },
                        underline: { className: undefined },
                        font: { className: undefined },
                      },
                    }}
                  />
                </div>

                <div id="imagenUno" className="form-group col-md-12" style={{ display: "inline" }}>
                  <GifLoader
                    loading={this.state.loading1}
                    imageSrc="https://media.giphy.com/media/17mNCcKU1mJlrbXodo/giphy.gif"
                    overlayBackground="none"
                    imageStyle={{ width: "30%", display: "block", marginRight: "auto", marginLeft: "auto" }}
                  />
                  <label className="font-weight-bold">Imagenes para la galeria</label>
                  <br></br>
                  <a>Nota: en el orden que se suban, será el orden en que aparezca</a>
                  <div class="container" style={{ marginTop: '10px' }}>
                    <table class="table">
                      <thead>
                        <tr>
                          <th class="text-center align-middle" style={{ width: "10%" }}>
                            <button class="btn btn-outline-light" onClick={this.clickPrevButton}><i class="fas fa-arrow-left"></i></button>
                          </th>
                          <th class="text-center align-middle" style={{ width: "80%" }}>
                            <img id="imgDos" style={{ marginBottom: '10px' }} src={this.state.linkImagen2} class="img-fluid" alt="Carga una imagen"></img>
                          </th>
                          <th class="text-center align-middle" style={{ width: "10%" }}>
                            <button class="btn btn-outline-light" onClick={this.clickNextButton}><i class="fas fa-arrow-right"></i></button>
                          </th>
                        </tr>
                        <tr>
                          <th class="text-center align-middle" style={{ width: "10%" }}></th>
                          <th class="text-center align-middle" id="imagenActual" style={{ width: "80%" }}>
                            {"Imagen 1"}
                          </th>
                          <th class="text-center align-middle" style={{ width: "10%" }}>
                            <button class="btn btn-danger" onClick={this.eliminarImagenDos}>Eliminar imagen <i className="far fa-trash-alt"></i></button>
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  <form id="imagenesGaleria" style={{ marginTop: '10px' }} className="form-group" onSubmit={this.submitFormHandlerImg1}>
                    <div class="input-group">
                      <input style={{ marginTop: '10px' }} id="imagenesGaleria" class="custom-file-input" type="file" accept="image/*" onChange={this.nameHandlerImg1} />
                      <label class="custom-file-label" id="nombreImg1">{this.state.imagenGaleriaTres}</label>
                      <button style={{ marginTop: '10px' }} className="btn btn-success" type="submit">Cargar</button>
                    </div>
                  </form>
                </div>

                {/* aaaaaaaaaaaaaaaaa */}
                <div id="imagenDosCarr" className="form-group col-md-12" style={{ display: "none" }}>
                  <GifLoader
                    loading={this.state.loading2}
                    imageSrc="https://media.giphy.com/media/17mNCcKU1mJlrbXodo/giphy.gif"
                    overlayBackground="none"
                    imageStyle={{ width: "30%", display: "block", marginRight: "auto", marginLeft: "auto" }}
                  />
                  <label className="font-weight-bold">Imagenes para la galeria</label>
                  <br></br>
                  <a>Nota: en el orden que se suban, será el orden en que aparezca</a>
                  <div class="container" style={{ marginTop: '10px' }}>
                    <table class="table">
                      <thead>
                        <tr>
                          <th class="text-center align-middle" style={{ width: "10%" }}>
                            <button class="btn btn-outline-light" onClick={this.clickPrevButton}><i class="fas fa-arrow-left"></i></button>
                          </th>
                          <th class="text-center align-middle" style={{ width: "80%" }}>
                            <img id="imgTres" style={{ marginBottom: '10px' }} src={this.state.linkImagen3} class="img-fluid" alt="Carga una imagen"></img>
                          </th>
                          <th class="text-center align-middle" style={{ width: "10%" }}>
                            <button class="btn btn-outline-light" onClick={this.clickNextButton}><i class="fas fa-arrow-right"></i></button>
                          </th>
                        </tr>
                        <tr>
                          <th class="text-center align-middle" style={{ width: "10%" }}></th>
                          <th class="text-center align-middle" id="imagenActual" style={{ width: "80%" }}>
                            {"Imagen 2"}
                          </th>
                          <th class="text-center align-middle" style={{ width: "10%" }}>
                            <button class="btn btn-danger" onClick={this.eliminarImagenTres}>Eliminar imagen <i className="far fa-trash-alt"></i></button>
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  <form id="imagenesGaleria2" style={{ marginTop: '10px' }} className="form-group" onSubmit={this.submitFormHandlerImg2}>
                    <div class="input-group">
                      <input style={{ marginTop: '10px' }} id="imagenesGaleria2" class="custom-file-input" type="file" accept="image/*" onChange={this.nameHandlerImg2} />
                      <label class="custom-file-label" id="nombreImg2">{this.state.imagenGaleriaNombre}</label>
                      <button style={{ marginTop: '10px' }} className="btn btn-success" >Cargar</button>
                    </div>
                  </form>
                </div>

                <div class="col-md-4">
                  <label htmlFor="validationCustom02" class="form-label">
                    Activo
                  </label>
                  <br></br>
                  <label class="switch">
                    <input
                      type="checkbox"
                      checked={this.state.estatus}
                      onChange={this.onChangeActivo}
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
                <div className="form-group col-sm-12 text-right">
                  <button onClick={this.guardarNoticia} class="btn btn-primary">
                    <i class="fas fa-save"></i> Guardar
                  </button>
                  <>&nbsp;</>
                  <button onClick={this.regresar} class="btn btn-info">
                    <i class="fas fa-undo-alt"></i> Regresar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

import Card from "react-bootstrap/Card";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import EditarNoticia from "./editarNoticia.component";
import NoticiaService from "../../services/noticia.service";
import Swal from "sweetalert2";
import ListarNoticias from "./listarNoticia.component";
import http from "../../http-common.js";

export default class NoticiaListaItem extends Component {

  constructor(props) {
    super(props);
    console.log(props)
    this.editarNoticia = this.editarNoticia.bind(this);
    this.onChangeActivo = this.onChangeActivo.bind(this);
    this.eliminarNoticia = this.eliminarNoticia.bind(this);
    this.dialogoPreview = this.dialogoPreview.bind(this);

    this.state = {
      id: this.props.id,
      noticia: this.props.noticia,
      textoPreview: this.props.textoPreview,
      textoNoticia: this.props.textoNoticia,
      estatus: this.props.estatus,
      imagen: this.props.imagen,
      linkImagenDos: this.props.imagenDos,
      linkImagenTres: this.props.imagenTres,
    };
  }

  onChangeActivo(e) {
    let target = e.target;
    let inicioActivo = this.state.estatus;
    console.log(target.checked);

    if (!target.checked) {
      inicioActivo = false;
    }

    this.setState({ estatus: target.checked }, () => {
      NoticiaService.update(this.state.id, this.state)
        .then((response) => {
          console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    });
  }

  dialogoPreview() {
    Swal.fire({
      icon: "warning",
      title: "¿Estas seguro de eliminar la noticia?",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      denyButtonText: `Don't save`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        http.delete('/s3url3/'+this.state.imagen);    
        
        this.eliminarNoticia();
        Swal.fire("Eliminada con exito!", "", "success");
        let divDash = document.getElementById("contenidoTablero");
        if (divDash.children.length > 0) {
          ReactDOM.unmountComponentAtNode(divDash);
        }
        ReactDOM.render(<ListarNoticias />, divDash);
      }
    });
  }

  eliminarNoticia() {
    NoticiaService.delete(this.state.id)
      .then((response) => {
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    let divDash = document.getElementById("contenidoTablero");
    if (divDash.children.length > 0) {
      ReactDOM.unmountComponentAtNode(divDash);
    }
    ReactDOM.render(<ListarNoticias />, divDash);
  }

  editarNoticia() {
    let divDash = document.getElementById("contenidoTablero");
    if (divDash.children.length > 0) {
      ReactDOM.unmountComponentAtNode(divDash);
    }

    ReactDOM.render(
      <EditarNoticia
        id={this.state.id}
        noticia={this.state.noticia}
        textoPreview={this.state.textoPreview}
        textoNoticia={this.state.textoNoticia}
        estatus={this.state.estatus}
        imagen={this.state.imagen}
        imagenDos={this.state.linkImagenDos} 
        imagenTres={this.state.linkImagenTres}         
      />,
      divDash
    );
  }

  render() {
    return (
      <tr key={this.state.id}>
        <td class="col-md-2 align-middle">{this.state.noticia}</td>
        <td class="col-md-2 align-middle">
          {String(this.state.textoPreview.substring(0, 20))} ...
        </td>
        <td class="col-md-2 align-middle">
          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              className="custom-control-input"
              onChange={this.onChangeActivo}
              id={"customSwitch" + this.state.id}
              checked={this.state.estatus}
            />
            <label
              className="custom-control-label"
              htmlFor={"customSwitch" + this.state.id}
            >
              {this.state.estatus ? "Activo" : "Inactivo"}
            </label>
          </div>
        </td>

        <td class="col-md-1 align-middle">
          <button class="btn btn-light" onClick={this.editarNoticia}>
            Editar
          </button>
        </td>
        <td class="col-md-1 align-middle">
          <button class="btn btn-danger" onClick={this.dialogoPreview}>
            <i className="far fa-trash-alt"></i>
          </button>
        </td>
      </tr>
    );
  }
}

import React, { Component } from "react";
import Swal from 'sweetalert2';
import ReactDOM from "react-dom";
import ListaSolicitudes from "./solicitudList.component.js";
import SolicitudService from "../../../services/solicitud.service.js";

export default class VerSolicitud extends Component {

    constructor(props) {
        super(props);

        this.obtenerSolicitudes = this.obtenerSolicitudes.bind(this);
        this.eliminarSolicitud = this.eliminarSolicitud.bind(this);

        this.state = {
            id: this.props.id,
            nombre: this.props.nombre,
            paterno: this.props.paterno,
            materno: this.props.materno,
            correo: this.props.correo,
            telefono: this.props.telefono,
            motivo: this.props.motivo
        };
    }

    obtenerSolicitudes(e) {
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }
        ReactDOM.render(<ListaSolicitudes />, divDash);
    }

    eliminarSolicitud() {
        Swal.fire({
            text: '¿Deseas eliminar este elemento?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
        }).then((result) => {
            if (result.isConfirmed) {
                SolicitudService.delete(this.props.id).then(response => {
                    let divDash = document.getElementById('contenidoTablero');
                    Swal.fire('Eliminado', 'Elemento Eliminado Correctamente', 'success')
                    if (divDash.children.length > 0) {
                        ReactDOM.unmountComponentAtNode(divDash);
                    }
                    ReactDOM.render(<ListaSolicitudes />, divDash);
                });
            }
        });
    }

    render() {

        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <h2 className="">Datos del voluntario</h2>
                </div>

                <form class="row g-1 needs-validation" noValidate>
                    <div class="col-md-4 mb-3">
                        <label for="validationCustom01" class="form-label">Nombre(s)</label>
                        <input readOnly type="text" class="form-control" id="validationCustom01" value={this.state.nombre}/>
                    </div>
                    <div class="col-md-4">
                        <label for="validationCustom02" class="form-label">Apellido paterno</label>
                        <input readOnly type="text" class="form-control" id="validationCustom02" value={this.state.paterno}/>
                    </div>
                    <div class="col-md-4">
                        <label for="validationCustom02" class="form-label">Apellido materno</label>
                        <input readOnly type="text" class="form-control" id="validationCustom02" value={this.state.materno}/>
                    </div>

                    <div class="col-md-6 mb-3">
                        <label for="validationCustomUsername" class="form-label">Correo</label>
                        <div class="input-group has-validation">
                            <span class="input-group-text" id="inputGroupPrepend"><i class="fas fa-at"></i></span>
                            <input readOnly type="text" class="form-control" id="validationCustomUsername" aria-describedby="inputGroupPrepend"
                                value={this.state.correo}/>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <label for="validationCustomUsername" class="form-label">Tel&eacute;fono</label>
                        <div class="input-group has-validation">
                            <span class="input-group-text" id="inputGroupPrepend"><i class="fas fa-phone-square-alt"></i></span>
                            <input readOnly type="text" class="form-control" id="validationCustomUsername" aria-describedby="inputGroupPrepend"
                                value={this.state.telefono}/>
                        </div>
                    </div>

                    <div class="col-md-12 mb-3">
                        <label for="validationCustomUsername" class="form-label">Motivacion</label>
                        <div class="input-group has-validation">
                            <textarea readOnly type="text" class="form-control" id="validationCustomUsername" aria-describedby="inputGroupPrepend"
                                value={this.state.motivo}/>
                        </div>
                    </div>
                </form>

                <div class="col-md-6">
                    <div class="row justify-content-end">
                        <div class="col">
                            <button class="btn btn-danger" onClick={this.eliminarSolicitud}><i class="fas fa-trash-alt"></i> Eliminar</button>
                        </div>
                        <div class="col">
                            <button onClick={this.obtenerSolicitudes} class="btn btn-info"><i class="fas fa-undo-alt"></i> Regresar</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
import http from "../http-common";

class BrigadaSaludTutorService {
    getAll() {
        return http.get("/brigadadesaludinfantil/tutores");
    }

    get(id) {
        return http.get("/brigadadesaludinfantil/tutores/" + id);
    }

    create(data) {
        return http.post("/brigadadesaludinfantil/tutores", data);
    }

    update(id, data) {
        return http.put("/brigadadesaludinfantil/tutores/" + id, data);
    }

    delete(id) {
        return http.delete(`/brigadadesaludinfantil/tutores/${id}`);
    }

    deleteAll() {
        return http.delete("/brigadadesaludinfantil/tutores");
    }
}

export default new BrigadaSaludTutorService();

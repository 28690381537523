import React, { Component } from "react";
import Swal from 'sweetalert2';
import ReactDOM from "react-dom";
import UsuarioService from "../../services/usuario.service.js";
import ListaUsuarios from "../Usuarios/usuarioList.component.js";
import CambiarPsw from "./cambiarPswd.component.js";
import GifLoader from 'react-gif-loader';
import http from "../../http-common.js";

export default class PerfilUsuario extends Component {

    constructor(props) {
        super(props);
        this.onChangeNombre = this.onChangeNombre.bind(this);
        this.onChangePaterno = this.onChangePaterno.bind(this);
        this.onChangeMaterno = this.onChangeMaterno.bind(this);
        this.onChangeCorreo = this.onChangeCorreo.bind(this);
        this.onChangeSesion = this.onChangeSesion.bind(this);
        this.onChangeFacebook = this.onChangeFacebook.bind(this);
        this.onChangeInstagram = this.onChangeInstagram.bind(this);
        this.onChangeTwitter = this.onChangeTwitter.bind(this);
        this.onChangeLinkedIn = this.onChangeLinkedIn.bind(this);
        this.guardarUsuario = this.guardarUsuario.bind(this);

        this.state = {
            id: this.props.usuario.id,
            nombre: this.props.usuario.nombre,
            paterno: this.props.usuario.paterno,
            materno: this.props.usuario.materno,
            correo: this.props.usuario.correo,
            sesion: this.props.usuario.sesion,
            imagen: this.props.usuario.imagen,
            facebook: this.props.usuario.facebook,
            instagram: this.props.usuario.instagram,
            twitter: this.props.usuario.twitter,
            linkedin: this.props.usuario.linkedin,
            rol: this.props.usuario.rol,
            bandera: null,
            loading: false,
            nombreImg: ""
        };
    }

    cambiarPswd = (e) => {
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }
        ReactDOM.render(<CambiarPsw id={this.state.id} />, divDash);
    }

    componentDidMount() {
        http.get('/s3url2/'+this.state.imagen).then(response => {document.getElementById('imgPerfil').src = response.data}).catch(error => {console.log(error);});   
    }

    onChangeNombre(e) {
        this.setState({
            nombre: e.target.value
        });
    }

    onChangePaterno(e) {
        this.setState({
            paterno: e.target.value
        });
    }

    onChangeMaterno(e) {
        this.setState({
            materno: e.target.value
        });
    }

    onChangeCorreo(e) {
        this.setState({
            correo: e.target.value
        });
    }

    onChangeSesion(e) {
        let target = e.target;
        let inicioActivo = !this.state.sesion;

        this.setState({ sesion: inicioActivo }, () => {
            UsuarioService.update(this.state.id, this.state)
                .then(response => {
                    console.log(response.data);
                })
                .catch(e => {
                    console.log(e);
                });
        });
    }

    onChangeFacebook(e) {
        this.setState({
            facebook: e.target.value
        });
    }

    onClickRecuadros(e){
        http.delete('/s3url3/'+this.state.imagen);
        document.getElementById('imgPerfil').src = "";
        this.setState({
            imagen: ""
        })
    }

    onChangeInstagram(e) {
        this.setState({
            instagram: e.target.value
        });
    }

    onChangeTwitter(e) {
        this.setState({
            twitter: e.target.value
        });
    }

    onChangeLinkedIn(e) {
        this.setState({
            linkedin: e.target.value
        });
    }

    nameHandler(e) {
        let archivo = e.target.files[0];

        if (archivo) {
            let nombre = "Archivo elegido: " + archivo.name;
            const scope = this;
            var reader = new FileReader();

            //Read the contents of Image File.
            reader.readAsDataURL(archivo);
            reader.onload = function (file) {
                var image = new Image();
                image.src = file.target.result;
                image.onload = function () {
                    let height = this.naturalHeight;
                    let width = this.naturalWidth;

                    if (height != width || height > 551 || width > 551) {
                        Swal.fire({
                            icon: 'warning',
                            text: "La imagen no debe exceder 551 pixeles de alto y 551 pixeles de ancho. " +
                             "Ademas, debe ser una imagen cuadrada."
                        });
                        return false;
                    }
                    scope.setState({ imagen: archivo, nombreImg: nombre });
                    return true;
                };
            };
        }
    }

    async submitFormHandler(e) {
        e.preventDefault()
        let file;
        file = this.state.imagen;        

        if(this.state.nombreImg !== ""){
            if(this.state.imagen!==""){
                http.delete('/s3url3/'+this.state.imagen);
            }            
            this.setState({
                nombreImg: "",
                loading: true
            })
            
            let data = new FormData();
            data.append('foto', file);
            
            await http.post('/s3Url', data).then((response) => {                        
    
                http.get('/s3url2/'+response.data.data).then(response => {document.getElementById('imgPerfil').src = response.data}).catch(error => {console.log(error);});   
    
                this.setState({ imagen: response.data.data, loading: false });
            })
        }else{
            Swal.fire({
                icon: 'warning',
                text: "Hay campos requeridos vacios."
            })
        }
    
        
    }

    guardarUsuario = (e) => {
        var data = {
            id: this.state.id,
            nombre: this.state.nombre,
            paterno: this.state.paterno,
            materno: this.state.materno,
            correo: this.state.correo,
            sesion: this.state.sesion,
            imagen: this.state.imagen,
            facebook: this.state.facebook,
            instagram: this.state.instagram,
            twitter: this.state.twitter,
            linkedin: this.state.linkedin,
            rol: this.state.rol
        };

        console.log(this.state.id);
        if (this.state.nombre && this.state.paterno && this.state.materno && this.state.imagen) {
            console.log(data);
            UsuarioService.update(this.state.id, data)
                .then(response => {
                    this.setState({
                        id: this.state.id,
                        nombre: this.state.nombre,
                        paterno: this.state.paterno,
                        materno: this.state.materno,
                        correo: this.state.correo,
                        sesion: this.state.sesion,
                        imagen: this.state.imagen,
                        facebook: this.state.facebook,
                        instagram: this.state.instagram,
                        twitter: this.state.twitter,
                        linkedin: this.state.linkedin,
                        rol: this.state.rol
                    });
                    sessionStorage.setItem('usuario', JSON.stringify(data));
                    Swal.fire({
                        icon: 'success',
                        text: "Se modificaron los datos.",
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.reload();
                        }
                    })
                })
                .catch(e => {
                    console.log(e);
                    Swal.fire({
                        icon: 'error',
                        text: "Hubo un error en la solicitud."
                    })
                });
        }
        else {
            Swal.fire({
                icon: 'warning',
                text: "Hay campos requeridos vacios."
            })
            console.log("error");
        }

    }

    render() {
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container">
                        <h2 className="myaccount-content">Datos del Usuario</h2>
                    </div>
                </div>

                <form class="row g-3 needs-validation" novalidate>
                    <div class="col-md-4 mb-3">
                        <label for="validationCustom01" class="form-label">Nombre(s)*</label>
                        <input type="text" class="form-control" id="validationCustom01" value={this.state.nombre} onChange={this.onChangeNombre} required />
                        <div class="valid-feedback">
                            Valido
                        </div>
                    </div>
                    <div class="col-md-4">
                        <label for="validationCustom02" class="form-label">Apellido paterno*</label>
                        <input type="text" class="form-control" id="validationCustom02" value={this.state.paterno} onChange={this.onChangePaterno} required />
                        <div class="valid-feedback">
                            Valido
                        </div>
                    </div>
                    <div class="col-md-4">
                        <label for="validationCustom02" class="form-label">Apellido materno*</label>
                        <input type="text" class="form-control" id="validationCustom02" value={this.state.materno} onChange={this.onChangeMaterno} required />
                        <div class="valid-feedback">
                            Valido
                        </div>
                    </div>
                    <div class="col-md-4">
                        <label for="validationCustomUsername1" class="form-label">Facebook</label>
                        <div class="input-group has-validation">
                            <span class="input-group-text" id="inputGroupPrepend1"><i class="fab fa-facebook-square"></i></span>
                            <input type="text" class="form-control" id="validationCustomUsername1" aria-describedby="inputGroupPrepend1"
                                value={this.state.facebook} onChange={this.onChangeFacebook} />
                        </div>
                    </div>

                    <div class="col-md-4 mb-3">
                        <label for="validationCustomUsername2" class="form-label">Instagram</label>
                        <div class="input-group has-validation">
                            <span class="input-group-text" id="inputGroupPrepend2"><i class="fab fa-instagram-square"></i></span>
                            <input type="text" class="form-control" id="validationCustomUsername2" aria-describedby="inputGroupPrepend2"
                                value={this.state.instagram} onChange={this.onChangeInstagram} />
                        </div>
                    </div>

                    <div class="col-md-4">
                        <label for="validationCustomUsername3" class="form-label">Twitter</label>
                        <div class="input-group has-validation">
                            <span class="input-group-text" id="inputGroupPrepend3"><i class="fab fa-twitter-square"></i></span>
                            <input type="text" class="form-control" id="validationCustomUsername3" aria-describedby="inputGroupPrepend3"
                                value={this.state.twitter} onChange={this.onChangeTwitter} />
                        </div>
                    </div>

                    <div class="col-md-4">
                        <label for="validationCustomUsername4" class="form-label">LinkedIn</label>
                        <div class="input-group has-validation">
                            <span class="input-group-text" id="inputGroupPrepend4"><i class="fab fa-linkedin"></i></span>
                            <input type="text" class="form-control" id="validationCustomUsername4" aria-describedby="inputGroupPrepend4"
                                value={this.state.linkedin} onChange={this.onChangeLinkedIn} />
                        </div>
                    </div>
                </form>
                <div class="col-md-12">
                    <div class="row justify-content-between">
                        <div class="col-6">
                            <div class="row">
                                <div class="col-md-4">
                                </div>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="row justify-content-end">
                                <div class="col">
                                    <button class="btn btn-primary" onClick={this.guardarUsuario}><i class="fas fa-save"></i> Guardar cambios</button>
                                </div>
                                <div class="col">
                                    <button class="btn btn-warning" onClick={this.cambiarPswd}><i class="fas fa-lock"></i> <br />Cambiar Contraseña</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 mb-3">
                    <label className="font-weight-bold">Foto de perfil*<span className="required"> * </span> </label>
                     <div id={"recuadro0"} className="recuadro-imagen align-content-center">
                        <GifLoader
                            loading={this.state.loading}
                            imageSrc="https://media.giphy.com/media/17mNCcKU1mJlrbXodo/giphy.gif"
                            overlayBackground="none"
                            imageStyle={{ width: "30%", display: "block", marginRight: "auto", marginLeft: "auto" }}
                        />
                        <img width="50%" id="imgPerfil" />
                        <button className="btn-danger" onClick={e => this.onClickRecuadros(e)}><i className="far fa-trash-alt"></i></button>
                    </div>
                    <form id="imageForm" style={{ "marginTop": '10px' }} className="form-group" onSubmit={e => this.submitFormHandler(e)}>
                        <div class="input-group">
                            <input id="imageInput" id="exampleInputFile" class="custom-file-input" type="file" accept="image/*" onChange={e => this.nameHandler(e)} />
                            <label class="custom-file-label" id="nombre">{this.state.nombreImg}</label>
                            <button style={{ "margin-top": '10px' }} className="btn btn-success" type="submit">Cargar</button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

import React, { Component } from "react";
import { Link } from 'react-router-dom'
import http from "../../http-common.js";
import UsuarioService from "../../services/usuario.service";

export default class NoticiaGridItem extends Component {

    constructor(props) {
        super(props);
        
        this.state={
            id: this.props.id,
            imagen: this.props.linkImagen,
            autorImagen:this.props.autorImagen
        }
        
    }

    datosAutorId = () =>{        
        UsuarioService.get(this.props.autorId)
            .then(response => {                          
                
            })
    }            

    componentDidMount() {
        this.datosAutorId();        
        var top = document.getElementById('barraNavegacion');
        var footer = document.getElementById('footerNavegacion');
        var estilo = document.getElementById('estiloPrincipal');
        var estiloPlugin = document.getElementById('estiloPlugin');

        document.body.className = "";
        top.style.display = 'block';
        footer.style.display = 'block';
        estilo.href = 'assets/css/styles.css';
        http.get('/s3url2/'+this.state.imagen).then(response => {document.getElementById('imgNoticia'+this.state.id).src = response.data}).catch(error => {console.log(error);});   
        console.log(this.props.autorId)
        UsuarioService.get(this.props.autorId)
            .then(response => {               
            http.get('/s3url2/'+response.data.imagen).then(response => {
                document.getElementById('imgAutor'+this.props.id).src = response.data;
            }).catch(error => {console.log(error);});                
            document.getElementById('nombreAutor'+this.props.id).innerHTML = response.data.nombre + ' ' + response.data.paterno
        })        
    }

    render() {
        return (
            <div class="col-md-6 col-lg-4 mt-1-9 wow fadeIn tilt" data-wow-delay="100ms">
                <article class="card card-style3 border-color-extra-light-gray h-100">
                    <div class="card-img">
                        <img id={"imgNoticia"+this.props.id} style={{height: '274px', width: '100%', 'object-fit':'fit'}} 
                            alt="..." />
                        <div class="author-info">
                            <div class="author-img">
                                <img  id={"imgAutor"+this.props.id} alt="..." />
                            </div>
                            <span class="display-30 text-white" id={"nombreAutor"+this.props.id}></span>
                        </div>
                    </div>
                    <div style={{ paddingBottom: "1px", marginBottom: "1px" }} class="card-body px-1-6 px-sm-1-9 py-2-3">
                        <h3 class="font-weight-600 h4 mb-1-6"><Link to={"/noticias" + this.props.id}>{this.props.noticia}</Link></h3>

                    </div>

                    <div class="card-body px-1-6 px-sm-1-9 py-2-3" style={{ paddingBottom: "1px", marginBottom: "1px", paddingTop: "1px" }}>

                        <p>{this.props.textoPreview}</p>
                    </div>

                    <div>
                        <center><a class="butn-read"><span><Link to={"/noticias" + this.props.id}>Leer mas...</Link></span></a></center>

                        <br></br>
                    </div>

                    <div class="d-flex justify-content-between card-footer px-1-6 px-sm-1-9 py-3 bg-white border-color-extra-light-gray">
                        <span class="display-30 font-weight-600"><i class="far fa-calendar-alt me-2 text-secondary"></i><a >{this.props.updatedAt.substr(0, 10)}</a></span>
                    </div>
                </article>
            </div>

        )
    }
}

import ProyectoService from "../../services/proyecto.service";
import React, {Component} from "react";
import {Link} from 'react-router-dom';

export default class CausasList extends Component {
    constructor(props){
        super(props);

        this.state = {
            id: 0
        }

        this.getId();
    }

    getId(){
        ProyectoService.getAll()
        .then((response) =>{
            if(response.data[0].id == undefined);            
            else
                this.setState({id: response.data[0].id});
                        
        })        
        .catch((error)=> console.log(error))     
    }

    render(){
        return(
            <>
            <li><a href="/causasGrid" >Causas</a>
                <ul>
                    <li><a><Link to={"/causasGrid"}>Causas Cuadricula</Link></a></li>                                              
                    <li><a><Link to={"/causasDetalle"+this.state.id}>Causas Detalle</Link></a></li>
                </ul>
            </li>
            </>
        )
    }
}
import React, { Component, useState } from "react";
import ProyectoListItem  from "./proyectoListItem.component";
import ProyectoService from "../../services/proyecto.service.js";
import ReactDOM from "react-dom";
import CrearProyecto from "../Causas/crearProyecto.component";

export default class ListaProyectos extends Component {

    constructor(props) {
        super(props);

        this.obtenerProyectos = this.obtenerProyectos.bind(this);
        this.crearProyecto = this.crearProyecto.bind(this);

        this.state = {
            proyectos: this.obtenerProyectos()
        };
    }

    crearProyecto() {
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }
        ReactDOM.render(<CrearProyecto id={0} />, divDash);
    }

    obtenerProyectos() {
        ProyectoService.getAll()
            .then(response => {
                var proyectos = [];
                for (let i = 0; i < response.data.length; i++) {
                    proyectos.push(<ProyectoListItem 
                                        titulo={response.data[i].titulo}
                                        textoPre={response.data[i].texto}
                                        activo={response.data[i].activo}
                                        mostrarInicio={response.data[i].mostrarInicio}
                                        id={response.data[i].id}
                                        imagenes={response.data[i].imagenes}
                                        />
                    );
                }

                this.setState({
                    proyectos: proyectos
                });                
            })
            .catch(e => {
                console.log(e);
            });
    }

    render() {
        const { proyectos } = this.state;

        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div class="row justify-content-between">
                        <div class="col-6">
                            <h2 className="myaccount-content">Proyectos registrados</h2>
                        </div>
                        <div class="col-3-2">
                            <button type="button" onClick={this.crearProyecto} class="btn btn-warning btn-lg">Agregar Proyecto</button>
                        </div>
                    </div>
                </div>

                <table class="table table-striped table-hover">
                    <thead class="thead-dark">
                        <tr>
                            <th class="text-center" scope="col">Titulo</th>                            
                            <th class="text-center" scope="col">Detalle</th>
                            <th class="text-center" scope="col">Activo</th>
                            <th class="text-center" scope="col">Mostrar Inicio</th>
                            <th class="text-center" scope="col">Editar</th>
                            <th class="text-center" scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {proyectos}
                    </tbody>
                </table>
            </div>
        )
    }
}
import React, { Component } from "react";
import Swal from 'sweetalert2';
import ProyectoService from "../../services/proyecto.service.js";
import { EditorState, convertFromHTML, ContentState} from 'draft-js'; //se importa convert from html para convertir el html a texto para el editor
import { convertToHTML } from 'draft-convert';//se importa y se instala con npm
import { Editor } from 'react-draft-wysiwyg';
import ListaProyectos from '../Causas/proyectoList.component';
import ReactDOM from "react-dom";
import GifLoader from 'react-gif-loader';
import http from "../../http-common.js";

export default class CrearProyecto extends Component {

    constructor(props){
        super(props);
        
        this.onChangeTitulo = this.onChangeTitulo.bind(this);
        this.onChangeApoyos = this.onChangeApoyos.bind(this);
        this.onChangeDineroActual = this.onChangeDineroActual.bind(this);
        this.onChangeDonadores = this.onChangeDonadores.bind(this);
        this.onChangeTextoDetalles = this.onChangeTextoDetalles.bind(this);
        this.onChangeTextoPre = this.onChangeTextoPre.bind(this);
        this.onChangeMetaProyecto = this.onChangeMetaProyecto.bind(this);
        this.onChangeEditor = this.onChangeEditor.bind(this);
        this.guardarProyecto = this.guardarProyecto.bind(this);
        this.onChangeMostrarInicio = this.onChangeMostrarInicio.bind(this);
        this.onChangeActivo = this.onChangeActivo.bind(this);      
        this.checarInicio = this.checarInicio.bind(this);   
        this.submitFormHandler = this.submitFormHandler.bind(this);                
        this.clickNextButton = this.clickNextButton.bind(this);
        this.clickPrevButton = this.clickPrevButton.bind(this);        
        this.eliminarImagenGaleria = this.eliminarImagenGaleria.bind(this);
        this.cancelar= this.cancelar.bind(this);

        this.state = {
            imagenGrande: "",
            imagenMediana1: "",
            imagenMediana2: "",
            imagenPre: "",
			titulo: "",
            donadores: 0,
            apoyos: 0,
            textoDetalle: "",
            textoPre: "",
            dineroActual: 0,
            metaProyecto: 0,
            mostrarInicio: false,
            thisIsMostrarInicio: false,
            numInicio: 0,            
            activo: true,            
            textoBoton: "Guardar",            
            editorState: EditorState.createEmpty(),            
            convertedContent: null,//se pone la propiedad convertedContent
            imagen: null,            
            recuadrosImagen: [],
            nombreFoto: "",
            imagenesGaleria: [],
            imagenActualCarrusel: "",
            indiceImagen: -1,
            imagenGaleriaNombre: "",
            loading: false
        };

        this.checarNumEnInicio()

        this.imagen = this.nameHandler.bind(this);        
        /*this.onImgLoad = this.onImgLoad.bind(this);*/

        if(this.props.id!=0){
            this.fillCampos();
        }        
    }

    fillCampos(){
        ProyectoService.get(this.props.id).then(response =>{
            if(response.data.mostrarInicio){
                this.setState({
                    thisIsMostrarInicio: true
                })
            }else{
                this.setState({
                    thisIsMostrarInicio: false
                })
            }

            //al recibir la info para verla y actualizarla se convierte a texto cob las siguientes funciones
            const sampleMarkup = response.data.textoDetalle;

            const blocksFromHTML = convertFromHTML(sampleMarkup);
            const state = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap,);                        

            this.setState({
                titulo: response.data.titulo,
                donadores: response.data.donadores,
                apoyos: response.data.apoyos,                
                textoPre: response.data.texto,
                dineroActual: response.data.dineroActual,
                metaProyecto: response.data.dineroMeta,
                mostrarInicio: response.data.mostrarInicio,
                activo: response.data.activo,       
                textoBoton: "Actualizar",
                editorState: EditorState.createWithContent(state),
                convertedContent: response.data.textoDetalle,
                imagenesGaleria: response.data.imagenes,
                imagenActualCarrusel: response.data.imagenes[0],
                indiceImagen: 0,
                imagenGaleriaNombre: ""                          
            })            

            http.get('/s3url2/'+response.data.imagenes[0]).then(response => {document.getElementById('imgCarrusel').src = response.data}).catch(error => {console.log(error);});   
            
        }).catch(error => console.log(error))    
                
    }

    cancelar(e){
        if(this.state.imagenesGaleria.length >0){
            for(let i = 0; i < this.state.imagenesGaleria.length; i++){
                http.delete('/s3url3/'+this.state.imagenesGaleria[i]);
            }
        }
        this.regresarAListar(e);
    }        

    regresarAListar(e){
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }
        ReactDOM.render(<ListaProyectos/>, divDash);
    }

    actualizarDatos(){  
        var bandera = true;                    
        if(this.state.mostrarInicio && !this.state.thisIsMostrarInicio){
            if(this.state.numInicio >= 6)
                bandera = false;
        }                         

        if(this.state.imagenesGaleria == null || this.state.imagenesGaleria.length < 1 ||this.state.imagenesGaleria == undefined || this.state.metaProyecto == 0){ 
            bandera = false;
        }

        if(bandera){
            var data = {
                id: this.props.id,
                titulo: this.state.titulo,
                donadores: this.state.donadores,
                apoyos: this.state.apoyos,
                //mandar a base de datos el contenido en html
                textoDetalle: this.state.convertedContent,
                texto: this.state.textoPre,
                dineroActual: this.state.dineroActual,
                dineroMeta: this.state.metaProyecto,
                activo: this.state.activo,
                mostrarInicio: this.state.mostrarInicio,
                imagenes: this.state.imagenesGaleria

            }; 
    
            ProyectoService.update(this.props.id, data).then(() => {
                Swal.fire({
                    icon: 'success',
                    text: "Proyecto actualizado con éxito"})
            })
            .catch(error => console.log(error))
        }else if(this.state.numInicio >= 6){        
            Swal.fire({
                icon: 'warning',
                text: "No puede poner más de tres proyectos en inicio"})            
        }else if( this.state.imagenesGaleria == null || this.state.imagenesGaleria.length < 1 ||this.state.imagenesGaleria == undefined){
            Swal.fire({
                icon: 'warning',
                text: "Necesita poner al menos una imagen"})            
        }else if( this.state.metaProyecto == 0){
            Swal.fire({
                icon: 'warning',
                text: "La meta no puede ser 0"})            
        }    

    }

    checarNumEnInicio = () => {                        
        ProyectoService.findByInicio().then(response => {             
            this.setState({ numInicio: response.data.length})
        }).catch(error => console.log(error));
        
    }
    //se cambia la forma de set el estado
    onChangeEditor = (editorState) =>{
        this.setState({
            editorState: editorState
        })        
        this.convertContentToHTML();
    }
    
    //Función para convertir el contenido a html
    convertContentToHTML = () =>{
        let currentContentAsHTML = convertToHTML(this.state.editorState.getCurrentContent());
        this.setState({convertedContent: currentContentAsHTML});
    }

    onChangeTitulo(e) {
        if(e.target.value.length >= 39){
            Swal.fire({
                icon: 'warning',
                text: "Texto demasiado largo"})      
        }else{
            this.setState({
                titulo: e.target.value
            });
        }
    }

    onChangeDonadores(e) {
        this.setState({
            donadores: e.target.value
        });
    }

    onChangeApoyos(e) {    
        this.setState({
            apoyos: e.target.value
        });        
    }

    onChangeTextoDetalles(e) {
        this.setState({
            textoDetalle: e.target.value
        });
    }

    onChangeTextoPre(e) {
        if(e.target.value.length >= 129){
            Swal.fire({
                icon: 'warning',
                text: "Texto demasiado largo"})      
        }else{
            this.setState({
                textoPre: e.target.value
            });
        }        
    }

    onChangeDineroActual(e){
        this.setState({
            dineroActual: e.target.value
        })
    }

    onChangeMetaProyecto(e){
        this.setState({
            metaProyecto: e.target.value
        })
    }

    onChangeActivo(){
        this.setState({
            activo: !this.state.activo
        })
    }

    onChangeMostrarInicio(){
        this.setState({
            mostrarInicio: !this.state.mostrarInicio
        })
    }

    checarInicio(){
        ProyectoService.findByInicio().then(response => {
            if(response.data.length >= 6){ 
                this.setState({
                    mostrarInicio: false
                });
                
                Swal.fire({
                    icon: 'warning',
                    text: "No puede mostrar más de 6 proyectos en inicio"});
                    
                return true;
                    
            }else{
                return false;
            }
        })
    }
    
    guardarProyecto = () =>{         
        var data = {};                          
        if(this.props.id!=0){            
            this.actualizarDatos();
        }else{            
            if(this.state.mostrarInicio){
                if(this.state.numInicio >= 6){                    
                    data = {
                        titulo: this.state.titulo,
                        donadores: this.state.donadores,
                        apoyos: this.state.apoyos,
                        //mandar a base de datos el contenido en html
                        textoDetalle: this.state.convertedContent,
                        texto: this.state.textoPre,
                        dineroActual: this.state.dineroActual,
                        dineroMeta: this.state.metaProyecto,
                        activo: this.state.activo,
                        mostrarInicio: false,
                        imagenes: this.state.imagenesGaleria
                    }                
                }else{
                    data = {
                        titulo: this.state.titulo,
                        donadores: this.state.donadores,
                        apoyos: this.state.apoyos,
                        //mandar a base de datos el contenido en html
                        textoDetalle: this.state.convertedContent,
                        texto: this.state.textoPre,
                        dineroActual: this.state.dineroActual,
                        dineroMeta: this.state.metaProyecto,
                        activo: this.state.activo,
                        mostrarInicio: this.state.mostrarInicio,
                        imagenes: this.state.imagenesGaleria
                    }
                }
            }else{
                data = {
                    titulo: this.state.titulo,
                    donadores: this.state.donadores,
                    apoyos: this.state.apoyos,
                    //mandar a base de datos el contenido en html
                    textoDetalle: this.state.convertedContent,
                    texto: this.state.textoPre,
                    dineroActual: this.state.dineroActual,
                    dineroMeta: this.state.metaProyecto,
                    activo: this.state.activo,
                    mostrarInicio: this.state.mostrarInicio,
                    imagenes: this.state.imagenesGaleria
                }
            }      
            
            if(this.state.imagenesGaleria == null || this.state.imagenesGaleria.length < 1 ||this.state.imagenesGaleria == undefined){
                Swal.fire({
                    icon: 'warning',
                    text: "Necesita poner al menos una imagen."})    
                    return;
            }            
            if(this.state.titulo && this.state.convertedContent && this.state.textoPre  && this.state.metaProyecto && this.state.metaProyecto != 0
                && this.state.imagenesGaleria.length != 0){                
                    ProyectoService.create(data)
                        .then(response => {
                            this.setState({
                                titulo: "",
                                donadores: 0,
                                apoyos: 0,
                                convertedContent: null,
                                textoPre: "",
                                dineroActual: 0,
                                metaProyecto: 0,
                                mostrarInicio: false,
                                activo: true,
                                editorState: EditorState.createEmpty(),
                                imagenesGaleria: null
                            });
                            
                            Swal.fire({
                                icon: 'success',
                                text: "Se introdujo el proyecto correctamente"});                            
                            let divDash = document.getElementById('contenidoTablero');
                            if (divDash.children.length > 0) {
                                ReactDOM.unmountComponentAtNode(divDash);
                            }
                            ReactDOM.render(<ListaProyectos/>, divDash);
                            
                        })
                        .catch(e => {                        
                            Swal.fire({
                                icon: 'error',
                                text: e.message})
                        });
            }
            else{
                Swal.fire({
                    icon: 'warning',
                    text: "Hay campos requeridos vacios."})            
            }
        }
    }
   
    nameHandler(e) {    
        this.setState({ imagen: e.target.files[0], nombre: e.target.files[0].name});    
    }  

    async submitFormHandler(e) {   
        e.preventDefault();     
        let file;        
        file = this.state.imagen;
                             
        //obtener la url segura
        if (file) {
                this.setState({
                    nombre: "",
                    loading: true
                })        
                    
                let data = new FormData();
                data.append('foto', file);
                await http.post('/s3Url', data).then((response) => {                                                            
                    let nuevasImagenes = this.state.imagenesGaleria;
                    let nuevoIndex = nuevasImagenes.length;
                    http.get('/s3url2/'+response.data.data).then(response => {document.getElementById('imgCarrusel').src = response.data}).catch(error => {console.log(error);});   
                    nuevasImagenes.push(response.data.data);
                    this.setState({
                        imagenesGaleria: nuevasImagenes,
                        imagenActualCarrusel: response.data.data,
                        indiceImagen: nuevoIndex,
                        imagen: null,
                        imagenGaleriaNombre: "",
                        loading: false
                    });            
                })
        }else {
            Swal.fire({
                icon: 'warning',
                text: "Debe cargar una imagen."
            });
        }              
    }

    clickNextButton() {
        if (this.state.imagenesGaleria.length > 0) {
            if (this.state.indiceImagen >= this.state.imagenesGaleria.length - 1) {        
                http.get('/s3url2/'+this.state.imagenesGaleria[0]).then(response => {document.getElementById('imgCarrusel').src = response.data}).catch(error => {console.log(error);});   
                this.setState({
                    indiceImagen: 0,
                    imagenActualCarrusel: this.state.imagenesGaleria[0]
                })
            }
            else {
                let nuevoIndex = this.state.indiceImagen + 1;
                http.get('/s3url2/'+this.state.imagenesGaleria[nuevoIndex]).then(response => {document.getElementById('imgCarrusel').src = response.data}).catch(error => {console.log(error);});   
                this.setState({
                    indiceImagen: nuevoIndex,
                    imagenActualCarrusel: this.state.imagenesGaleria[nuevoIndex]
                })
            }
        }
    }

    clickPrevButton() {
        if (this.state.imagenesGaleria.length > 0) {
            if (this.state.indiceImagen <= 0) {                
                let nuevoIndex = this.state.imagenesGaleria.length - 1;                
                http.get('/s3url2/'+this.state.imagenesGaleria[nuevoIndex]).then(response => {document.getElementById('imgCarrusel').src = response.data}).catch(error => {console.log(error);});   
                this.setState({
                    indiceImagen: nuevoIndex,
                    imagenActualCarrusel: this.state.imagenesGaleria[nuevoIndex]
                })
            }
            else {
                let nuevoIndex = this.state.indiceImagen - 1;
                http.get('/s3url2/'+this.state.imagenesGaleria[nuevoIndex]).then(response => {document.getElementById('imgCarrusel').src = response.data}).catch(error => {console.log(error);});   
                this.setState({
                    indiceImagen: nuevoIndex,
                    imagenActualCarrusel: this.state.imagenesGaleria[nuevoIndex]
                })
            }
        }
    }

    updateImagenes = (url) =>{

        if(this.state.imagenes === undefined||this.state.imagenes == null){
            let arr = []  

            arr.push(url);

            this.setState({ imagenes: arr });                         

            this.addRecuadroImagen(this.state.imagenes.length-1 ,url);        
        }else{
            let arr = this.state.imagenes; 

            arr.push(url);

            this.setState({ imagenes: arr });                         

            this.addRecuadroImagen(this.state.imagenes.length-1 ,url);        
        }
    }

    eliminarImagenGaleria() {
        if (this.state.imagenesGaleria.length > 0) {
            let indexImagen = this.state.imagenesGaleria.indexOf(this.state.imagenActualCarrusel);

            if (indexImagen != -1) {
                var value = this.state.imagenActualCarrusel;
                var nuevaGaleria = this.state.imagenesGaleria.filter(function (item) {
                    return item !== value
                })

                if (indexImagen == 0) {
                    if (this.state.imagenesGaleria.length > 1) {                        
                        http.delete('/s3url3/'+this.state.imagenesGaleria[indexImagen]);
                        this.setState({
                            imagenActualCarrusel: this.state.imagenesGaleria[indexImagen + 1]
                        });
                        http.get('/s3url2/'+this.state.imagenesGaleria[indexImagen+1]).then(response => {document.getElementById('imgCarrusel').src = response.data}).catch(error => {console.log(error);});   

                    } else {
                        http.delete('/s3url3/'+this.state.imagenesGaleria[indexImagen]);
                        document.getElementById("imgCarrusel").src = "";
                        this.setState({
                            imagenActualCarrusel: "",
                            indiceImagen: -1
                        });
                    }
                }
                else if (indexImagen == (this.state.imagenesGaleria.length - 1) && this.state.imagenesGaleria.length > 1) {
                    http.delete('/s3url3/'+this.state.imagenesGaleria[indexImagen]);
                    this.setState({
                        imagenActualCarrusel: this.state.imagenesGaleria[indexImagen - 1],
                        indiceImagen: indexImagen - 1
                    });
                    http.get('/s3url2/'+this.state.imagenesGaleria[indexImagen-1]).then(response => {document.getElementById('imgCarrusel').src = response.data}).catch(error => {console.log(error);});   
                }

                this.setState({
                    imagenesGaleria: nuevaGaleria
                });
            }
        }
        else {
            Swal.fire({
                icon: 'warning',
                text: "La galeria está vacía."
            })
        }
    }

    render(){
        const { editorState } = this.state;

        return(
            <div className="content-wrapper">
            <div className="content-header">
                <div className="container">                    
                    <h2 className="myaccount-content">Datos del proyecto</h2>
                </div>
            </div>

            <section className="content">
                <div className="container">
                    <div className="row"> 
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label className="font-weight-bold">Titulo<span className="required"> * </span> </label>
                                <input type="text" size="29" value={this.state.titulo} onChange={this.onChangeTitulo} name="titulo" className="form-control" placeholder="Titulo" required />
                            </div>
                            <div className="form-group col-md-12">
                                <label className="font-weight-bold">Texto resumen<span className="required"> * </span> </label>
                                <textarea size="129" height="400px" value={this.state.textoPre} onChange={this.onChangeTextoPre} name="subtitulo" className="form-control" placeholder="Pon un resumen del proyecto" required />
                            </div>
                            <label className="font-weight-bold descrip">Descripcion<span className="required"> * </span> </label>
                            <div className="form-group col-md-12 editor">
                                
                                <Editor
                                    editorState={editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"                                    
                                    onEditorStateChange={this.onChangeEditor}
                                    toolbar={{
                                        options: ["inline", "blockType", "colorPicker", "list"],
                                        inline: {
                                            inDropdown: false,
                                            className: "test",
                                            component: undefined,
                                            dropdownClassName: undefined,
                                            options: ["bold", "italic", "underline"],
                                            bold: { className: "test", style: { color: "red" } },
                                            italic: { className: undefined },
                                            underline: { className: undefined },
                                            font: { className: undefined }
                                        },
                                        colorPicker: {                                            
                                            className: undefined,
                                            component: undefined,
                                            popupClassName: undefined,
                                            colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
                                              'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
                                              'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
                                              'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
                                              'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
                                              'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
                                          }
                                        }}
                                />
                            </div>
                            <div class="col-md-6 mb-3">
                                <label htmlFor="validationCustom01" class="form-label">Apoyos:</label>
                                <input type="number" min="0" class="form-control" id="validationCustom01" value={this.state.apoyos} onChange={this.onChangeApoyos} required/>
                                
                            </div>
                            <div class="col-md-6">
                                <label htmlFor="validationCustom02" class="form-label">Donadores:</label>
                                <input type="number" min="0" class="form-control" id="validationCustom02" value={this.state.donadores} onChange={this.onChangeDonadores} required/>
                                
                            </div>
                            <br/>
                            <div class="col-md-6 mb-3">
                                <label htmlFor="validationCustom01" class="form-label">Dinero actual:</label>   
                                <div className="input-group">
                                    <span class="input-group-text" id="inputGroupPrepend"><i class="fas fa-dollar-sign"></i></span>
                                    <input type="number" min="0" class="form-control" id="validationCustom01" value={this.state.dineroActual} onChange={this.onChangeDineroActual} required/>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label htmlFor="validationCustom02" class="form-label">Meta proyecto:*</label>
                                <div className="input-group">
                                    <span class="input-group-text" id="inputGroupPrepend"><i class="fas fa-dollar-sign"></i></span>
                                    <input type="number" min="0" class="form-control" id="validationCustom02" value={this.state.metaProyecto} onChange={this.onChangeMetaProyecto} required/>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label htmlFor="validationCustom02" class="form-label">Mostrar en inicio</label>
                                <br></br>
                                <label class="switch">
                                    <input type="checkbox" checked={this.state.mostrarInicio} onChange={this.onChangeMostrarInicio}/>
                                    <span class="slider round"></span>
                                </label>
                            </div>
                            <div class="col-md-6">  
                                <label htmlFor="validationCustom02" class="form-label">Activo</label>
                                <br></br>
                                <label class="switch">
                                    <input type="checkbox" checked={this.state.activo} onChange={this.onChangeActivo}/>
                                    <span class="slider round"></span>
                                </label>
                            </div> 
                            <div id="recuadros-div" style={{"marginTop": '10px' }} class="col-md-8">
                                <GifLoader
                                    loading={this.state.loading}
                                    imageSrc="https://media.giphy.com/media/17mNCcKU1mJlrbXodo/giphy.gif"                        
                                    overlayBackground="none"
                                    imageStyle={{width: "30%", display: "block", marginRight: "auto", marginLeft: "auto"}}
                                /> 
                            </div>
                            <div class="container" style={{ marginTop: '10px' }}>
                            
                                <table class="table">
                                    <thead>
                                        <h4>Imágenes*</h4>
                                        <tr>
                                            <th class="text-center align-middle" style={{ width: "10%" }}>
                                                <button class="btn btn-outline-light" onClick={this.clickPrevButton}><i class="fas fa-arrow-left"></i></button>
                                            </th>
                                            <th class="text-center align-middle" style={{ width: "80%" }}>                                                
                                                <img style={{ marginBottom: '10px' }} id="imgCarrusel" class="img-fluid" alt="Carga una imagen"></img>
                                            </th>
                                            <th class="text-center align-middle" style={{ width: "10%" }}>
                                                <button class="btn btn-outline-light" onClick={this.clickNextButton}><i class="fas fa-arrow-right"></i></button>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th class="text-center align-middle" style={{ width: "10%" }}></th>
                                            <th class="text-center align-middle" style={{ width: "80%" }}>
                                                {this.state.indiceImagen == -1 ? "" : "Imagen " + (this.state.indiceImagen + 1)}
                                            </th>
                                            <th class="text-center align-middle" style={{ width: "10%" }}>
                                                <button class="btn btn-danger" onClick={this.eliminarImagenGaleria}>Eliminar imagen <i className="far fa-trash-alt"></i></button>
                                            </th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <div class="col-md-12">                                       
                                <form id="imageForm" style={{"marginTop": '10px' }} className="form-group" onSubmit={e => this.submitFormHandler(e)}>                                    
                                    <div class="input-group">                                                             
                                        <input id="imageInput" id="exampleInputFile"  class="custom-file-input" type="file" accept="image/*" onChange={e => this.nameHandler(e)} />
                                        <label class="custom-file-label" id="nombre">{this.state.nombre}</label>    
                                        <button style={{"margin-top": '10px' }} className="btn btn-success" type="submit">Cargar</button>                                    
                                    </div>                                    
                                </form>
                            </div>
                            <div className="form-group col-sm-12 text-right">
                                <button onClick={this.guardarProyecto}  class="btn btn-primary"><i class="fas fa-save"></i> {this.state.textoBoton}</button>
                                <>&nbsp;</>
                                <button onClick={this.cancelar} class="btn btn-info"><i class="fas fa-undo-alt"></i> Regresar</button>
                            </div> 
                        </div> 
                    </div>
                </div>
            </section>
        </div>
        )
    }
}
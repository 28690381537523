import React, { Component } from 'react';
import ReactDOM from "react-dom";

class HijoItem extends Component {
    constructor(props) {
        super(props);
    }


    render() {
        return (
            <tr key={this.props.id}>
                <td class="col-md-2 text-center align-middle">{this.props.nombre}</td>
                <td class="col-md-3 text-center align-middle">{this.props.paterno}   {this.props.materno}</td>
                <td class="col-md-2 text-center align-middle">{this.props.curp} </td>
                <td class="col-md-2 text-center align-middle">{this.props.edad} años</td>
                <td class="col-md-3 text-center align-middle">{this.props.discapacidad}</td>
                <td class="col-md-5 text-center align-middle">{this.props.servicios.join(", ") || "S/N"}</td>
            </tr>
        );
    }
}

export default HijoItem;
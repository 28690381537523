import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import brigadaSaludTutorService from "../../services/brigadaSaludTutor.service.js";
import brigadaSaludHijoService from "../../services/brigadaSaludHijo.service.js";
import { Navigate, useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import BrigadaDatosHijo from "./verificarHijo.component.js";

const VerificaLiga = () => {
    const [tutor, setTutor] = useState([]);
    const [fecha, setFecha] = useState();
    const [botonesHijos, setBotones] = useState([]);
    const [divHijos, setDivs] = useState([]);
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        brigadaSaludTutorService.get(parseInt(params.id))
            .then((response) => {
                var now = new Date();
                var data = response.data;
                data.verificado = true;

                setTutor(response.data);
                setFecha(now.getDate() + "/" + (now.getMonth() + 1) + "/" + now.getFullYear());

                brigadaSaludHijoService.get(parseInt(params.id))
                    .then((response) => { 
                        crearFichasHijo(response.data);
                    })
                    .catch((error) => console.log(error));

                brigadaSaludTutorService.update(parseInt(params.id), data)
                    .then((response) => { console.log("Verificado") })
                    .catch((error) => console.log(error));
            })
            .catch((error) => navigate('/*'));

        changeStyle();

        const loggedInUser = localStorage.getItem("usuario");
        if (!loggedInUser) {
            navigate('/iniciar-sesion');
        }
    }, []);

    const crearFichasHijo = (hijos) => {
        let nuevosDiv = [];
        let nuevosBotones = [];

        for (let i = 0; i < hijos.length; i++) {
            nuevosBotones.push(
                <button class="nav-link" id={"fichaHijo" + (i + 1) + "-tab"} data-bs-toggle="tab" 
                    data-bs-target={"#fichaHijo" + (i + 1)} type="button" role="tab" aria-controls={"fichaHijo" + (i + 1)} aria-selected="false">
                    {"Hijo #" + (i + 1)}
                </button>);

            nuevosDiv.push(
                <div class="tab-pane fade" id={"fichaHijo" + (i + 1)} role="tabpanel" aria-labelledby={"fichaHijo" + (i + 1) + "-tab"}>
                    <BrigadaDatosHijo data={hijos[i]}/>
                </div>
            );
        }

        setBotones(nuevosBotones);
        setDivs(nuevosDiv);
    }

    const changeStyle = () => {
        var top = document.getElementById('barraNavegacion');
        var footer = document.getElementById('footerNavegacion');
        var estilo = document.getElementById('estiloPrincipal');
        var estiloPlugin = document.getElementById('estiloPlugin');

        document.body.className = "";
        top.style.display = 'block';
        footer.style.display = 'block';
        estilo.href = 'assets/css/styles.css';
        estiloPlugin.href = 'assets/css/plugins.css';
    }

    return (
        <>
            <Helmet>
                <title>M&eacute;xico Amparame - Registro Existoso</title>
            </Helmet>
            <section class="page-title-section bg-img cover-background mx-lg-4 mx-xl-6 rounded-lg" data-overlay-dark="4" style={{
                backgroundImage: `url("/assets/img/photo4.jpg")`
            }}>
                <div class="container">
                    <div class="row text-center">
                        <div class="col-md-12">
                            <h1 class="text-animation fadeInRight" >Registro Verificado</h1>
                            <ul>
                                <li><a><Link to={"/"}>Regresar</Link></a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="position-absolute z-index-1 right bottom-n5 opacity2 ani-left-right">
                    <span class="square bg-primary"></span>
                    <span class="square bg-secondary"></span>
                </div>
            </section>

            <center style={{ paddingTop: 25, paddingBottom: 50 }}>
                <i className="fas fa-check-circle" style={{ fontSize: "250px", color: "green" }} /><br /><br /><br />
                <h1>Registro Verificado</h1>
                <h3>Fecha de verificacion: {fecha}</h3>
                <h5>Juntos ayudamos a todos.</h5>
            </center>

            <section class="pt-2">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col">
                            <div className="ps-lg-1-6">
                                <div className="p-1-6 p-lg-1-9 p-xl-2-5 border border-color-extra-light-gray rounded">
                                    <div className="quform-elements">
                                        <div className="row">
                                            <div className="d-block d-md-flex justify-content-md-between mb-4 mt-4 pb-1 border-bottom border-color-extra-light-gray">
                                                <h4>DATOS DEL TUTOR</h4>
                                            </div>

                                            <div className="col-sm">
                                                <div className="quform-element">
                                                    <label htmlFor="name">Nombre</label>
                                                    <div className="quform-input">
                                                        <input className="form-control" readOnly value={tutor.nombre} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm">
                                                <div className="quform-element">
                                                    <label htmlFor="name">Apellido Paterno</label>
                                                    <div className="quform-input">
                                                        <input className="form-control" readOnly value={tutor.paterno} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm">
                                                <div className="quform-element">
                                                    <label htmlFor="name">Apellido Materno</label>
                                                    <div className="quform-input">
                                                        <input className="form-control" readOnly value={tutor.materno} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-8">
                                                <div className="quform-element">
                                                    <label htmlFor="name">Direcci&oacute;n</label>
                                                    <div className="quform-input">
                                                        <input className="form-control" readOnly value={tutor.calle 
                                                        + (tutor.int === 'N/A' ? "" : (" Int. " + tutor.int)) +    
                                                        ", Num. Ext. " + tutor.ext + ", " 
                                                        + tutor.colonia
                                                        } />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="quform-element">
                                                    <label htmlFor="name">C&oacute;digo Postal</label>
                                                    <div className="quform-input">
                                                        <input className="form-control" readOnly value={tutor.cp} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm">
                                                <div className="quform-element">
                                                    <label htmlFor="name">Ciudad</label>
                                                    <div className="quform-input">
                                                        <input className="form-control" readOnly value={tutor.ciudad} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm">
                                                <div className="quform-element">
                                                    <label htmlFor="phone">Estado</label>
                                                    <div className="quform-input">
                                                        <input className="form-control" readOnly value={tutor.estado} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm">
                                                <div className="quform-element">
                                                    <label htmlFor="subject">Correo</label>
                                                    <div className="quform-input">
                                                        <input className="form-control" readOnly value={tutor.correo} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm">
                                                <div className="quform-element">
                                                    <label htmlFor="phone">T&eacute;lefono</label>
                                                    <div className="quform-input">
                                                        <input className="form-control" readOnly value={tutor.telefono} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm">
                                                <div className="quform-element">
                                                    <label htmlFor="phone">N&uacute;mero de hijos <span className="quform-required">*</span></label>
                                                    <div className="quform-input">
                                                        <input className="form-control" readOnly value={tutor.hijos} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-block d-md-flex justify-content-md-between mb-4 mt-4 pb-1 border-bottom border-color-extra-light-gray">
                                            <h4>{tutor.hijos > 1 ? "DATOS DE LOS HIJOS" : "DATOS DEL HIJO"}</h4>
                                        </div>

                                        <nav>
                                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                {botonesHijos}
                                            </div>
                                        </nav>
                                        <div class="tab-content mb-4" id="nav-tabContent">
                                            {divHijos}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}

export default VerificaLiga;
import React, { useState, useEffect } from "react";
import ProyectoService from "../../services/proyecto.service";
import {useParams} from 'react-router-dom';
import { Link} from 'react-router-dom';
import DOMPurify from 'dompurify';//se instala DOMPurify con npm y se importa
import http from "../../http-common.js";
import { Helmet } from 'react-helmet';

const CausasDetalle = () => {
    const [datos, setDatos] = useState([]);
    const [imagen, setImagen] = useState("");
    const params = useParams();

    useEffect(() => {        
        ProyectoService.get(parseInt(params.id))
        .then((response) => {
            setDatos(response.data);
            putImages(response.data);
        })
        .catch((error)=> console.log(error))       
        
        changeStyle();    
        
    }, []);

    const changeStyle = () =>{
        var top = document.getElementById('barraNavegacion');
        var footer = document.getElementById('footerNavegacion');
        var estilo = document.getElementById('estiloPrincipal');
        var estiloPlugin = document.getElementById('estiloPlugin');

        document.body.className = "";        
        top.style.display = 'block';    
        footer.style.display = 'block';
        estilo.href = 'assets/css/styles.css';
        estiloPlugin.href = 'assets/css/plugins.css';
        
    }   
    //esta función retorna el html con texto de parámetro
    const createMarkup = (html) => {
        return  {
          __html: DOMPurify.sanitize(html)
        }
    }

    const putImages = (datos)=> {
        for(let i = 0; i < 4; i++){
            if(i < datos.imagenes.length){
                if(i === 0){
                    http.get('/s3url2/'+datos.imagenes[i]).then(response => {document.getElementById('img-'+(i+1)).src = response.data
                    setImagen(response.data);
                }).catch(error => {console.log(error);});                     
                }else{
                    http.get('/s3url2/'+datos.imagenes[i]).then(response => {document.getElementById('img-'+(i+1)).src = response.data}).catch(error => {console.log(error);});                         
                }
                
            }else{
                document.getElementById('img-'+(i+1)).style.display = 'none';
            }
        }        
    }
    
    return (             
    <>
        <Helmet>
            <title>M&eacute;xico Amparame - Causas</title>
            <meta property="og:type" content="website"/>
            <meta property="og:title" content={datos.titulo}/>
            <meta property="og:image" content={imagen}/>

            <meta property="twitter:card" content="summary_large_image"/>
            <meta property="twitter:title" content={datos.titulo}/>
            <meta property="twitter:description" content="Causas de México Ampárame."/>
            <meta property="twitter:image" content={imagen}/>    
        </Helmet> 
        <section class="page-title-section bg-img cover-background mx-lg-4 mx-xl-6 rounded-lg" data-overlay-dark="4" style={{ 
      backgroundImage: `url("/assets/img/causes/causas-banner.jpg")` 
        }}>                
                <div class="container">
                    <div class="row text-center">
                        <div class="col-md-12">
                            <h1 class="text-animation fadeInRight" >Causas</h1>
                            <ul>
                                <li><a><Link to={"/"}>Regresar</Link></a></li>                                
                            </ul>
                        </div>
                    </div>
                </div>
            
            <div class="position-absolute z-index-1 right bottom-n5 opacity2 ani-left-right">
                <span class="square bg-primary"></span>
                <span class="square bg-secondary"></span>
            </div>
        </section>   
        <section className="cause-details">
            <div className="container">
                <div className="row"> 
                    {/*<!-- left content -->*/}
                    <div className="col-lg-12 pe-lg-2-5 pe-xl-2-9 mb-2-9 mb-lg-0">
                        <div>
                            <img id="img-1" className="rounded-top" alt="..."/>
                            <div id="bar" className="skills yellow mb-1-9">
                                <div className="skills-progress">
                                    <span  data-value={(Math.ceil(datos.dineroActual*100/datos.dineroMeta))>100 ? ('+'+(100)+'%') : ((Math.ceil(datos.dineroActual*100/datos.dineroMeta))+'%')}></span>                                    
                                </div>
                            </div>
                        </div>
                        <h2 className="mb-4">{datos.titulo}</h2>
                        <div className="d-block d-md-flex justify-content-md-between mb-1-9 pb-1-9 border-bottom border-color-extra-light-gray wow fadeIn" data-wow-delay="200ms">
                            <div>
                                <ul className="list-style3 clearfix">
                                    <li><a href="#!" className="font-weight-600">Meta: <span className="text-primary">${datos.dineroMeta}</span></a></li>
                                    <li><a href="#!" className="font-weight-600">Actual: <span className="text-primary">${datos.dineroActual}</span></a></li>
                                    <li className="mb-0"><a href="#!" className="font-weight-600">Apoyos: <span className="text-primary">{datos.apoyos}</span></a></li>
                                    <li className="mb-0"><a href="#!" className="font-weight-600">Donadores: <span className="text-primary">{datos.donadores}</span></a></li>
                                </ul>
                            </div>
                            <div className="mt-3 mt-md-0">
                                <a className="butn medium"><Link to={"/dona"+params.id}>Dona</Link></a>
                            </div>
                        </div>
                        <div className="mb-2-5 wow fadeIn" data-wow-delay="400ms">
                            <h3  className="mb-3 h4">Sobre la causa</h3>      
                            {/* Así se pone para que aparezca como html*/}                      
                            <div className="editor-text" dangerouslySetInnerHTML={createMarkup(datos.textoDetalle)}></div>                              
                            <div className="row mb-1-9 mb-md-2-5">
                                <div id="img-detalles1" className="col-md-6 mb-1-6 mb-md-0">
                                    <img id="img-2" className="rounded" alt="..."/>
                                </div>
                                <div id="img-detalles2" className="col-md-6">
                                    <img id="img-3" className="rounded"  alt="..."/>
                                </div>
                            </div>
                            <p className="mb-1-9"></p>                            
                            <p className="lead font-weight-600 text-primary fadeInRight">Gente como tú están ayudando &amp; haciendo cosas buenas.</p>
                            
                        </div>                        
                    </div>                                        
                </div>
            </div>
        </section> 
    </>          
    )    
}
export default   CausasDetalle;
import React, { Component } from "react";
import ProgramaService from "../../services/programa.service.js";
import { IconPicker, IconPickerItem } from 'react-fa-icon-picker'
import {Link} from 'react-router-dom';

export default class ProgramaTarjeta extends Component {

    constructor(props) {
        super(props);
        this.obtenerProgramas = this.obtenerProgramas.bind(this);

        this.state = {
            programas: this.obtenerProgramas()
        };
    }

    componentDidMount() {
        var top = document.getElementById('barraNavegacion');
        var footer = document.getElementById('footerNavegacion');
        var estilo = document.getElementById('estiloPrincipal');
        var estiloPlugin = document.getElementById('estiloPlugin');

        document.body.className = "";
        top.style.display = 'block';
        footer.style.display = 'block';
        estilo.href = './assets/css/styles.css';
        estiloPlugin.href = './assets/css/plugins.css';
    }

    obtenerProgramas() {
        ProgramaService.getAllHomePage()
            .then(response => {
                var tresProgramas = [];
                tresProgramas = response.data;
                console.log(tresProgramas);

                this.setState({
                    programas: tresProgramas
                });
            })
            .catch(e => {
                console.log(e);
            });
    }

    render() {
        const { programas } = this.state;

        return (
            <section>
                <div class="container">
                    <div class="section-heading">
                        <span class="d-block text-primary display-22 display-md-21 display-lg-20 alt-font wow text-animation fadeInRight" >Lo que hacemos</span>
                        <h2>Nuestros programas</h2>
                    </div>
                    <div class="row justify-content-center">
                        {programas && programas.map((programa) => {
                            return <div key={programa.id} class="col-sm-4 mb-3 tilt" data-wow-delay="200ms">
                                <div class="card h-100 card-style2 border-0">
                                    <div class="card-body px-1-6 px-sm-1-9 py-1-9">
                                        <div class="mb-1-6">
                                            <IconPickerItem icon={programa.icono} size={24} color={programa.colorIcono}/>
                                        </div>
                                        <div>
                                            <h3 class="h4 mb-3"><a><Link to={"programasDetalle"+programa.id}>{programa.titulo}</Link></a></h3>
                                            <p class="mb-1-6">{programa.subtitulo}</p>

                                        </div>
                                    </div>
                                    <div class="card-footer mb-2 bg-transparent">
                                        <a class="btn-read"><span><Link to={"programasDetalle"+programa.id}>Leer más</Link></span></a>
                                    </div>
                                </div>
                            </div>
                        })}
                        <center><a class="butn secondary getMoreCausasBtn" href="/programas">Ver más programas</a></center>
                    </div>
                </div>
                <img src="img/icons/icon-9.png" alt="" class="position-absolute right-5 top-75 ani-top-bottom opacity7 d-none d-lg-block" />
            </section>
        )
    }
}

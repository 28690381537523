import React, { Component } from "react";
import { IconPicker, IconPickerItem } from 'react-fa-icon-picker';
import { Link } from "react-router-dom";

export default class ProgramaItem extends Component {

    render() {
        return (
            <div key={this.props.id} class="col-sm-4 mb-3 tilt" data-wow-delay="200ms">
                <div class="card h-100 card-style2 border-0">
                    <div class="card-body px-1-6 px-sm-1-9 py-1-9" >
                        <div class="mb-1-6">
                            <IconPickerItem icon={this.props.icono} size={38} color={this.props.colorIcono}/>
                        </div>
                        <div>
                            <h3 class="h4 mb-3"><a><Link to={"/programasDetalle"+this.props.id}>{this.props.titulo}</Link></a></h3>                            
                            <p class="mb-1-6">{this.props.subtitulo}</p>

                        </div>
                    </div>
                    <div class="card-footer mb-2 bg-transparent">
                        <a  class="butn-read"><span><Link to={"/programasDetalle"+this.props.id}>Leer más</Link></span></a>
                    </div>
                </div>
            </div>
        )
    }

}

import React, { Component } from "react";

export default class FooterDash extends Component {
    render(){
        return(
            <div>
                <footer className="main-footer">
                    <strong>Desarrollado por <a href="https://karimnot.com/public">karimnot.com</a></strong>
                </footer>
            </div>
        )
    }
}
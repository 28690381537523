import React, { Component } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromHTML, ContentState } from "draft-js"; //se importa convert from html para convertir el html a texto para el editor
import { convertToHTML } from "draft-convert"; //se importa y se instala con npm
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import NoticiaService from "../../services/noticia.service";
import Swal from "sweetalert2";
import GifLoader from "react-gif-loader";
import http from "../../http-common.js";
import ListarNoticias from "./listarNoticia.component";
import ReactDOM from "react-dom";

export default class CrearNoticia extends Component {
  constructor(props) {
    super(props);
    this.onChangeTituloNoticia = this.onChangeTituloNoticia.bind(this);
    this.onChangeTextoPreview = this.onChangeTextoPreview.bind(this);
    this.onChangeTextoNoticia = this.onChangeTextoNoticia.bind(this);
    this.onChangeEditor = this.onChangeEditor.bind(this);
    this.onChangeActivo = this.onChangeActivo.bind(this);
    this.guardarNoticia = this.guardarNoticia.bind(this);
    this.onChangeLinkImagen = this.onChangeLinkImagen.bind(this);
    this.onChangelinkImagenDos = this.onChangelinkImagenDos.bind(this);
    this.onChangelinkImagenTres = this.onChangelinkImagenTres.bind(this);
    this.clickNextButton = this.clickNextButton.bind(this);
    this.clickPrevButton = this.clickPrevButton.bind(this);
    this.nameHandler = this.nameHandler.bind(this);
    this.nameHandlerImg1 = this.nameHandlerImg1.bind(this);
    this.nameHandlerImg2 = this.nameHandlerImg2.bind(this);
    this.submitFormHandlerImg1 = this.submitFormHandlerImg1.bind(this);
    this.submitFormHandlerImg2 = this.submitFormHandlerImg2.bind(this);
    this.eliminarImagenDos = this.eliminarImagenDos.bind(this);
    this.eliminarImagenTres = this.eliminarImagenTres.bind(this);

    this.state = {
      id: null,
      noticia: "",
      textoPreview: "",
      linkImagen: "",
      linkImagen2: "",
      linkImagen3: "",
      image: "",
      imagenDos: "",
      imagenTres: "",
      imagenActual: 1,
      textoNoticia: "",
      autorId: this.props.autorId,
      autor: this.props.autor,
      autorImagen: this.props.autorImagen,
      estatus: true,
      editorState: EditorState.createEmpty(),
      convertedContent: null,
      loading: false,
      loading1: false,
      loading2: false,
    };
  }

  nameHandler(e) {
    this.setState({ image: e.target.files[0] });
    if (e.target.files[0].name != null || e.target.files[0].name != "") {
      document.getElementById("nombre").innerHTML = e.target.files[0].name;
    }
  }

  onChangeTituloNoticia(e) {
    if (e.target.value.length >= 47) {
      Swal.fire({
        icon: "warning",
        text: "Titulo demasiado largo!",
      });
    } else {
      this.setState({
        noticia: e.target.value,
      });
    }
  }

  onChangeTextoNoticia(e) {
    this.setState({
      textoNoticia: e.target.value,
    });
  }

  onChangeTextoPreview(e) {
    if (e.target.value.length >= 190) {
      Swal.fire({
        icon: "warning",
        text: "Texto preview demasiado largo!",
      });
    } else {
      this.setState({
        textoPreview: e.target.value,
      });
    }
  }

  onChangeLinkImagen(e) {
    this.setState({
      linkImagen: e.target.value,
    });
  }

  eliminarImagenDos(e) {
    this.setState({
      linkImagen2: ""
    })
  }
  
  eliminarImagenTres(e) {
    this.setState({
      linkImagen3: ""
    })
  }

  onChangelinkImagenDos(e) {
    this.setState({
      linkImagen2: e.target.value,
    });
  }

  onChangelinkImagenTres(e) {
    this.setState({
      linkImagen3: e.target.value,
    });
  }

  clickNextButton(e) {
    let elementoUno = document.getElementById('imagenUno');
    let elementoDos = document.getElementById('imagenDosCarr');


    if (this.state.imagenActual == 1) {
      this.state.imagenActual = 2;
      ReactDOM.findDOMNode(elementoUno).style.display = "none";
      ReactDOM.findDOMNode(elementoDos).style.display = "inline";
    } else if (this.state.imagenActual == 2) {
      this.state.imagenActual = 1;
      ReactDOM.findDOMNode(elementoUno).style.display = "inline";
      ReactDOM.findDOMNode(elementoDos).style.display = "none";
    }

    console.log("Estamos en la imagen no" + this.state.imagenActual)

  }

  async submitFormHandlerImg1(e) {
    e.preventDefault();
    const file = this.state;

    if (file.imagenDos != null && file.imagenDos != "") {
      if (this.state.linkImagen2 !== "") {
        http.delete("/s3url3/" + this.state.linkImagen2);
      }
      this.setState({ loading1: true });
      let data = new FormData();
      data.append("foto", file.imagenDos);

      await http.post("/s3Url", data).then((response) => {
        this.setState({ loading1: false });
        http
          .get("/s3url2/" + response.data.data)
          .then((response) => {
            document.getElementById("imgDos").src = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
        this.setState({
          imagenDos: "",
          linkImagen2: response.data.data,
        });
      });
    } else {
      Swal.fire({
        icon: "error",
        text: "No ha cargado un arhivo aun.",
      });
    }
  }

  async submitFormHandlerImg2(e) {
    e.preventDefault();
    const file = this.state;
    console.log(this.state)

    if (file.imagenTres != null && file.imagenTres != "") {
      if (this.state.linkImagen3 != "") {

        http.delete("/s3url3/" + this.state.linkImagen3);
      }
      this.setState({ loading2: true });
      let data = new FormData();
      data.append("foto", file.imagenTres);

      await http.post("/s3Url", data).then((response) => {
        this.setState({ loading2: false });
        http
          .get("/s3url2/" + response.data.data)
          .then((response) => {
            document.getElementById("imgTres").src = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
        this.setState({
          imagenTres: "",
          linkImagen3: response.data.data,
        });
      });
    } else {
      Swal.fire({
        icon: "error",
        text: "No ha cargado un arhivo aun.",
      });
    }
  }

  clickPrevButton(e) {
    let elementoUno = document.getElementById('imagenUno');
    let elementoDos = document.getElementById('imagenDosCarr');


    if (this.state.imagenActual == 1) {
      this.state.imagenActual = 2;
      ReactDOM.findDOMNode(elementoUno).style.display = "none";
      ReactDOM.findDOMNode(elementoDos).style.display = "inline";
    } else if (this.state.imagenActual == 2) {
      this.state.imagenActual = 1;
      ReactDOM.findDOMNode(elementoUno).style.display = "inline";
      ReactDOM.findDOMNode(elementoDos).style.display = "none";
    }

    console.log("Estamos en la imagen no" + this.state.imagenActual)
  }

  nameHandlerImg1(e) {
    this.setState({ imagenDos: e.target.files[0] });
    if (e.target.files[0].name != null || e.target.files[0].name != "") {
      document.getElementById("nombreImg1").innerHTML = e.target.files[0].name;
    }
  }

  nameHandlerImg2(e) {
    this.setState({ imagenTres: e.target.files[0] });
    if (e.target.files[0].name != null || e.target.files[0].name != "") {
      document.getElementById("nombreImg2").innerHTML = e.target.files[0].name;
    }
  }

  onChangeEditor = (editorState) => {
    this.setState({
      editorState: editorState,
    });
    this.convertContentToHTML();
  };

  convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(
      this.state.editorState.getCurrentContent()
    );
    this.setState({ convertedContent: currentContentAsHTML });
  };

  onChangeActivo() {
    this.setState({
      estatus: !this.state.estatus,
    });
  }

  async submitFormHandler(e) {
    e.preventDefault();
    const file = this.state;

    if (file.image != null) {
      if (this.state.linkImagen !== "") {
        http.delete("/s3url3/" + this.state.linkImagen);
      }
      this.setState({ loading: true });
      let data = new FormData();
      data.append("foto", file.image);

      await http.post("/s3Url", data).then((response) => {
        this.setState({ loading: false });
        http
          .get("/s3url2/" + response.data.data)
          .then((response) => {
            document.getElementById("imgNoticia").src = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
        this.setState({
          image: "",
          linkImagen: response.data.data,
        });
      });
    } else {
      Swal.fire({
        icon: "error",
        text: "No ha cargado un arhivo aun.",
      });
    }
  }

  regresar = () => {
    let divDash = document.getElementById("contenidoTablero");
    if (divDash.children.length > 0) {
      ReactDOM.unmountComponentAtNode(divDash);
    }
    ReactDOM.render(<ListarNoticias />, divDash);
  };

  guardarNoticia() {
    let data = {
      noticia: this.state.noticia,
      textoPreview: this.state.textoPreview,
      linkImagen: this.state.linkImagen,
      linkImagenDos: this.state.linkImagen2,
      linkImagenTres: this.state.linkImagen3,
      textoNoticia: this.state.convertedContent,
      autorId: this.state.autorId,
      autor: this.state.autor,
      autorImagen: this.state.autorImagen,
      estatus: this.state.estatus,
    };
    console.log(this.state);
    console.log("AAAAAAAAAAAAAAAAAAAAAAAA");
    console.log(data);

    if (
      this.state.noticia &&
      this.state.textoPreview &&
      this.state.linkImagen &&
      this.state.convertedContent
    ) {
      console.log(data);
      NoticiaService.create(data)
        .then((response) => {
          console.log(response.data);
          Swal.fire({
            text: "Noticia agregada con éxito",
            icon: "success",
          });
        })
        .catch((e) => {
          console.log(e);
        });
      Swal.fire({
        icon: "success",
        text: "Proyecto actualizado con éxito",
      });

      let divDash = document.getElementById("contenidoTablero");
      if (divDash.children.length > 0) {
        ReactDOM.unmountComponentAtNode(divDash);
      }
      ReactDOM.render(<ListarNoticias />, divDash);
    } else {
      if (!this.state.noticia) {
        Swal.fire({
          text: "Debes de colocar un titulo!",
          icon: "warning",
        });
      } else if (!this.state.textoPreview) {
        Swal.fire({
          text: "Debes de colocar un texto para la vista previa de la noticia!",
          icon: "warning",
        });
      } else if (!this.state.linkImagen) {
        Swal.fire({
          text: "No te olvides de subir una imagen!",
          icon: "warning",
        });
      } else if (!this.state.convertedContent) {
        Swal.fire({
          text: "¡No te olvides de colocar el texto de la noticia!",
          icon: "warning",
        });
      }
    }
  }

  render() {
    const { editorState } = this.state;

    return (
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container">
            <h2 className="myaccount-content">Datos de la Noticia</h2>
          </div>
        </div>

        <section className="content">
          <div className="container">
            <div className="row">
              <div className="form-row">
                <div className="form-group col-md-12">
                  <label className="font-weight-bold">
                    Titulo<span className="required"> * </span>{" "}
                  </label>
                  <input
                    type="text"
                    value={this.state.noticia}
                    onChange={this.onChangeTituloNoticia}
                    name="titulo"
                    className="form-control"
                    placeholder="Titulo"
                    required
                  />
                </div>
                <div className="form-group col-md-12">
                  <label className="font-weight-bold">
                    Vista previa<span className="required"> * </span>{" "}
                  </label>
                  <textarea
                    size="129"
                    height="400px"
                    value={this.state.textoPreview}
                    onChange={this.onChangeTextoPreview}
                    name="subtitulo"
                    className="form-control"
                    placeholder="Coloca tu texto preview"
                    required
                  />
                </div>

                <div className="form-group col-md-12">
                  <label className="font-weight-bold">
                    Imagen de la noticia<span className="required"> * </span>{" "}
                  </label>
                  <GifLoader
                    loading={this.state.loading}
                    imageSrc="https://media.giphy.com/media/17mNCcKU1mJlrbXodo/giphy.gif"
                    overlayBackground="none"
                    imageStyle={{
                      width: "30%",
                      display: "block",
                      marginRight: "auto",
                      marginLeft: "auto",
                    }}
                  />
                  <center>
                    <img
                      style={{
                        width: "580px",
                        height: "280px",
                        marginBottom: "10px",
                      }}
                      id="imgNoticia"
                      class="img-fluid"
                      alt="Carga una imagen"
                    ></img>
                  </center>
                </div>

                <div className="form-group col-md-12">
                  <div
                    class="form-group"
                    onSubmit={this.submitFormHandler.bind(this)}
                  >
                    <div class="input-group">
                      <div class="custom-file">
                        <div class="container" style={{ marginTop: "10px" }}>
                          <input
                            type="file"
                            class="custom-file-input"
                            id="exampleInputFile"
                            accept="image/*"
                            onChange={this.nameHandler}
                          />
                          <label
                            class="custom-file-label"
                            htmlFor="exampleInputFile"
                            id="nombre"
                          >
                            Elije un Archivo
                          </label>
                        </div>
                      </div>
                      <div class="input-group-append">
                        <span
                          class="input-group-text"
                          id=""
                          style={{ cursor: "pointer" }}
                          onClick={this.submitFormHandler.bind(this)}
                        >
                          Cargar
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group col-md-12 editor">
                  <label className="font-weight-bold">
                    Texto noticia<span className="required"> * </span>{" "}
                  </label>
                  <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={this.onChangeEditor}
                    toolbar={{
                      options: ["inline", "blockType", "list"],
                      inline: {
                        inDropdown: false,
                        className: "test",
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ["bold", "italic", "underline"],
                        bold: { className: "test", style: { color: "red" } },
                        italic: { className: undefined },
                        underline: { className: undefined },
                        font: { className: undefined },
                      },
                    }}
                  />
                </div>

                <div id="imagenUno" className="form-group col-md-12" style={{ display: "inline" }}>
                  <GifLoader
                    loading={this.state.loading1}
                    imageSrc="https://media.giphy.com/media/17mNCcKU1mJlrbXodo/giphy.gif"
                    overlayBackground="none"
                    imageStyle={{ width: "30%", display: "block", marginRight: "auto", marginLeft: "auto" }}
                  />
                  <label className="font-weight-bold">Imagenes para la galeria</label>
                  <br></br>
                  <a>Nota: en el orden que se suban, será el orden en que aparezca</a>
                  <div class="container" style={{ marginTop: '10px' }}>
                    <table class="table">
                      <thead>
                        <tr>
                          <th class="text-center align-middle" style={{ width: "10%" }}>
                            <button class="btn btn-outline-light" onClick={this.clickPrevButton}><i class="fas fa-arrow-left"></i></button>
                          </th>
                          <th class="text-center align-middle" style={{ width: "80%" }}>
                            <img id="imgDos" style={{ marginBottom: '10px' }} src={this.state.linkImagen2} class="img-fluid" alt="Carga una imagen"></img>
                          </th>
                          <th class="text-center align-middle" style={{ width: "10%" }}>
                            <button class="btn btn-outline-light" onClick={this.clickNextButton}><i class="fas fa-arrow-right"></i></button>
                          </th>
                        </tr>
                        <tr>
                          <th class="text-center align-middle" style={{ width: "10%" }}></th>
                          <th class="text-center align-middle" id="imagenActual" style={{ width: "80%" }}>
                            {"Imagen 1"}
                          </th>
                          <th class="text-center align-middle" style={{ width: "10%" }}>
                            <button class="btn btn-danger" onClick={this.eliminarImagenDos}>Eliminar imagen <i className="far fa-trash-alt"></i></button>
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  <form id="imagenesGaleria" style={{ marginTop: '10px' }} className="form-group" onSubmit={this.submitFormHandlerImg1}>
                    <div class="input-group">
                      <input style={{ marginTop: '10px' }} id="imagenesGaleria" class="custom-file-input" type="file" accept="image/*" onChange={this.nameHandlerImg1} />
                      <label class="custom-file-label" id="nombreImg1">{this.state.imagenGaleriaTres}</label>
                      <button style={{ marginTop: '10px' }} className="btn btn-success" type="submit">Cargar</button>
                    </div>
                  </form>
                </div>

                {/* aaaaaaaaaaaaaaaaa */}
                <div id="imagenDosCarr" className="form-group col-md-12" style={{ display: "none" }}>
                  <GifLoader
                    loading={this.state.loading2}
                    imageSrc="https://media.giphy.com/media/17mNCcKU1mJlrbXodo/giphy.gif"
                    overlayBackground="none"
                    imageStyle={{ width: "30%", display: "block", marginRight: "auto", marginLeft: "auto" }}
                  />
                  <label className="font-weight-bold">Imagenes para la galeria</label>
                  <br></br>
                  <a>Nota: en el orden que se suban, será el orden en que aparezca</a>
                  <div class="container" style={{ marginTop: '10px' }}>
                    <table class="table">
                      <thead>
                        <tr>
                          <th class="text-center align-middle" style={{ width: "10%" }}>
                            <button class="btn btn-outline-light" onClick={this.clickPrevButton}><i class="fas fa-arrow-left"></i></button>
                          </th>
                          <th class="text-center align-middle" style={{ width: "80%" }}>
                            <img id="imgTres" style={{ marginBottom: '10px' }} src={this.state.linkImagen3} class="img-fluid" alt="Carga una imagen"></img>
                          </th>
                          <th class="text-center align-middle" style={{ width: "10%" }}>
                            <button class="btn btn-outline-light" onClick={this.clickNextButton}><i class="fas fa-arrow-right"></i></button>
                          </th>
                        </tr>
                        <tr>
                          <th class="text-center align-middle" style={{ width: "10%" }}></th>
                          <th class="text-center align-middle" id="imagenActual" style={{ width: "80%" }}>
                            {"Imagen 2"}
                          </th>
                          <th class="text-center align-middle" style={{ width: "10%" }}>
                            <button class="btn btn-danger" onClick={this.eliminarImagenTres}>Eliminar imagen <i className="far fa-trash-alt"></i></button>
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  <form id="imagenesGaleria2" style={{ marginTop: '10px' }} className="form-group" onSubmit={this.submitFormHandlerImg2}>
                    <div class="input-group">
                      <input style={{ marginTop: '10px' }} id="imagenesGaleria2" class="custom-file-input" type="file" accept="image/*" onChange={this.nameHandlerImg2} />
                      <label class="custom-file-label" id="nombreImg2">{this.state.imagenGaleriaNombre}</label>
                      <button style={{ marginTop: '10px' }} className="btn btn-success" >Cargar</button>
                    </div>
                  </form>
                </div>


                <div class="col-md-6">
                  <label htmlFor="validationCustom02" class="form-label">
                    Activo
                  </label>
                  <br></br>
                  <label class="switch">
                    <input
                      type="checkbox"
                      checked={this.state.estatus}
                      onChange={this.onChangeActivo}
                    />
                    <span class="slider round"></span>
                  </label>
                </div>

                <div className="form-group col-sm-12 text-right">
                  <button onClick={this.guardarNoticia} class="btn btn-primary">
                    <i class="fas fa-save"></i> Guardar
                  </button>
                  <>&nbsp;</>
                  <button onClick={this.regresar} class="btn btn-info">
                    <i class="fas fa-undo-alt"></i> Regresar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section >
      </div >
    );
  }
}

import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import { saveAs } from "file-saver";

export async function createPdf(data) {
    const canvas = document.getElementById("codigoQR");
    const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");

    const pdfDoc = await PDFDocument.create()
    const page = pdfDoc.addPage();
    const form = pdfDoc.getForm();
    const { width, height } = page.getSize()

    page.drawText('Id de la solicitud: ' + data.id.toString(), { x: 50, y: 720, size: 12 });
    page.drawText('Nombre del tutor: ' + data.nombre + ' ' + data.paterno + ' ' + data.materno, { x: 50, y: 700, size: 12 });
    page.drawText('Correo del tutor: ' + data.correo, { x: 50, y: 680, size: 12 });
    page.drawText('Teléfono del tutor: ' + data.telefono, { x: 50, y: 660, size: 12 });
    page.drawText('Codigo QR: ', { x: 50, y: 640, size: 12 });
    const pngImage = await pdfDoc.embedPng(pngUrl);
    const pngDims = pngImage.scale(1);

    page.drawImage(pngImage, {
        x: width / 2 - pngDims.width / 2,
        y: 100,
        width: pngDims.width,
        height: pngDims.height,
    });

    const pdfBytes = await pdfDoc.save();
    var blob = new Blob([pdfBytes], { type: "application/pdf" });
    saveAs(blob, data.nombre + data.paterno + "Comprobante.pdf");
}

import http from "../http-common";

class BrigadaSaludHijoService {
    getAll() {
        return http.get("/brigadadesaludinfantil/hijos");
    }

    get(id) {
        return http.get("/brigadadesaludinfantil/hijos/" + id);
    }

    create(data) {
        return http.post("/brigadadesaludinfantil/hijos", data);
    }

    update(id, data) {
        return http.put("/brigadadesaludinfantil/hijos/" + id, data);
    }

    delete(id) {
        return http.delete(`/brigadadesaludinfantil/hijos/${id}`);
    }

    deleteAll() {
        return http.delete("/brigadadesaludinfantil/hijos");
    }

    deleteByTutor(id) {
        return http.delete(`/brigadadesaludinfantil/hijos/tutor/${id}`);
    }

}

export default new BrigadaSaludHijoService();

import React, { Component } from 'react';
import HijosTabla from './brigadaSaludHijosList.component';
import ReactDOM from "react-dom";
import TablaSalud from './brigadaSaludList.component';
import brigadaSaludHijoService from '../../services/brigadaSaludHijo.service';
import Swal from "sweetalert2";
import brigadaSaludTutorService from '../../services/brigadaSaludTutor.service';

class TutorItem extends Component {
    constructor(props) {
        super(props);

        this.verHijos = this.verHijos.bind(this);
    }

    verHijos() {
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }
        ReactDOM.render(<HijosTabla
            id={this.props.id}
            nombre={this.props.nombre}
        />, divDash);
    }

    eliminarTutor = () => {
        Swal.fire({
            text: '¿Deseas eliminar este elemento?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
        }).then((result) => {
            if (result.isConfirmed) {
                brigadaSaludTutorService.delete(this.props.id)
                    .then(response => {
                        brigadaSaludHijoService.deleteByTutor(this.props.id)
                            .then(info => {
                                let divDash = document.getElementById('contenidoTablero');
                                Swal.fire('Eliminado', 'Elemento Eliminado Correctamente', 'success');
                                if (divDash.children.length > 0) {
                                    ReactDOM.unmountComponentAtNode(divDash);
                                }
                                ReactDOM.render(<TablaSalud />, divDash);
                            })
                            .catch(e => {
                                Swal.fire({
                                    icon: 'error',
                                    text: "Hubo un error al intentar eliminar al tutor."
                                });
                            });
                    })
                    .catch(e => {
                        console.log(e);
                        Swal.fire({
                            icon: 'error',
                            text: "Hubo un error al intentar eliminar al tutor."
                        });
                    });
            }

        });
    }

    render() {
        return (
            <tr key={this.props.id}>
                <td class="col-md-2 text-center align-middle">{this.props.nombre}</td>
                <td class="col-md-4 text-center align-middle">{this.props.paterno}   {this.props.materno}</td>
                <td class="col-md-4 text-center align-middle">{this.props.calle}   {this.props.ext},  CP: {this.props.cp},   {this.props.colonia}</td>
                <td class="col-md-4 text-center align-middle">{this.props.correo}</td>
                <td class="col-md-4 text-center align-middle">{this.props.telefono}</td>
                <td class="col-md-4 text-center align-middle">{this.props.hijos}</td>
                <td class="col-md-4 text-center align-middle">{this.props.verificado ? <i className="fas fa-check-circle" /> : <i class="fas fa-times-circle"></i>}</td>
                <td class="col-md-4 align-middle"><button class="btn btn-light" onClick={this.verHijos}>Ver Hijos</button></td>
                <td class="col-md-1 align-middle"><button class="btn btn-danger" onClick={this.eliminarTutor}><i className="far fa-trash-alt"></i></button></td>
            </tr>
        );
    }
}

export default TutorItem;
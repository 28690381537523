import React, { Component } from 'react';

class DonacionListItem extends Component {
    constructor(props) {
        super(props);    
    }
    render() {
        return (
            <tr key={this.props.id}>
                <td class="col-md-2 text-center align-middle">{this.props.nombreDonador}</td>
                <td class="col-md-2 text-center align-middle">{this.props.apellidos}</td>
                <td class="col-md-4 text-center align-middle">{this.props.cantidad}</td>
                <td class="col-md-4 text-center align-middle">{this.props.causa}</td>
                <td class="col-md-4 text-center align-middle">{this.props.email}</td>
                <td class="col-md-4 text-center align-middle">{this.props.telefono}</td>
                <td class="col-md-4 text-center align-middle">{this.props.mensaje}</td>
            </tr>
        );
    }
}

export default DonacionListItem;
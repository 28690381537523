import React, { Component } from "react";
import ReactDOM from "react-dom";
import CrearNoticia from "../Noticias/crearNoticia.component";
import ListaProyectos from "../Causas/proyectoList.component"
import ListarNoticias from "../Noticias/listarNoticia.component";
import ListarCarousel from "../Carousel/listCarousel.component";
import ListaProgramas from "../Programas/programaList.component";
import ListaUsuarios from "../Usuarios/usuarioList.component"
import ListarTestimonio from "../Testimonios/listarTestimonio.component";
import ListaVoluntarios from "../Voluntarios/voluntarioList.component";
import PerfilUsuario from "../Usuarios/perfilUsuario.component";
import http from "../../http-common.js";
import ListaSolicitudesApoyo from "../Apoyo/apoyoList.component";
import Beneficiarios from "./beneficiarios.component";
import TablaDonadores from "../Donaciones/donacionesList.component";
import TablaSalud from "../BrigadaInfantil/brigadaSaludList.component";

export default class SideBarDash extends Component {

    constructor(props) {
        super(props);

        this.obtenerProgramas = this.obtenerProgramas.bind(this);
        this.obtenerVoluntarios = this.obtenerVoluntarios.bind(this);
        this.crearCarousel = this.crearCarousel.bind(this);
        this.obtenerProgramas = this.obtenerProgramas.bind(this);
        this.mostrarPerfil = this.mostrarPerfil.bind(this);
        this.tablaDonadores = this.tablaDonadores.bind(this);
        this.tablaSaludInfantil = this.tablaSaludInfantil.bind(this);


        this.state = {
            imagenPerfil: this.props.usuario.imagen
        }

    }

    obtenerProgramas(e) {
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }
        ReactDOM.render(<ListaProgramas />, divDash);
    }

    obtenerSolicitudesApoyo(e) {
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }
        ReactDOM.render(<ListaSolicitudesApoyo />, divDash);
    }


    obtenerVoluntarios(e) {
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }
        ReactDOM.render(<ListaVoluntarios />, divDash);
    }

    listarUsuarios(e, id, rol) {
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }
        console.log(id, " ", rol)
        ReactDOM.render(<ListaUsuarios idUs={id} rol={rol} />, divDash);
    }

    mostrarPerfil(e, usuario, rolOrigen) {
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }
        ReactDOM.render(<PerfilUsuario usuario={usuario} />, divDash);
    }

    listarProyectos(e) {
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }
        ReactDOM.render(<ListaProyectos />, divDash);
    }

    listarTestimonios(e) {
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }
        ReactDOM.render(<ListarTestimonio />, divDash);
    }

    crearNoticia(e) {
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }
        ReactDOM.render(<CrearNoticia />, divDash);
    }

    listarNoticias(e, usuario) {
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }

        ReactDOM.render(<ListarNoticias
            autorId={usuario.id}
            autor={usuario.nombre.split(" ")[0] + " " + usuario.paterno}
            autorImagen={usuario.imagen}
        />, divDash);
    }

    crearCarousel(e) {
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }
        ReactDOM.render(<ListarCarousel />, divDash);
    }

    vistaBeneficiarios(e) {
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }
        ReactDOM.render(<Beneficiarios />, divDash);
    }

    componentDidMount() {
        http.get('/s3url2/' + this.state.imagenPerfil).then(response => { document.getElementById('imgPerfilSB').src = response.data }).catch(error => { console.log(error); });
    }

    tablaDonadores() {
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }
        ReactDOM.render(<TablaDonadores />, divDash);
    }

    tablaSaludInfantil(){
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }
        ReactDOM.render(<TablaSalud />, divDash);
    }

    render() {
        return (
            <div>
                <aside className="main-sidebar sidebar-dark-primary elevation-4">
                    <a href="/dashboard" className="brand-link">
                        <img src="/assets/img/logos/apple-touch-icon-57x57.png" alt="." className="brand-image img-circle elevation-3" style={{ opacity: '.8' }} />
                        <span className="brand-text font-weight-light">M&eacute;xico Amparame</span>
                    </a>

                    <div className="sidebar">
                        <br></br>
                        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                            <div className="image">
                                <img id="imgPerfilSB" className="img-circle elevation-2" alt="." />
                            </div>
                            <div className="info">
                                <a href="" className="d-block">{this.props.usuario.nombre.split(" ")[0] + " " + this.props.usuario.paterno}</a>
                            </div>
                        </div>

                        {/* SidebarSearch Form 
                        <div className="form-inline">
                            <div className="input-group" data-widget="sidebar-search">
                                <input className="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search" />
                                <div className="input-group-append">
                                    <button className="btn btn-sidebar">
                                        <i className="fas fa-search fa-fw" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        */}

                        {/* Sidebar Menu */}
                        <nav className="mt-2">
                            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                                {/* Add icons to the links using the .nav-icon class with font-awesome or any other icon font library */}
                                <li className="nav-header">COMPONENTES</li>
                                <li className="nav-item">
                                    <a style={{ cursor: 'pointer' }} onClick={this.vistaBeneficiarios} className="nav-link">
                                        <i className="nav-icon fas fa-list-ol" />
                                        <p>Beneficiarios</p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a style={{ cursor: 'pointer' }} onClick={this.obtenerProgramas} className="nav-link">
                                        <i className="nav-icon fas fa-list-alt" />
                                        <p>Programas</p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a style={{ cursor: 'pointer' }} onClick={this.obtenerVoluntarios} className="nav-link">
                                        <i className="nav-icon fas fa-users" />
                                        <p>Voluntarios</p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a style={{ cursor: 'pointer' }} onClick={this.crearCarousel} className="nav-link">
                                        <i className="nav-icon fab fa-artstation" />
                                        <p>Carousel</p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a style={{ cursor: 'pointer' }} onClick={this.listarProyectos} className="nav-link">
                                        <i className="nav-icon fas fa-hands-helping"></i>
                                        <p>Proyectos</p>
                                    </a>
                                </li>

                                <li className="nav-item">
                                    {this.props.usuario.rol == 'Administrador'
                                        ? <a style={{ cursor: 'pointer' }} onClick={e => this.listarUsuarios(e, this.props.usuario.id, this.props.usuario.rol)} className="nav-link">
                                            <i className="nav-icon fas fa-users"></i>
                                            <p>Usuarios</p>
                                        </a>
                                        : <a style={{ cursor: 'pointer' }} onClick={e => this.mostrarPerfil(e, this.props.usuario)} className="nav-link">
                                            <i className="nav-icon fas fa-user"></i>
                                            <p>Mi perfil</p>
                                        </a>}
                                </li>
                                <li className="nav-item">
                                    <a style={{ cursor: 'pointer' }} onClick={e => this.listarNoticias(e, this.props.usuario)} className="nav-link">
                                        <i className="nav-icon fas fa-newspaper" />
                                        <p>Noticias</p>
                                    </a>
                                </li>

                                <li className="nav-item">
                                    <a style={{ cursor: 'pointer' }} onClick={e => this.obtenerSolicitudesApoyo(e, this.props.usuario)} className="nav-link">
                                        <i className="nav-icon fas fa-clipboard-list" />
                                        <p>Apoyos</p>
                                    </a>
                                </li>

                                <li className="nav-item">
                                    <a style={{ cursor: 'pointer' }} onClick={this.listarTestimonios} className="nav-link">
                                        <i className="nav-icon fas fa-hands-helping"></i>
                                        <p>Testimonios</p>
                                    </a>
                                </li>


                                <li className="nav-item">
                                    {
                                        this.props.usuario.rol == 'Administrador'
                                            ?
                                            <a style={{ cursor: 'pointer' }} onClick={this.tablaDonadores} className="nav-link">                                                
                                                <i className="nav-icon fas fa-hand-holding-usd"></i>
                                                <p>Donadores</p>
                                            </a>
                                            :
                                            null
                                    }
                                </li>

                                <li className="nav-item">
                                    {
                                        this.props.usuario.rol == 'Administrador'
                                            ?
                                            <a style={{ cursor: 'pointer' }} onClick={this.tablaSaludInfantil} className="nav-link">                                                
                                                <i className="nav-icon fas fa-heart"></i>
                                                <p>Salud Infantil</p>
                                            </a>
                                            :
                                            null
                                    }
                                </li>

                                {/*
                                <li className="nav-header"> </li>
                                <li className="nav-header">EJEMPLOS</li>
                                <li className="nav-item">
                                    <a href="pages/calendar.html" className="nav-link">
                                        <i className="nav-icon fas fa-calendar-alt" />
                                        <p>
                                            Calendar
                                            <span className="badge badge-info right">2</span>
                                        </p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="pages/gallery.html" className="nav-link">
                                        <i className="nav-icon far fa-image" />
                                        <p>
                                            Gallery
                                        </p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="https://adminlte.io/docs/3.1/" className="nav-link">
                                        <i className="nav-icon fas fa-file" />
                                        <p>Documentation</p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="#" className="nav-link">
                                        <i className="nav-icon fas fa-circle" />
                                        <p>
                                            Level 1
                                            <i className="right fas fa-angle-left" />
                                        </p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <a href="#" className="nav-link">
                                                <i className="far fa-circle nav-icon" />
                                                <p>
                                                    Level 2
                                                    <i className="right fas fa-angle-left" />
                                                </p>
                                            </a>
                                            <ul className="nav nav-treeview">
                                                <li className="nav-item">
                                                    <a href="#" className="nav-link">
                                                        <i className="far fa-dot-circle nav-icon" />
                                                        <p>Level 3</p>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-header">ETIQUETAS</li>
                            
                                <li className="nav-item">
                                    <a href="#" className="nav-link">
                                        <i className="nav-icon far fa-circle text-danger" />
                                        <p className="text">Important</p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="#" className="nav-link">
                                        <i className="nav-icon far fa-circle text-warning" />
                                        <p>Warning</p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="#" className="nav-link">
                                        <i className="nav-icon far fa-circle text-info" />
                                        <p>Informational</p>
                                    </a>
                                </li>
                                */}
                            </ul>
                        </nav>
                        {/* /.sidebar-menu */}
                    </div>
                    {/* /.sidebar */}
                </aside>
            </div>
        )
    }
}

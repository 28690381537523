import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import PaypalService from "../services/paypal.service"
import { useParams } from 'react-router-dom';
import ProyectoService from "../services/proyecto.service";
import validator from 'validator'
import Swal from 'sweetalert2';
import Stripe from "./stripe.component";
import { Helmet } from 'react-helmet';

const Dona = () => {
    const [state, setState] = useState([]);
    const params = useParams();

    useEffect(() => {

        ProyectoService.get(parseInt(params.id))
            .then((response) => {
                setState(
                    response.data
                );
            })
            .catch((error) => console.log(error))
        setState({
            ...state,
            cantidad: 0,
            id: params.id,
            causa: "",
            donador: null,
            apellidos: "",
            email: "",
            numeroTelefonico: "",
            mensaje: ""
        })
        changeStyle();
        getCausas();



    }, []);

    const changeStyle = () => {
        var top = document.getElementById('barraNavegacion');
        var footer = document.getElementById('footerNavegacion');
        var estilo = document.getElementById('estiloPrincipal');
        var estiloPlugin = document.getElementById('estiloPlugin');

        document.body.className = "";
        top.style.display = 'block';
        footer.style.display = 'block';
        estilo.href = 'assets/css/styles.css';
        estiloPlugin.href = 'assets/css/plugins.css';
    }

    const validatePhoneNumber = (number) => {
        const isValidPhoneNumber = validator.isMobilePhone(number)
        return (isValidPhoneNumber)
    }

    const validateEmail = (email) => {
        const isValidEmail = validator.isEmail(email)
        return (isValidEmail)
    }

    const validarDatos = () => {
        const datosDonacion = {
            cantidad: state.cantidad,
            causa: state.titulo,
            causaID: state.causaId,
            donador: state.donador,
            apellidos: state.apellidos,
            email: state.email,
            numeroTelefonico: state.numeroTelefonico,
            mensaje: state.mensaje
        }
        console.log(datosDonacion)

        if (state.donador == "" || state.donador == null || state.numeroTelefonico == undefined ||
            !validatePhoneNumber(state.numeroTelefonico) || state.email == undefined ||
            !validateEmail(state.email) || state.cantidad <= 0 || state.cantidad == "" ||
            state.cantidad == null || state.cantidad == undefined ||
            state.apellidos == undefined || state.apellidos == null || state.apellidos == "" ||
            state.mensaje == undefined || state.mensaje == null || state.mensaje == ""
        ) {
            if (state.cantidad <= 0 || state.cantidad == "" || state.cantidad == null || state.cantidad == undefined) {
                Swal.fire({
                    icon: 'warning',
                    text: "La cantidad no es valida."
                });
            } else if (state.donador == "" || state.donador == null) {
                Swal.fire({
                    icon: 'warning',
                    text: "El nombre no es valido."
                });
            } else if (state.apellidos == undefined || state.apellidos == null || state.apellidos == "") {
                Swal.fire({
                    icon: 'warning',
                    text: "Apellidos no validos."
                });
            }
            else if (state.email == undefined || !validateEmail(state.email)) {
                Swal.fire({
                    icon: 'warning',
                    text: "El correo electronico no es valido."
                });
            }
            else if (state.numeroTelefonico == undefined || !validatePhoneNumber(state.numeroTelefonico)) {
                Swal.fire({
                    icon: 'warning',
                    text: "El numero telefonico no es valido."
                });
            }
            else if (state.mensaje == undefined || state.mensaje == null || state.mensaje == "") {
                Swal.fire({
                    icon: 'warning',
                    text: "Mensaje no valido."
                });
            }
            return false;
        }
        return true;
    }

    const pagar = (e) => {
        const datosDonacion = {
            cantidad: state.cantidad,
            causa: state.titulo,
            causaID: state.causaId,
            donador: state.donador,
            apellidos: state.apellidos,
            email: state.email,
            numeroTelefonico: state.numeroTelefonico,
            mensaje: state.mensaje
        }

        if (validarDatos()) {
            document.getElementById('divTarjeta').style.display = 'hidden';
            PaypalService.create(datosDonacion)
                .then(response => {
                    window.location.href = response.data.links[1].href
                })
        }
    }

    const pagarTarjeta = (e) => {
        if (validarDatos()) {
            document.getElementById('divTarjeta').style.display = 'block';
        }
    }

    const cambiarCantidad = (valor) => {
        setState(
            {
                ...state,
                cantidad: valor
            }
        )
    }

    const cambiarNombre = (e) => {
        setState({
            ...state,
            donador: e.target.value
        })
    }

    const cambiarApellidos = (e) => {
        setState({
            ...state,
            apellidos: e.target.value
        })
    }

    const cambiarCausa = (e) => {

        setState({
            ...state,
            titulo: e.target.value
        })

        console.log(state)
    }

    const cambiarEmail = (e) => {
        setState({
            ...state,
            email: e.target.value
        })
    }

    const getCausas = () => {

        if (params.id == 0) {
            ProyectoService.getAll().then(response => {

                for (let i = 0; i < response.data.length; i++) {
                    if (i == 0) {
                        setState({
                            ...state,
                            causaId: response.data[i].id,
                            titulo: response.data[i].titulo
                        })


                    }
                    let option = document.createElement("option");
                    option.key = i;
                    option.innerHTML = response.data[i].titulo;
                    document.getElementById('causas_dona').appendChild(option);
                }
            });
        } else {
            ProyectoService.get(params.id).then(response => {
                let option = document.createElement("option");
                option.innerHTML = response.data.titulo;

                document.getElementById('causas_dona').appendChild(option);
                document.getElementById('causas_dona').disabled = true;
            });
        }

    }

    const cambiarNumeroTelefonico = (e) => {
        setState({
            ...state,
            numeroTelefonico: e.target.value
        })
    }


    const cambiarMensaje = (e) => {
        setState({
            ...state,
            mensaje: e.target.value
        })
    }

    return (
        <>
            <Helmet>
                <title>M&eacute;xico Amparame - Donaciones</title>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content="Dona a México Ampárame"/>
                <meta property="og:image" content="https://eacnur.org/blog/wp-content/uploads/2017/12/formas-de-ayuda-solidaria.jpg"/>

                <meta property="twitter:card" content="summary_large_image"/>
                <meta property="twitter:title" content="Dona a México Ampárame"/>
                <meta property="twitter:description" content="Página de donaciones de México Ampárame."/>
                <meta property="twitter:image" content="https://eacnur.org/blog/wp-content/uploads/2017/12/formas-de-ayuda-solidaria.jpg"/>    
            </Helmet>
            <section class="page-title-section bg-img cover-background mx-lg-4 mx-xl-6 rounded-lg" data-overlay-dark="4" style={{ backgroundImage: 'url(assets/img/bg/donaciones-banner.jpg)' }}>
                <div class="container">
                    <div class="row text-center">
                        <div class="col-md-12">
                            <h1 class="text-animation" data-in-effect="fadeInRight">Detalles de la donación</h1>
                            <ul>
                                <li><a><Link to={"/"}>Regresar</Link></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="position-absolute z-index-1 right bottom-n5 opacity2 ani-left-right">
                    <span class="square bg-primary"></span>
                    <span class="square bg-secondary"></span>
                </div>
            </section>

            {/*<!-- DONATE NOW
                ================================================== --*/}
            <section>
                <div class="container">
                    <div class="section-heading">
                        <span class="d-block text-primary display-22 display-md-21 display-lg-20 alt-font wow text-animation" data-in-effect="fadeInRight">
                            <p>{state.titulo}</p>
                        </span>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="p-1-6 p-lg-1-9 border border-color-extra-light-gray rounded">
                                <div class="form-group">
                                    <p className="font-weight-600 text-primary">{params.id == 0 ? "¿A qué causa te gustaría donar?" : "Causa a donar"}</p>
                                    <select
                                        class="form-control"
                                        id="causas_dona"
                                        onChange={
                                            (e) => cambiarCausa(e)
                                        }
                                    >
                                    </select>
                                </div>
                                <p class="font-weight-600 text-primary">¿Cuánto te gustaría donar?</p>
                                <form method="post" action="#!" class="donate-form mailform off2">
                                    <div class="mb-2-5">
                                        <div class="select-amount clearfix">
                                            <div class="select-box">
                                                <input
                                                    type="radio"
                                                    name="payment-group"
                                                    id="radio-one"
                                                    onChange={
                                                        (e) => { cambiarCantidad(10) }
                                                    }
                                                />
                                                <label for="radio-one">$10</label>
                                            </div>
                                            <div class="select-box">
                                                <input
                                                    type="radio"
                                                    name="payment-group"
                                                    id="radio-two"
                                                    onChange={
                                                        (e) => { cambiarCantidad(20) }
                                                    }
                                                />
                                                <label for="radio-two">$20</label>
                                            </div>
                                            <div class="select-box">
                                                <input
                                                    type="radio"
                                                    name="payment-group"
                                                    id="radio-three"
                                                    onChange={
                                                        (e) => { cambiarCantidad(50) }
                                                    }
                                                />
                                                <label for="radio-three">$50</label>
                                            </div>
                                            <div class="select-box">
                                                <input
                                                    type="radio"
                                                    name="payment-group"
                                                    id="radio-four"
                                                    onChange={
                                                        (e) => { cambiarCantidad(100) }
                                                    }
                                                />
                                                <label for="radio-four">$100</label>
                                            </div>
                                        </div>
                                        <div class="input-box">
                                            <input
                                                type="number"
                                                className="form-control"
                                                name="other-payment"
                                                value={state.cantidad}
                                                onChange={
                                                    (e) => { cambiarCantidad(parseInt(e.target.value)) }
                                                }
                                                placeholder="Otra cantidad"

                                            />
                                        </div>
                                    </div>

                                </form>

                                <p class="font-weight-600 text-primary">Información personal</p>
                                <form action="#!">

                                    <div class="quform-elements">

                                        <div class="row">

                                            {/*<!-- Begin Text input element -->*/}
                                            <div class="col-md-6">
                                                <div class="quform-element">
                                                    <div class="quform-input">
                                                        <input
                                                            class="form-control"
                                                            id="name"
                                                            type="text"
                                                            name="name"
                                                            placeholder="Nombre(s) *"
                                                            onChange={
                                                                (e) => { cambiarNombre(e) }
                                                            }
                                                            required="required"
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                            {/*<!-- End Text input element -->

                                                <!-- Begin Text input element -->*/}
                                            <div class="col-md-6">
                                                <div class="quform-element">
                                                    <div class="quform-input">
                                                        <input
                                                            class="form-control"
                                                            id="name2"
                                                            type="text"
                                                            name="name2"
                                                            placeholder="Apellidos"
                                                            onChange={
                                                                (e) => { cambiarApellidos(e) }
                                                            }
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                            {/*<!-- End Text input element -->

                                                <!-- Begin Text input element -->*/}
                                            <div class="col-md-6">
                                                <div class="quform-element">
                                                    <div class="quform-input">
                                                        <input
                                                            class="form-control"
                                                            id="email"
                                                            type="text"
                                                            name="email"
                                                            placeholder="Email"
                                                            onChange={
                                                                (e) => { cambiarEmail(e) }
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {/*<!-- End Text input element -->

                                                <!-- Begin Text input element -->*/}
                                            <div class="col-md-6">
                                                <div class="quform-element">
                                                    <div class="quform-input">
                                                        <input
                                                            class="form-control"
                                                            id="phone"
                                                            type="text"
                                                            name="phone"
                                                            placeholder="Número de teléfono"
                                                            onChange={
                                                                (e) => { cambiarNumeroTelefonico(e) }
                                                            }
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                            {/*<!-- End Text input element -->

                                                <!-- Begin Textarea element -->*/}
                                            <div class="col-md-12">
                                                <div class="quform-element">
                                                    <div class="quform-input">
                                                        <textarea
                                                            class="form-control"
                                                            id="message"
                                                            name="message"
                                                            rows="3"
                                                            placeholder="Mensaje"
                                                            onChange={
                                                                (e) => { cambiarMensaje(e) }
                                                            }
                                                        ></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </form>
                                <div class="col-md-12 mb-4">
                                    <p class="font-weight-600 text-primary">Dona ahora</p>
                                    <div className="row justify-content-start">
                                        <div className="col-2">
                                            <button class="butn" onClick={(e) => pagar(e)}>
                                                <i class="fab fa-cc-paypal"></i> PayPal
                                            </button>
                                        </div>
                                        <div className="col-4">
                                            <button type="button" class="butn" onClick={(e) => pagarTarjeta(e)} style={{ marginRight: '10px' }}>
                                                <i class="far fa-credit-card"></i> {"Tarjeta de crédito / débito"}
                                            </button>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12" id="divTarjeta" style={{ display: 'none' }}>
                                    <Stripe
                                        nombreDonador={state.donador ? state.donador : ""}
                                        apellidos={state.apellidos}
                                        email={state.email}
                                        telefono={state.numeroTelefonico}
                                        cantidad={state.cantidad}
                                        mensaje={state.mensaje}
                                        causa={state.titulo}
                                        causaId={state.causaId}
                                        >
                                    </Stripe>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*<!-- CLIENTS
                ================================================== -->*/}
            <section class="py-5 wow fadeIn bg-secondary" data-wow-delay="100ms">
                <div class="container-fluid px-md-8 px-lg-10 px-xl-14">
                    <div class="owl-carousel owl-theme client-style1 text-center">
                    </div>
                </div>
            </section>
        </>
    );

}

export default Dona;
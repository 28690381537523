import React, { Component } from "react";
import Swal from 'sweetalert2';
import ReactDOM from "react-dom";
import ApoyoService from "../../services/apoyo.service.js";
import ListaSolicitudesApoyo from "./apoyoList.component.js";
import ProgramaService from "../../services/programa.service.js";

export default class VerSolicitudApoyo extends Component {

    constructor(props) {
        super(props);

        this.obtenerSolicitudes = this.obtenerSolicitudes.bind(this);
        this.eliminarSolicitud = this.eliminarSolicitud.bind(this);
        this.onChangeNombre = this.onChangeNombre.bind(this);
        this.onChangePaterno = this.onChangePaterno.bind(this);
        this.onChangeMaterno = this.onChangeMaterno.bind(this);
        this.onChangeTelefono = this.onChangeTelefono.bind(this);
        this.onChangeElector = this.onChangeElector.bind(this);
        this.onChangeCorreo = this.onChangeCorreo.bind(this);
        this.onChangeCalle = this.onChangeCalle.bind(this);
        this.onChangeInt = this.onChangeInt.bind(this);
        this.onChangeExt = this.onChangeExt.bind(this);
        this.onChangeColonia = this.onChangeColonia.bind(this);
        this.onChangeCiudad = this.onChangeCiudad.bind(this);
        this.onChangeEstado = this.onChangeEstado.bind(this);
        this.onChangeCP = this.onChangeCP.bind(this);
        this.onChangePrograma = this.onChangePrograma.bind(this);
        this.onChangeSituacion = this.onChangeSituacion.bind(this);
        this.guardarSolicitud = this.guardarSolicitud.bind(this);
        this.crearComboEstados = this.crearComboEstados.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.validateElector = this.validateElector.bind(this);
        this.validateTelefono = this.validateTelefono.bind(this);

        this.state = {
            solicitudPersona: this.props.solicitudPersona,
            id: this.props.solicitudPersona.id,
            nombre: this.props.solicitudPersona.nombre,
            paterno: this.props.solicitudPersona.paterno,
            materno: this.props.solicitudPersona.materno,
            elector: this.props.solicitudPersona.elector,
            correo: this.props.solicitudPersona.correo,
            telefono: this.props.solicitudPersona.telefono,
            calle: this.props.solicitudPersona.calle,
            int: this.props.solicitudPersona.int,
            ext: this.props.solicitudPersona.ext,
            colonia: this.props.solicitudPersona.colonia,
            ciudad: this.props.solicitudPersona.ciudad,
            estado: this.props.solicitudPersona.estado,
            cp: this.props.solicitudPersona.cp,
            situacion: this.props.solicitudPersona.situacion,
            programa: this.props.solicitudPersona.programa,
            programas: [],
            estados: []
        }
    }

    validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    validateElector = (elector) => {
        return elector.length == 18 && elector.match("^[A-Za-z0-9]+$");
    };

    validateTelefono = (telefono) => {
        return telefono.match("^[0-9]+$");
    };

    checarNuevoEstado = (e) => {
        let input = document.getElementById(e.target.id);
        if (e.target.value == "") {
            input.className = "form-control is-invalid";
        }
        else {
            input.className = "form-control";
        }
    }

    onChangeNombre(e) {
        this.setState({
            nombre: e.target.value
        });

        this.checarNuevoEstado(e);
    }

    onChangePaterno(e) {
        this.setState({
            paterno: e.target.value
        });

        this.checarNuevoEstado(e);
    }

    onChangeMaterno(e) {
        this.setState({
            materno: e.target.value
        });

        this.checarNuevoEstado(e);
    }

    onChangeElector(e) {
        this.setState({
            elector: e.target.value
        });

        this.checarNuevoEstado(e);
    }

    onChangeTelefono(e) {
        this.setState({
            telefono: e.target.value
        });

        this.checarNuevoEstado(e);
    }

    onChangeCorreo(e) {
        this.setState({
            correo: e.target.value
        });

        this.checarNuevoEstado(e);
    }

    onChangeCalle(e) {
        this.setState({
            calle: e.target.value
        });

        this.checarNuevoEstado(e);
    }

    onChangeInt(e) {
        this.setState({
            int: e.target.value
        });
    }

    onChangeExt(e) {
        this.setState({
            ext: e.target.value
        });

        this.checarNuevoEstado(e);
    }

    onChangeColonia(e) {
        this.setState({
            colonia: e.target.value
        });

        this.checarNuevoEstado(e);
    }

    onChangeCiudad(e) {
        this.setState({
            ciudad: e.target.value
        });

        this.checarNuevoEstado(e);
    }

    onChangeEstado(e) {
        this.setState({
            estado: e.target.value
        });

        this.checarNuevoEstado(e);
    }

    onChangeCP(e) {
        this.setState({
            cp: e.target.value
        });

        this.checarNuevoEstado(e);
    }

    onChangePrograma(e) {
        this.setState({
            programa: e.target.value
        });

        this.checarNuevoEstado(e);
    }

    onChangeSituacion(e) {
        this.setState({
            situacion: e.target.value
        });

        this.checarNuevoEstado(e);
    }

    crearComboEstados() {
        var estadosDos = ["Aguascalientes", "Baja California", "Baja California Sur", "Campeche", "Coahuila", "Colima", "Chiapas",
            "Chihuahua", "Ciudad de México", "Durango", "Guanajuato", "Guerrero", "Hidalgo", "Jalisco", "Estado de México", "Michoacán",
            "Morelos", "Nayarit", "Nuevo León", "Oaxaca", "Puebla", "Querétaro", "Quintana Roo", "San Luis Potosí", "Sinaloa", "Sonora",
            "Tabasco", "Tamaulipas", "Tlaxcala", "Veracruz", "Yucatán", "Zacatecas"];
        var estadosOpcion = [];

        for (let i = 0; i < estadosDos.length; i++) {
            estadosOpcion.push(<option key={i} value={estadosDos[i]}>
                {estadosDos[i]}
            </option>);
        }

        this.setState({
            estados: estadosOpcion
        });
    }

    obtenerProgramas() {
        ProgramaService.getAll()
            .then(response => {
                var tresProgramas = [];

                for (let i = 0; i < response.data.length; i++) {
                    tresProgramas.push(<option key={response.data[i].id} value={response.data[i].id}>
                        {response.data[i].titulo}
                    </option>);
                }

                this.setState({
                    programas: tresProgramas
                });
            })
            .catch(e => {
                console.log(e);
            });
    }

    guardarSolicitud(e) {
        var data = {
            nombre: this.state.nombre,
            paterno: this.state.paterno,
            materno: this.state.materno,
            elector: this.state.elector,
            calle: this.state.calle,
            int: this.state.int === "" ? "N/A" : this.state.int,
            ext: this.state.ext,
            cp: this.state.cp,
            colonia: this.state.colonia,
            ciudad: this.state.ciudad,
            estado: this.state.estado,
            correo: this.state.correo,
            telefono: this.state.telefono,
            programa: this.state.programa,
            situacion: this.state.situacion
        };

        if (this.state.nombre !== "" && this.state.paterno !== "" && this.state.materno !== ""
            && this.validateElector(this.state.elector) && this.state.calle !== ""
            && this.state.ext !== "" && this.state.cp !== "" && this.state.colonia !== ""
            && this.state.ciudad !== "" && this.state.estado !== "" && this.validateTelefono(this.state.telefono)
            && this.state.programa !== 0 && this.state.situacion !== "" && this.validateEmail(this.state.correo)) {
            ApoyoService.update(this.state.id, data)
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        text: "Se actualizó la solicitud."
                    });
                    this.obtenerSolicitudes();
                })
                .catch(e => {
                    Swal.fire({
                        icon: 'error',
                        text: "Hubo un error al actualizar."
                    })
                });
        }
        else if (!this.validateElector(this.state.elector)) {
            Swal.fire({
                icon: 'warning',
                text: "La clave de elector es inválida."
            });
        }
        else if (!this.validateEmail(this.state.correo)) {
            Swal.fire({
                icon: 'warning',
                text: "El email es inválido."
            });
        }
        else if (!this.validateTelefono(this.state.telefono)) {
            Swal.fire({
                icon: 'warning',
                text: "El telefono es inválido."
            });
        }
        else {
            Swal.fire({
                icon: 'warning',
                text: "Hay campos requeridos vacios."
            });
        }
        e.preventDefault();
    }

    obtenerSolicitudes(e) {
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }
        ReactDOM.render(<ListaSolicitudesApoyo />, divDash);
    }

    eliminarSolicitud() {
        Swal.fire({
            text: '¿Deseas eliminar este elemento?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
        }).then((result) => {
            if (result.isConfirmed) {
                ApoyoService.delete(this.state.solicitudPersona.id).then(response => {
                    let divDash = document.getElementById('contenidoTablero');
                    Swal.fire('Eliminado', 'Elemento Eliminado Correctamente', 'success')
                    if (divDash.children.length > 0) {
                        ReactDOM.unmountComponentAtNode(divDash);
                    }
                    ReactDOM.render(<ListaSolicitudesApoyo />, divDash);
                });
            }
        });
    }

    componentDidMount() {
        this.obtenerProgramas();
        this.crearComboEstados();
    };

    render() {

        const { programas, estados } = this.state;

        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <h2 className="">Datos de la solicitud</h2>
                </div>

                <form class="row g-1 needs-validation" onSubmit={this.guardarSolicitud} noValidate>
                    <div class="col-md-4 mb-3">
                        <label for="nombre" class="form-label">Nombre(s) *</label>
                        <input type="text" class="form-control" onChange={this.onChangeNombre} value={this.state.nombre}
                            aria-describedby="nombreInvalido" id="nombre" />
                        <div id="nombreInvalido" class="invalid-feedback">
                            Este campo es obligatorio
                        </div>
                    </div>
                    <div class="col-md-4">
                        <label class="form-label">Apellido paterno<span className="required"> * </span></label>
                        <input type="text" class="form-control" onChange={this.onChangePaterno} value={this.state.paterno} 
                            aria-describedby="paternoInvalido" id="paterno"/>
                        <div id="paternoInvalido" class="invalid-feedback">
                            Este campo es obligatorio
                        </div>
                    </div>
                    <div class="col-md-4">
                        <label class="form-label">Apellido materno<span className="required"> * </span></label>
                        <input type="text" class="form-control" onChange={this.onChangeMaterno} value={this.state.materno} 
                            aria-describedby="maternoInvalido" id="materno"/>
                        <div id="maternoInvalido" class="invalid-feedback">
                            Este campo es obligatorio
                        </div>
                    </div>

                    <div class="col-md-6 mb-3">
                        <label class="form-label">Folio INE<span className="required"> * </span></label>
                        <input type="text" class="form-control" onChange={this.onChangeElector} value={this.state.elector} 
                            aria-describedby="claveInvalida" id="elector"/>
                        <div id="claveInvalida" class="invalid-feedback">
                            Este campo es obligatorio
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="form-label">Calle<span className="required"> * </span></label>
                        <input type="text" class="form-control" onChange={this.onChangeCalle} value={this.state.calle} 
                            aria-describedby="calleInvalida" id="calle"/>
                        <div id="calleInvalida" class="invalid-feedback">
                            Este campo es obligatorio
                        </div>
                    </div>

                    <div class="col-md-4">
                        <label class="form-label">N&uacute;m. Exterior<span className="required"> * </span></label>
                        <input type="text" class="form-control" onChange={this.onChangeExt} value={this.state.ext} 
                            aria-describedby="extInvalido" id="ext"/>
                        <div id="extInvalido" class="invalid-feedback">
                            Este campo es obligatorio
                        </div>
                    </div>
                    <div class="col-md-4 mb-3">
                        <label class="form-label">N&uacute;m. Interior</label>
                        <input type="text" class="form-control" onChange={this.onChangeInt} value={this.state.int}/>
                    </div>
                    <div class="col-md-4">
                        <label class="form-label">C&oacute;digo Postal<span className="required"> * </span></label>
                        <input type="number" class="form-control" onChange={this.onChangeCP} value={this.state.cp} 
                            aria-describedby="cpInvalido" id="cp"/>
                        <div id="cpInvalido" class="invalid-feedback">
                            Este campo es obligatorio
                        </div>
                    </div>

                    <div class="col-md-4 mb-3">
                        <label class="form-label">Colonia<span className="required"> * </span></label>
                        <input type="text" class="form-control" onChange={this.onChangeColonia} value={this.state.colonia} 
                            aria-describedby="coloniaInvalida" id="colonia"/>
                        <div id="coloniaInvalida" class="invalid-feedback">
                            Este campo es obligatorio
                        </div>
                    </div>
                    <div class="col-md-4">
                        <label class="form-label">Ciudad<span className="required"> * </span></label>
                        <input type="text" class="form-control" onChange={this.onChangeCiudad} value={this.state.ciudad} 
                            aria-describedby="ciudadInvalida" id="ciudad"/>
                        <div id="ciudadInvalida" class="invalid-feedback">
                            Este campo es obligatorio
                        </div>
                    </div>
                    <div class="col-md-4">
                        <label class="form-label">Estado<span className="required"> * </span></label>
                        <select class="form-control" id="selectEstados" onChange={this.onChangeEstado} value={this.state.estado}
                            aria-describedby="estadoInvalido">
                            {estados}
                        </select>
                        <div id="estadoInvalido" class="invalid-feedback">
                            Este campo es obligatorio
                        </div>
                    </div>

                    <div class="col-md-4 mb-3">
                        <label class="form-label">Correo<span className="required"> * </span></label>
                        <div class="input-group has-validation">
                            <span class="input-group-text"><i class="fas fa-at"></i></span>
                            <input type="text" class="form-control" onChange={this.onChangeCorreo} aria-describedby="correoInvalido"
                                id="correo" value={this.state.correo} />
                            <div id="correoInvalido" class="invalid-feedback">
                                Este campo es obligatorio
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <label class="form-label">Tel&eacute;fono<span className="required"> * </span></label>
                        <div class="input-group has-validation">
                            <span class="input-group-text"><i class="fas fa-phone-square-alt"></i></span>
                            <input type="text" class="form-control" onChange={this.onChangeTelefono} aria-describedby="telefonoInvalido"
                               id="telefono" value={this.state.telefono} />
                            <div id="telefonoInvalido" class="invalid-feedback">
                                Este campo es obligatorio
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <label class="form-label">Programa<span className="required"> * </span></label>
                        <select class="form-control" id="selectProgramas" onChange={this.onChangePrograma} value={this.state.programa}
                            aria-describedby="programaInvalido">
                            {programas}
                        </select>
                        <div id="programaInvalido" class="invalid-feedback">
                            Este campo es obligatorio
                        </div>
                    </div>

                    <div class="col-md-12 mb-3">
                        <label class="form-label">Situaci&oacute;n<span className="required"> * </span></label>
                        <div class="input-group has-validation">
                            <textarea type="text" class="form-control" onChange={this.onChangeSituacion} aria-describedby="situacionInvalida"
                                id="situacion" value={this.state.situacion} rows={5}/>
                            <div id="situacionInvalida" class="invalid-feedback">
                                Este campo es obligatorio
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12  mb-3">
                        <div class="row justify-content-end">
                            <div class="col-6-1">
                                <button type="submit" class="btn btn-primary"><i class="fas fa-save"></i> Guardar</button>
                            </div>
                            <div class="col-6-1 ml-2">
                                <button type="button" class="btn btn-danger" onClick={this.eliminarSolicitud}><i class="fas fa-trash-alt"></i> Eliminar</button>
                            </div>
                            <div class="col-6-1 ml-2">
                                <button type="button" onClick={this.obtenerSolicitudes} class="btn btn-info"><i class="fas fa-undo-alt"></i> Regresar</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}
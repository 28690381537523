import React, { Component } from "react";
import BrigadaSaludTutorService from "../../services/brigadaSaludTutor.service.js";
import BrigadaSaludHijoService from "../../services/brigadaSaludHijo.service.js";
import Swal from "sweetalert2";
import { Helmet } from 'react-helmet';
import PasitosHijo from "./brigadaSaludHijo.component.js";
import QRCode from "qrcode.react";
import ReactDOM from "react-dom";
import { createPdf } from "./brigadaRegistroPDF.componente.js";

export default class PasitosSanos extends Component {

    constructor(props) {
        super(props);

        this.onChangeNombre = this.onChangeNombre.bind(this);
        this.onChangePaterno = this.onChangePaterno.bind(this);
        this.onChangeMaterno = this.onChangeMaterno.bind(this);
        this.onChangeTelefono = this.onChangeTelefono.bind(this);
        this.onChangeCorreo = this.onChangeCorreo.bind(this);
        this.onChangeCalle = this.onChangeCalle.bind(this);
        this.onChangeInt = this.onChangeInt.bind(this);
        this.onChangeExt = this.onChangeExt.bind(this);
        this.onChangeColonia = this.onChangeColonia.bind(this);
        this.onChangeCiudad = this.onChangeCiudad.bind(this);
        this.onChangeEstado = this.onChangeEstado.bind(this);
        this.onChangeCP = this.onChangeCP.bind(this);
        this.onChangeHijos = this.onChangeHijos.bind(this);
        this.onChangeInfoHijo = this.onChangeInfoHijo.bind(this);
        this.mandarSolicitud = this.mandarSolicitud.bind(this);
        this.crearComboEstados = this.crearComboEstados.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.validateTelefono = this.validateTelefono.bind(this);

        this.state = {
            nombre: "",
            paterno: "",
            materno: "",
            correo: "",
            telefono: "",
            calle: "",
            int: "",
            ext: "",
            colonia: "",
            ciudad: "",
            estado: "",
            cp: "",
            hijos: "",
            infoHijos: [],
            botonesHijos: [],
            divHijos: [],
            estados: []
        }
    }

    validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    validateTelefono = (telefono) => {
        return telefono.match("^[0-9]+$");
    };

    validateCURP = (curp) => {
        return curp.length == 18 && curp.match("^[A-Za-z0-9]+$");
    };

    validateDefined = (valor) => {
        return valor != '' && valor != undefined;
    };

    validateArrayNoVacio = (array) => {
        return array.length > 0;
    };

    validarHijos = () => {
        let bandera = false;
        for (let i = 0; i < this.state.infoHijos.length; i++) {
            let element = this.state.infoHijos[i];
            bandera = this.validateDefined(element.nombre) && this.validateDefined(element.paterno) &&
                this.validateDefined(element.materno) && this.validateDefined(element.curp) &&
                this.validateCURP(element.curp) && this.validateDefined(element.edad) &&
                this.validateDefined(element.discapacidad) && this.validateArrayNoVacio(element.servicios);
        }
        return this.state.infoHijos.length > 0 && bandera;
    }

    onChangeNombre(e) {
        this.setState({
            nombre: e.target.value
        });
    }

    onChangePaterno(e) {
        this.setState({
            paterno: e.target.value
        });
    }

    onChangeMaterno(e) {
        this.setState({
            materno: e.target.value
        });
    }

    onChangeTelefono(e) {
        this.setState({
            telefono: e.target.value
        });
    }

    onChangeCorreo(e) {
        this.setState({
            correo: e.target.value
        });
    }

    onChangeCalle(e) {
        this.setState({
            calle: e.target.value
        });
    }

    onChangeInt(e) {
        this.setState({
            int: e.target.value
        });
    }

    onChangeExt(e) {
        this.setState({
            ext: e.target.value
        });
    }

    onChangeColonia(e) {
        this.setState({
            colonia: e.target.value
        });
    }

    onChangeCiudad(e) {
        this.setState({
            ciudad: e.target.value
        });
    }

    onChangeEstado(e) {
        this.setState({
            estado: e.target.value
        });
    }

    onChangeCP(e) {
        this.setState({
            cp: e.target.value
        });
    }

    onChangeHijos(e) {
        let nuevosDiv = [];
        let nuevosBotones = [];

        for (let i = 0; i < e.target.value; i++) {
            nuevosBotones.push(
                <button class="nav-link" id={"fichaHijo" + (i + 1) + "-tab"} data-bs-toggle="tab" data-bs-target={"#fichaHijo" + (i + 1)}
                    type="button" role="tab" aria-controls={"fichaHijo" + (i + 1)} aria-selected="false">
                    {"Hijo #" + (i + 1)}
                </button>);

            nuevosDiv.push(
                <div class="tab-pane fade" id={"fichaHijo" + (i + 1)} role="tabpanel" aria-labelledby={"fichaHijo" + (i + 1) + "-tab"}>
                    <PasitosHijo onChange={this.onChangeInfoHijo} id={i} />
                </div>
            );
        }

        this.setState({
            hijos: e.target.value,
            botonesHijos: nuevosBotones,
            divHijos: nuevosDiv
        });
    }

    onChangeInfoHijo(hijoId, campo, valor) {
        let hijos = [...this.state.infoHijos];
        let hijo = { ...hijos[hijoId] };
        hijo[campo] = valor;
        hijos[hijoId] = hijo;

        this.setState({
            infoHijos: hijos
        });
    }

    crearComboEstados() {
        var estadosDos = ["Aguascalientes", "Baja California", "Baja California Sur", "Campeche", "Coahuila", "Colima", "Chiapas",
            "Chihuahua", "Ciudad de México", "Durango", "Guanajuato", "Guerrero", "Hidalgo", "Jalisco", "Estado de México", "Michoacán",
            "Morelos", "Nayarit", "Nuevo León", "Oaxaca", "Puebla", "Querétaro", "Quintana Roo", "San Luis Potosí", "Sinaloa", "Sonora",
            "Tabasco", "Tamaulipas", "Tlaxcala", "Veracruz", "Yucatán", "Zacatecas"];
        var estadosOpcion = [];

        for (let i = 0; i < estadosDos.length; i++) {
            estadosOpcion.push(<option key={i} value={estadosDos[i]}>
                {estadosDos[i]}
            </option>);
        }

        this.setState({
            estados: estadosOpcion
        });
    }

    mandarSolicitud() {
        var data = {
            nombre: this.state.nombre,
            paterno: this.state.paterno,
            materno: this.state.materno,
            elector: this.state.elector,
            calle: this.state.calle,
            int: this.state.int === "" ? "N/A" : this.state.int,
            ext: this.state.ext,
            cp: this.state.cp,
            colonia: this.state.colonia,
            ciudad: this.state.ciudad,
            estado: this.state.estado,
            correo: this.state.correo,
            telefono: this.state.telefono,
            hijos: this.state.hijos,
            verificado: false
        };

        if (this.state.nombre !== "" && this.state.paterno !== "" && this.state.materno !== "" &&
            this.state.hijos !== "" && this.state.calle !== "" && this.state.ext !== "" &&
            this.state.cp !== "" && this.state.colonia !== "" && this.state.ciudad !== "" &&
            this.state.estado !== "" && this.validateTelefono(this.state.telefono) &&
            this.validateEmail(this.state.correo) && this.validarHijos()) {

            BrigadaSaludTutorService.create(data)
                .then(response => {
                    let id = response.data.id;
                    for (let i = 0; i < this.state.infoHijos.length; i++) {
                        let element = this.state.infoHijos[i];
                        element['tutorId'] = id;
                        BrigadaSaludHijoService.create(element)
                            .then(response => {
                                console.log("Hijo agregado");
                            })
                            .catch(e => {
                                console.log(e);
                                Swal.fire({
                                    icon: 'error',
                                    text: "Hubo un error al registrar a los hijos."
                                })
                            });
                    }

                    this.setState({
                        nombre: "",
                        paterno: "",
                        materno: "",
                        correo: "",
                        telefono: "",
                        calle: "",
                        int: "",
                        ext: "",
                        colonia: "",
                        ciudad: "",
                        estado: "",
                        cp: "",
                        hijos: "",
                        infoHijos: [],
                        botonesHijos: [],
                        divHijos: [],
                        estados: []
                    });

                    document.getElementById("selectEstados").selectedIndex = 0;
                    let wrapper = document.createElement('div');
                    ReactDOM.render(<QRCode id="codigoQR" value={"https://mexicoamparame.ac/verificacion" + id} />, wrapper);
                    let codigoQR = wrapper.firstChild;
                    Swal.fire({
                        icon: 'success',
                        html: codigoQR,
                        title: "<h5>Se registró tu solicitud.</h5>",
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Guardar comprobante',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            createPdf(response.data);
                        }
                    })
                })
                .catch(e => {
                    console.log(e);
                    Swal.fire({
                        icon: 'error',
                        text: "Hubo un error al registrar al tutor."
                    })
                });
        }
        else if (!this.validarHijos()) {
            Swal.fire({
                icon: 'warning',
                text: "Hay campos requeridos vacios del hijo."
            });
        }
        else if (this.state.hijos === "") {
            Swal.fire({
                icon: 'warning',
                text: "Debe registrar por lo menos 1 hijo."
            });
        }
        else if (!this.validateEmail(this.state.correo)) {
            Swal.fire({
                icon: 'warning',
                text: "El email es inválido."
            });
        }
        else if (!this.validateTelefono(this.state.telefono)) {
            Swal.fire({
                icon: 'warning',
                text: "El telefono es inválido."
            });
        }
        else {
            Swal.fire({
                icon: 'warning',
                text: "Hay campos requeridos vacios."
            });
        }
    }



    componentDidMount() {
        var top = document.getElementById('barraNavegacion');
        var footer = document.getElementById('footerNavegacion');
        var estilo = document.getElementById('estiloPrincipal');
        var estiloPlugin = document.getElementById('estiloPlugin');

        document.body.className = "";
        top.style.display = 'block';
        footer.style.display = 'block';
        estilo.href = 'assets/css/styles.css';
        estiloPlugin.href = 'assets/css/plugins.css';
        this.crearComboEstados();
    };

    render() {

        const { divHijos, botonesHijos, estados } = this.state;

        return (
            <div>
                <Helmet>
                    <title>M&eacute;xico Amparame - Salud Infantil</title>
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="Brigada de Salud Infantil - México Ampárame" />
                    <meta property="og:image" content="https://c.stocksy.com/a/VDJ500/z9/1265513.jpg" />

                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:title" content="Brigada de Salud Infantil -  México Ampárame" />
                    <meta property="twitter:description" content="Puedes solicitar apoyo a México Ampárame en esta página." />
                    <meta property="twitter:image" content="https://c.stocksy.com/a/VDJ500/z9/1265513.jpg" />
                </Helmet>
                <section class="page-title-section bg-img cover-background mx-lg-4 mx-xl-6 rounded-lg" data-overlay-dark="4" style={{ backgroundImage: 'url(assets/img/bg/pasitos-sanos.jpg)' }}>
                    <div class="container">
                        <div class="row text-center">
                            <div class="col-md-12">
                                <h1 class="text-animation" data-in-effect="fadeInRight">Brigada de Salud Infantil</h1>
                                <ul>
                                    <li><a href="/">Regresar</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="position-absolute z-index-1 right bottom-n5 opacity2 ani-left-right">
                        <span class="square bg-primary"></span>
                        <span class="square bg-secondary"></span>
                    </div>
                </section>

                <section class="pt-6">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col">
                                <div className="ps-lg-1-6">
                                    <div className="p-1-6 p-lg-1-9 p-xl-2-5 border border-color-extra-light-gray rounded">
                                        <div className="quform-elements">
                                            <div className="row">
                                                <div className="d-block d-md-flex justify-content-md-between mb-4 mt-4 pb-1 border-bottom border-color-extra-light-gray">
                                                    <h4>DATOS DEL TUTOR</h4>
                                                </div>

                                                <div className="col-sm">
                                                    <div className="quform-element">
                                                        <label htmlFor="name">Nombre(s) <span className="quform-required">*</span></label>
                                                        <div className="quform-input">
                                                            <input className="form-control" id="nombre" type="text" name="nombre" value={this.state.nombre} onChange={this.onChangeNombre} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm">
                                                    <div className="quform-element">
                                                        <label htmlFor="name">Apellido Paterno <span className="quform-required">*</span></label>
                                                        <div className="quform-input">
                                                            <input className="form-control" id="paterno" type="text" name="paterno" value={this.state.paterno} onChange={this.onChangePaterno} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm">
                                                    <div className="quform-element">
                                                        <label htmlFor="name">Apellido Materno <span className="quform-required">*</span></label>
                                                        <div className="quform-input">
                                                            <input className="form-control" id="materno" type="text" name="materno" value={this.state.materno} onChange={this.onChangeMaterno} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm">
                                                    <div className="quform-element">
                                                        <label htmlFor="name">Calle <span className="quform-required">*</span></label>
                                                        <div className="quform-input">
                                                            <input className="form-control" id="calle" type="text" name="calle" value={this.state.calle} onChange={this.onChangeCalle} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm">
                                                    <div className="quform-element">
                                                        <label htmlFor="name">Colonia <span className="quform-required">*</span></label>
                                                        <div className="quform-input">
                                                            <input className="form-control" id="colonia" type="text" name="colonia" value={this.state.colonia} onChange={this.onChangeColonia} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm">
                                                    <div className="quform-element">
                                                        <label htmlFor="name">N&uacute;m. Exterior <span className="quform-required">*</span></label>
                                                        <div className="quform-input">
                                                            <input className="form-control" id="ext" type="text" name="ext" value={this.state.ext} onChange={this.onChangeExt} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm">
                                                    <div className="quform-element">
                                                        <label htmlFor="name">N&uacute;m. Interior</label>
                                                        <div className="quform-input">
                                                            <input className="form-control" id="int" type="text" name="int" value={this.state.int} onChange={this.onChangeInt} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm">
                                                    <div className="quform-element">
                                                        <label htmlFor="name">C&oacute;digo Postal <span className="quform-required">*</span></label>
                                                        <div className="quform-input">
                                                            <input className="form-control" id="cp" type="number" name="cp" value={this.state.cp} onChange={this.onChangeCP} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm">
                                                    <div className="quform-element">
                                                        <label htmlFor="name">Ciudad <span className="quform-required">*</span></label>
                                                        <div className="quform-input">
                                                            <input className="form-control" id="ciudad" type="text" name="ciudad" value={this.state.ciudad} onChange={this.onChangeCiudad} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm">
                                                    <div className="quform-element">
                                                        <label htmlFor="phone">Estado <span className="quform-required">*</span></label>
                                                        <div className="quform-input">
                                                            <select id="selectEstados" className="form-control" style={{ boxSizing: 'border-box', padding: 10, margin: 0 }}
                                                                onChange={this.onChangeEstado}>
                                                                <option selected disabled hidden> Selecciona una entidad</option>
                                                                {estados}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm">
                                                    <div className="quform-element">
                                                        <label htmlFor="subject">Correo <span className="quform-required">*</span></label>
                                                        <div className="quform-input">
                                                            <input className="form-control" id="correo" type="email" name="correo" value={this.state.correo} onChange={this.onChangeCorreo} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm">
                                                    <div className="quform-element">
                                                        <label htmlFor="phone">T&eacute;lefono <span className="quform-required">*</span></label>
                                                        <div className="quform-input">
                                                            <input className="form-control" id="telefono" type="text" name="telefono" value={this.state.telefono} onChange={this.onChangeTelefono} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm">
                                                    <div className="quform-element">
                                                        <label htmlFor="phone">N&uacute;mero de hijos <span className="quform-required">*</span></label>
                                                        <div className="quform-input">
                                                            <input className="form-control" id="hijos" type="number" name="hijos" min="1" max="99" maxLength="2"
                                                                value={this.state.hijos} onChange={this.onChangeHijos} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="d-block d-md-flex justify-content-md-between mb-4 mt-4 pb-1 border-bottom border-color-extra-light-gray">
                                                <h4>DATOS DEL HIJO</h4>
                                            </div>

                                            <nav>
                                                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                    {botonesHijos}
                                                </div>
                                            </nav>
                                            <div class="tab-content mb-4" id="nav-tabContent">
                                                {divHijos}
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12 text-center">
                                                    <button className="butn" onClick={this.mandarSolicitud}>ENVIAR SOLICITUD</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
import http from "../http-common";

class VolunteerService {
    getAll() {
        return http.get("/voluntarios");
    }

    get(id) {
        return http.get("/voluntarios/" + id);
    }

    create(data) {
        return http.post("/voluntarios", data);
    }

    update(id, data) {
        return http.put("/voluntarios/" + id, data);
    }

    delete(id) {
        return http.delete("/voluntarios/" + id);
    }

    deleteAll() {
        return http.delete("/voluntarios");
    }

    getAllActive(){
        return http.get("/voluntarios/activos");
    }

    getAllHomePage(){
        return http.get("/voluntarios/habilitados");
    }

}

export default new VolunteerService();

import React, { Component } from "react";

export default class Gracias extends Component {
    componentDidMount() {
        var top = document.getElementById('barraNavegacion');
        var footer = document.getElementById('footerNavegacion');
        var estilo = document.getElementById('estiloPrincipal');
        var estiloPlugin = document.getElementById('estiloPlugin');

        document.body.className = "";
        top.style.display = 'block';
        footer.style.display = 'block';
        estilo.href = 'assets/css/styles.css';
        estiloPlugin.href = 'assets/css/plugins.css';
    }
    render() {
        return (
            <>
                <center>
                <h1>Gracias por tu donación.</h1>
                <h5>Con tu donacion ayudas a todas y a todos.</h5>
                </center>
                <img src="https://blog.stockcrowd.com/hubfs/gracias_Blog_Post.png"></img>
            </>
        )
    }

}
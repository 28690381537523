import ProgramaService from "../../services/programa.service";
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { IconPickerItem } from "react-fa-icon-picker";
import http from "../../http-common.js";
import { Helmet } from 'react-helmet';

const ProgramaDetalle = () => {
    const [datos, setDatos] = useState([]);
    const [imagen, setImagen] = useState("");
    const [programas, setProgramas] = useState([]);
    const params = useParams();

    useEffect(() => {
        ProgramaService.get(parseInt(params.id))
            .then((response) => {
                setDatos(response.data);
                putImages(response.data);
            })
            .catch((error) => console.log(error));

        ProgramaService.getAllActive()
            .then((response) => {
                setProgramas(response.data);
            })
            .catch((error) => console.log(error))

        changeStyle();

    }, []);

    const changeStyle = () => {
        var top = document.getElementById('barraNavegacion');
        var footer = document.getElementById('footerNavegacion');
        var estilo = document.getElementById('estiloPrincipal');
        var estiloPlugin = document.getElementById('estiloPlugin');

        document.body.className = "";
        top.style.display = 'block';
        footer.style.display = 'block';
        estilo.href = 'assets/css/styles.css';
        estiloPlugin.href = 'assets/css/plugins.css';
    }

    const createMarkup = (html) => {
        return {
            __html: DOMPurify.sanitize(html)
        }
    }

    const putImages = (datos) => {
        for (let i = 0; i < 5; i++) {
            if (i < datos.imagenes.length) {
                if(i === 0){
                    http.get('/s3url2/'+datos.imagenes[i]).then(response => {document.getElementById('img-' + (i + 1)).src = response.data
                    setImagen(response.data);
                }).catch(error => {console.log(error);});                   
                }else{
                    http.get('/s3url2/'+datos.imagenes[i]).then(response => {document.getElementById('img-' + (i + 1)).src = response.data}).catch(error => {console.log(error);});                   
                }
                
            } else {
                document.getElementById('img-' + (i + 1)).style.display = 'none';
            }
        }
    }

    return (
        <>
            <Helmet>
                <title>M&eacute;xico Amparame - Programas</title>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content={datos.titulo}/>
                <meta property="og:image" content={imagen}/>

                <meta property="twitter:card" content="summary_large_image"/>
                <meta property="twitter:title" content={datos.titulo}/>
                <meta property="twitter:description" content={datos.subtitulo}/>
                <meta property="twitter:image" content={imagen}/>    
            </Helmet>
            <section class="page-title-section bg-img cover-background mx-lg-4 mx-xl-6 rounded-lg"
                data-overlay-dark="4" style={{ backgroundImage: `url("/assets/img/banner/programasBanner.jpeg")` }}>
                <div class="container">
                    <div class="row text-center">
                        <div class="col-md-12">
                            <h1 class="text-animation" data-in-effect="fadeInRight">Programas</h1>
                            <ul>
                                <li><a><Link to={"/"}>Regresar</Link></a></li>                                
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="position-absolute z-index-1 right bottom-n5 opacity2 ani-left-right">
                    <span class="square bg-primary"></span>
                    <span class="square bg-secondary"></span>
                </div>
            </section>

            <section className="blogs">
                <div className="container">
                    <div className="row">
                        {/*<!-- left content -->*/}
                        <div class="col-lg-9 pe-lg-2-5 pe-xl-2-9 mb-2-9 mb-lg-0">
                            <div class="posts">
                                <div className="mb-4 wow fadeIn" data-wow-delay="200ms">
                                    <img id="img-1" className="img-fluid rounded-top" alt="..." />
                                </div>
                                <div class="content wow fadeIn" data-wow-delay="400ms">
                                    <div class="meta">
                                        <h2 class="h3 mb-3">{datos.titulo}</h2>
                                    </div>
                                    <div className="d-block d-md-flex justify-content-md-between mb-4 pb-1 border-bottom border-color-extra-light-gray wow fadeIn"
                                        data-wow-delay="200ms">
                                    </div>
                                    <div className="mb-2-5 wow fadeIn" data-wow-delay="400ms">
                                        <h1 class="lead font-weight-400 text-primary">{datos.subtitulo}</h1>
                                        {/* Así se pone para que aparezca como html*/}
                                        <div className="editor-text" dangerouslySetInnerHTML={createMarkup(datos.descripcion)}></div>
                                        <div className="row mt-4 mb-1-9 mb-md-2-5">
                                            <div id="img-detalles1" className="col-md-6 d-flex justify-content-center">
                                                <img id="img-2" className="rounded" alt="..." />
                                            </div>
                                            <div id="img-detalles2" className="col-md-6 d-flex justify-content-center">
                                                <img id="img-3" className="rounded" alt="..." />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div id="img-detalles3" className="col-md-6 d-flex justify-content-center">
                                                <img id="img-4" className="rounded" alt="..." />
                                            </div>
                                            <div id="img-detalles3" className="col-md-6 d-flex justify-content-center">
                                                <img id="img-5" className="rounded" alt="..." />
                                            </div>
                                        </div>
                                        <p className="pb-1 mb-2"></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3">
                            <div class="side-bar">
                                <div class="widget wow fadeInUp" data-wow-delay="400ms">
                                    <h6 class="h5 mb-3">Otros programas</h6>
                                    {programas && programas.map((programa) => {
                                        return <div class="media mb-1-6">
                                            <IconPickerItem icon={programa.icono} size={48} color={programa.colorIcono}/>
                                            <div class="media-body align-self-center">
                                                <h4 class="h6 mb-1"><a href={"/programasDetalle" + programa.id}>{programa.titulo}</a></h4>
                                                <small>{programa.subtitulo}</small>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ProgramaDetalle;
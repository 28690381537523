import React, { Component } from "react";
import VoluntarioItem from "./voluntarioGridItem.component.js";
import VoluntarioService from "../../services/voluntario.service.js";

export default class VoluntarioTarjeta extends Component {

    constructor(props){
        super(props);
        this.obtenerVoluntarios = this.obtenerVoluntarios.bind(this);
        this.crearNombreApellido = this.crearNombreApellido.bind(this);

        this.state = {
            voluntarios: this.obtenerVoluntarios()
        };
    }

    crearNombreApellido(nombre, apellido){
        let unSoloNombre = nombre.split(" ")[0];
        return unSoloNombre + " " + apellido;
    }

    obtenerVoluntarios(){
        VoluntarioService.getAllHomePage()
           .then(response => {
               let tresVoluntarios = [];
               let tarjetasVoluntarios = [];
               tresVoluntarios = response.data;
               for (let i = 0; i < tresVoluntarios.length; i++) {
                    let nombreTitulo = this.crearNombreApellido(tresVoluntarios[i].nombre, tresVoluntarios[i].paterno);
                    tarjetasVoluntarios.push(<VoluntarioItem
                        nombre={nombreTitulo}
                        id={tresVoluntarios[i].id}
                        facebookLink={tresVoluntarios[i].facebook}
                        instagramLink={tresVoluntarios[i].instagram}
                        twitterLink={tresVoluntarios[i].twitter}
                        linkedinLink={tresVoluntarios[i].linkedin}
                        imagen={tresVoluntarios[i].imagen}
                        />);              
               }
               

              this.setState({
                voluntarios: tarjetasVoluntarios
              });
           })
           .catch(e => {
               console.log(e);
           });
    }

    render(){

        const { voluntarios } = this.state;

        return(
            <section>
                <div class="container">
                    <div class="section-heading">
                        <span class="d-block text-primary display-22 display-md-21 display-lg-20 alt-font wow text-animation fadeInRight" >Nuestro equipo</span>
                        <h2>Voluntarios</h2>
                    </div>
                    <div class="row justify-content-center">
                        {voluntarios}
                        <center><a class="butn secondary getMoreCausasBtn" href="/voluntarios">Ver más voluntarios</a></center>
                    </div>
                </div>
                <img src="img/icons/icon-9.png" alt="" class="position-absolute right-5 top-75 ani-top-bottom opacity7 d-none d-lg-block"/>
            </section>
        )
    }
}
import React, { Component } from "react";
import ReactDOM from "react-dom";
import Swal from "sweetalert2";
import ApoyoService from "../../services/apoyo.service.js";
import ListaSolicitudesApoyo from "./apoyoList.component.js";
import VerSolicitudApoyo from "./editarApoyo.component.js";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";
import ProgramaService from "../../services/programa.service.js";

export default class SolicitudApoyoListItem extends Component {

    constructor(props) {
        super(props);

        this.eliminarSolicitud = this.eliminarSolicitud.bind(this);
        this.obtenerProgramaPorId = this.obtenerProgramaPorId.bind(this);
        this.verSolicitud = this.verSolicitud.bind(this);

        this.state = {
            solicitudPersona: this.props.solicitudPersona,
            nombrePrograma: this.obtenerProgramaPorId(this.props.solicitudPersona.programa)
        }
    }

    obtenerProgramaPorId(id) {
        ProgramaService.get(id)
            .then(response => {
                var titulo = response.data.titulo;

                this.setState({
                    nombrePrograma: titulo
                });
            })
            .catch(e => {
                console.log(e);
            });
    }

    eliminarSolicitud() {
        Swal.fire({
            text: '¿Deseas eliminar este elemento?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
        }).then((result) => {
            if (result.isConfirmed) {                
                ApoyoService.delete(this.props.solicitudPersona.id).then(response => {                    
                    let divDash = document.getElementById('contenidoTablero');
                    
                    if (divDash.children.length > 0) {
                        ReactDOM.unmountComponentAtNode(divDash);
                    }
                    ReactDOM.render(<ListaSolicitudesApoyo />, divDash);
                    Swal.fire('Eliminado', 'Elemento Eliminado Correctamente', 'success')
                });
            }
        });
    }

    verSolicitud() {
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }

        ReactDOM.render(<VerSolicitudApoyo solicitudPersona={this.state.solicitudPersona}/>, divDash);
    }

    render() {
        const { redes, solicitudPersona, nombrePrograma } = this.state;

        const generarPDF = () => {

            const loadFile = (url, callback) => {
                PizZipUtils.getBinaryContent(url, callback);
            };

            const crearNombre = () => {
                return solicitudPersona.nombre + " " + solicitudPersona.paterno + " " + solicitudPersona.materno;
            };

            const crearDomicilio = () => {
                var dom = "";
                var colonia = solicitudPersona.colonia.replaceAll("colonia", "").replaceAll("Colonia", "").replaceAll("COLONIA", "");
                colonia = colonia.trim();

                if (solicitudPersona.ext && solicitudPersona.ext != "N/A") {
                    dom = solicitudPersona.calle + " Núm Int. " + solicitudPersona.int + ", Núm. Ext. " + solicitudPersona.ext
                        + ", Colonia " + colonia + ", " + solicitudPersona.ciudad + ", " + solicitudPersona.estado;
                }
                else {
                    dom = solicitudPersona.calle + " Núm Int. " + solicitudPersona.int + ", Colonia " + colonia
                        + ", " + solicitudPersona.ciudad + ", " + solicitudPersona.estado;
                }
                return dom;
            };

            loadFile(
                "/assets/plantillaSolicitud.docx",
                function (error, content) {
                    if (error) {
                        throw error;
                    }
                    const zip = new PizZip(content);
                    const doc = new Docxtemplater(zip, {
                        paragraphLoop: true,
                        linebreaks: true,
                    });

                    let fecha = new Date();
                    let meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", 
                        "Septiembre", "Octubre", "Noviembre", "Diciembre"];

                    doc.render({
                        idsolicitud: solicitudPersona.id,
                        dia: fecha.getDate() > 10 ? fecha.getDate() : "0" + fecha.getDate(),
                        mes: meses[fecha.getMonth()],
                        anio: fecha.getFullYear().toString().substring(2),
                        nombrecompleto: crearNombre(),
                        elector: solicitudPersona.elector,
                        domiciliocompleto: crearDomicilio(),
                        situacion: solicitudPersona.situacion,
                        programa: nombrePrograma,
                    });

                    const out = doc.getZip().generate({
                        type: "blob",
                        mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    });

                    //Output the document using Data-URI
                    saveAs(out, solicitudPersona.elector + ".docx");
                }
            );
        };

        return (
            <tr key={this.state.id}>
                <td class="col-md-2 align-middle text-center">{this.state.solicitudPersona.nombre + " " + this.state.solicitudPersona.paterno
                    + " " + this.state.solicitudPersona.materno}</td>
                <td class="col-md-2 align-middle text-center">{this.state.solicitudPersona.elector}</td>
                <td class="col-md-2 align-middle text-center">{this.state.solicitudPersona.correo}</td>
                <td class="col-md-1 align-middle text-center">{this.state.solicitudPersona.telefono}</td>
                <td class="col-md-1 align-middle text-center"><button class="btn btn-light" onClick={this.verSolicitud}>Editar solicitud</button></td>
                <td class="col-md-1 align-middle text-center"><button class="btn btn-info" onClick={generarPDF}>Generar Word</button></td>
                <td class="col-md-1 align-middle text-center"><button class="btn btn-danger" onClick={this.eliminarSolicitud}><i className="far fa-trash-alt"></i></button></td>
            </tr>
        )
    }
} 
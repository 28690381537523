import React, { Component, useEffect } from "react";
import Swal from 'sweetalert2';
import { EditorState, convertFromHTML, ContentState, stateFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';//se importa y se instala con npm
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ReactDOM from "react-dom";

import ProgramaService from "../../services/programa.service.js";
import ListaProgramas from "./programaList.component.js";
import { IconPicker, IconPickerItem } from 'react-fa-icon-picker'
import { CompactPicker, CirclePicker } from 'react-color';
import GifLoader from 'react-gif-loader';
import http from "../../http-common.js";

export default class EditarPrograma extends Component {

    constructor(props) {
        super(props);
        this.onChangeTitulo = this.onChangeTitulo.bind(this);
        this.onChangeSubtitulo = this.onChangeSubtitulo.bind(this);
        this.onChangeDescripcion = this.onChangeDescripcion.bind(this);
        this.onChangeEditor = this.onChangeEditor.bind(this);
        this.onChangeIcon = this.onChangeIcon.bind(this);
        this.onChangeColorIcon = this.onChangeColorIcon.bind(this);
        this.obtenerProgramas = this.obtenerProgramas.bind(this);
        this.onChangeInicio = this.onChangeInicio.bind(this);
        this.onChangeActivo = this.onChangeActivo.bind(this);
        this.submitFormHandler = this.submitFormHandler.bind(this);
        this.nameHandler = this.nameHandler.bind(this);
        this.clickNextButton = this.clickNextButton.bind(this);
        this.clickPrevButton = this.clickPrevButton.bind(this);
        this.guardarNoticia = this.guardarNoticia.bind(this);
        this.eliminarImagenGaleria = this.eliminarImagenGaleria.bind(this);

        this.state = {
            convertedContent: this.props.descripcion,
            editorState: EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(this.props.descripcion))),
            id: this.props.id,
            titulo: this.props.titulo,
            subtitulo: this.props.subtitulo,
            descripcion: this.props.descripcion,
            activo: this.props.activo,
            inicio: this.props.inicio,
            textoActivo: "Inactivo",
            imagenPrincipal: this.props.imagenPrincipal,
            imagenPrincipalCopy: "",
            imagenesGaleria: this.props.imagenesGaleria,
            imagenesGaleriaCopy: [],
            imagenNombre: "",
            imagenGaleriaNombre: "",
            imagen: null,
            imagenActualCarrusel: this.props.imagenesGaleria[0],
            indiceImagen: this.props.indice,
            icono: this.props.icono,
            colorIcono: this.props.colorIcono,
            loading: false,
            loadingPrincipal: false
        };
    }

    componentDidMount() {
        http.get('/s3url2/' + this.state.imagenPrincipal).then(response => { document.getElementById('imgP').src = response.data }).catch(error => { console.log(error); });
        http.get('/s3url2/' + this.state.imagenActualCarrusel).then(response => { document.getElementById('imgCarrusel').src = response.data }).catch(error => { console.log(error); });
    }

    onChangeTitulo(e) {
        if (e.target.value.length >= 39) {
            Swal.fire({
                icon: 'warning',
                text: "Texto demasiado largo"
            })
        } else {
            this.setState({
                titulo: e.target.value
            });
        }
    }

    onChangeSubtitulo(e) {
        this.setState({
            subtitulo: e.target.value
        });
    }

    onChangeDescripcion(e) {
        this.setState({
            descripcion: e.target.value
        });
    };

    onChangeIcon(e) {
        this.setState({
            icono: e
        });
    };

    onChangeColorIcon(e) {
        this.setState({
            colorIcono: e.hex
        });
    };

    onChangeInicio(e) {
        let target = e.target;
        let value = target.checked;

        if (target.checked && this.state.activo) {
            ProgramaService.getAllHomePage()
                .then(response => {
                    if (response.data.length >= 3) {
                        Swal.fire({
                            icon: 'warning',
                            text: "Solo se permiten tres programas para mostrar en el inicio."
                        })
                    }
                    else {
                        this.setState({ inicio: value }, () => {
                            ProgramaService.update(this.state.id, this.state)
                                .then(response => {
                                    console.log(response.data);
                                })
                                .catch(e => {
                                    console.log(e);
                                });
                        });
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        }
        else if (!target.checked) {
            this.setState({ inicio: value }, () => {
                ProgramaService.update(this.state.id, this.state)
                    .then(response => {
                        console.log(response.data);
                    })
                    .catch(e => {
                        console.log(e);
                    });
            });
        }
        else {
            Swal.fire({
                icon: 'warning',
                text: "Necesita estar activo para mostrarse en el inicio."
            })
        }
    };

    onChangeActivo(e) {
        let target = e.target;
        let inicioActivo = this.state.inicio;
        console.log(target.checked);

        if (!target.checked) {
            inicioActivo = false;
        }

        this.setState({ activo: target.checked, inicio: inicioActivo }, () => {
            ProgramaService.update(this.state.id, this.state)
                .then(response => {
                    console.log(response.data);
                })
                .catch(e => {
                    console.log(e);
                });
        });
    };

    onChangeEditor = (editorState) => {
        this.setState({
            editorState: editorState
        })
        this.convertContentToHTML();
    };



    obtenerProgramas() {
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }
        ReactDOM.render(<ListaProgramas />, divDash);
    }

    guardarNoticia() {

        let imagenes = this.state.imagenesGaleria;

        if (this.state.imagenPrincipal) {
            imagenes.unshift(this.state.imagenPrincipal);
        }

        var data = {
            titulo: this.state.titulo,
            subtitulo: this.state.subtitulo,
            descripcion: this.state.convertedContent,
            activo: this.state.activo,
            inicio: this.state.inicio,
            imagenes: imagenes,
            icono: this.state.icono,
            colorIcono: this.state.colorIcono
        };

        if (this.state.titulo && this.state.subtitulo && this.state.icono && this.state.imagenPrincipal
            && this.state.editorState.getCurrentContent().getPlainText('\u0001') != "") {
            ProgramaService.update(this.state.id, data)
                .then(response => {
                    console.log(response.data);
                    Swal.fire({
                        icon: 'success',
                        text: "Actualización exitosa."
                    })
                })
                .catch(e => {
                    console.log(e);
                    Swal.fire({
                        icon: 'error',
                        text: "Hubo un error en la solicitud."
                    })
                });

            this.obtenerProgramas();
        }
        else {
            if (!this.state.icono) {
                Swal.fire({
                    icon: 'warning',
                    text: "Debe seleccionar un icono."
                });
            }
            else if (!this.state.imagenPrincipal) {
                Swal.fire({
                    icon: 'warning',
                    text: "Debe seleccionar una imagen principal."
                });
            }
            else if (this.state.titulo == '' || !this.state.subtitulo == '' || this.state.editorState.getCurrentContent().getPlainText('\u0001') == "") {
                var inputTitulo = document.getElementById('titulo');
                var inputSubtitulo = document.getElementById('subtitulo');

                inputTitulo.className = inputTitulo.value == '' ? "form-control is-invalid" : "form-control";
                inputSubtitulo.className = inputSubtitulo.value == '' ? "form-control is-invalid" : "form-control";

                Swal.fire({
                    icon: 'warning',
                    text: "Hay campos requeridos vacios."
                });
            }
        }
    }

    nameHandler(e) {
        let archivo = e.target.files[0];
        console.log(e.target.files);

        if (archivo) {
            let nombre = "Archivo elegido: " + archivo.name;
            const scope = this;
            var reader = new FileReader();

            //Read the contents of Image File.
            reader.readAsDataURL(archivo);
            reader.onload = function (file) {
                var image = new Image();
                image.src = file.target.result;
                image.onload = function () {
                    let height = this.height;
                    let width = this.width;

                    if (e.target.id == "imagenMain") {
                        if (height > 532 || width > 932) {
                            Swal.fire({
                                icon: 'warning',
                                text: "La imagen no debe exceder 932 pixeles de alto y  532 pixeles de ancho."
                            });
                            return false;
                        }
                        scope.setState({ imagen: archivo, imagenNombre: nombre });
                    }
                    else {
                        if (height > 266 || width > 488) {
                            Swal.fire({
                                icon: 'warning',
                                text: "La imagen no debe exceder 488 pixeles de alto y 266 pixeles de ancho."
                            });
                            return false;
                        }
                        scope.setState({ imagen: archivo, imagenGaleriaNombre: nombre });
                    }
                    return true;
                };
            };
        }
    }

    async submitFormHandler(e) {
        e.preventDefault();
        let file;
        file = this.state.imagen;

        if (file) {

            if (e.target.id == "imagenMain") this.setState({ loadingPrincipal: true });
            if (e.target.id != "imagenMain") this.setState({ loading: true });

            let data = new FormData();
            data.append('foto', file);

            await http.post('/s3Url', data).then((response) => {

                if (e.target.id == "imagenMain") {
                    if (this.state.imagenPrincipal !== "") {
                        http.delete('/s3url3/'+this.state.imagenPrincipal);    
                    }
                    http.get('/s3url2/' + response.data.data).then(response => { document.getElementById('imgP').src = response.data }).catch(error => { console.log(error); });
                    this.setState({
                        imagenPrincipalCopy: response.data.data,
                        imagenPrincipal: response.data.data,
                        imagen: null,
                        imagenNombre: "",
                        loadingPrincipal: false
                    });
                }
                else {
                    let nuevoIndex = this.state.indiceImagen + 1;

                    http.get('/s3url2/' + response.data.data).then(response => { document.getElementById('imgCarrusel').src = response.data }).catch(error => { console.log(error); });
                    let nuevasImagenes = this.state.imagenesGaleria;
                    nuevasImagenes.push(response.data.data);
                    this.setState({
                        imagenesGaleriaCopy: nuevasImagenes,
                        imagenesGaleria: nuevasImagenes,
                        imagenActualCarrusel: response.data.data,
                        indiceImagen: nuevoIndex,
                        imagen: null,
                        imagenGaleriaNombre: "",
                        loading: false
                    });
                }
            })
        }
        else {
            this.setState({
                loading: false
            });
            Swal.fire({
                icon: 'warning',
                text: "Debe cargar una imagen."
            })
        }
    }

    clickNextButton() {
        if (this.state.imagenesGaleria.length > 0) {
            if (this.state.indiceImagen >= this.state.imagenesGaleria.length - 1) {
                http.get('/s3url2/' + this.state.imagenesGaleria[0]).then(response => { document.getElementById('imgCarrusel').src = response.data }).catch(error => { console.log(error); });
                this.setState({
                    indiceImagen: 0,
                    imagenActualCarrusel: this.state.imagenesGaleria[0]
                })
            }
            else {
                let nuevoIndex = this.state.indiceImagen + 1;
                http.get('/s3url2/' + this.state.imagenesGaleria[nuevoIndex]).then(response => { document.getElementById('imgCarrusel').src = response.data }).catch(error => { console.log(error); });
                this.setState({
                    indiceImagen: nuevoIndex,
                    imagenActualCarrusel: this.state.imagenesGaleria[nuevoIndex]
                })
            }
        }
    }

    clickPrevButton() {
        if (this.state.imagenesGaleria.length > 0) {
            if (this.state.indiceImagen <= 0) {
                let nuevoIndex = this.state.imagenesGaleria.length - 1;
                http.get('/s3url2/' + this.state.imagenesGaleria[nuevoIndex]).then(response => { document.getElementById('imgCarrusel').src = response.data }).catch(error => { console.log(error); });
                this.setState({
                    indiceImagen: nuevoIndex,
                    imagenActualCarrusel: this.state.imagenesGaleria[nuevoIndex]
                })
            }
            else {
                let nuevoIndex = this.state.indiceImagen - 1;
                http.get('/s3url2/' + this.state.imagenesGaleria[nuevoIndex]).then(response => { document.getElementById('imgCarrusel').src = response.data }).catch(error => { console.log(error); });
                this.setState({
                    indiceImagen: nuevoIndex,
                    imagenActualCarrusel: this.state.imagenesGaleria[nuevoIndex]
                })
            }
        }
    }

    convertContentToHTML = () => {
        let currentContentAsHTML = convertToHTML(this.state.editorState.getCurrentContent());
        this.setState({ convertedContent: currentContentAsHTML });
    }

    eliminarImagenGaleria() {
        if (this.state.imagenesGaleria.length > 0) {
            let indexImagen = this.state.imagenesGaleria.indexOf(this.state.imagenActualCarrusel);

            if (indexImagen != -1) {
                var value = this.state.imagenActualCarrusel;
                var nuevaGaleria = this.state.imagenesGaleria.filter(function (item) {
                    return item !== value
                })

                if (indexImagen == 0) {
                    if (this.state.imagenesGaleria.length > 1) {
                        http.delete('/s3url3/' + this.state.imagenesGaleria[indexImagen]);
                        this.setState({
                            imagenActualCarrusel: this.state.imagenesGaleria[indexImagen + 1]
                        });
                        http.get('/s3url2/' + this.state.imagenesGaleria[indexImagen + 1]).then(response => { document.getElementById('imgCarrusel').src = response.data }).catch(error => { console.log(error); });

                    } else {
                        http.delete('/s3url3/' + this.state.imagenesGaleria[indexImagen]);
                        document.getElementById("imgCarrusel").src = "";
                        this.setState({
                            imagenActualCarrusel: "",
                            indiceImagen: -1
                        });
                    }
                }
                else if (indexImagen == (this.state.imagenesGaleria.length - 1) && this.state.imagenesGaleria.length > 1) {
                    http.delete('/s3url3/' + this.state.imagenesGaleria[indexImagen]);
                    this.setState({
                        imagenActualCarrusel: this.state.imagenesGaleria[indexImagen - 1],
                        indiceImagen: indexImagen - 1
                    });
                    http.get('/s3url2/' + this.state.imagenesGaleria[indexImagen - 1]).then(response => { document.getElementById('imgCarrusel').src = response.data }).catch(error => { console.log(error); });
                }

                this.setState({
                    imagenesGaleria: nuevaGaleria
                });
            }
        }
        else {
            Swal.fire({
                icon: 'warning',
                text: "La galeria está vacía."
            })
        }
    }

    render() {

        const { editorState } = this.state;

        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <h2 className="myaccount-content">Datos del programa</h2>
                </div>

                <section className="content">
                    <div className="container">
                        <div className="row">
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <div class="row">
                                        <div class="col">
                                            <label className="font-weight-bold">Icono<span className="required"> * </span> </label>
                                            <IconPicker value={this.state.icono} onChange={this.onChangeIcon}
                                                buttonIconStyles={{ color: this.state.colorIcono }} hideSearch={true} />
                                        </div>
                                        <div class="col">
                                            <label className="font-weight-bold">Color del icono<span className="required"></span> </label>
                                            <CirclePicker onChange={this.onChangeColorIcon} color={"#000"} />
                                        </div>
                                    </div>


                                </div>
                                <div className="form-group col-md-12">

                                    <label className="font-weight-bold">Imagen principal<span className="required"> * </span> </label>
                                    <div class="container" style={{ marginTop: '10px' }}>
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th class="text-center align-middle" style={{ width: "100%" }}>
                                                        <GifLoader
                                                            loading={this.state.loadingPrincipal}
                                                            imageSrc="https://media.giphy.com/media/17mNCcKU1mJlrbXodo/giphy.gif"
                                                            overlayBackground="none"
                                                            imageStyle={{ width: "30%", display: "block", marginRight: "auto", marginLeft: "auto" }}
                                                        />
                                                        <img style={{ marginBottom: '10px' }} id="imgP" class="img-fluid" alt="Carga una imagen"></img>

                                                    </th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                    <form id="imagenMain" style={{ "marginTop": '10px' }} className="form-group" onSubmit={this.submitFormHandler}>
                                        <div class="input-group">

                                            <input id="imagenMain" class="custom-file-input" type="file" accept="image/*" onChange={this.nameHandler} />
                                            <label class="custom-file-label" id="nombre">{this.state.imagenNombre}</label>
                                            <button style={{ marginTop: '10px' }} className="btn btn-success" type="submit">Cargar</button>
                                        </div>
                                    </form>
                                </div>

                                <div className="form-group col-md-12">
                                    <label className="font-weight-bold">Titulo<span className="required"> * </span> </label>
                                    <input type="text" value={this.state.titulo} onChange={this.onChangeTitulo} id="titulo" className="form-control"
                                        placeholder="Titulo" aria-describedby="tituloInvalido" />
                                    <div id="tituloInvalido" class="invalid-feedback">
                                        Este campo es obligatorio
                                    </div>
                                </div>

                                <div className="form-group col-md-12">
                                    <label className="font-weight-bold">Subtitulo<span className="required"> * </span> </label>
                                    <input type="text" value={this.state.subtitulo} onChange={this.onChangeSubtitulo} id="subtitulo" className="form-control"
                                        placeholder="Subtitulo" aria-describedby="SubtituloInvalido" />
                                    <div id="SubtituloInvalido" class="invalid-feedback">
                                        Este campo es obligatorio
                                    </div>
                                </div>

                                <div className="form-group col-md-12 editor">
                                    <label className="font-weight-bold">Descripcion<span className="required"> * </span> </label>
                                    <Editor
                                        editorState={editorState}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={this.onChangeEditor}
                                        toolbar={{
                                            options: ["inline", "blockType", "colorPicker", "list"],
                                            inline: {
                                                inDropdown: false,
                                                className: "test",
                                                component: undefined,
                                                dropdownClassName: undefined,
                                                options: ["bold", "italic", "underline"],
                                                bold: { className: "test", style: { color: "red" } },
                                                italic: { className: undefined },
                                                underline: { className: undefined },
                                                font: { className: undefined }
                                            },
                                            colorPicker: {
                                                className: undefined,
                                                component: undefined,
                                                popupClassName: undefined,
                                                colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
                                                    'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
                                                    'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
                                                    'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
                                                    'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
                                                    'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
                                            }
                                        }}
                                    />
                                </div>

                                <div className="form-group col-md-12">
                                    <GifLoader
                                        loading={this.state.loading}
                                        imageSrc="https://media.giphy.com/media/17mNCcKU1mJlrbXodo/giphy.gif"
                                        overlayBackground="none"
                                        imageStyle={{ width: "30%", display: "block", marginRight: "auto", marginLeft: "auto" }}
                                    />
                                    <label className="font-weight-bold">Imagenes para la galeria</label>
                                    <br></br>
                                    <a>Nota: en el orden que se suban, será el orden en que aparezca</a>
                                    <div class="container" style={{ marginTop: '10px' }}>
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th class="text-center align-middle" style={{ width: "10%" }}>
                                                        <button class="btn btn-outline-light" onClick={this.clickPrevButton}><i class="fas fa-arrow-left"></i></button>
                                                    </th>
                                                    <th class="text-center align-middle" style={{ width: "80%" }}>
                                                        <img style={{ marginBottom: '10px' }} id="imgCarrusel" class="img-fluid" alt="Carga una imagen"></img>
                                                    </th>
                                                    <th class="text-center align-middle" style={{ width: "10%" }}>
                                                        <button class="btn btn-outline-light" onClick={this.clickNextButton}><i class="fas fa-arrow-right"></i></button>
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th class="text-center align-middle" style={{ width: "10%" }}></th>
                                                    <th class="text-center align-middle" style={{ width: "80%" }}>
                                                        {this.state.indiceImagen == -1 ? "" : "Imagen " + (this.state.indiceImagen + 1)}
                                                    </th>
                                                    <th class="text-center align-middle" style={{ width: "10%" }}>
                                                        <button class="btn btn-danger" onClick={this.eliminarImagenGaleria}>Eliminar imagen <i className="far fa-trash-alt"></i></button>
                                                    </th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                    <form id="imagenesGaleria" style={{ marginTop: '10px' }} className="form-group" onSubmit={this.submitFormHandler}>
                                        <div class="input-group">
                                            <input style={{ marginTop: '10px' }} id="imagenesGaleria" class="custom-file-input" type="file" accept="image/*" onChange={this.nameHandler} />
                                            <label class="custom-file-label" id="nombre">{this.state.imagenGaleriaNombre}</label>
                                            <button style={{ marginTop: '10px' }} className="btn btn-success" type="submit">Cargar</button>
                                        </div>
                                    </form>
                                </div>

                                <div className="form-group col-sm-12">
                                    <div class="row justify-content-between">
                                        <div class="col-6">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <label htmlFor="validationCustom02" class="form-label">Activo</label>
                                                    <br></br>
                                                    <label class="switch">
                                                        <input type="checkbox" checked={this.state.activo} onChange={this.onChangeActivo} />
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                                <div class="col-md-4">
                                                    <label htmlFor="validationCustom02" class="form-label">Inicio</label>
                                                    <br></br>
                                                    <label class="switch">
                                                        <input type="checkbox" checked={this.state.inicio} onChange={this.onChangeInicio} />
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="form-group col-sm-12 text-right">
                                                <button onClick={this.obtenerProgramas} class="btn btn-info"><i class="fas fa-undo-alt"></i> Regresar</button>
                                                <>&nbsp;</>
                                                <button onClick={this.guardarNoticia} class="btn btn-primary"><i class="fas fa-save"></i> Guardar</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

import React, { Component } from "react";
import NoticiaService from "../../services/noticia.service";
import NoticiaListaItem from "./noticiaListaItem.component";
import CrearNoticia from "./crearNoticia.component";
import ReactDOM from "react-dom";

export default class ListarNoticias extends Component {

    constructor(props) {
        super(props);

        this.obtenerNoticias = this.obtenerNoticias.bind(this);
        this.crearNoticia = this.crearNoticia.bind(this);

        this.state = {
            noticias: this.obtenerNoticias(),
            autorId: this.props.autorId,
            autor: this.props.autor,
            autorImagen: this.props.autorImagen
        }
    }

    crearNoticia() {
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }
        
        ReactDOM.render(<CrearNoticia
            autorId={this.state.autorId}
            autor= {this.state.autor}
            autorImagen = {this.state.autorImagen}
        />, divDash);
    }

    componentDidMount(){
        this.obtenerNoticias();    
    }

    obtenerNoticias() {
        NoticiaService.getAll()
            .then(response => {
                let noticiasArr = [];
                for (let i = 0; i < response.data.length; i++) {
                    noticiasArr.push(<NoticiaListaItem
                        id={response.data[i].id}
                        noticia={response.data[i].noticia}
                        textoPreview={response.data[i].textoPreview}
                        textoNoticia={response.data[i].textoNoticia}
                        estatus={response.data[i].estatus} 
                        imagen={response.data[i].linkImagen} 
                        imagenDos={response.data[i].linkImagenDos} 
                        imagenTres={response.data[i].linkImagenTres} 
                        />
                    );
                }

                this.setState({
                    noticias: noticiasArr
                });

                console.log(this.state.noticias);

            })
            .catch(e => {
                console.log(e);
            });
    }

    render() {
        const { noticias } = this.state;


        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div class="row justify-content-between">
                        <div class="col-6">
                            <h2 className="myaccount-content">Noticias registradas</h2>
                        </div>
                        <div class="col-3-2">
                            <button type="button" onClick={this.crearNoticia} class="btn btn-warning btn-lg">Agregar noticias</button>
                        </div>
                    </div>
                </div>

                <table class="table table-striped table-hover">
                    <thead class="thead-dark">
                        <tr>
                            <th class="text-center" scope="col">Noticia</th>
                            <th class="text-center" scope="col">Vista previa</th>
                            <th class="text-center" scope="col">Estatus</th>
                            <th class="text-center" scope="col"></th>
                            <th class="text-center" scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {noticias}
                    </tbody>
                </table>
            </div>
        )
    }
}
import React, { Component } from "react";
import ReactDOM from "react-dom";
import UsuarioService from "../../services/usuario.service";
import EditarUsuario from "./editarUsuario.component";
import ListaUsuarios from "./usuarioList.component";
import Swal from 'sweetalert2';
import http from "../../http-common.js";

export default class UsuarioListItem extends Component {

    constructor(props) {
        super(props);

        this.editarUsuario = this.editarUsuario.bind(this);
        this.onChangeSesion = this.onChangeSesion.bind(this);

        this.state = {
            id: this.props.id,
            nombre: this.props.nombre,
            paterno: this.props.paterno,
            materno: this.props.materno,
            correo: this.props.correo,
            password: this.props.password,
            sesion: this.props.sesion,
            imagen: this.props.imagen,
            facebook: this.props.facebook,
            instagram: this.props.instagram,
            twitter: this.props.twitter,
            linkedin: this.props.linkedin,
            rol: this.props.rol,
            rolOrigen: this.props.rolOrigen,
            isAdmin: this.props.isAdmin
        }
    }

    componentDidMount() {        
        http.get('/s3url2/'+this.state.imagen).then(response => {document.getElementById('imgPerfil'+this.state.id).src = response.data}).catch(error => {console.log(error);});   
    }

    onChangeSesion = (e) =>{
        let target = e.target;
        let inicioActivo = !this.state.sesion;

        this.setState({ sesion: inicioActivo }, () => {
            UsuarioService.update(this.state.id, this.state)
                .then(response => {
                    
                })
                .catch(e => {
                    console.log(e);
                });
        });
    }


    editarUsuario() {
        let divDash = document.getElementById('contenidoTablero');
        if (divDash.children.length > 0) {
            ReactDOM.unmountComponentAtNode(divDash);
        }
        console.log(this.props.rolOrigen);
        ReactDOM.render(<EditarUsuario 
            idUs={this.props.idUs}
            id={this.state.id}
            nombre={this.state.nombre}
            paterno={this.state.paterno}
            materno={this.state.materno}
            correo={this.state.correo}
            password={this.state.password}
            sesion={this.state.sesion}
            imagen={this.state.imagen}
            facebook={this.state.facebook}
            instagram={this.state.instagram}
            twitter={this.state.twitter}
            linkedin={this.state.linkedin}
            rol={this.state.rol}
            rolOrigen={this.state.rolOrigen}
            isAdmin={this.state.rol == 'Administrador' ? true : false}
        />, divDash
        );
    }

    render() {
        return (
            <tr
                key={this.state.id}>
                <td class="col-md-2 align-middle"><img width="80%" style={{ borderRadius: '50px' }} id={"imgPerfil"+this.props.id}></img></td>
                <td class="col-md-2 align-middle">{this.state.nombre}</td>
                <td class="col-md-2 align-middle">{this.state.paterno}</td>
                <td class="col-md-2 align-middle">{this.state.materno}</td>
                <td class="col-md-2 align-middle">{this.state.correo}</td>
                <td class="col-md-2 align-middle">
                    <div className="custom-control custom-switch">
                        <input type="checkbox" className="custom-control-input" onChange={this.onChangeSesion} id={"customSwitch" + this.state.id} checked={this.state.sesion} />
                        <label className="custom-control-label" htmlFor={"customSwitch" + this.state.id}>{this.state.sesion ? "Activo" : "Inactivo"}</label>
                    </div>
                </td>
                <td class="col-md-1 align-middle"><button class="btn btn-light" onClick={this.editarUsuario}>Editar</button></td>
                <td class="col-md-1 align-middle"><button class="btn btn-danger" onClick={() => {
                    if (this.props.id == this.props.idUs) {
                        Swal.fire({
                            icon: 'warning',
                            text: "No puede eliminar esta cuenta"
                        });
                    } else {
                        if(this.state.rolOrigen != 'Administrador'){
                            Swal.fire({
                                icon: 'warning',
                                text: "No tiene permisos para eliminar"
                            });
                        }
                        else{
                            Swal.fire({
                                text: 'Deseas eliminar este elemento?',
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonText: 'Eliminar',
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    http.delete('/s3url3/'+this.state.imagen);
                                    UsuarioService.delete(this.props.id).then(response => {
                                        let divDash = document.getElementById('contenidoTablero');
                                        Swal.fire('Eliminado', 'Elemento Eliminado Correctamente', 'success')
                                        if (divDash.children.length > 0) {
                                            ReactDOM.unmountComponentAtNode(divDash);
                                        }
                                        ReactDOM.render(<ListaUsuarios idUs={this.props.idUs} />, divDash);
                                    });
                                }
    
                            });
                        }
                    }

                }}><i className="far fa-trash-alt"></i></button></td>
            </tr>
        )
    }
} 
import { Component } from "react";
import {Link} from 'react-router-dom';
import ProyectoService from "../../services/proyecto.service";
import CausasGridItem from '../Causas/causasGridItem.component.js'

export default class InicioGrid extends Component {
    constructor(props) {
        super(props);        

        this.state = {               
            arrayCasosInicio: [],
            arrayCasos: []
        };
    }

    componentDidMount(){        
        this.getGridItems();            
    }  

    getGridItems(){
        let arr = [];        
        ProyectoService.findByInicio().then(response => {   
            if(response.data.length > 0){                               
                for (let i = 0; i < 3; i++) {    
                    if(i<response.data.length){
                        
                        var porcentaje = Math.ceil((response.data[i].dineroActual * 100) / response.data[i].dineroMeta);
                        if(porcentaje > 100){
                            porcentaje = '+'+100;
                        }
                        arr.push(<CausasGridItem titulo={response.data[i].titulo}            
                                                    texto={response.data[i].texto} 
                                                    dineroActual={response.data[i].dineroActual} 
                                                    dineroMeta={response.data[i].dineroMeta} 
                                                    porcentaje={porcentaje+'%'}
                                                    id={response.data[i].id}
                                                    imagenes={response.data[i].imagenes}/>); 
                    }                                             
                }
                this.setState({
                    arrayCasosInicio : arr
                })            
            }
        });    
    }    
    
    render() {        
        return(
            <>
            <div class="section-heading">
                <span class="d-block text-primary display-22 display-md-21 display-lg-20 alt-font wow text-animation fadeInRight">Nuestros proyectos</span>
                <h2>Casos más populares</h2>
            </div>
            <div class="row mt-n1-9">
                {this.state.arrayCasosInicio}  
                {this.state.arrayCasos}                          
                <center><a onClick={this.getMoreGridItems} class="butn secondary getMoreCausasBtn"><Link to={"/causasGrid"}>Ver más proyectos</Link></a></center>
            </div>
        </>
        );
    }




}